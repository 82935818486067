// LoginPage.tsx
import React, { useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import loginValidationSchema from "../validation/loginFormValidation";
import { Toast } from "primereact/toast";
import { UserLogin } from "../components/common/paths/requetsPath";
import JSEncrypt from 'jsencrypt';

interface FormValues {
  userName: string;
  password: string;
}
interface LoginPageProps {
  handleLogin: () => void; // Define the type for handleLogin function
}
const LoginPage: React.FC<LoginPageProps> = (props: any) => {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = React.useState(false);
  const toast = useRef<Toast>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    // Clear localStorage on component mount
    localStorage.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (values: FormValues) => {
    try {
      setSubmitting(true);
      if (formik.isValid) {
        setLoading(true);
        setSubmitting(false);
        await getAllUsers(values);
      }
    } catch (error) {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      userName: "",
      password: "",
    },
    validationSchema: loginValidationSchema,
    onSubmit: handleSubmit,
  });

  const publicKey = `-----BEGIN RSA PUBLIC KEY-----
`+ process.env.REACT_APP_PUBLIC_KEY +`
-----END RSA PUBLIC KEY-----`;

const encryptData = (data:string) => {
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKey);
  const encryptedData = encrypt.encrypt(data);
  return encryptedData;
};

  const getAllUsers = async (values: FormValues) => {
    const encryptedUsername = encryptData(values.userName);
    const encryptedPassword = encryptData(values.password);
    var data = {
      UserName: encryptedUsername,
      Password: encryptedPassword,
    };
    const URL = process.env.REACT_APP_BASE_API_ENDPOINT + UserLogin.url;
    var config = {
      method: "post",
      url: URL,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ values }),
      data: data,
    };

    try {
      const response = await axios(config);

      if (response.status === 200) {
        toast.current?.show({ severity: "success", summary: "Success", detail: "Login successful", life: 3000 });

        setTimeout(() => {
          if (response.data.authToken) {
            setLoading(false);
            localStorage.setItem("user", JSON.stringify(response.data));
            const user = localStorage.getItem("user");
            let menuDetailsResponses: any[] = [];
            if (user) {
              menuDetailsResponses = JSON.parse(user).menuDetailsResponses ?? [];
            }
            if (menuDetailsResponses.length > 0 && menuDetailsResponses[0].p_Url) {
              navigate(menuDetailsResponses[0].p_Url);
              window.location.reload();
            }

          }
          return response.data;
        }, 1000)
      }

    } catch (error: any) {
      setLoading(false);
      if (error.response && error.response.status === 401) {
        toast.current?.show({ severity: "warn", summary: "Warning", detail: "Invalid credentials", life: 3000 });
      }
    } finally {
      setSubmitting(false);
      setLoading(false);
    }
  };

  const onForgetPassword = () => {
    navigate("/forget-password", { replace: true });
  };


  return (
    <div className="Login-page flex align-items-center h-screen">
      {/* Your login page UI */}
      <Toast ref={toast} />
      <div className="flex flex-row grid justify-content-center">
        <div className="login-info col-5">
          <h2>A Utopia of Vedic Living Made a Reality in the 21st Century</h2>
          <p>
            In one land, experience the best of Vedic Civilization, as restored by Maharishi Mahesh Yogi in a scientific manner, and the most modern
            approach of living. Experience Vedic Civilization and ultra-modern technology.
          </p>
          <p>
            All of our greatest thinkers see that the world needs a new paradigm for society to fulfill the challenges of the time – from poverty and
            violence to eco-sustainability. A new paradigm is needed, not based only on economy or on faith, but on scientific technology of
            consciousness applied to society.
          </p>
          <p>
            This is the new paradigm offered through Veda: a scientific technology of consciousness for living the full value of human existence in
            the 21st century.
          </p>
        </div>
        <div className="login-form flex align-items-center justify-content-end col-5">
          <form onSubmit={formik.handleSubmit}>
            <div className="login-card w-70">
              <h5>Login</h5>
              <label htmlFor="userName" className="block">
                User name/ ID <span className="text-red">*</span>
              </label>
              <div className="relative mb-2">
                <InputText
                  id="userName"
                  type="text"
                  placeholder="Enter user name"
                  className="w-full mb-3"
                  value={formik.values.userName}
                  onChange={formik.handleChange}
                />
                {formik.errors.userName && formik.touched.userName && <div className="text-red-500 text-xs">{formik.errors.userName}</div>}
              </div>

              <div className="relative">
                <label htmlFor="password" className="block">
                  Password <span className="text-red">*</span>
                </label>
                <InputText
                  id="password"
                  type="password"
                  placeholder="Enter password"
                  className="w-full mb-3"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
                {formik.errors.password && formik.touched.password && <div className="text-red-500 text-xs">{formik.errors.password}</div>}
              </div>
              <div className="flex align-items-center justify-content-between mb-6">
                <div className="flex align-items-center">
                </div>
                <Button label="Login" className="login-button" onClick={props.handleLogin} type="submit" loading={loading} disabled={submitting} />
              </div>
              <div className="text-center mb-4">
                <a className="text-orange-500 font-14" href="/forget-password" onClick={onForgetPassword}>
                  Forgot  password?
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
