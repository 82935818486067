// LoginPage.tsx
import React, { useContext, useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import LoginServices from "../services/loginServices";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import ChangePasswordValidation from "../validation/ChangePasswordValidation";
import HeaderWithBackButton from "../components/common/HeaderWIthBackButton";
import { contextStore } from "../context/CustomStateProvider";
import { useNavigate } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";

interface FormValues {
    CurrentPassword: string;
    NewPassword: string;
    ConfirmPassword: string;
}

const ChangePassWord = () => {
    const [, setSubmitting] = React.useState(false);
    const toast = useRef<Toast>(null);
    const [successMessage, setSuccessMessage] = useState(false);
    const context = useContext(contextStore);
    const resource = context.state.strings;
    const passwordInput: HTMLInputElement = document.getElementById("NewPassword") as HTMLInputElement;
    const passwordStrength: NodeListOf<HTMLDivElement> = document.querySelectorAll(".password-check > div");
    const [loading, setLoading] = useState(false);
    const [strengthCheck, setStrengthCheck] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState(false);
    const [CurrentPassword, setCurrentPassword] = useState(false);
    const [loadingSpinnerLayout, setLoadingSpinnerLayout] = useState<boolean>(true);
    const [validationMessage, setValidationMessage] = useState('');


    useEffect(() => {
        setTimeout(() => {
            setLoadingSpinnerLayout(false);
        }, 500)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const navigate = useNavigate();
    const handleSubmit = async (values: FormValues) => {
        setLoading(true);
        try {
            setSubmitting(true);
            if (formik.isValid) {
                setSubmitting(false);
                await onSubmit(values);
            }
        } catch (error) {
            setSubmitting(false);
        }
    };

    const formik = useFormik({
        initialValues: {
            CurrentPassword: '',
            NewPassword: '',
            ConfirmPassword: '',
        },
        validationSchema: ChangePasswordValidation,
        onSubmit: handleSubmit,
    });

    const onSubmit = (values: FormValues) => {
   
        setSubmitting(true);
        LoginServices.ChangePasswordService(values).then((res: any) => {
            setLoadingSpinnerLayout(true);
            try {
                if (res.status === 200) {
                    toast.current?.show({ severity: "success", summary: "Success", detail: "Password change successfully", life: 3000 });
                    setSuccessMessage(true);
                    setLoading(false);
                    formik.setFieldValue("CurrentPassword", "");
                    formik.setFieldValue("NewPassword", "");
                    formik.setFieldValue("ConfirmPassword", "");
                    setConfirmPassword(true);
                    setLoadingSpinnerLayout(false);
                    setValidationMessage('');
               
                }

                if (res.response.status === 400 && res.response.data.success === false) {
                    //setCurrentPassword(true);
                    //setValidationMessage(res.response.data.errorMessage)
                    toast.current?.show({
                        severity: "warn",
                        summary: "Warning",
                        detail: res.response.data.errorMessage,
                        life: 3000,
                      });
                    setLoading(false);
                    setLoadingSpinnerLayout(false);
              
                }
                return res.data;
                // Redirect the user to the protected route
            } catch (error: any) {
                setLoadingSpinnerLayout(false);
            }
        });
    };

    function checkPasswordStrength(password: string): number {
        let strength = 0;
        const hasLower = /[a-z]/.test(password);
        const hasUpper = /[A-Z]/.test(password);
        const hasDigit = /\d/.test(password);
        const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>?]/.test(password);
        const length = password.length;

        if (length < 8) {
            return 0; // Too short
        }

        if (hasLower) strength++;
        if (hasUpper) strength++;
        if (hasDigit) strength++;
        if (hasSpecialChar) strength++;

        if (strength >= 3) {
            return 3; // Strong
        } else if (strength >= 2) {
            return 2; // Moderate
        } else {
            return 1; // Weak
        }
    }

    passwordInput?.addEventListener("input", () => {
        const password: string = passwordInput.value;
        const strength: number = checkPasswordStrength(password);

        passwordStrength.forEach((element: HTMLDivElement, index: number) => {
            if (index < strength) {
                element.classList.add("active");
                setStrengthCheck(true);
            } else {
                element.classList.remove("active");
            }
        });
    });

    return (
        <div className="container h-screen">
            {/* Your login page UI */}
            <Toast ref={toast} />

            {loadingSpinnerLayout ?
                <ProgressSpinner style={{ width: '70px', height: '70px', }} strokeWidth="2" fill="var(--surface-ground)" animationDuration=".9s" /> :
                <>
                    <form onSubmit={formik.handleSubmit}>


                        <HeaderWithBackButton title={resource.changePassword} controls={[]} isSearch />
                        <div className="login-form">
                            <><div className="transparant-table">

                                <div className="col-4  update-password-input relative">
                                    <label htmlFor="CurrentPassword" className="block  text-900 font-medium mb-2">
                                        Current Password{" "} <span className="text-red">*</span>
                                    </label>
                                    <InputText id="CurrentPassword"  type="password" placeholder="Enter current password" className="w-full"
                                        value={formik.values.CurrentPassword}
                                        onChange={(e) => {
                                            setCurrentPassword(false);
                                            setValidationMessage('');
                                            formik.handleChange(e);
                                        }} />
                                    {formik.errors.CurrentPassword && formik.touched.CurrentPassword && !CurrentPassword && <div className="error-msg">{formik.errors.CurrentPassword}</div>}
                                    {CurrentPassword && (
                                        <div className="error-msg">{validationMessage}</div>
                                    )}
                                </div>
                                <div className="col-4 update-password-input relative">
                                    <label htmlFor="NewPassword" className="block  text-900 font-medium mb-2">
                                        New Password{" "} <span className="text-red">*</span>
                                    </label>
                                    <InputText id="NewPassword" type="password" placeholder="Enter new Password" className="w-full"
                                        value={formik.values.NewPassword}
                                        onChange={formik.handleChange}
                                    />
                                    {formik.errors.NewPassword && formik.touched.NewPassword && <div className="error-msg">{formik.errors.NewPassword}</div>}

                                </div>
                                <div className={strengthCheck ? "col-4 p-0 pl-2 mt-3 password-check flex" : "col-4 p-0 pl-2  password-check flex"}>
                                    <div className="w-3 mr-3   flex flex-column text-center ">
                                        <div className="Poor  w-full mb-1" ></div>
                                        <div className="password-strength mb-3">Poor</div>
                                    </div>
                                    <div className="w-3 mr-3  flex flex-column text-center">
                                        <div className="Normal  w-full  mb-1" ></div>
                                        <div className="password-strength mb-3">Normal</div>
                                    </div>
                                    <div className="w-3 mr-3  flex flex-column text-center ">
                                        <div className="Strong  w-full  mb-1" ></div>
                                        <div className="password-strength mb-3">Strong</div>
                                    </div>
                                </div>
                                <div className=" col-4 update-password-input  relative">
                                    <label htmlFor="ConfirmPassword" className="block  text-900 font-medium mb-2">
                                        Confirm Password{" "} <span className="text-red">*</span>
                                    </label>
                                    <InputText id="ConfirmPassword" type="password" placeholder="Enter confirm Password" className="w-full"
                                        value={formik.values.ConfirmPassword}
                                        onChange={formik.handleChange} />
                                    {formik.errors.ConfirmPassword && formik.touched.ConfirmPassword && !confirmPassword && <div className="error-msg">{formik.errors.ConfirmPassword}</div>}

                                </div>
                                <div className="col-4 flex p-3 align-items-start justify-content-between ">
                                    <div>
                                        <h6 className="mb-2 font-12">Password Must content</h6>
                                        <ul className="green-tick-add">
                                            <li>Min 8 Characters</li>
                                            <li>Lower- case letter </li>
                                            <li>Upper -case letter</li>
                                            <li>Special Character</li>
                                            <li>Number</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <Button label="Save" className="login-button" type="submit" loading={loading} />
                                    </div>
                                </div>
                           
                            </div>
                            </>

                        </div>

                    </form>
                </>}
        </div>
    );
};

export default ChangePassWord;
