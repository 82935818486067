import { useState, useRef, useEffect } from "react";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import attributeServices from "../../services/attributeServices";
import fileUpload from "../../services/fileUpload";
import poiServices from "../../services/poiServices";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { Image } from "primereact/image";
import { ProgressBar } from 'primereact/progressbar';


const GalleryLayout = (props: any) => {
  const toast = useRef<Toast>(null);

  const [attribute, setAttribute] = useState<any>(null);
  const [imageFileName, setImageFileName] = useState<any>();
  const [, setImageSelectedFile] = useState<File | null>(null);
  const [, setImageUploadedFileUrl] = useState<any>();
  const [loadingSpinnerLayout, setLoadingSpinnerLayout] = useState<boolean>(true);
  const [progressForImage, setProgressForImage] = useState<number>(0); // To track progress




  useEffect(() => {
    setTimeout(() => {
      setLoadingSpinnerLayout(false);
    }, 400)
  }, [])

  const AttributeDetails = () => {

    let data = {
      PoiId: props.poiId,
      AttributeId: props.attributeId,
    };
    attributeServices.GetAttributeDetails(data).then((res: any) => {
      try {
        if (res.status === 200) {
          setAttribute(res.data);
          setTimeout(() => {
          }, 400);
          setImageFileName("");

        }
        return true;
        // Redirect the user to the protected route
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: "Unauthorized",
            life: 3000,
          });
        }
      }
    });
  };

  useEffect(() => {
    AttributeDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onPoiClick]);



  const onImageFileSelect = (event: any, attributeDetailId: any) => {
    if (!event || !event.files || event.files.length === 0) {

      // Optionally, you can show a message to the user indicating that no file was selected
      toast.current?.show({
        severity: "warn",
        summary: "Please select a file.",
        life: 3000,
      });
      return;
    }
    const file: File = event.files[0];
    if (file) {
      const reader = new FileReader();
      if ((!file.type.startsWith("image/png")) && (!file.type.startsWith("image/jpeg")) && (!file.type.startsWith("image/jpg"))) {
        props.toast.current?.show({
          severity: "warn",
          summary: "Warning",
          detail: "Please select an image file only.",
          life: 3000,
        });
        return;
      } 
      // else if (file.size > 5000000) {
      //   props.toast.current?.show({
      //     severity: "warn",
      //     summary: "Warning",
      //     detail: "Selected file exceeds the 5MB limit.",
      //     life: 3000,
      //   });
      //   return;
      // }
      reader.onloadend = () => {
        setImageFileName(file.name);
        if (file !== null) {
          const data = {
            PoiId: props.poiId as number,
            AttributeId: props.attributeId as number,
            AttributeDetailId: attributeDetailId as number,
            File: file,
          };

          UploadImageFile(data);
        }
      };

      reader.readAsDataURL(file);
    } else {
      props.toast.current?.show({
        severity: "warn",
        summary: "Warning",
        detail: "Something went wrong.",
        life: 3000,
      });
      return;
    }
  };
  const UploadImageFile = (data: any) => {
    fileUpload.GallreyImageUpload(data, (progress: number) => {
      setProgressForImage(progress); 
  }).then((res: any) => {
      try {
        if (res.status === 200) {
          props.toast.current?.show({ severity: "success", summary: "Success", detail: "Image uploaded successfully", life: 3000 });
          AttributeDetails();
        }
        setProgressForImage(100); // Set to 100 when done
        setTimeout(() => setProgressForImage(0), 2000);
        return res.data;
        // Redirect the user to the protected route
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: "Unauthorized",
            life: 3000,
          });
        }
      }
    });
  };
  const onDeleteImage = (values: any) => {
    confirmDialog({
      message: `Are you sure you want to delete this image?`,
      header: "Confirm delete media",
      icon: "pi pi-exclamation-triangle",
      accept: () => onDeleteFiles(values), // Pass a function to be executed on confirmation
    });
  };

  const onDeleteFiles = (values: any) => {
    const data = {
      poimediaid: values,
    };
    poiServices.DeleteFile(data).then((res: any) => {
      try {
        if (res.status === 200) {
          props.toast.current?.show({
            severity: "success",
            summary: "Success",
            detail: "Media deleted successfully",
            life: 3000,
          });
          AttributeDetails();
        }
        return res.data;
        // Redirect the user to the protected route
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
        }
      }
    });
  };

  return (
    <div className="scrollable-content-tab">
      {loadingSpinnerLayout ? (
        <ProgressSpinner style={{ width: "70px", height: "70px" }} strokeWidth="2" fill="var(--surface-ground)" animationDuration=".9s" />
      ) : (
        <>
          {attribute?.map((items: any, index: any) => {
            return (
              <>
                {/* Render the form fields */}
                <div key={index}>
                  <div className="flex col-12">
                    <div className="flex  w-full align-items-start">
                      <label htmlFor={items.p_attributedetailid} className="mt-2">
                        {items.p_attributedetailname}
                      </label>

                      {items.p_attributedetailid === 17 && (
                        <>
                          <div >


                            <div className="flex flex-column">
                              <div className="flex">
                                <InputText
                                  id={items.p_attributedetailid}
                                  type="text"
                                  className="fileinput"
                                  name="images"
                                  placeholder="Choose Image"
                                  value={imageFileName}
                                />
                                <FileUpload
                                  mode="basic"
                                  name={items.p_attributedetailname}
                                  accept="image/*"
                                  // maxFileSize={1000000}
                                  auto
                                  chooseLabel="Select"
                                  className=" flex justify-content-center  fileselectbtn"
                                  onSelect={(e) => onImageFileSelect(e, items.p_attributedetailid)}
                                />
                                {progressForImage > 0 && (
                              <div className="ml-3" style={{ width: "150px" }}>
                                <ProgressBar
                                  value={progressForImage}
                                  showValue={true}
                                  className="custom-progress-bar" 
                                />
                              </div>
                              )}

                              </div>

                              <small id="file-size" className="mt-1 ml-6">File type JPG, PNG,JPEG</small>
                            </div>

                            <div className="image-layout ml-5">
                              {attribute
                                .filter((item: any) => item.p_attributedetailid === 17)
                                .map((items: any) => {
                                  const images = JSON.parse(items.p_file);

                                  return (
                                    <div key={index} className="flex">
                                      {images.map((image: any, imageIndex: any) => (
                                        <div key={imageIndex} className="image-wrapper ml-3">

                                          <>
                                            <Button
                                              label=""
                                              icon="pi pi-trash"
                                              className="icon-delete ml-2"
                                              onClick={() => onDeleteImage(image.poimediaid)}
                                            />
                                            <Image className="image-style" src={image.mediaurl} alt="images" />
                                          </>
                                        </div>
                                      ))}
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            );
          })}
          {/* <div className="cancel-submit-btn  w-full justify-content-end mt-6">
            <Button className="cancel-btn " label={resource.cancel} type="reset" />
            <Button className="submit-btn ml-2 " label={resource.submit} type="submit" loading={loading} />
          </div> */}
        </>
      )}
    </div>
  );
};

export default GalleryLayout;
