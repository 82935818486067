import React, { createContext, useReducer } from "react";
import LocalizedStrings from "react-localization";
import enStrings from "../res/strings/english";

const initialState: { state: { strings: any }; contextDispatch: any } = {
  state: {
    strings: new LocalizedStrings({ en: enStrings }, { logsEnabled: false }),
  },
  contextDispatch: () => {},
};

const contextStore = createContext(initialState);
const { Provider } = contextStore;
const CustomStateProvider: React.FC = ({ children }: any) => {
  const setLocalizedStrings = (state: any) => {
    const newStrings = {
      en: enStrings,
    };

    const strings = new LocalizedStrings(newStrings, { logsEnabled: false });
    return {
      ...state,
      strings: strings,
    };
  };

  const [state, contextDispatch] = useReducer((state: any, action: { type: any }) => {
    switch (action.type) {
      case "setLanguage":
        return setLocalizedStrings(state);
      default:
        throw state;
    }
  }, initialState);

  return <Provider value={{ state, contextDispatch }}>{children}</Provider>;
};

export { contextStore, CustomStateProvider };
