import { Dialog } from "primereact/dialog";
import { useContext, useEffect, useState, useRef } from "react";
import { contextStore } from "../../context/CustomStateProvider";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { useFormik } from "formik";
import poiServices from "../../services/poiServices";
import AddPoiValidation from "../../validation/AddPoiValidation";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { Image } from "primereact/image";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputSwitch } from "primereact/inputswitch";

interface Area {
  name: string;
}
interface FormValue {
  poiid: any;
  poiname: string;
  code: string;
  capacity: any;
  description: string;
  areaid: string;
  subareaid: string;
  categoryid: string;
  iconurl: string;
  imageurl: string;
  latitude: string;
  longitude: string;
  status: boolean;
  sequence: any;
}

interface AreaResponse {
  p_areaid: number;
  p_areaname: string;
  p_code: string;
  p_isactive: boolean;
  p_createddatetime: string;
  p_createdbyid: number;
  p_createdbyname: string | null;
}

interface Area {
  name: string;
  areaid: string;
}

interface SubAreaResponse {
  p_subareaid: number;
  p_subareaname: string;
  p_areaid: number;
  p_areaname: string;
  p_isactive: boolean;
  p_createddatetime: string;
  p_createdbyid: number;
  p_createdbyname: string | null;
}
interface SubArea {
  name: string;
  areaid: string;
}

interface Category {
  id: string;
  name: string;
}


const AddPoiPopup = (props: any) => {
  const context = useContext(contextStore);
  const resource = context.state.strings;
  const [submitting] = useState(false);

  const [selectedArea, setselectedArea] = useState<any>(null);
  const [selectedSubArea, setselectedSubArea] = useState<any>(null);
  const [selectedCategoty, setselectedCategory] = useState<any>(null);
  const [area, setArea] = useState<Area[]>([]);
  const [subArea, setSubArea] = useState<SubArea[]>([]);
  const [category, setCategory] = useState<Category[]>([]);
  const [editPoiData, setEditPoiData] = useState<any>(null);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [imageName, setImageName] = useState("");
  const [iconName, setIconName] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [iconURL, setIconURL] = useState("");
  const [selectedIcon, setSelectedIcon] = useState<File | null>(null);
  const [loadingImageSpinner, setLoadingImageSpinner] = useState<boolean>(false);
  const [loadingIconSpinner, setLoadingIconSpinner] = useState<boolean>(false);
  const [loadingSpinner, setLoadingSpinner] = useState<boolean>(false);

  const initialValues = {
    poiid: null,
    poiname: "",
    code: "",
    capacity: "",
    description: "",
    areaid: "",
    subareaid: "",
    categoryid: "",
    iconurl: "",
    imageurl: "",
    latitude: "",
    longitude: "",
    status: true,
    sequence:""
  };

  useEffect(() => {
    if (props.addPoiPopup === true) {
      var data = {
        id: null,
      };
      poiServices.getActiveArea(data).then((res: any) => {
        try {
          const formattedData: Area[] = res.data.map((item: AreaResponse) => ({
            name: item.p_areaname,
            areaid: item.p_areaid.toString(),
          }));
          setArea(formattedData);
          if (props.editMode) {
            formattedData.forEach((area: any) => {
              if (parseInt(area.areaid) === parseInt(props.editPoiData.p_areaid)) {
                formik.setFieldValue("areaid", area.areaid);
                setselectedArea(area);
              }
            });
          }
        } catch (error: any) {
          if (error.response && error.response.status === 401) {
            props.toast.current?.show({
              severity: "warn",
              summary: "Warning",
              detail: "Unauthorized",
              life: 3000,
            });
            window.location.pathname = "/";
          }
        }
      });
      
      getCategory();
      if (props.editMode) {
        setLoadingImageSpinner(true);
        setLoadingIconSpinner(true);
        var POIdata = {
          poiid: props.editPoiData.p_poiid,
          imageUrl: props.editPoiData.p_imageurl,
          iconUrl: props.editPoiData.p_iconurl,
        };
        poiServices.getPresignedURL(POIdata).then((res: any) => {
          try {
            setImageURL(res.data.imageFile);
            if (res.data.imageFile === null || res.data.imageFile === "") {
              setLoadingImageSpinner(false);
            }
            setIconURL(res.data.iconFile);
            if (res.data.iconFile === null || res.data.iconFile === "") {
              setLoadingIconSpinner(false);
            }
          } catch (error: any) {
            setLoadingImageSpinner(false);
            setLoadingIconSpinner(false);
            if (error.response && error.response.status === 401) {
              props.toast.current?.show({
                severity: "warn",
                summary: "Warning",
                detail: "Unauthorized",
                life: 3000,
              });
              window.location.pathname = "/";
            }
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.addPoiPopup]);

  useEffect(() => {
    if (selectedArea !== null) {
      var data = {
        Id: null,
        AreaId: parseInt(formik.values.areaid),
      };
      poiServices.getActiveSubArea(data).then((res: any) => {
        try {
          const formattedData: SubArea[] = res.data.map((item: SubAreaResponse) => ({
            name: item.p_subareaname,
            subAreaId: item.p_subareaid.toString(),
          }));
          setSubArea(formattedData);
          if (props.editMode) {
            formattedData.forEach((area: any) => {
              if (parseInt(area.subAreaId) === parseInt(props.editPoiData.p_subareaid)) {
                formik.setFieldValue("subareaid", area.subAreaId);
                setselectedSubArea(area);
              }
            });
          }
        } catch (error: any) {
          if (error.response && error.response.status === 401) {
            props.toast.current?.show({
              severity: "warn",
              summary: "Warning",
              detail: "Unauthorized",
              life: 3000,
            });
            window.location.pathname = "/";
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedArea]);

  const getCategory = () =>
  {
    formik.setFieldValue("categoryid", 0);
    setselectedCategory(null);
    poiServices.getCategory().then((res: any) => {
      try {
        const formattedData: Category[] = res.data.map((item: Category) => ({
          id: item.id,
          name: item.name,
        }));
        setCategory(formattedData);
        if (props.editMode) {
          
          formattedData.forEach((category: any) => {
            if (parseInt(category.id) === parseInt(props.editPoiData.p_categoryid)) {
              formik.setFieldValue("categoryid", category.id);
              setselectedCategory(category);
            }
          });
        }
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: "Unauthorized",
            life: 3000,
          });
          window.location.pathname = "/";
        }
      }
    });
  }

  
 

  useEffect(() => {
    setEditPoiData(props.editPoiData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editPoiData]);

  const onImageSelect = (event: any) => {
    const file = event.files[0];
    if (file) {
      const reader = new FileReader();
      if ((!file.type.startsWith("image/png")) && (!file.type.startsWith("image/jpeg")) && (!file.type.startsWith("image/jpg"))) {
        props.toast.current?.show({
          severity: "warn",
          summary: "Warning",
          detail: "Please select an image file only",
          life: 3000,
        });
        return;
      } 
      // else if (file.size > 5000000) {
      //   props.toast.current?.show({
      //     severity: "warn",
      //     summary: "Warning",
      //     detail: "Selected file exceeds the 5MB limit.",
      //     life: 3000,
      //   });
      //   return;
      // }

      reader.onloadend = () => {
        setSelectedImage(file);
        setImageName(file.name);
      };

      reader.readAsDataURL(file);
    } else {
      props.toast.current?.show({
        severity: "warn",
        summary: "Warning",
        detail: "Something went wrong.",
        life: 3000,
      });
      return;
    }
  };

  useEffect(() => {
    if (selectedImage !== null) {
      setImageURL(URL.createObjectURL(selectedImage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedImage]);

  const onIconSelect = (event: any) => {
    const file = event.files[0];
    if (file) {
      const reader = new FileReader();
      if ((!file.type.startsWith("image/png")) && (!file.type.startsWith("image/jpeg")) && (!file.type.startsWith("image/jpg"))) {

        props.toast.current?.show({
          severity: "warn",
          summary: "Warning",
          detail: "Please select an image file only",
          life: 3000,
        });
        return;
      } 
      // else if (file.size > 5000000) {
      //   props.toast.current?.show({
      //     severity: "warn",
      //     summary: "Warning",
      //     detail: "Selected file exceeds the 5MB limit.",
      //     life: 3000,
      //   });
      //   return;
      // }

      reader.onloadend = () => {
        setSelectedIcon(file);
        setIconName(file.name);
      };

      reader.readAsDataURL(file);
    } else {
      props.toast.current?.show({
        severity: "warn",
        summary: "Warning",
        detail: "Something went wrong.",
        life: 3000,
      });
      return;
    }
  };

  useEffect(() => {
    if (selectedIcon !== null) {
      setIconURL(URL.createObjectURL(selectedIcon));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIcon]);

  useEffect(() => {
    if (editPoiData) {
      OnEditData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editPoiData]);

  const OnEditData = () => {
    if (editPoiData) {
      formik.setValues({
        poiid: editPoiData.p_poiid ? editPoiData.p_poiid : null,
        poiname: editPoiData.p_poiname,
        code: editPoiData.p_code,
        capacity: editPoiData.p_capacity,
        description: editPoiData.p_description,
        areaid: editPoiData.p_areaid,
        subareaid: editPoiData.p_subareaid,
        categoryid: editPoiData.p_categoryid,
        iconurl: editPoiData.p_iconurl,
        imageurl: editPoiData.p_imageurl,
        latitude: editPoiData.p_latitude,
        longitude: editPoiData.p_longitude,
        status: editPoiData.p_isactive,
        sequence:editPoiData.p_sequence?editPoiData.p_sequence:0,
      });

      if (editPoiData.p_imageurl !== null && editPoiData.p_imageurl !== "") {
        setImageName(editPoiData.p_imageurl.split("/").pop());
      }

      if (editPoiData.p_iconurl !== null && editPoiData.p_iconurl !== "") {
        setIconName(editPoiData.p_iconurl.split("/").pop());
      }
    }
  };

  const handleSubmit = async (values: FormValue) => {
    try {
      if (formik.isValid) {
        if (props.editMode) {
          await EditPOI(values);
        } else {
          await AddPOI(values);
        }
      }
    } catch (error) {
      console.error("Failed to add employee:", error);
    }
  };

  const AddPOI = async (values: FormValue) => {
    setLoadingSpinner(true);
    var data = {
      poiname: values.poiname,
      description: values.description,
      code: values.code,
      capacity: values.capacity,
      imageurl: values.imageurl,
      iconurl: values.iconurl,
      latitude: values.latitude,
      longitude: values.longitude,
      areaid: values.areaid,
      categoryid: values.categoryid,
      subareaid: values.subareaid,
      sequence:values.sequence?values.sequence:0
    };

    poiServices.AddPOI(data, selectedImage, selectedIcon).then((res: any) => {
      try {
        if (res.status === 200) {
          if (res.data.response) {
            setLoadingSpinner(false);
            props.toast.current?.show({ severity: "success", summary: "Success", detail: "POI created successfully", life: 3000 });
            props.setReloadPOIGrid(!props.reloadPOIGrid);
            formik.resetForm();
            setselectedArea(null);
            setselectedSubArea(null);
            props.setAddPoiPopup(false);
            props.setEditPoiData(null);
            setImageName("");
            setIconName("");
            setSelectedImage(null);
            setSelectedIcon(null);
            setIconURL("");
            setImageURL("");
            setSubArea([]);
          } else {
            props.toast.current?.show({ severity: "warn", summary: "Warning", detail: res.data.msg, life: 3000 });
            setLoadingSpinner(false);
          }
        }
        return res.data;
        // Redirect the user to the protected route
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          props.toast.current?.show({ severity: "warn", summary: "Warning", detail: "Error while creating POI", life: 3000 });
          setLoadingSpinner(false);
          formik.resetForm();
          setselectedArea(null);
          setselectedSubArea(null);
          props.setAddPoiPopup(false);
          setImageName("");
          setIconName("");
          setSelectedImage(null);
          setSelectedIcon(null);
          setIconURL("");
          setImageURL("");
          setSubArea([]);
        }
      }
    });
  };

  const EditPOI = async (values: FormValue) => {
    setLoadingSpinner(true);
    var data = {
      poiid: values.poiid ? values.poiid : null,
      poiname: values.poiname,
      description: values.description,
      code: values.code,
      capacity: values.capacity,
      imageurl: values.imageurl,
      iconurl: values.iconurl,
      latitude: values.latitude,
      longitude: values.longitude,
      areaid: values.areaid,
      subareaid: values.subareaid,
      categoryid: values.categoryid,
      isactive: values.status,
      sequence:values.sequence?values.sequence:0
    };

    poiServices.EditPOI(data, selectedImage, selectedIcon).then((res: any) => {
      try {
        if (res.status === 200) {
          if (res.data.response) {
            setLoadingSpinner(false);
            props.toast.current?.show({ severity: "success", summary: "Success", detail: "POI updated successfully", life: 3000 });
            props.setReloadPOIGrid(!props.reloadPOIGrid);
            formik.resetForm();
            setselectedArea(null);
            setselectedSubArea(null);
            props.setAddPoiPopup(false);
            props.setEditPoiData(null);
            props.setEditMode(false);
            setIconURL("");
            setImageURL("");
            setImageName("");
            setSubArea([]);
          } else {
            props.toast.current?.show({ severity: "warn", summary: "Warning", detail: res.data.msg, life: 3000 });
            setLoadingSpinner(false);
          }
        }
        return res.data;
        // Redirect the user to the protected route
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          props.toast.current?.show({ severity: "warn", summary: "Warning", detail: "Error while creating POI", life: 3000 });
          setLoadingSpinner(false);
          formik.resetForm();
          setselectedArea(null);
          setselectedSubArea(null);
          props.setAddPoiPopup(false);
          props.setEditMode(false);
          setIconURL("");
          setImageURL("");
          setImageName("");
          setSubArea([]);
        }
      }
    });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: AddPoiValidation,
    onSubmit: handleSubmit,
  });

  const onCancelClick = () => {
    formik.resetForm();
    props.setEditMode(false);
    props.setEditPoiData(null);
    setselectedArea(null);
    setselectedSubArea(null);
    setImageName("");
    setIconName("");
    setSelectedImage(null);
    setSelectedIcon(null);
    setIconURL("");
    setImageURL("");
    props.setAddPoiPopup(false);
    setSubArea([]);
  };

  const handleRemoveImage = () => {
    formik.setFieldValue("imageurl", "");
    setSelectedImage(null);
    setImageURL("");
    setImageName("");
  };

  const handleRemoveIcon = () => {
    formik.setFieldValue("iconurl", "");
    setIconURL("");
    setSelectedIcon(null);
    setIconName("");
  };

  const handleImageLoad = () => {
    setLoadingImageSpinner(false);
  };

  const handleIconLoad = () => {
    setLoadingIconSpinner(false);
  };

  const handleCapacityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (/^[0-9]*$/.test(value)) {
      // Only allow positive numbers
      formik.setFieldValue("capacity", value === "" ? "" : parseInt(value, 10));
    }
  };
  
  const handleSequenceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (/^[0-9]*$/.test(value)) {
      // Only allow positive numbers
      formik.setFieldValue("sequence", value === "" ? "" : parseInt(value, 10));
    }
  };

   
  const header = (
    <div className="p-d-flex p-jc-between p-ai-center">
      <span>{resource.poiDetails}</span>
      <Button
        icon="pi pi-times"
        className="p-button-rounded p-button-text popup-close-button"
        onClick={() => {
          props.setAddPoiPopup(false);
          onCancelClick();
        }}
      />
    </div>
  );

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (props.addPoiPopup) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 0);
    }
  }, [props.addPoiPopup]);

  return (
    <div>
      <Dialog
        className="popup popup-card-layout"
        header={header}
        draggable={false}
        visible={props.addPoiPopup}
        style={{ width: "70vw" }}
        onHide={() => {
          props.setAddPoiPopup(false);
          onCancelClick();
        }}
      >
        {loadingSpinner && (
          <div className="poi-spinner-container">
            <ProgressSpinner
              style={{ width: "50px", height: "50px", fillOpacity: 0.1, background: "transparent" }}
              strokeWidth="2"
              fill="var(--surface-ground)"
              animationDuration=".9s"
            />
          </div>
        )}
        <form onSubmit={formik.handleSubmit}>
          <div className="">
            <div className="card-wrapper">
              <div className="flex mb-2">
                <div className="col-4 relative">
                  <label htmlFor="poiName" className="block text-900 font-medium mb-2">
                    {resource.poiName} <span className="text-red">*</span>
                  </label>
                  <InputText
                    id="poiname"
                    type="text"
                    placeholder="Enter POI name"
                    className="w-full mb-1"
                    value={formik.values.poiname}
                    onChange={formik.handleChange}
                    tabIndex={1}
                    ref={inputRef}
                    />
                  {formik.errors.poiname && formik.touched.poiname && <div className="text-red-500 text-xs">{formik.errors.poiname}</div>}
                </div>

                <div className="col-4 relative">
                  <label htmlFor="poiCode" className="block text-900 font-medium mb-2">
                    {resource.poiCode} <span className="text-red">*</span>
                  </label>
                  <InputText
                    id="code"
                    type="text"
                    placeholder="Enter POI code"
                    className="w-full mb-1"
                    value={formik.values.code}
                    onChange={formik.handleChange}
                    tabIndex={2}
                  />
                  {formik.errors.code && formik.touched.code && <div className="text-red-500 text-xs">{formik.errors.code}</div>}
                </div>

                <div className="col-4 relative">
                  <label htmlFor="masterCapacity" className="block text-900 font-medium mb-2">
                    {resource.MasterCapacity} <span className="text-red">*</span>
                  </label>

                  <InputText
                    id="capacity"
                    type="text"
                    placeholder="Enter master capacity"
                    className="w-full mb-1"
                    value={formik.values.capacity}
                    onChange={handleCapacityChange}
                    tabIndex={3}
                  />

                  {formik.errors.capacity && formik.touched.capacity && <div className="text-red-500 text-xs">{formik.errors.capacity}</div>}
                </div>
              </div>
              <div className="flex mb-2">
                <div className="col-12 relative poi-description">
                  <label htmlFor="description" className="block text-900 font-medium mb-2">
                    {resource.description}
                  </label>
                  <InputTextarea
                    id="description"
                    rows={2}
                    placeholder="Enter description"
                    className="w-full "
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    tabIndex={4}
                  />
                {formik.errors.description && formik.touched.description && <div className="text-area-red-bottom text-xs">{formik.errors.description}</div>}
  
                </div>
              </div>
            </div>

            <div className="card-wrapper">
              <div className="flex mb-2">
                <div className="col-4 relative">
                  <label htmlFor="AreaName" className="block text-900 font-medium mb-2">
                    {resource.area} <span className="text-red">*</span>
                  </label>
                  <Dropdown
                    value={selectedArea}
                    onChange={(e: DropdownChangeEvent) => {
                      formik.setFieldValue("areaid", e.value.areaid);
                      setselectedArea(e.value);
                      setselectedSubArea(null);
                      formik.setFieldValue("subareaid", null);
                    }}
                    options={area}
                    optionLabel="name"
                    placeholder="Please select area"
                    className="w-full mb-2"
                    tabIndex={5}
                    filter
                    filterBy="name"
                  />
                  {formik.errors.areaid && formik.touched.areaid && <div className="text-red-500 text-xs">{formik.errors.areaid}</div>}
                </div>

                <div className="col-4 relative">
                  <label htmlFor="subAreaName" className="block text-900 font-medium mb-2">
                    {resource.subAreaName} <span className="text-red">*</span>
                  </label>

                  <Dropdown
                    value={selectedSubArea}
                    onChange={(e: DropdownChangeEvent) => {
                      formik.setFieldValue("subareaid", e.value.subAreaId);
                      setselectedSubArea(e.value);
                    }}
                    options={subArea}
                    optionLabel="name"
                    placeholder="Please select subarea"
                    className="w-full mb-2"
                    tabIndex={6}
                    filter
                    filterBy="name"
                  />
                  {formik.errors.subareaid && formik.touched.subareaid && <div className="text-red-500 text-xs">{formik.errors.subareaid}</div>}
                </div>

                <div className="col-4 relative">
                  <label htmlFor="Category" className="block text-900 font-medium mb-2">
                    {resource.categoryName} <span className="text-red">*</span>
                  </label>

                  <Dropdown
                    value={selectedCategoty}
                    onChange={(e: DropdownChangeEvent) => {
                      formik.setFieldValue("categoryid", e.value.id);
                      setselectedCategory(e.value);
                    }}
                    options={category}
                    optionLabel="name"
                    placeholder="Please select category"
                    className="w-full mb-2"
                    tabIndex={6}
                    filter
                    filterBy="name"
                  />
                  {formik.errors.categoryid  && formik.touched.categoryid && <div className="text-red-500 text-xs">{formik.errors.categoryid}</div>}
                </div>

                
              </div>

              <div className="flex mb-2">
                <div className="col-4 relative">
                  <label htmlFor="uploadImage" className="block text-900 font-medium mb-2">
                    {resource.uploadImage}
                  </label>
                  <div className="p-inputgroup  flex-2">
                    <InputText id="other_doc" type="text" className="" placeholder="Please select image to upload" value={imageName} tabIndex={8} />
                    <FileUpload
                      mode="basic"
                      name="demo[]"
                      accept="image/*"
                      // maxFileSize={50000000}
                      auto
                      chooseLabel="Select"
                      className=" flex justify-content-center  file-upload fileselectbtn"
                      onSelect={onImageSelect}
                    />
                  </div>
                  <div>
                    {imageURL && (
                      <div className="card flex justify-content-center file-upload-spinner">
                        <Image
                          src={imageURL ? imageURL : ""}
                          zoomSrc={imageURL ? imageURL : ""}
                          alt="Image"
                          width="60"
                          height="60"
                          preview
                          onLoad={handleImageLoad}
                        />
                        {loadingImageSpinner && (
                          <ProgressSpinner
                            style={{ width: "70px", height: "70px" }}
                            strokeWidth="2"
                            fill="var(--surface-ground)"
                            animationDuration=".5s"
                          />
                        )}
                        <Button
                          style={{ color: "black", backgroundColor: "white", borderColor: "red" }}
                          label=""
                          icon="pi pi-trash"
                          className="submit-btn ml-2"
                          onClick={handleRemoveImage}
                        />
                      </div>
                    )}
                  </div>
                  <small id="file-size">File type JPG, PNG,JPEG</small>
                </div>

                <div className="col-4 relative">
                  <label htmlFor="uploadIcon" className="block text-900 font-medium mb-2">
                    {resource.uploadIcon}
                  </label>

                  <div className="p-inputgroup  flex-2">
                    <InputText id="other_doc" type="text" className="" placeholder="Please select icon to upload" value={iconName} tabIndex={9} />
                    <FileUpload
                      mode="basic"
                      name="demo[]"
                      accept="image/*"
                      //maxFileSize={50000000}
                      auto
                      chooseLabel="Select"
                      className=" flex justify-content-center file-upload fileselectbtn"
                      onSelect={onIconSelect}
                    />
                  </div>
                  {iconURL && (
                    <div className="card flex justify-content-center file-upload-spinner">
                      <Image
                        src={iconURL ? iconURL : ""}
                        zoomSrc={iconURL ? iconURL : ""}
                        alt="Image"
                        width="60"
                        height="60"
                        preview
                        onLoad={handleIconLoad}
                      />
                      {loadingIconSpinner && (
                        <ProgressSpinner
                          style={{ width: "70px", height: "70px" }}
                          strokeWidth="2"
                          fill="var(--surface-ground)"
                          animationDuration=".5s"
                        />
                      )}
                      <Button
                        style={{ color: "black", backgroundColor: "white", borderColor: "red" }}
                        label=""
                        icon="pi pi-trash"
                        className="submit-btn ml-2"
                        onClick={handleRemoveIcon}
                      />
                    </div>
                  )}
                  <small id="file-size">File type JPG, PNG,JPEG</small>
                </div>
                <div className="col-4 relative ">
                  <label className="block text-900 font-medium mb-2">{resource.status}</label>
                  <div className="custom-inputswitch">
                    <InputSwitch
                      id="IsActive"
                      checked={formik.values.status}
                      onChange={(e) => formik.setFieldValue("status", e.target.value)}
                      tabIndex={7}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="card-wrapper">
              <div className="flex mb-2">
                <div className="col-4 relative">
                  <label htmlFor="lat" className="block text-900 font-medium mb-2">
                    {resource.lat} <span className="text-red">*</span>
                  </label>
                  <InputText
                    id="latitude"
                    type="text"
                    placeholder="Enter latitude"
                    className="w-full mb-1"
                    value={formik.values.latitude}
                    onChange={formik.handleChange}
                    tabIndex={10}
                  />
                  {formik.errors.latitude && formik.touched.latitude && <div className="text-red-500 text-xs">{formik.errors.latitude}</div>}
                </div>
                <div className="col-4 relative">
                  <label htmlFor="long" className="block text-900 font-medium mb-2">
                    {resource.long} <span className="text-red">*</span>
                  </label>
                  <InputText
                    id="longitude"
                    type="text"
                    placeholder="Enter longitude"
                    className="w-full mb-1"
                    value={formik.values.longitude}
                    onChange={formik.handleChange}
                    tabIndex={11}
                  />
                  {formik.errors.longitude && formik.touched.longitude && <div className="text-red-500 text-xs">{formik.errors.longitude}</div>}
                </div>
                <div className="col-4 relative">
                  <label htmlFor="sequence" className="block text-900 font-medium mb-2">
                    {resource.sequence}
                  </label>

                  <InputText
                    id="sequence"
                    type="text"
                    placeholder="Enter sequence"
                    className="w-full mb-1"
                    value={formik.values.sequence}
                    onChange={handleSequenceChange}
                    tabIndex={12}
                  />
                </div>
              </div>
            </div>
            <div className="cancel-submit-btn mt-8">
              <Button label="Cancel" className="cancel-btn " onClick={onCancelClick} type="reset" tabIndex={12} />
              <Button label="Submit" className="submit-btn ml-2" type="submit" disabled={submitting} tabIndex={13} loading={loadingSpinner} />
            </div>
          </div>
        </form>
      </Dialog>
    </div>
  );
};

export default AddPoiPopup;
