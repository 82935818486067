import { useContext, useEffect, useRef, useState } from "react";
import HeaderWithBackButton from "../common/HeaderWIthBackButton";
import { contextStore } from "../../context/CustomStateProvider";
import AddTherapyPopup from "./AddTherapyPopup";
import { Toast } from "primereact/toast";
import TherapyTable from "./TherapyTable";
import { ConfirmDialog } from "primereact/confirmdialog";
import { ProgressSpinner } from "primereact/progressspinner";

const TherapyLayout = (props: any) => {
  const context = useContext(contextStore);
  const resource = context.state.strings;
  const [addTherapyChange, setAddTherapyChange] = useState(false);
  const [backButton, setBackButton] = useState(false);
  const [addNewTherapyPopup, setaddNewTherapyPopup] = useState(false);
  const [isEditTherapy, setIsEditTherapy] = useState();
  const [editMode, setEditMode] = useState(false);
  const toast = useRef<Toast>(null);
  const [reloadTherapyGrid, setReloadTherapyGrid] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [therapyDataFilter, setTherapyDataFilter] = useState([]);
  const [loadingSpinnerLayout, setLoadingSpinnerLayout] = useState<boolean>(true);

  const cardControls = [
    {
      ctrlType: "button",
      name: "btnAdd",
      title: resource.addNewTherapy,
      type: "button",
      disabled: false,
      className: "green-btn mr-2",
    },
  ];

  const onButtonClick = (e: string) => {
    if (e === "btnAdd") {
      setAddTherapyChange(true);
      setaddNewTherapyPopup(true);
      setEditMode(false);
    } else if (e === "btnAddSubArea") {
      setAddTherapyChange(true);
      setEditMode(false);
    }
  };
  const onBackButtonClick = () => {
    setBackButton(!backButton);
  };

  const onEditTherapyClicked = (data: any) => {
    setaddNewTherapyPopup(true);
    setIsEditTherapy(data);
    setEditMode(true);
  };

  const handleSearch = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const filteredTherapyData = therapyDataFilter?.filter((therapyDataFilter: any) => {
    return (
      therapyDataFilter?.p_therapyname?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      therapyDataFilter?.p_description?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });
  useEffect(() => {
    setTimeout(() => {
      setLoadingSpinnerLayout(false);
    }, 400);
  }, []);

  return (
    <div className="ml-2">
      <Toast ref={toast} />
      {loadingSpinnerLayout ? (
        <ProgressSpinner style={{ width: "70px", height: "70px" }} strokeWidth="2" fill="var(--surface-ground)" animationDuration=".9s" />
      ) : (
        <>
          <div>
            {addNewTherapyPopup && (
              <AddTherapyPopup
                isEditTherapy={isEditTherapy}
                addNewTherapyPopup={addNewTherapyPopup}
                setaddNewTherapyPopup={setaddNewTherapyPopup}
                editMode={editMode}
                toast={toast}
                setReloadTherapyGrid={setReloadTherapyGrid}
                reloadTherapyGrid={reloadTherapyGrid}
              />
            )}

            <HeaderWithBackButton
              title={!addTherapyChange || backButton ? resource.therapyDetails : resource.therapyDetails}
              controls={cardControls}
              onButtonClicked={onButtonClick}
              onBackButtonClick={onBackButtonClick}
              handleSearch={handleSearch}
              searchQuery={searchQuery}
            />
            <div className="transparant-table">
              <div className="row flex">
                <div className="col-12">
                  <TherapyTable
                    onEditTherapyClicked={onEditTherapyClicked}
                    addNewTherapyPopup={addNewTherapyPopup}
                    setaddNewTherapyPopup={setaddNewTherapyPopup}
                    reloadTherapyGrid={reloadTherapyGrid}
                    filteredTherapyData={filteredTherapyData}
                    setTherapyDataFilter={setTherapyDataFilter}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TherapyLayout;
