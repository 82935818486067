import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import EmployeeService from "../../services/EmlpoyeeServices";

interface EmployeeData {
  srNo: number;
  p_EmployeeId: string;
  p_FirstName: string;
  p_LastName: string;
  p_MobileNo: string;
  p_EmailId: string;
  p_DateOfJoining: any;
  p_DateOfRelease: any;
  p_IsActive:boolean;
  p_RoleName:any;
}

const UsersTable = (props: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const AddEmployee = () => {
    setLoading(true);
    EmployeeService.GetEmployeeData().then((res: any) => {
      props.setEmployeeDatFilter(res.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (!props.addEmployeedPopup) {
      AddEmployee();
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!props.addEmployeedPopup]);

  // const JoiningDate = (rowData: EmployeeData) => {
  //   var DateOfJoining = moment(rowData.p_DateOfJoining).format("DD/MM/YYYY");

  //   return (
  //     <div className="flex align-items-center gap-2">
  //       <span>{DateOfJoining}</span>
  //     </div>
  //   );
  // };
  const SrNo = (rowData: EmployeeData, index: any) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{index.rowIndex + 1}</span>
      </div>
    );
  };
  
  const Status = (rowData: EmployeeData) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{rowData.p_IsActive === true ? "Active" : "InActive"}</span>
      </div>
    );
  };

  const EmployeeName = (rowData: EmployeeData) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>
          {rowData.p_FirstName} {rowData.p_LastName}{" "}
        </span>
      </div>
    );
  };

  const onEditClick = (editData: EmployeeData) => {
    props.onEditUser(editData);
  };

  const onDeleteClick = (deleteData: EmployeeData) => {
    props.onDeleteClick(deleteData);
  };
  const verifiedBodyTemplate = (rowData: EmployeeData) => {
    return (
      <React.Fragment>
        <Button className="table-button custome-icon  edit-icon " onClick={() => onEditClick(rowData)} tooltip="Edit" />
        {/* <Button className="table-button custome-icon delete-icon" onClick={() => onDeleteClick(rowData)} tooltip="Delete"></Button> */}
      </React.Fragment>
    );
  };

  return (
    <div className="table-section">
      <DataTable value={props.filteredEmployeeData} dataKey="id" scrollable scrollHeight="600px" loading={loading} emptyMessage="No user found." paginator rows={15} >
        <Column header="Sr.No." style={{ minWidth: "2rem" }} body={SrNo} />
        <Column
          field="p_FirstName"
          header="User name"
          body={EmployeeName}
          showFilterMenu={false}
          filterMenuStyle={{ width: "4rem" }}
          style={{ minWidth: "4rem" }}
          sortable
        />
        <Column
          field="p_MobileNo"
          header="Mobile no"
          showFilterMenu={false}
          filterMenuStyle={{ width: "4rem" }}
          style={{ minWidth: "4rem" }}
          sortable
        />
        <Column field="p_EmailId" header="Email" showFilterMenu={false} filterMenuStyle={{ width: "4rem" }} style={{ minWidth: "4rem" }} sortable />
        <Column field="p_RoleName" header="Role" showFilterMenu={false} filterMenuStyle={{ width: "4rem" }} style={{ minWidth: "4rem" }} sortable />
        <Column field="p_IsActive" header="Status" showFilterMenu={false}  filterMenuStyle={{ width: "4rem" }} style={{ width: "4rem" }} sortable body={Status} />
        <Column field="Action" header="Action" style={{ minWidth: "6rem" }} body={verifiedBodyTemplate} showFilterMenu={false} />
      </DataTable>
    </div>
  );
};

export default UsersTable;
