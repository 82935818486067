import axios from "axios";
import authHeader from "./authHeader";

const AddPOI = async (jsonData: any, image: any, icon: any) => {
  try {
    const formData = new FormData();

    // Append JSON data as a string
    formData.append("jsonData", JSON.stringify(jsonData));

    // Append image and icon files, if they are provided
    if (image) {
      formData.append("image", image);
    }

    if (icon) {
      formData.append("icon", icon);
    }

    const response = await axios.post(`${process.env.REACT_APP_BASE_CMS_ENDPOINT}api/POI/RegisterPOI`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authHeader(),
      },
    });

    return response;
  } catch (error) {
    return error;
  }
};

// const EditPOI = async (data: any) => {
//   try {
//     const response = await axios.put(`${process.env.REACT_APP_BASE_CMS_ENDPOINT}api/POI/UpdatePOI`, data, {
//       headers: {
//         "Content-Type": "application/json",
//         ...authHeader(),
//       },
//     });
//     return response;
//   } catch (error) {
//     return error;
//   }
// };

const EditPOI = async (jsonData: any, image: any, icon: any) => {
  try {
    const formData = new FormData();

    // Append JSON data as a string
    formData.append("jsonData", JSON.stringify(jsonData));

    // Append image and icon files, if they are provided
    if (image) {
      formData.append("image", image);
    }

    if (icon) {
      formData.append("icon", icon);
    }

    const response = await axios.put(`${process.env.REACT_APP_BASE_CMS_ENDPOINT}api/POI/UpdatePOI`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authHeader(),
      },
    });

    return response;
  } catch (error) {
    return error;
  }
};

const getArea = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_CMS_ENDPOINT + `api/Area/GetArea`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const getActiveArea = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_CMS_ENDPOINT + `api/Area/GetActiveArea`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const getSubArea = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_CMS_ENDPOINT + `api/SubArea/GeSubArea`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const getActiveSubArea = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_CMS_ENDPOINT + `api/SubArea/GetActiveSubArea`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const getCategory = async () => {
  try {
    const response = await axios.get(process.env.REACT_APP_BASE_CMS_ENDPOINT + `api/MapSync/getCategory`, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const GetPOIData = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_CMS_ENDPOINT  + `api/POI/GetPOI`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const GetPoIAttribute = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_CMS_ENDPOINT + `api/POI/GetPOIAttribute`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const UpdatePoiDetails = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_CMS_ENDPOINT + `api/POI/updatePoidetails`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
const UpdateFloraFaunaDetails = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_CMS_ENDPOINT + `api/POI/updatePoidetails`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const getPresignedURL = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_CMS_ENDPOINT + `api/POI/GetPresignedPoiUrl`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
const GetActivity = async () => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_CMS_ENDPOINT + `api/Activity/GetAllActivity`, {}, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const GetPoiActivity = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_CMS_ENDPOINT + `api/POI/GetPOIActivity`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },

    });
    return response;
  } catch (error) {
    return error;

  };
}

const GetTherapy = async () => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_CMS_ENDPOINT + `api/Therapy/GetAllTherapy`, {}, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const GetPoiTherapy = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_CMS_ENDPOINT + `api/POI/GetPOITherapy`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },

    });
    return response;
  } catch (error) {
    return error;

  };
}



const DeletePOIData = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_CMS_ENDPOINT + `api/POI/DeletePOI`, data, {

      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },

    });
    return response;
  } catch (error) {
    return error;
  }
}

const AddActivity = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_CMS_ENDPOINT + `api/POI/MappPOIActivity`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },

    });
    return response;
  } catch (error) {
    return error;
  }
};

const AddTherapy = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_CMS_ENDPOINT + `api/POI/MappPOITherapy`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },

    });
    return response;
  } catch (error) {
    return error;
  }
};

const DeletePOIActivity = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_CMS_ENDPOINT + `api/POI/DeletedPOIActivity`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },

    });
    return response;
  } catch (error) {
    return error;
  }
};

const DeletePOITherapy = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_CMS_ENDPOINT + `api/POI/DeletedPOITherapy`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },

    });
    return response;
  } catch (error) {
    return error;
  }
};

const DeleteFile = async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_CMS_ENDPOINT}api/POI/DeleteFile`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const AddPOIAttribute = async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_CMS_ENDPOINT}api/POI/AddPOIAttribute`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};


const poiServices = {
  AddPOI,
  getArea,
  getActiveArea,
  getActiveSubArea,
  getSubArea,
  GetPOIData,
  EditPOI,
  GetPoIAttribute,
  UpdatePoiDetails,
  getPresignedURL,
  UpdateFloraFaunaDetails,
  GetActivity,
  GetPoiActivity,
  GetTherapy,
  GetPoiTherapy,
  AddActivity,
  AddTherapy,
  DeletePOIActivity,
  DeletePOITherapy,
  DeletePOIData,
  DeleteFile,
  AddPOIAttribute,
  getCategory
};

export default poiServices;
