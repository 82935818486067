import * as Yup from "yup";

const today = new Date();

const AddEmployeeValidationSchema = Yup.object().shape({  
  firstName: Yup.string().required("Please enter first name")
  .max(25, 'First name cannot be longer than 25 characters.')
  .matches(/^[A-Za-z\s]+$/, 'Please enter a first name using only letters.'),
  lastName: Yup.string().required("Please enter last name") 
  .max(25, 'Last name cannot be longer than 25 characters.')
  .matches(/^[A-Za-z\s]+$/, 'Please enter a last name using only letters.'),
   middleName: Yup.string().matches(/^[A-Za-z\s]+$/, 'Please enter a middle name using only letters.')
  .max(25, 'Middle name cannot be longer than 25 characters'),
  mobileNumber: Yup.string()
  .required('Please enter mobile no')
  .matches(/^[0-9]+$/, 'Mobile number must be digits only')
  .max(10, 'Mobile number must be 10 digits'),
  companyAssignedNo: Yup.string()
  .matches(/^[0-9]+$/, 'Mobile number must be digits only')
  .min(10, 'Mobile number must be 10 digits')
  .max(10, 'Mobile number must be 10 digits'),
  emailId: Yup.string().required("Please enter email id")
  .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Please enter valid email id.'),
  dateOfJoin: Yup.string().required("Please enter joining date")
  .test(
    'max-date',
    'Date of joining must be a date before today.',
    function(value) {
        if (!value) return true; 
        const date = new Date(value);
        return date <= today;
    }
),  role: Yup.string().required("Please select role"),
});

export default AddEmployeeValidationSchema;
