// PageNotFound.tsx
import React from 'react';

const PageNotFound: React.FC = () => {
  return (
    <div className=' flex justify-content-center align-items-center h-screen'>
      <h1>Page Not Found</h1>

    </div>
  );
};

export default PageNotFound;