import { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import attributeServices from "../../services/attributeServices";
import fileUpload from "../../services/fileUpload";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import poiServices from "../../services/poiServices";
import { ProgressSpinner } from "primereact/progressspinner";
import { ProgressBar } from 'primereact/progressbar';



const MediaLayout = (props: any) => {
  const toast = useRef<Toast>(null);
  const [attribute, setAttribute] = useState<any>(null);
  const [videoFileName, setVideoFileName] = useState("");
  const [videoSelectedFile, setVideoSelectedFile] = useState<File | null>(null);
  const [, setAudioUploadedFileUrl] = useState<any>();
  const [audioFileName, setAudioFileName] = useState("");
  const [audioSelectedFile, setAudioSelectedFile] = useState<File | null>(null);
  const [, setVideoUploadedFileUrl] = useState<any>();
  const [videoname, setVideoName] = useState<string>("");
  const [audioname, setAudioName] = useState<string>("");
  const [loadingSpinnerLayout, setLoadingSpinnerLayout] = useState<boolean>(true);
  const [, setVideoDuration] = useState<any>(null);
  const [, setAudioDuration] = useState<any>(null);
  const [progressForAudioFile, setProgressForAudioFile] = useState<number>(0);
  const [progressForvideoFile, setProgressForvideoFile] = useState<number>(0);



  useEffect(() => {
    setTimeout(() => {
      setLoadingSpinnerLayout(false);
    }, 400);
  }, [])
  const AttributeDetails = () => {

    let data = {
      PoiId: props.poiId,
      AttributeId: props.attributeId,
    };
    attributeServices.GetAttributeDetails(data).then((res: any) => {
      try {
        if (res.status === 200) {
          setAttribute(res.data);

        }
        return true;
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
        }
      }
    });
  };
  useEffect(() => {
    AttributeDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onPoiClick]);

  const onAudioSelect = (event: any, attributeDetailId: any) => {
    if ((!audioname || !event.files || event.files.length === 0) && !(audioFileName !== "" && audioSelectedFile)) {
      toast.current?.show({
        severity: "warn",
        summary: "Please enter a name for the audio and select a file.",
        life: 3000,
      });
      return;
    }

    if (!event || !event.files || event.files.length === 0) {
      console.error("No file selected.");
      props.toast.current?.show({
        severity: "warn",
        summary: "Please select a file.",
        life: 3000,
      });
      return;
    }

    const file: File = event.files[0];
    setAudioFileName(file.name);
    if (file) {
      const reader = new FileReader();
      if (!file.type.startsWith("audio/")) {
        props.toast.current?.show({
          severity: "warn",
          summary: "Warning",
          detail: "Please select an audio file only.",
          life: 3000,
        });
        setAudioName("");
        setAudioSelectedFile(null);
        return;
      }
      //  else if (file.size > 5000000) {
      //   props.toast.current?.show({
      //     severity: "warn",
      //     summary: "Warning",
      //     detail: "Selected file exceeds the 5MB limit.",
      //     life: 3000,
      //   });
      //   setAudioName("");
      //   setAudioSelectedFile(null);
      //   return;
      // }

      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const audio = document.createElement<any>("audio");
        audio.src = reader.result;
        audio.onloadedmetadata = () => {
          const durationInSeconds = audio.duration;
          const hours = Math.floor(durationInSeconds / 3600);
          const minutes = Math.floor((durationInSeconds % 3600) / 60);
          const seconds = Math.floor(durationInSeconds % 60);
          const durationString = `${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}`;
          setAudioDuration(durationString);

          if (file !== null && audioname !== null) {
            const data = {
              PoiId: props.poiId as number,
              AttributeId: props.attributeId as number,
              AttributeDetailId: attributeDetailId as number,
              File: file,
              FileName: audioname,
              duration: durationString,
            };

            UploadAudioFile(data);
          } else if (audioname === "") {
            toast.current?.show({
              severity: "warn",
              summary: "Please enter a name for the audio and select a file.",
              life: 3000,
            });
            setAudioName("");
            setAudioSelectedFile(null);
          }
        };
        setAudioFileName(file.name);
        setAudioSelectedFile(file);
        setAudioUploadedFileUrl(reader.result as string);
      };
    }

  };
  function pad(number: any, length: any) {
    return String(number).padStart(length, "0");
  }

  const onVideoSelect = (event: any, attributeDetailId: any) => {
    if ((!videoname || event.files.length === 0) && !(videoFileName !== "" && videoSelectedFile !== null)) {
      toast.current?.show({
        severity: "warn",
        summary: "Please enter a name for the video and select a file.",
        life: 3000,
      });
      return;
    }

    if (!event || !event.files || event.files.length === 0) {
      console.error("No file selected.");
      props.toast.current?.show({
        severity: "warn",
        summary: "Please select a file.",
        life: 3000,
      });
      setVideoFileName("");
      setVideoSelectedFile(null);
      return;
    }

    const file: File = event.files[0];
    setVideoFileName(file.name);
    if (file) {
      const reader = new FileReader();
      if (!file.type.startsWith("video/")) {
        props.toast.current?.show({
          severity: "warn",
          summary: "Warning",
          detail: "Please select an video file only.",
          life: 3000,
        });
        setVideoFileName("");
        setVideoSelectedFile(null);
        return;
      } 
      // else if (file.size > 5000000) {
      //   props.toast.current?.show({
      //     severity: "warn",
      //     summary: "Warning",
      //     detail: "Selected file exceeds the 5MB limit.",
      //     life: 3000,
      //   });
      //   setVideoFileName("");
      //   setVideoSelectedFile(null);
      //   return;
      // }

      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const video = document.createElement<any>("video");
        video.src = reader.result;
        video.onloadedmetadata = () => {
          const durationInSeconds = video.duration;
          const hours = Math.floor(durationInSeconds / 3600);
          const minutes = Math.floor((durationInSeconds % 3600) / 60);
          const seconds = Math.floor(durationInSeconds % 60);
          const durationString = `${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}`;

          setVideoDuration(durationString);
          if (file !== null && videoname !== "") {
            const data = {
              PoiId: props.poiId as number,
              AttributeId: props.attributeId as number,
              AttributeDetailId: attributeDetailId as number,
              File: file,
              FileName: videoname,
              duration: durationString,
            };

            UploadVideoFile(data);
          } else if (videoname === "") {
            toast.current?.show({
              severity: "warn",
              summary: "Please enter a name for the video and select a file.",
              life: 3000,
            });
            setVideoFileName("");
            setVideoSelectedFile(null);
          }
        };

        setVideoFileName(file.name);
        setVideoSelectedFile(file);
        setVideoUploadedFileUrl(reader.result as string);
      };
    }
  };

  const UploadVideoFile = (data: any) => {

    fileUpload.MediaVideoUpload(data, (progress: number) => {
      setProgressForvideoFile(progress); // Set progress using the number received
      }).then((res: any) => {
      try {
        if (res.status === 200) {
          props.toast.current?.show({
            severity: "success",
            summary: "Success",
            detail: "Video uploaded successfully",
            life: 3000,
          });
          setVideoName("");
          AttributeDetails();
          setVideoFileName("");
        }
        else
        {
          toast.current?.show({ severity: "warn",
           summary: "Warning",
            detail: "video not uploaded",
             life: 3000 });

      }
      setProgressForvideoFile(100); // Set to 100 when done
      setTimeout(() => setProgressForvideoFile(0), 2000);
        return res.data;
      } catch (error: any) {

        if (error.response && error.response.status === 401) {
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: "Unauthorized",
            life: 3000,
          });
        }
      }
    });


  };

  const UploadAudioFile = (data: any) => {

    fileUpload.MediaAudioUpload(data, (progress: number) => {
      setProgressForAudioFile(progress); // Set progress using the number received
  }).then((res: any) => {
      try {
        if (res.status === 200) {
          props.toast.current?.show({
            severity: "success",
            summary: "Success",
            detail: "Audio uploaded successfully",
            life: 3000,
          });

          setAudioName("");
          setVideoName("");
          AttributeDetails();
          setAudioFileName("");
          setAudioSelectedFile(null);
        }
        setProgressForAudioFile(100); 
        setTimeout(() => setProgressForAudioFile(0), 2000);
        return res.data;
        // Redirect the user to the protected route
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: "Unauthorized",
            life: 3000,
          });
        }
      }
    });

  };

  const onVideoDelete = (values: any) => {
    confirmDialog({
      message: `Are you sure you want to delete this video?`,
      header: "Confirm delete media",
      icon: "pi pi-exclamation-triangle",
      accept: () => onDeleteMedia(values), // Pass a function to be executed on confirmation
    });
  };

  const onAudioDelete = (values: any) => {
    confirmDialog({
      message: `Are you sure you want to delete this audio?`,
      header: "Confirm delete media",
      icon: "pi pi-exclamation-triangle",
      accept: () => onDeleteMedia(values), // Pass a function to be executed on confirmation
    });
  };
  const onDeleteMedia = (values: any) => {
    const data = {
      poimediaid: values,
    };
    poiServices.DeleteFile(data).then((res: any) => {
      try {
        if (res.status === 200) {
          props.toast.current?.show({
            severity: "success",
            summary: "Success",
            detail: "Media deleted successfully",
            life: 3000,
          });
          AttributeDetails();
        }
        return res.data;
        // Redirect the user to the protected route
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
        }
      }
    });
  };

  return (
    <div className="tab-scrollable-content m-0">
      <Toast ref={toast} />

      {loadingSpinnerLayout ? (
        <ProgressSpinner style={{ width: "70px", height: "70px" }} strokeWidth="2" fill="var(--surface-ground)" animationDuration=".9s" />
      ) : (
        <>
          {attribute?.map((items: any, index: any) => {
            return (
              <>
                {/* Render the form fields */}
                <div key={index}>
                  <div className="flex col-12">
                    <div className="flex w-full align-items-start mb-5">
                      <label htmlFor={items.p_attributedetailid} className="mt-2">
                        {items.p_attributedetailname}
                      </label>

                      {items.p_attributedetailid === 18 && (
                        <>
                          <div className="flex flex-column">
                            <div className="flex">
                              <InputText
                                id="image-name"
                                type="text"
                                className="fileinamenput"
                                name="imageName"
                                placeholder="Enter video name"
                                value={videoname}
                                onChange={(e) => setVideoName(e.target.value)}
                              />
                              <div className="flex flex-column">
                                <div className="flex">
                                  <InputText
                                    id={items.p_attributedetailid}
                                    type="text"
                                    className="fileinput"
                                    name={items.p_attributedetailname}
                                    placeholder="Choose video"
                                    value={videoFileName}
                                  />
                                  <FileUpload
                                    mode="basic"
                                    name={items.p_attributedetailname}
                                    accept="video/MP4"
                                    chooseLabel="Select"
                                    auto
                                    className="flex justify-content-center fileselectbtn"
                                    onSelect={(e) => {
                                      onVideoSelect(e, items.p_attributedetailid);
                                    }}
                                  />
                                  {progressForvideoFile > 0 && (
                              <div className="ml-3" style={{ width: "150px" }}>
                                <ProgressBar
                                  value={progressForvideoFile}
                                  showValue={true}
                                  className="custom-progress-bar" 
                                />
                              </div>
                              )}
                                </div>

                                <small id="file-size" className="mt-1 ml-6">File type MP4</small>
                              </div>

                            </div>
                            {attribute
                              .filter((item: any) => item.p_attributedetailid === 18)
                              .map((items: any, index: any) => {
                                const videos = JSON.parse(items.p_file);
                                return (
                                  <div key={index} className="flex video-layout">
                                    {videos.map((video: any, videoIndex: any) => (
                                      <div key={videoIndex} className="image-wrapper ">
                                        <Button
                                          label=""
                                          icon="pi pi-trash"
                                          className="icon-delete ml-2"
                                          onClick={() => onVideoDelete(video.poimediaid)}
                                        />
                                        <video className="video-style" src={video.mediaurl} controls />
                                        <div className="text-center mt-2">
                                          <span> {video.medianame}</span>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                );
                              })}
                          </div>
                        </>
                      )}

                      {items.p_attributedetailid === 19 && (
                        <>
                          <div className="flex flex-column ">
                            <div className="flex">


                              <InputText
                                id="image-name"
                                type="text"
                                className="fileinamenput"
                                name="imageName"
                                placeholder="Enter audio name"
                                value={audioname}
                                onChange={(e) => setAudioName(e.target.value)}
                              />
                              <div className="flex flex-column ">
                                <div className="flex">

                                  <InputText
                                    id={items.p_attributedetailid}
                                    type="text"
                                    className="fileinput"
                                    name={items.p_attributedetailname}
                                    placeholder="Choose audio"
                                    value={audioFileName}
                                  />
                                  <FileUpload
                                    mode="basic"
                                    name={items.p_attributedetailname}
                                    accept="audio/MP3"
                                    auto
                                    chooseLabel="Select"
                                    className=" flex justify-content-center  fileselectbtn"
                                    onSelect={(e) => {
                                      onAudioSelect(e, items.p_attributedetailid);
                                    }}
                                  />
                                  {progressForAudioFile > 0 && (
                              <div className="ml-3" style={{ width: "150px" }}>
                                <ProgressBar
                                  value={progressForAudioFile}
                                  showValue={true}
                                  className="custom-progress-bar" 
                                />
                              </div>
                              )}

                                </div>
                                <small id="file-size" className="mt-1 ml-6">File type MP3</small>
                              </div>
                            </div>



                            {attribute
                              .filter((item: any) => item.p_attributedetailid === 19)
                              .map((items: any) => {
                                const audios = JSON.parse(items.p_file);
                                return (
                                  <div key={index} className="flex audio-layout">
                                    {audios.map((audio: any, audioIndex: any) => (
                                      <div className="flex flex-column">
                                        <div key={audioIndex} className="image-wrapper ml-3">
                                          <Button
                                            label=""
                                            icon="pi pi-trash"
                                            className="icon-delete ml-2"
                                            onClick={() => onAudioDelete(audio.poimediaid)}
                                          />
                                          <audio className="audio-style" src={audio.mediaurl} controls />
                                        </div>
                                        <div className="text-center mb-3">
                                          <span> {audio.medianame}</span>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                );
                              })}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            );
          })}
          {/* <div className="cancel-submit-btn  w-full justify-content-end mt-6">
            <Button
              className="cancel-btn "
              label={resource.cancel}
              type="reset"
              onClick={onCancelClick}
            />
            <Button
              className="submit-btn ml-2 "
              label={resource.submit}
              type="submit"
              loading={loading}
            />
          </div> */}
        </>
      )}
    </div>
  );
};

export default MediaLayout;
