import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { useContext, useEffect, useRef, useState } from "react";
import { contextStore } from "../../../context/CustomStateProvider";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import SitumSettingValidation from "../../../validation/SitumSettingValidation";
import { Dialog } from "primereact/dialog";
import SitumServices from "../../../services/SitumServices";
import { confirmDialog } from "primereact/confirmdialog";


const SitumSettingPopup = (props: any) => {
    const toast = useRef<Toast>(null);
    const [loadingSpinnerLayout, setLoadingSpinnerLayout] = useState<boolean>(true);
    const context = useContext(contextStore);
    const resource = context.state.strings;
    const [loading, setLoading] = useState<boolean>(false);
    const[settingId,setSettingId]=useState('');


    
    interface SitumSetting {
        id:any;
        baseurl: string;
        apikey: string;
        buildingid: any;
        floorid: any;
        actionedbyname:string;
        actionedon:string;

      }
     
      const GetSitumSetting=()=>
      {
        SitumServices.GetSitumSetting().then((res: any) => {
          try {
            if (res.status === 200) {
            setSitumData(res.data);
            setLoading(false);
            }    
            return res.data;
          } catch (error: any) {
            if (error.response && error.response.status === 401) {
             props.toast.current?.show({
                severity: "warn",
                summary: "Warning",
                detail: "Unauthorized",
                life: 3000,
              });
              setLoading(false);
              window.location.pathname = "/";
            
          }
        }});
      }
      const setSitumData=(data:SitumSetting)=>
      {
        setSettingId(data.id);
        formik.setFieldValue("baseurl", data.baseurl);
        formik.setFieldValue("apikey", data.apikey);
        formik.setFieldValue("buildingid", data.buildingid);
        formik.setFieldValue("floorid", data.floorid);
        formik.setFieldValue("actionedbyname", data.actionedbyname);
        formik.setFieldValue("actionedon", new Date(data.actionedon).toLocaleDateString('en-GB'));

      }
      const handleSubmit = async (values: SitumSetting) => 
      {
        updateSitumSetting(values)
      

      };  
      const updateSitumSetting=(values: SitumSetting)=>
       {
              const data = {
                id : settingId,
                baseurl: values.baseurl,
                apikey: values.apikey,
                buildingid: values.buildingid,
                floorid:values.floorid
              };
              SitumServices.UpdateSitumSetting(data).then((res: any) => {
                try {
                  if (res.status === 200) {
                    if (res.data.p_response) {
                      props.toast.current?.show({ severity: "success", summary: "Success", detail: res.data.p_msg, life: 3000 });
                    } else {
                      props.toast.current?.show({ severity: "warn", summary: "Warning", detail: res.data.p_msg, life: 3000 });
                    }
                    props.setSitumSettingPopup(false);
                  }
                  else if (res.response.status === 400 &&  res.response.data.success===false)
                  {
                    const errorMessage = res.response.data.errorMessage;
                    props.toast.current?.show({ severity: "warn", summary: "Warning", detail: errorMessage, life: 3000 });
                  }
                  return res.data;
                } catch (error: any) {
                  if (error.response && error.response.status === 401) {
                    props.toast.current?.show({
                      severity: "warn",
                      summary: "Warning",
                      detail: "Unauthorized",
                      life: 3000,
                    });
                  }
                }
              });
         };

      const onCancelClick = () => {
        formik.resetForm();
        props.setSitumSettingPopup(false);
      };

      const header = (
        <div className="p-d-flex p-jc-between p-ai-center">
          <span>{resource.situmSettingUpdate}</span>
          <Button
            icon="pi pi-times"
            className="p-button-rounded p-button-text popup-close-button"
            onClick={() => {
              props.setSitumSettingPopup(false);
              onCancelClick();
            }}
          />
        </div>
      );
     

    useEffect(() => {
        GetSitumSetting()
        setLoading(true);
        setTimeout(() => {
          setLoadingSpinnerLayout(false);
        }, 400);
      }, [props.situmSettingPopup]);
    
      const formik = useFormik({
        initialValues: {
            id:null,
            baseurl: "",
            apikey: "",
            buildingid: "",
            floorid: "",
            actionedbyname:"",
            actionedon:""
        },
        validationSchema: SitumSettingValidation,
        onSubmit: handleSubmit,
      });

  return (
    <div>
    <Dialog className="popup popup-card-layout" header={header} draggable={false} visible={props.situmSettingPopup} style={{ width: "40vw" }} onHide={()=> { props.setSitumSettingPopup(false); onCancelClick(); }}>

        <form onSubmit={formik.handleSubmit}>
            <div className="card-wrapper">
                <div className="flex mb-2">
                    <div className="col-6 relative">
                        <label htmlFor="baseUrl" className="block font-medium mb-2">
                            {resource.baseUrl}
                            <span style={{ color: "red" }}>*</span>
                        </label>
                        <InputText id="baseurl" type="text" className="inputbox w-full" value={formik.values.baseurl} onChange={(e)=> formik.handleChange(e)} placeholder="Enter baseurl" autoFocus={true} tabIndex={1} /> {formik.errors.baseurl && formik.touched.baseurl &&
                            <div className="text-red-500 text-xs">{formik.errors.baseurl}</div>}
                    </div>
                    <div className="col-6 relative">
                        <label htmlFor="apikey" className="block font-medium mb-2">
                            {resource.apiKey}
                            <span style={{ color: "red" }}>*</span>
                        </label>
                        <InputText id="apikey" type="text" className="inputbox w-full" value={formik.values.apikey} onChange={(e)=> formik.handleChange(e)} placeholder="Enter apikey" tabIndex={2} /> {formik.errors.apikey && formik.touched.apikey &&
                            <div className="text-red-500 text-xs">{formik.errors.apikey}</div>}
                    </div>
                </div>
            </div>
            <div className="card-wrapper">

                <div className="flex mb-2">
                    <div className="col-6 relative">
                        <label htmlFor="buildingid" className="block font-medium mb-2">
                            {resource.buildingId}
                            <span style={{ color: "red" }}>*</span>
                        </label>
                        <InputText id="buildingid" type="text" className="inputbox w-full" value={formik.values.buildingid} onChange={(e)=> { const value = e.target.value; if (/^\d{0,10}$/.test(value)) { formik.handleChange(e); } }} placeholder="Enter buildingid" tabIndex={3} /> {formik.errors.buildingid && formik.touched.buildingid &&
                            <div className="text-red-500 text-xs">{formik.errors.buildingid}</div>}
                    </div>
                    <div className="col-6 relative">
                        <label htmlFor="floorid" className="block font-medium mb-2">
                            {resource.floorId}
                            <span style={{ color: "red" }}>*</span>
                        </label>
                        <InputText id="floorid" type="text" className="inputbox w-full" value={formik.values.floorid} onChange={(e)=> { const value = e.target.value; if (/^\d{0,10}$/.test(value)) { formik.handleChange(e); } }} placeholder="Enter floorid" tabIndex={4} /> {formik.errors.floorid && formik.touched.floorid &&
                            <div className="text-red-500 text-xs">{formik.errors.floorid}</div>}
                    </div>
                </div>
            </div>
            <div className="card-wrapper">
                <div className="flex mb-2">
                    <div className="col-6 relative">
                        <label htmlFor="actionedbyname" className="block font-medium mb-2">
                            {resource.actionedbyname}
                        </label>
                        <InputText id="actionedbyname" type="text" className="inputbox w-full" value={formik.values.actionedbyname} onChange={(e)=> formik.handleChange(e)} autoFocus={true} disabled={true} />
                    </div>
                    <div className="col-6 relative">
                        <label htmlFor="actionedon" className="block font-medium mb-2">
                            {resource.actionedon}
                        </label>
                        <InputText id="actionedon" type="text" className="inputbox w-full" value={formik.values.actionedon} onChange={(e)=> formik.handleChange(e)}  disabled={true} /> 
                    </div>
                </div>
            </div>

            <div className="cancel-submit-btn mt-8">
                <Button type="button" onClick={()=> onCancelClick()} label="Cancel" className="cancel-btn" tabIndex={5} />
                    <Button type="submit" label="Save" className="submit-btn ml-2" tabIndex={6} />
            </div>
        </form>

    </Dialog>
</div>     
 
);

};
export default SitumSettingPopup