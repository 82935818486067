import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useContext, useEffect, useRef, useState } from 'react'
import GetAllCustomerService from '../../services/getallcustomer';
import moment from 'moment';
import { contextStore } from '../../context/CustomStateProvider';
import HeaderWithBackButton from '../common/HeaderWIthBackButton';
import { InputSwitch } from 'primereact/inputswitch';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';


const Visitors = () => {
  const [customerdata, setCustomerData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const context = useContext(contextStore);
  const resource = context.state.strings;
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState<any>([]);
  const [isStaying, setIsStaying] = useState<boolean>(false);
  const toast = useRef<Toast>(null);

  useEffect(() => {
    ShowAllCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchQuery) {
      const lowercasedFilter = searchQuery.toLowerCase();
      const filteredData = customerdata.filter((customer: any) => {
        return Object.keys(customer).some(key =>
          String(customer[key]).toLowerCase().includes(lowercasedFilter)
        );
      });
      setFilteredData(filteredData);
    } else {
      setFilteredData(customerdata);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, customerdata]);

  const ShowAllCustomers = () => {
    setLoading(true)
    GetAllCustomerService.GetAllCustomers(null).then((res: any) => {
      try {
        if (res.status === 200) {
          setCustomerData(res.data);
          setLoading(false);
        }
        if (res.status === 401) {
          setLoading(false);
        }
        return res.data;
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          setLoading(false);
        }
      }
    });
  };
  const SrNo = (rowData: any, index: any) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{index.rowIndex + 1}</span>
      </div>
    );
  };

  const DateOfbirth = (rowData: any) => {
    const DateOfbirth = rowData.p_Dob ? moment(rowData.p_Dob).format("DD/MM/YYYY") : '';


    return (
      <div className="flex align-items-center gap-2">
        <span>{DateOfbirth}</span>
      </div>
    );
  };

  const handleSearch = (event: any) => {
    setSearchQuery(event.target.value);
  };
   
  const changeCustomerType=(e:boolean,data:any)=>
  {

    let message='';
    if(e===true)
    {
      message = "Are you sure you want to change the customer type to 'Staying'?"
    }  
    else
    {
      message = "Are you sure you want to change the customer type to 'Visitor'?"

    }
      confirmDialog
      ({
        message: message,
        header: "Confirm customer type change",
        icon: "pi pi-exclamation-triangle",
        accept: () => updateCustometype(data.p_CustomerId,e),
      });
    }
    
    const updateCustometype=(customerid:bigint,isstaying:boolean)=>
    {
      setLoading(true);
      const data = {
        Customerid: customerid,
        isstaying: isstaying,
      };

    GetAllCustomerService.UpdateCustomerType(data)
    .then((res: any) => 
    {
      setLoading(false);
      if (res.status === 200)
      {
        if (res.data.p_response)
         {
          toast.current?.show({ severity: "success", summary: "Success", detail: res.data.p_msg, life: 3000 });
          ShowAllCustomers();
        } else
         {
          toast.current?.show({ severity: "warn", summary: "Warning", detail: res.data.p_msg, life: 3000 });
        }          
      } 
        else
      {
        toast.current?.show({ severity: "warn", summary: "Warning", detail: "Failed to update customer", life: 3000 });
      }
    })
    .catch((error) => {
      setLoading(false);
      console.error("Updatecustomer error:", error);
      toast.current?.show({ severity: "error", summary: "Error", detail: "Something went wrong", life: 3000 });
    });
  };

  return (
    
    <div className="container ">
      <HeaderWithBackButton
        title={resource.visitorsDetails}
        controls={[]}
        handleSearch={handleSearch}
        searchQuery={searchQuery}
      />
      <div className="table-section">
      <Toast ref={toast} />
        <DataTable
          paginator rows={15}
          value={filteredData}
          dataKey="id"
          scrollable
          scrollHeight="600px"
          loading={loading}
          emptyMessage="No POI found."

        >
          <Column field="srNo" header="Sr. No." style={{ minWidth: "3rem" }} body={SrNo} />
          <Column field="p_FirstName" header="First Name" sortable />
          <Column field="p_LastName" header="Last Name" sortable />
          <Column field="p_email" header="Email" sortable />
          <Column field="p_MobileNo" header="Mobile No." sortable />
          <Column field="p_AadharNo" header="Adhar Number" style={{ minWidth: "2rem" }} sortable />
          <Column field="p_Dob" header="DOB" body={DateOfbirth} sortable />
          <Column
                header="Staying"
                body={(rowData, options) => (
                  <div className="custom-inputswitch">
                    <InputSwitch
                      id={`IsStaying_${options.rowIndex}`}
                      checked={rowData.p_isstaying}
                      onChange={(e) =>
                        changeCustomerType(e.target.value,rowData)
                      }
                    />
                  </div>
                )}
                style={{ minWidth: "8rem" }}
            />
          {/* <Column field="Action" header="Action" style={{ minWidth: "2rem" }} body={verifiedBodyTemplate} /> */}
        </DataTable>
      </div>
    </div>
  )
}

export default Visitors
