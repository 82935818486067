import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useFormik } from "formik";
import { contextStore } from "../../context/CustomStateProvider";
import poiServices from "../../services/poiServices";
import ActictyValiDation from "../../validation/ActivityValidation";

interface Activity {
  p_activityid: any;
  p_activityname: string;
  p_duration: any;
}

interface ActictyTable {
  srNo: number;
  p_activityname: string;
  p_capacity: string;
}

interface ActivityChange {
  activity: any;
  capacity: any;
}

const ActivityLayout = (props: any) => {
  const context = useContext(contextStore);
  const resource = context.state.strings;
  const [loading, setLoading] = useState(true);
  const [activityList, setActivityList] = useState<ActictyTable[]>([]);
  const toastTopRight = useRef<Toast>(null);
  const [selectedActivity, setSelectedActivity] = useState<Activity | null>(null);
  const [activityValue, setActivityValue] = useState([]);
  const [activityDisabled, setactivityDisabled] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const GetActivityValues = () => {
    poiServices.GetActivity().then((res: any) => {
      setActivityValue(res.data);
    });
  };
  const GetPoiActivityValues = () => {
    setLoading(true);
    let data = {
      PoiId: props.poiId,
    };
    setTimeout(() => {
      poiServices.GetPoiActivity(data).then((res: any) => {
        setActivityList(res.data);
        setLoading(false);
        setSelectedActivity(null);
        setactivityDisabled(false);
        setButtonClicked(false);
        formik.setFieldValue("capacity", "");
      });
    }, 600);
  };

  useEffect(() => {
    GetActivityValues();
    GetPoiActivityValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (values: ActivityChange) => {
    ActivityAdded(values);
  };
  const formik = useFormik({
    initialValues: {
      activity: null,
      capacity: "",
    },
    validationSchema: ActictyValiDation,
    onSubmit: handleSubmit,
  });
  const onEditClick = (rowData: any) => {
    setactivityDisabled(true);
    formik.setFieldValue("capacity", rowData.p_capacity);

    activityValue.forEach((value: any) => {
      if (parseInt(value.p_activityid) === parseInt(rowData.p_activityid)) {
        formik.setFieldValue("activity", value.p_activityid);
        setSelectedActivity(value);
      }
    });
  };

  const onDeleteClick = (values: any) => {
    confirmDialog({
      message: `Are you sure you want to delete this field?`,
      header: "Confirm delete field",
      icon: "pi pi-exclamation-triangle",
      accept: () => OnDeleteActivity(values), // Pass a function to be executed on confirmation
    });
  };

  const OnDeleteActivity = (values: any) => {
    let data = {
      PoiId: props.poiId,
      activityid: values.p_activityid,
    };

    poiServices.DeletePOIActivity(data).then((res: any) => {
      try {
        setLoading(false);
        if (res.status === 200) {
          props.toast.current?.show({
            severity: "success",
            summary: "Success",
            detail: "Activity deleted successfully",
            life: 3000,
          });

          GetPoiActivityValues();
        }
        return res.data;
        // Redirect the user to the protected route
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: "Unauthorized",
            life: 3000,
          });
        }
      }
    });
  };

  const onCancelClick = () => {
    setactivityDisabled(false);
    setSelectedActivity(null);
    formik.setFieldValue("capacity", "");
  };

  const ActivityAdded = (values: any) => {
    setLoading(true);
    let data = {
      PoiId: props.poiId,
      Activityid: values.activity?.p_activityid ? values.activity?.p_activityid : values.activity,
      Capacity: values.capacity,
    };
    poiServices.AddActivity(data).then((res: any) => {
      try {
        setLoading(false);
        formik.resetForm();
        if (res.status === 200 && res.data.p_response === false) {
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: res.data.p_msg,
            life: 3000,
          });
        } else if (res.status === 200) {
          GetPoiActivityValues();
          setButtonClicked(true);
          if (!activityDisabled) {
            props.toast.current?.show({
              severity: "success",
              summary: "Sucess",
              detail: "Activity added successfully ",
              life: 3000,
            });
          } else {
            props.toast.current?.show({
              severity: "success",
              summary: "Sucess",
              detail: "Activity updated successfully ",
              life: 3000,
            });
          }
        }
        return res.data;
        // Redirect the user to the protected route
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
        }
      }
    });
  };

  const Action = (rowData: any) => {
    return (
      <React.Fragment>
        <Button className="table-button custome-icon  edit-icon " onClick={() => onEditClick(rowData)} />
        <Button className="table-button custome-icon delete-icon" onClick={() => onDeleteClick(rowData)}></Button>
      </React.Fragment>
    );
  };

  const SrNo = (rowData: any, index: any) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{index.rowIndex + 1}</span>
      </div>
    );
  };

  let TableData = (
    <div className="table-section">
      <DataTable value={activityList} emptyMessage="No package detail found" loading={loading}>
        <Column header="Sr.No." style={{ minWidth: "2rem" }} body={SrNo} />
        <Column field="p_activityname" header="Activity"></Column>
        <Column field="p_capacity" header="Capacity"></Column>
        <Column field="Action" header="Action" body={Action}></Column>
      </DataTable>
    </div>
  );
  const selectedRoleTemplate = (option: Activity) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.p_activityname}</div>
        </div>
      );
    }
    return <span>{resource.selectActivity}</span>;
  };
  const RoleOptionTemplate = (option: Activity) => {
    return (
      <div className="flex align-items-center">
        <div>{option.p_activityname}</div>
      </div>
    );
  };

  return (
    <>
      <Toast ref={toastTopRight} />
      <form onSubmit={formik.handleSubmit}>
        <div className="flex ml-3 mt-2">
          <div className="col-3 relative">
            <label htmlFor="activity" className="block  font-medium mb-2">
              {resource.activity}
              <span style={{ color: "red" }}>*</span>
            </label>
            <Dropdown
              value={selectedActivity}
              onChange={(e: DropdownChangeEvent) => {
                formik.setFieldValue("activity", e.value);
                setSelectedActivity(e.value);
              }}
              disabled={activityDisabled}
              options={activityValue}
              valueTemplate={selectedRoleTemplate}
              itemTemplate={RoleOptionTemplate}
              optionLabel="name"
              placeholder="Select Activity"
              className=" dropdown w-full"
              tabIndex={1}
              filter
              filterBy="p_activityname"
            />
          </div>
          <div className="col-3 relative">
            <label htmlFor="capacity" className="block  font-medium mb-2">
              {resource.subCapacity}
              <span style={{ color: "red" }}>*</span>
            </label>
            <InputText
              id="capacity"
              type="text"
              disabled={!selectedActivity}
              className="inputbox w-full"
              value={formik.values.capacity}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d{0,10}$/.test(value)) {
                  formik.handleChange(e);
                }
              }}
              placeholder="Enter sub capacity"
              tabIndex={2}
            />
            {formik.errors.capacity && formik.touched.capacity && !buttonClicked && (
              <div className="text-red-500 text-xs">{formik.errors.capacity}</div>
            )}{" "}
          </div>
        </div>

        <>
          <div className="flex mt-2 mb-4 justify-content-end">
            <Button
              type="button"
              onClick={() => {
                onCancelClick();
              }}
              label="Cancel"
              className="cancel-btn"
            />

            <Button type="submit" disabled={!selectedActivity} label="Save" className="orange-btn ml-3" />
          </div>
        </>
      </form>

      {TableData}
    </>
  );
};

export default ActivityLayout;
