import { useContext, useEffect, useRef, useState } from "react";
import { contextStore } from "../../context/CustomStateProvider";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import AddRoleMenue from "../../services/roleServices";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import RoleValidation from "../../validation/RoleValidation";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";


interface RoleId {
  p_roleid: any;
  p_rolename: string;
  p_code: any;
}

interface settingForm {
  roleId: any;
  Dashboard: boolean | undefined;
  POI: boolean | undefined;
  Users: boolean | undefined;
  CMS: boolean | undefined;
  Visitors: boolean | undefined;
  Protocol: boolean | undefined;
  Area: boolean | undefined;
  SubArea: boolean | undefined;
  Report: boolean | undefined;
  Setting: boolean | undefined;
}
interface MenuToggleMap {
  [key: number]: boolean;
}


const RoleSetting = (props: any) => {
  const context = useContext(contextStore);
  const resource = context.state.strings;
  const [RoleMenu, setRoleMenu] = useState([]);
  const [selectedRoleMenu, setselectedRoleMenu] = useState<RoleId | null>();
  const [roleSetting, setRoleSetting] = useState<any>();
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);
  const [menuToggles, setMenuToggles] = useState<MenuToggleMap>({});
  const [loadingSpinnerLayout, setLoadingSpinnerLayout] = useState<boolean>(true);

  const GetRole = () => {
    AddRoleMenue.RoleMenus().then((res: any) => {
      setRoleMenu(res.data);

      if (res?.response?.status === 401) {
        window.location.pathname = "/";
      }
    });
  };

  const GetRoleCheck = () => {
    AddRoleMenue.RoleMenusCheck().then((res: any) => {
      setTimeout(() => {
        setLoadingSpinnerLayout(false);
      }, 400)

      setRoleSetting(res.data);
      if (res?.response?.status === 401) {
        window.location.pathname = "/";
      }
    });
  };
  const GetRoleMenu = (item: any) => {

    var data = {
      RoleID: item
    };
    AddRoleMenue.GetAllMenusByRole(data).then((res: any) => {

      if (res.status === 200) {
        const toggles = res.data.reduce((acc: any, curr: any) => {
          acc[curr.p_MenuId] = true;
          return acc;
        }, {});
        setMenuToggles(toggles);

      }
      if (res?.response?.status === 401) {
        window.location.pathname = "/";

      }
    });
  };

  const handleSubmit = async (values: settingForm) => {
    setLoading(true);
    const newArray = [];
    const RoleId = values.roleId;

    for (const [key, value] of Object.entries(menuToggles)) {
      if (value) {
        newArray.push({
          "p_MenuId": parseInt(key),
          "checked": true
        });
      }
    }
    const data = {
      RoleId,
      MenuIds: newArray
    };
    if (data.MenuIds.length === 0) {
      setLoading(false);
      toast.current?.show({ severity: "warn", summary: "Warning", detail: "Please select atleast one Menu", life: 3000 });
    }
    if (formik.isValid && data.MenuIds.length !== 0) {
      try {
        await UpdateRole(data);

      } catch (error) {
        console.error("Failed to add employee:", error);
      }
    }
  };

  const onRoleMenuChange = (item: any) => {
    setselectedRoleMenu(item);
    GetRoleMenu(item.p_code);
  };


  const UpdateRole = (data: any) => {

    AddRoleMenue.UpdateRoleSetting(data).then((res: any) => {
      try {
        if (res.status === 200) {
          setLoading(false);
          toast.current?.show({ severity: "success", summary: "success", detail: "Roles updated successfully", life: 3000 });
        }
        if (res.response.status === 400) {
          setLoading(false);
          const errorMessage = res.response.data.errorMessage;
          toast.current?.show({ severity: "warn", summary: "Warning", detail: errorMessage, life: 3000 });
        }
        return res.data;
        // Redirect the user to the protected route
      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          setLoading(false);
          const errorMessage = error.response.data.errorMessage;
          props.toast.current?.show({ severity: "warn", summary: "Warning", detail: errorMessage, life: 3000 });
        }
      }
    });
  }


  const selectedRoleTemplate = (option: RoleId) => {

    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.p_rolename}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const RoleOptionTemplate = (option: RoleId) => {
    return (
      <div className="flex align-items-center">
        <div>{option.p_rolename}</div>
      </div>
    );
  };


  useEffect(() => {
    GetRole();
    GetRoleCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const initialValues = {
    roleId: null,
    Dashboard: false,
    POI: false,
    Users: false,
    CMS: false,
    Visitors: false,
    Protocol: false,
    Area: false,
    SubArea: false,
    Report:false,
    Setting: false,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RoleValidation,
    onSubmit: handleSubmit,
  });

  const onSaveClick = (data: any) => {
    formik.handleSubmit(data);
  };

  const onCancelClick = () => {
    formik.resetForm();
    setselectedRoleMenu(null);
  }
  return (

    <div className="ml-2">
      <Toast ref={toast} />
      {loadingSpinnerLayout ?
        <ProgressSpinner style={{ width: '70px', height: '70px', }} strokeWidth="2" fill="var(--surface-ground)" animationDuration=".9s" />
        : <>
          <div className="transparant-table">
            <form onSubmit={formik.handleSubmit}>
              <div className="col-4 relative mt-2 ml-2">
                <label htmlFor="role" className="block text-900 font-medium mb-2">
                  {resource.selectUserRole}
                </label>
                <Dropdown
                  value={selectedRoleMenu}
                  onChange={(e: DropdownChangeEvent) => {
                    formik.setFieldValue("roleId", e.value.p_code)
                    onRoleMenuChange(e.value);
                  }}
                  options={RoleMenu}
                  valueTemplate={selectedRoleTemplate}
                  itemTemplate={RoleOptionTemplate}
                  optionLabel="roleId"
                  placeholder="Select a role"
                  className="w-full"
                  filter
                  filterBy="p_rolename"
                />
                {formik.errors.roleId && formik.touched.roleId && <div className="text-red-500 text-xs">{formik.errors.roleId}</div>}

              </div>
              <div className="p-3">
                <h4 className="mb-4">{resource.assignPermissions}</h4>

                <></>
                <div className="card col-6 flex mt-3 flex-wrap bg-white" >
                  {roleSetting && Array.isArray(roleSetting) && roleSetting.map((item: any, index: number) => (

                    <div className="col-6 flex align-items-center" key={index}>
                      <div className="col-6">
                        <label htmlFor={item.p_Description} className="block text-900 font-medium mb-2">
                          {item.p_Description}
                        </label>
                      </div>
                      <div className="col-6">
                        <InputSwitch
                          checked={menuToggles[item.p_MenuId]}
                          onChange={(e) => {
                            const newValue = !menuToggles[item.p_MenuId];
                            setMenuToggles(prevState => ({ ...prevState, [item.p_MenuId]: newValue }));
                            formik.setFieldValue(item.p_MenuId, newValue);
                          }}
                        />
                      </div>
                    </div>

                  ))}

                  <div className="col-12 cancel-submit-btn mb-4 mt-4">
                    <Button className="cancel-btn " label={resource.cancel} type="reset" onClick={onCancelClick} />
                    <Button className="submit-btn ml-2 " label={resource.submit} type="submit" loading={loading} onClick={onSaveClick} />
                  </div>
                </div>
                {/* <UploadComponent/> */}

              </div>
            </form>
          </div>
        </>}

    </div>
  );
};

export default RoleSetting;