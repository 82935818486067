import React, { useEffect, useState, useRef } from "react";
import { DataTable, DataTableExpandedRows, DataTableRowEvent, DataTableValueArray } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import therapyServices from "../../services/therapyServices";

interface Therapy {
  p_therapyid: number;
  p_therapyname: string;
  p_description: string;
  p_isactive: boolean;
}

const TherapyTable = (props: any) => {
  const [selectedTherapy, setSelectedTherapy] = useState<Therapy[]>([]);
  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | DataTableValueArray | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const toast = useRef<Toast>(null);
  const [totalRecordsTherapy, setTotalRecordsTherapy] = useState<number>(0);

  const EditTherapy = (rowData: Therapy) => {
    props.onEditTherapyClicked(rowData);
  };

  const onDeleteClick = async (data: Therapy) => {
  
    const therapyId = {
      therapyId: data.p_therapyid,
    };
    confirmDialog({
      message: `Are you sure you want to delete Therapy?`,
      header: "Confirm delete Therapy",
      icon: "pi pi-exclamation-triangle",
      accept: () => onTherapyDelete(therapyId)
    });
  };

  const onTherapyDelete = async (therapyId: any) => {
    setLoading(true);
    await therapyServices.DeleteTherapy(therapyId)
      .then((res: any) => {
        if (res.data.p_response) {
          toast.current?.show({ severity: "success", summary: "Success", detail: res.data.p_msg, life: 3000 });
          GetTherapy();
        } else {
          toast.current?.show({ severity: "warn", summary: "Warning", detail: res.data.p_msg, life: 3000 });
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("DeleteTherapy error:", error);
        toast.current?.show({ severity: "error", summary: "Error", detail: "Something went wrong", life: 3000 });
      });
  };

  const verifiedBodyTemplate = (rowData: Therapy) => {
    return (
      <React.Fragment>
        <Button className="table-button custome-icon edit-icon" onClick={() => EditTherapy(rowData)} tooltip="Edit" />
        <Button className="table-button custome-icon delete-icon" onClick={() => onDeleteClick(rowData)} tooltip="Delete"></Button>
      </React.Fragment>
    );
  };

  const onRowExpand = (event: DataTableRowEvent) => {
    setExpandedRows(event.data);
  };

  const rowExpansionTemplate = (data: Therapy) => {
    return (
      <div className="p-3">
        <h5>Orders for {data.p_therapyname}</h5>
      </div>
    );
  };

  useEffect(() => {
    setLoading(true);
    GetTherapy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    GetTherapy();
  }, [props.reloadTherapyGrid]);

  const GetTherapy = () => {
    const data = {
      therapyid: null,
      poiid: null,
    };

    therapyServices.GetTherapy(data)
      .then((res: any) => {
        setLoading(true);
        if (res.status === 200) {
          setSelectedTherapy(res.data);
          setTotalRecordsTherapy(res.data.length);
          props.setTherapyDataFilter(res.data);
          setLoading(false);
        } else {
          setLoading(false);
          toast.current?.show({ severity: "warn", summary: "Warning", detail: "Failed to fetch activities", life: 3000 });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("GetTherapy error:", error);
        toast.current?.show({ severity: "error", summary: "Error", detail: "Something went wrong", life: 3000 });
      });
  };

  const SrNo = (rowData: any, index: any) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{ index.rowIndex + 1}</span>
      </div>
    );
  };

  const Status = (rowData: Therapy) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{rowData.p_isactive ? "Active" : "Inactive"}</span>
      </div>
    );
  };

  return (
    <div className="table-section">
      <Toast ref={toast} />
      <DataTable
        value={props.filteredTherapyData}
        //  value={selectedTherapy}
        dataKey="p_therapyid"
        scrollHeight="600px"
        paginator rows={15}
        loading={loading}
        emptyMessage="No Therapy found."
        onRowExpand={onRowExpand}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
      >
        <Column field="srNo" header="Sr. No." style={{ minWidth: "3rem" }} body={SrNo} />
        <Column field="p_therapyname" header="Therapy name" style={{ minWidth: "12rem" }} sortable />
        <Column field="p_description" header="Description" style={{ minWidth: "12rem" }} sortable />
        <Column field="p_isactive" header="Status" body={Status} sortable />
        <Column field="Action" header="Action" style={{ minWidth: "6rem" }} body={verifiedBodyTemplate} />
      </DataTable>
    </div>
  );
};

export default TherapyTable;