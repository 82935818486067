import axios from "axios";

const data = {
  id: null,
};

const GetAllCountry = async () => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_CMS_ENDPOINT}api/CSC/GetCountry`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const GetAllState = async (stateData: any) => {
  return axios
    .post(process.env.REACT_APP_BASE_CMS_ENDPOINT + `api/CSC/GetState`, stateData, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res)
    .catch((err) => err);
};

const GetAllCity = async (cityData: any) => {
  return axios
    .post(process.env.REACT_APP_BASE_CMS_ENDPOINT + `api/CSC/GetCity`, cityData, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res)
    .catch((err) => err);
};
const AddressService = {
  GetAllCountry,
  GetAllState,
  GetAllCity,
};

export default AddressService;
