import { useContext, useEffect, useRef, useState } from "react";
import SitumServices from "../../../services/SitumServices";
import SitumTableLayout from "./SitumTable";
import SitumTable from "./SitumTable";
import HeaderWithBackButton from "../../common/HeaderWIthBackButton";
import { contextStore } from "../../../context/CustomStateProvider";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";
import SitumSettingPopup from "./SitumSettingPopup";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { isEmptyArray } from "formik";

const SitumLayout = (props: any) => {
  const context = useContext(contextStore);
  const resource = context.state.strings;
  const [searchQuery, setSearchQuery] = useState("");
  const [poiDataFilter, setPoiDataFilter] = useState([]);
  const [loadingSpinnerLayout, setLoadingSpinnerLayout] =
    useState<boolean>(true);
  const toast = useRef<Toast>(null);
  const [situmSettingPopup, setSitumSettingPopup] = useState(false);
  const [poiData, setPoiData] = useState<PoiData[]>([]);
  const [refreshData, setRefreshData] = useState<boolean>(false);

  interface PoiData {
    poiId: number;
    poiName: string;
    latitude: string;
    longitude: string;
    synctype: string;
    areaname: string;
    subareaname: string;
  }

  const cardControls = [
    {
      ctrlType: "button",
      name: "btnAdd",
      title: resource.situmSettings,
      type: "button",
      disabled: false,
      className: "green-btn mr-2",
    },
  ];

  const handleSearch = (event: any) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    setTimeout(() => {
      setRefreshData(false);
      setLoadingSpinnerLayout(false);
    }, 400);
  }, []);

 

  const handlePoiData = (data: any[]) => {
    setPoiData(data);
  };

  const onButtonClick = () => {
    setSitumSettingPopup(true);
  };

  const onSyncButtonClick = () => {
    if (poiData.length > 10) {
      toast.current?.show({
        severity: "warn",
        summary: "Warning",
        detail: "Please select only up to 10 POIs to sync",
        life: 3000,
      });
      return;
    }
    
    if (poiData.length > 0) {
      setLoadingSpinnerLayout(true);
      const poiJson = poiData.reduce((acc, poi) => {
        if (!acc.POIId) {
          acc.POIId = [];
        }
        acc.POIId.push(poi.poiId);
        return acc;
      }, {} as { POIId: number[] });

      PublishPoi(poiJson);
    } else {
      emptyPoiToast();
    }
  };
  const emptyPoiToast = () => {
    toast.current?.show({
      severity: "warn",
      summary: "Warning",
      detail: "Please select poi to sync",
      life: 3000,
    });
  };
  const PublishPoi = (poiJson: any) => {
    SitumServices.PublishPoi(poiJson).then((res: any) => {
      try {
        if (res.status === 200) {
          setLoadingSpinnerLayout(false);
          if (res.data.p_response) {
            toast.current?.show({
              severity: "success",
              summary: "Success",
              detail: res.data.p_msg,
              life: 4000,
            });
          } else {
            toast.current?.show({
              severity: "warn",
              summary: "Warning",
              detail: res.data.p_msg,
              life: 4000,
            });
          }
          RefeshTablePoiData();
        } else if (res.response.status === 500) {
          setLoadingSpinnerLayout(false);
          const errorMessage = res.response.data.errorMessage;
          toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: errorMessage,
            life: 4000,
          });
          RefeshTablePoiData();
        } else if (
          res.response.status === 400 &&
          res.response.data.success === false
        ) {
          setLoadingSpinnerLayout(false);
          const errorMessage = res.response.data.errorMessage;
          toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: errorMessage,
            life: 4000,
          });
          RefeshTablePoiData();
        }
        RefeshTablePoiData();

        return res.data;
      } catch (error: any) {
        setLoadingSpinnerLayout(false);
        if (error.response && error.response.status === 401) {
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: "Unauthorized",
            life: 4000,
          });
        }
      }
      setLoadingSpinnerLayout(false);
    });
  };

  const RefeshTablePoiData = () => {
    setRefreshData(true);
  };

  const filteredPoiData = poiDataFilter?.filter((poiDataFilter: any) => {
    return (
      poiDataFilter?.poiName
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      poiDataFilter?.latitude
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      poiDataFilter?.longitude
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      poiDataFilter?.synctype
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      poiDataFilter?.areaname
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      poiDataFilter?.subareaname
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase())
    );
  });

  return (
    <div className="ml-2">
        <Toast ref={toast} />
        {loadingSpinnerLayout ? (
          <ProgressSpinner
            style={{ width: "70px", height: "70px" }}
            strokeWidth="2"
            fill="var(--surface-ground)"
            animationDuration=".9s"
          />
        ) : (
          <>
            {situmSettingPopup && (
              <SitumSettingPopup
                situmSettingPopup={situmSettingPopup}
                setSitumSettingPopup={setSitumSettingPopup}
                toast={toast}
              />
            )}       
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",// Aligns items to the right
              alignItems: "center",
            }}
          >
          <div className="col-4">
              <h3>{resource.syncPoiData}</h3>
            </div>
            <div className="col-4">
          <HeaderWithBackButton  controls={[]} handleSearch={handleSearch} searchQuery={searchQuery} /> 
          </div>
          <div className="col-4">
          <Button
            label={resource.situmSettings}
            onClick={onButtonClick}
            className="green-btn mr-2 mb-3 mt-2"
            />
            <Button
              label={resource.syncPOiData}
              onClick={onSyncButtonClick}
              className="green-btn mr-2 mb-3 mt-2"
            />
          </div>


          </div>
            <div className="transparant-table">
              <div className="row flex">
                <div className="col-11">
                  <SitumTable
                    poiDataFilter={poiDataFilter}
                    setPoiDataFilter={setPoiDataFilter}
                    filteredPoiData={filteredPoiData}
                    onPoiData={handlePoiData}
                    RefeshTablePoiData={RefeshTablePoiData}
                    refreshData={refreshData}
                    setRefreshData={setRefreshData}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
  );
};
export default SitumLayout;
