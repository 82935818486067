import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable, DataTableExpandedRows, DataTableRowEvent, DataTableValueArray } from "primereact/datatable";
import React, { useEffect, useState, useRef } from "react";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import ExpertiesService from "../../services/expertiseServices";
import { Paginator } from "primereact/paginator";

const ExpertiesTable = (props: any) => {
  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | DataTableValueArray | undefined>(undefined);
  const [, setExpand] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const toast = useRef<Toast>(null);

  useEffect(() => {
    if (!props.addNewAreaPopupe) {
      props.setLoading(true);
      GetExpert(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.addNewAreaPopup === false]);


  useEffect(() => {
    if (props.isSubmitValue === true) {
      props.setLoading(true);
      GetExpert(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isSubmitValue === true]);

  const GetExpert = (experdata: any) => {
    props.setLoading(false);
    ExpertiesService.GetAllExpter().then((res: any) => {
      try {
        if (res.status === 200) {
          props.setExpertDatFilter(res.data);
          props.setLoading(false);
          props.setIsSubmitValue(false);
        }
        return res.data;
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
        }
      }
    });
  };

  const EditExpert = (rowData: any) => {
    setTimeout(() => {
      props.onEditExpertClicked(rowData);
    }, 10);
  };

  const onDeleteClick = (data: any) => {
    const values = {
      expertpanelid: data.expertpanelid,
    };

    confirmDialog({
      message: `Are you sure you want to delete expert?`,
      header: "Confirm delete area",
      icon: "pi pi-exclamation-triangle",
      accept: () => onExpertDelete(values),
    });
  };

  const onExpertDelete = (values: any) => {
    props.setLoading(true);
    ExpertiesService.DeleteExpter(values)
      .then((res: any) => {
        if (res.data.p_response) {
          toast.current?.show({ severity: "success", summary: "Success", detail: res.data.p_msg, life: 3000 });
          GetExpert(null);
        } else {
          toast.current?.show({ severity: "warn", summary: "Warning", detail: res.data.p_msg, life: 3000 });
          props.setLoading(false);
        }
      })
      .catch((error) => {
        props.setLoading(false);
        console.error("AddNewAreaService error:", error);
        toast.current?.show({ severity: "error", summary: "Error", detail: "Something went wrong", life: 3000 });
      });
  };
  const verifiedBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <Button className="table-button custome-icon  edit-icon " onClick={() => EditExpert(rowData)} tooltip="Edit" />
        <Button className="table-button custome-icon delete-icon" onClick={() => onDeleteClick(rowData)} tooltip="Delete"></Button>
      </React.Fragment>
    );
  };

  const onRowExpand = (event: DataTableRowEvent) => {
    setExpand(true);
  };

  const onRowCollapse = (event: DataTableRowEvent) => {
    setExpand(false);
  };

  const rowExpansionTemplate = (data: any) => {
    return (
      <div className="p-3">
        <h5>Orders for</h5>
      </div>
    );
  };

  const SrNo = (rowData: any, index: any) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{index.rowIndex + 1}</span>
      </div>
    );
  };
  const Status = (rowData: any) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{rowData.isactive === true ? "Active" : "InActive"}</span>
      </div>
    );
  };

  return (
    <div className="table-section">
      <Toast ref={toast} />
      <DataTable paginator rows={15}
        value={props.filteredExpterData}
        dataKey="id"
        scrollHeight="600px"
        loading={props.loading}
        emptyMessage="No expert found."
        onRowExpand={onRowExpand}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        onRowCollapse={onRowCollapse}
        rowExpansionTemplate={rowExpansionTemplate}
      >
        <Column field="srNo" header="Sr.No." style={{ minWidth: "3rem" }} body={SrNo} />
        <Column field="expertpanelname" header=" Expert name" style={{ minWidth: "12rem" }} sortable />
        <Column header="Description" field="description" style={{ minWidth: "12rem" }} sortable />
        <Column field="isactive" header="Status" body={Status} sortable />
        <Column field="Action" header="Action" style={{ minWidth: "6rem" }} body={verifiedBodyTemplate} />
      </DataTable>
    </div>
  );
};

export default ExpertiesTable;
