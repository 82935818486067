import { useContext, useEffect, useRef, useState } from "react";
import HeaderWithBackButton from "../common/HeaderWIthBackButton";
import { contextStore } from "../../context/CustomStateProvider";
import AddAreaPopup from "./AddAreaPopup";
import { Toast } from "primereact/toast";
import NewAreaTable from "./NewAreaTable";
import { ConfirmDialog } from "primereact/confirmdialog";

const AreaLayout = (props: any) => {
  const context = useContext(contextStore);
  const resource = context.state.strings;
  const [addAreaChange, setAddAreaChange] = useState(false);
  const [backButton, setBackButton] = useState(false);
  const [addNewAreaPopup, setaddNewAreaPopup] = useState(false);
  const [isEditSubArea, setIsEditSubArea] = useState();
  const [editMode, setEditMode] = useState(false);
  const toast = useRef<Toast>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [areaDataFilter, setAreaDatFilter] = useState([]);
  const [isAreaAdmin, setIsAreaAdmin] = useState(false);
  const AREA_ADMIN_ROLE_ID = 97;

  const cardControls = [
    {
      ctrlType: "button",
      name: "btnAdd",
      title: resource.addNewArea,
      type: "button",
      disabled: false,
      className: "green-btn mr-2",
    },
  ];

  const onButtonClick = (e: string) => {
    if (e === "btnAdd") {
      setAddAreaChange(true);
      setaddNewAreaPopup(true);
      setEditMode(false);
    } else if (e === "btnAddSubArea") {
      setAddAreaChange(true);
      setEditMode(false);
    }
  };
  const onBackButtonClick = () => {
    setBackButton(!backButton);
  };

  const onEditSubAreaClicked = (data: any) => {
    setaddNewAreaPopup(true);
    setIsEditSubArea(data);
    setEditMode(true);
  };

  useEffect(() => {
    const UserName = localStorage.getItem("user");
    if (UserName !== null) {
      const userData = JSON.parse(UserName);
      setIsAreaAdmin(parseInt(userData.p_roleid) === AREA_ADMIN_ROLE_ID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const filteredEmployeeData = areaDataFilter?.filter((areaDataFilter: any) => {
    return (
      areaDataFilter?.p_areaname?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      areaDataFilter?.p_description?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      areaDataFilter?.p_description?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  return (
    <div className="container ">
      <Toast ref={toast} />
      {addNewAreaPopup && (
        <AddAreaPopup
          isEditSubArea={isEditSubArea}
          addNewAreaPopup={addNewAreaPopup}
          setaddNewAreaPopup={setaddNewAreaPopup}
          editMode={editMode}
          toast={toast}
          isAreaAdmin={isAreaAdmin}
        />
      )}

      <HeaderWithBackButton
        title={!addAreaChange || backButton ? resource.areaDetails : resource.areaDetails}
        controls={!isAreaAdmin ? cardControls : []}
        onButtonClicked={onButtonClick}
        searchQuery={searchQuery}
        handleSearch={handleSearch}
        onBackButtonClick={onBackButtonClick}
      />

      <div className="transparant-table">
        <div className="row flex">
          <div className="col-12">
            <NewAreaTable
              onEditSubAreaClicked={onEditSubAreaClicked}
              filteredEmployeeData={filteredEmployeeData}
              addNewAreaPopup={addNewAreaPopup}
              setAreaDatFilter={setAreaDatFilter}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AreaLayout;
