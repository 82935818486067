import * as Yup from "yup";


const timeFormatRegex = /^(0[1-9]|1[0-2]):([0-5][0-9])\s?(AM|PM)$/;

const AddProtocolValidation = Yup.object().shape({
    activityId: Yup.string().required('Please select activity'),
    poiId: Yup.string().required('Please select POI'),
    date: Yup.string().required('Please select date'),
    does: Yup.string().max(500, "Do's cannot be longer than 500 characters")
   .matches(/^[A-Za-z0-9\s,-.?!/()%&']+$/, "Please enter do's using letter and digits only."),
   donts: Yup.string().max(500, "Don'ts cannot be longer than 500 characters")
   .matches(/^[A-Za-z0-9\s,-.?!/()%&']+$/, "Please enter don'ts using letter and digits only."),
    customTimeChangeValue: Yup.string()
    .required("Please enter time")  
   .matches(timeFormatRegex, "Please enter valid time")
});


export default AddProtocolValidation;
