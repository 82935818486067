import React, { useEffect, useState, useRef } from "react";
import { DataTable, DataTableExpandedRows, DataTableValueArray } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import poiServices from "../../services/poiServices";
import { FilterMatchMode } from "primereact/api";
import { Image } from "primereact/image";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { Paginator } from "primereact/paginator";

interface POIData {
  p_poiid: string;
  p_poiname: string;
  p_description: string;
  p_code: string;
  p_capacity: string;
  p_latitude: string;
  p_longitude: string;
  p_isactive: boolean;
  p_imageurl: string;
  p_iconurl: string;
}

const PoiTable = (props: any) => {
  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | DataTableValueArray | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingImageSpinner, setLoadingImageSpinner] = useState<boolean>(false);

  const [filters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    p_areaname: { value: null, matchMode: FilterMatchMode.CONTAINS },
    p_subareaname: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [imageURL, setImageURL] = useState("");
  const [visible, setVisible] = useState<boolean>(false);
  const toast = useRef<Toast>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);
  const [totalRecordsPOI, settotalRecordsPOI] = useState<number>();
  const [poiTableData, setPoiTableData] = useState([]);
  const [previousSearch, setPreviousSearch] = useState(null);

  useEffect(() => {
    ShowPOI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reloadPOIGrid, currentPage]);

  useEffect(() => {
    if (props.searchQuery.trim() !== previousSearch) {
      ShowPOI();
      setPreviousSearch(props.searchQuery.trim());
      setCurrentPage(1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.searchQuery]);

  const ShowPOI = () => {
    setLoading(true);
    var data = {
      poiid: null,
      areaid: null,
      subareaid: null,
      currentPage: currentPage,
      itemsPerPage: itemsPerPage,
      searchQuery: props.searchQuery,
    };

    poiServices.GetPOIData(data).then((res: any) => {
      try {
        if (res.status === 200) {
          if (res.data && res.data[0] && res.data[0].total_count !== undefined) {
            settotalRecordsPOI(res.data[0].total_count);
          }
          setPoiTableData(res.data);
          setLoading(false);
        }
        return res.data;
        // Redirect the user to the protected route
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          setLoading(false);
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: "Unauthorized",
            life: 3000,
          });
          window.location.pathname = "/";
        }
      }
    });
  };

  const onEditClick = (editData: POIData) => {
    props.onEditPoi(editData);
  };

  const onDeleteClick = (data: any) => {
    var PoiId = {
      poiid: data.p_poiid,
    };

    confirmDialog({
      message: `Are you sure you want to delete POI?`,
      header: "Confirm delete POI",
      icon: "pi pi-exclamation-triangle",
      accept: () => onPOIDelete(),
    });

    const onPOIDelete = () => {
      setLoading(true);
      poiServices
        .DeletePOIData(PoiId)
        .then((res: any) => {
          if (res.data.p_response) {
            toast.current?.show({ severity: "success", summary: "Success", detail: res.data.p_msg, life: 3000 });
            ShowPOI();
          } else {
            toast.current?.show({ severity: "warn", summary: "Warning", detail: res.data.p_msg, life: 3000 });
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("AddNewAreaService error:", error);
          toast.current?.show({ severity: "error", summary: "Error", detail: "Something went wrong", life: 3000 });
        });
    };
  };

  const onShowImageClick = (editData: POIData) => {
    if (editData.p_imageurl !== null || editData.p_imageurl !== "") {
      setLoadingImageSpinner(true);
      var POIdata = {
        poiid: editData.p_poiid,
        imageUrl: editData.p_imageurl,
        iconUrl: null,
      };

      poiServices.getPresignedURL(POIdata).then((res: any) => {
        try {
          if (res.data.imageFile) {
            setVisible(true);
            setImageURL(res.data.imageFile);
          } else {
            setLoadingImageSpinner(false);
          }
        } catch (error: any) {
          setLoadingImageSpinner(false);
          if (error.response && error.response.status === 401) {
            props.toast.current?.show({
              severity: "warn",
              summary: "Warning",
              detail: "Unauthorized",
              life: 3000,
            });
            window.location.pathname = "/";
          }
        }
      });
    }
  };

  const onShowIconClick = (editData: POIData) => {
    if (editData.p_iconurl !== null || editData.p_iconurl !== "") {
      setLoadingImageSpinner(true);
      var POIdata = {
        poiid: editData.p_poiid,
        imageUrl: null,
        iconUrl: editData.p_iconurl,
      };

      poiServices.getPresignedURL(POIdata).then((res: any) => {
        try {
          if (res.data.iconFile) {
            setVisible(true);
            setImageURL(res.data.iconFile);
          } else {
            setLoadingImageSpinner(false);
          }
        } catch (error: any) {
          setLoadingImageSpinner(false);
          if (error.response && error.response.status === 401) {
            props.toast.current?.show({
              severity: "warn",
              summary: "Warning",
              detail: "Unauthorized",
              life: 3000,
            });
            window.location.pathname = "/";
          }
        }
      });
    }
  };

  const verifiedBodyTemplate = (rowData: POIData) => {
    return (
      <React.Fragment>
        <Button className="table-button custome-icon  edit-icon " onClick={() => onEditClick(rowData)} tooltip="Edit" />
        <Button className="table-button custome-icon delete-icon" onClick={() => onDeleteClick(rowData)} tooltip="Delete"></Button>
      </React.Fragment>
    );
  };

  const ShowImages = (rowData: POIData) => {
    if (rowData.p_imageurl) {
      return (
        <React.Fragment>
          <Button className="table-button p-2" onClick={() => onShowImageClick(rowData)}>
            <i className="pi pi-eye" style={{ color: "#e0a553" }}></i>
          </Button>
        </React.Fragment>
      );
    }
  };

  const ShowIcon = (rowData: POIData) => {
    if (rowData.p_iconurl) {
      return (
        <React.Fragment>
          <Button className="table-button p-2" onClick={() => onShowIconClick(rowData)}>
            <i className="pi pi-eye" style={{ color: "#e0a553" }}></i>
          </Button>
        </React.Fragment>
      );
    }
  };

  const rowExpansionTemplate = (data: any) => {
    return (
      <div className="p-3">
        <h5>Orders for</h5>
      </div>
    );
  };

  const SrNo = (rowData: POIData, index: any) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{(currentPage - 1) * itemsPerPage + (index.rowIndex + 1)}</span>
      </div>
    );
  };

  const Status = (rowData: POIData) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{rowData.p_isactive === true ? "Active" : "InActive"}</span>
      </div>
    );
  };

  const handleImageLoad = () => {
    setLoadingImageSpinner(false);
  };

  return (
    <div className="table-section">
      <Toast ref={toast} />
      <div className="card flex justify-content-center  ">
        <Dialog
          header="Image"
          visible={visible}
          style={{ width: "50vw" }}
          onHide={() => {
            if (!visible) return;
            setLoadingImageSpinner(false);
            setVisible(false);
          }}
          className="pop-up-close"
          draggable={false}
        >
          <p className="m-0">
            {imageURL && <Image src={imageURL} alt="Image" width="250" onLoad={handleImageLoad} />}</p>
          {loadingImageSpinner && (
            <div className=" file-upload-spinner">
              <ProgressSpinner style={{ width: "70px", height: "70px" }} strokeWidth="2" fill="var(--surface-ground)" animationDuration=".8s" />
            </div>
          )}
        </Dialog>
      </div>

      <DataTable
        value={poiTableData}
        dataKey="id"
        scrollable
        scrollHeight="70vh"
        loading={loading}
        emptyMessage="No POI found."
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        filterDisplay="row"
        // paginator
        // rows={15}
        filters={filters}
        globalFilterFields={["country.name"]}
      >
        <Column field="srNo" header="Sr. No." style={{ minWidth: "5rem", maxWidth: "5rem" }} body={SrNo} />
        <Column field="p_poiname" header="POI name" sortable style={{ minWidth: "8rem", maxWidth: "12rem" }} />
        <Column field="Images" header="Image" style={{ minWidth: "5rem", maxWidth: "5rem" }} body={ShowImages} />
        <Column field="Icon" header="Icon" style={{ minWidth: "5rem", maxWidth: "5rem" }} body={ShowIcon} />
        <Column field="p_code" header="Code" style={{ minWidth: "8rem", maxWidth: "10rem" }} sortable />
        {/* <Column field="icon"header="Icon" style={{ minWidth: "2rem" }} sortable /> */}
        {/* <Column field="p_description" header="Description" style={{ width: "15rem" }} sortable /> */}
        <Column field="p_capacity" header="Master capacity" sortable style={{ minWidth: "10rem", maxWidth: "15rem" }} />
        <Column field="p_latitude" header="Lat" sortable style={{ minWidth: "8rem", maxWidth: "10rem" }} />
        <Column field="p_longitude" header="Long" sortable style={{ minWidth: "8rem", maxWidth: "10rem" }} />
        <Column field="p_areaname" header="Area" sortable filter filterField="p_areaname" style={{ minWidth: "10rem", maxWidth: "15rem" }} />
        <Column field="p_subareaname" header="Subarea" filter filterField="p_subareaname" sortable style={{ minWidth: "10rem", maxWidth: "15rem" }} />
        <Column field="p_sequence" header="Sequence" sortable style={{ minWidth: "10rem", maxWidth: "15rem" }} />
        {/* <Column field="p_images" header="Images" sortable/> */}
        <Column field="p_isactive" header="Status" body={Status} sortable style={{ minWidth: "6rem", maxWidth: "8rem" }} />
        <Column field="Action" header="Action" style={{ minWidth: "8rem", maxWidth: "12rem" }} body={verifiedBodyTemplate} />
      </DataTable>
      <Paginator
        first={(currentPage - 1) * itemsPerPage}
        rows={itemsPerPage}
        totalRecords={totalRecordsPOI}
        onPageChange={(event) => setCurrentPage(event.page + 1)}
      />
    </div>
  );
};

export default PoiTable;
