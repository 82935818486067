import { useEffect, useRef, useState } from "react";
import SitumServices from "../../../services/SitumServices";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import { Tooltip } from "primereact/tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { DataTable, DataTableExpandedRows, DataTableRowEvent, DataTableValueArray } from "primereact/datatable";




interface PoiData {
    poiId:number;
    poiName: string,
    latitude: string,
    longitude: string,
    synctype: string,
    areaname: string,
    subareaname: string,
    situm_error :string    
  }

const SitumTable = (props: any) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | DataTableValueArray | undefined>(undefined);
    const [selectedPoiData, setSelectedPoiData] = useState<PoiData[]>([]);
    const [selectedPOIs, setSelectedPOIs] = useState<PoiData[]>([]);
    const [allSelected, setAllSelected] = useState(false); 
    const [, setExpand] = useState(false);





    const GetPOIDataForSync = async () =>
 {
    SitumServices.GetPOIDataForSync().then((res: any) => {
      try {
        if (res.status === 200) {
        setSelectedPoiData(res.data);
        props.setPoiDataFilter(res.data);
        setLoading(false);
        }

        return res.data;
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
         props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: "Unauthorized",
            life: 3000,
          });
          setLoading(false);
          window.location.pathname = "/";
        
      }
 }});
  };

  const onRowExpand = (event: DataTableRowEvent) => {
    setExpandedRows(event.data);
  };

  const rowExpansionTemplate = (data: PoiData) => {
    return (
      <div className="p-3">
        <h5>Orders for {data.poiName}</h5>
      </div>
    );
  };

  const onRowCollapse = (event: DataTableRowEvent) => {
    setExpand(false);
  };

  const errorIconTemplate = (rowData: PoiData) => {
    if (!rowData.situm_error) {
      return null;
    }
    
    return (
      <>
        <FontAwesomeIcon 
          icon={faExclamationTriangle} 
          id={`errorIcon_${rowData.poiId}`} 
          style={{ color: 'red', cursor: 'pointer',fontSize: '1.3rem' }} 
        />
        <Tooltip 
          target={`#errorIcon_${rowData.poiId}`} 
          content={rowData.situm_error} 
        />
      </>
    );
  };

    useEffect(() => {
        setLoading(true);
        //GetPOIDataForSync();
        GetPOIDataForSync().then(() => {
          setAllSelected(false); 
        });
      }, []);
    
      useEffect(() => {
        props.onPoiData(selectedPOIs); 
      }, [selectedPOIs, props.onPoiData]);

      useEffect(() => {
        if(props.refreshData)
        {
        GetPOIDataForSync(); 
        props.setRefreshData(false);
        setSelectedPOIs([]);
        }
      }, [props.RefeshTablePoiData]);

  const renderCheckbox = (rowData:PoiData) => {
    return (
      <Checkbox
        onChange={(e) => onCheckboxChange(e, rowData)}
        checked={selectedPOIs.includes(rowData)}
      />
    );
  };
  const onCheckboxChange = (e:any, poi:PoiData) => {
    let updatedSelectedPOIs = [...selectedPOIs];
    if (e.checked) {
      updatedSelectedPOIs.push(poi);
    } else {
      updatedSelectedPOIs = updatedSelectedPOIs.filter(selected => selected.poiId !== poi.poiId);
    }
    setSelectedPOIs(updatedSelectedPOIs);
    setAllSelected(updatedSelectedPOIs.length === props.filteredPoiData.length);
  };
  
  const onSelectAllChange = () => {
    const isChecked = !allSelected;
    setAllSelected(isChecked);
    if (isChecked) {
      setSelectedPOIs([...props.filteredPoiData]);
    } else {
      setSelectedPOIs([]);
    }
  };

      const SrNo = (rowData: any, index: any) => {
        return (
          <div className="flex align-items-center gap-2">
            <span>{ index.rowIndex + 1}</span>
          </div>
        );
      }; 

      const syncTypeTemplate = (rowData:PoiData) => {
        let color;
        switch (rowData.synctype.toLowerCase()) {
          case 'created':
            color = 'green';
            break;
          case 'deleted':
            color = 'red';
            break;
          case 'updated':
            color = 'orange';
            break;  
          default:
            color = 'black';
        }
        return <span style={{ color }}>{rowData.synctype}</span>;
      };

      useEffect(() => {
        setLoading(true);
        GetPOIDataForSync();
      }, []);

      useEffect(() => {
        setAllSelected(selectedPOIs.length === props.filteredPoiData.length);
      }, [selectedPOIs, props.filteredPoiData.length]
    );

      return (
        <>
        <div className="table-section">
        <DataTable
        paginator rows={15}
        value={props.filteredPoiData}
        dataKey="id"
        scrollHeight="60vh"
        loading={loading}
        emptyMessage="No POI found."
        onRowExpand={onRowExpand}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        onRowCollapse={onRowCollapse}
        rowExpansionTemplate={rowExpansionTemplate}
      >

         <Column
          header={
            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={onSelectAllChange}>
              <Checkbox onChange={onSelectAllChange} checked={allSelected} />
              <span style={{ textDecoration: 'underline', marginLeft: '5px' }}>All</span>
            </div>
          }
          body={renderCheckbox}
          style={{ minWidth: "6rem" }}
           />

            <Column field="srNo" header="Sr. No." style={{ minWidth: "3rem" }} body={SrNo} />
            <Column field="poiName" header="Poi name" style={{ minWidth: "12rem" }} sortable />
            <Column field="latitude" header="Latitude" style={{ minWidth: "12rem" }} sortable />
            <Column field="longitude" header="Longitude" style={{ minWidth: "12rem" }} sortable />
            <Column field="synctype" header="Sync type" style={{ minWidth: "12rem" }} body={syncTypeTemplate} sortable />
            <Column field="areaname" header="Area name" style={{ minWidth: "12rem" }} sortable />
            <Column field="subareaname" header="Subarea name" style={{ minWidth: "12rem" }} sortable />
            <Column field="situm_error" header="Sync error" style={{ minWidth: "12rem" }} body={errorIconTemplate} sortable/>
          </DataTable >
          </div>            
        </>
      );
};
export default SitumTable;