import React, { useContext, useEffect, useRef, useState } from "react";
import HeaderWithBackButton from "../common/HeaderWIthBackButton";
import { contextStore } from "../../context/CustomStateProvider";
import AddUserForm from "./AddUserForm";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";



const ProfileLayout = () => {
  const context = useContext(contextStore);
  const resource = context.state.strings;
  const [backButton, setBackButton] = useState(false);
  const toast = useRef<Toast>(null);
  const onBackButtonClick = () => {
    setBackButton(!backButton);
  };
  const [loadingSpinnerLayout, setLoadingSpinnerLayout] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setLoadingSpinnerLayout(false);
    }, 500)

  }, [])
  return (
    <div className="container ">
      <Toast ref={toast} />
      {loadingSpinnerLayout ?
        <ProgressSpinner style={{ width: '70px', height: '70px', }} strokeWidth="2" fill="var(--surface-ground)" animationDuration=".9s" /> :
        <>

          <HeaderWithBackButton title={resource.profile} controls={[]} onBackButtonClick={onBackButtonClick} isSearch />
          <div className="transparant-table">
            <AddUserForm toast={toast} setLoadingSpinnerLayout={setLoadingSpinnerLayout} />
          </div>
        </>
      }
    </div>
  );
};

export default ProfileLayout;
