import { Column } from "primereact/column";
import {
  DataTable,
  DataTableExpandedRows,
  DataTableValueArray,
} from "primereact/datatable";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import protocolServices from "../../services/protocolServices";
import { Toast } from "primereact/toast";
import vedicTreatmentServices from "../../services/vedicTreatmentServices";
import MedicineServices from "../../services/medicineServices";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import moment from "moment";
import HeaderWithBackButton from "../common/HeaderWIthBackButton";


interface Customer {
  srNo: number;
  p_prescriptiondate: any;
  p_prescriptionname: string;
  p_prescriptionid: number;
}

interface Therapyitem {
  srNo: number;
  p_therapylineitemid: number;
  p_therapyname: string;
  p_therapyid: string;
  p_scheduledate: string;
  p_scheduletime: string;
  p_does: string;
  p_donts: string;
}
const TreatmentHistory = (props: any) => {
  const [customerdata, setCustomerData] = useState<Customer[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState<any>([]);
  const toast = useRef<Toast>(null);
  const [expandedRows, setExpandedRows] = useState< DataTableExpandedRows | DataTableValueArray | undefined>(undefined);

  const [therapyItemList, setTherapyItemList] = useState<Therapyitem[]>([]);
  const [vedicTreatment, setvedicTreatment] = useState([]);
  const [medicineList, setMedicineList] = useState([]);
  const [protocolItemList, setProtocolItemList] = useState([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [datatableloading, setdatatableloading] = useState<boolean>(false);



  const handleSearch = (event: any) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    if (props.userTreatmentid) {
      ShowAllHistory();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userTreatmentid]);

  useEffect(() => {
    if (searchQuery) {
      const lowercasedFilter = searchQuery.toLowerCase();
      const filteredData = customerdata.filter((customer: any) => {
        return Object.keys(customer).some((key) =>
          String(customer[key]).toLowerCase().includes(lowercasedFilter)
        );
      });
      setFilteredData(filteredData);
    } else {
      setFilteredData(customerdata);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, customerdata]);

  useEffect(() => {
    if (expandedRows) {
      const expandedKeys = Object.keys(expandedRows);
      if (expandedKeys.length > 0) {
        const expandedKey = expandedKeys[0];
        const expandedRow = customerdata.find(
          (item) => item.p_prescriptionid === Number(expandedKey)
        );
        if (expandedRow) {
          GetMedicineLineItemValues(expandedRow.p_prescriptionid);
          GetTherapy(expandedRow.p_prescriptionid);
          GetProtocol(expandedRow.p_prescriptionid);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedRows]);

  const ShowAllHistory = () => {
    setLoading(true);
    const data = {
      prescriptiontoid: props.userTreatmentid,
    };
    protocolServices.GetProtocolTreatmentHistory(data).then((res: any) => {
      try {
        if (res.status === 200) {
          setCustomerData(res.data);
          setLoading(false);
        }
        if (res.response.status === 500) {
          toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: "Something went wrong",
            life: 3000,
          });
          setLoading(false);
        }
        return res.data;
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          setLoading(false);
        }
      }
    });
  };

  const SrNo = (rowData: any, index: any) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{index.rowIndex + 1}</span>
      </div>
    );
  };

  const onEditClick = (value: any) => {
    props.setAddProtcol(true);
    props.setEditPrescription(true);
    const data = {
      Prescriptionid: value.p_prescriptionid,
    };
    protocolServices.GetPrescription(data).then((res: any) => {
      try {
        if (res.status === 200) {
          props.setEditPrescriptionto(res.data);
        }
        return res.data;
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
        }
      }
    });
  };

  const onDeleteClick = (value: any) => {};

  const Action = (rowData: any) => {
    return (
      <React.Fragment>
         <Button className="table-button custome-icon  edit-icon " onClick={() => onEditClick(rowData)} />
        {/* <Button
        className="table-button custome-icon delete-icon"
        onClick={() => onDeleteClick(rowData)}>
        </Button> */}
        <Button
          className="table-button"
          onClick={() => onShowImageClick(rowData)}
          >
          <i className="pi pi-eye" style={{ color: "#e0a553" }}></i>
        </Button>
      </React.Fragment>
    );
  };
  const onShowImageClick = (editData: any) => {
    setdatatableloading(true);
    setVisible(true);
    GetMedicineLineItemValues(editData.p_prescriptionid);
    GetTherapy(editData.p_prescriptionid);
    GetProtocol(editData.p_prescriptionid);
    setTimeout(() => {
       setdatatableloading(false);
    }, 1000);
   
  };

  const GetTherapy = (value: number) => {
    const data = {
      Prescriptionid: value,
    };
    vedicTreatmentServices.GetTherapy(data).then((res: any) => {
      setTherapyItemList(res.data);
    });
  };
  const GetMedicineLineItemValues = (value: number) => {
    let data = {
      Prescriptionid: value,
    };

    MedicineServices.GetMedicineLineItem(data).then((res: any) => {
      setMedicineList(res.data);
    });
  };
  const GetProtocol = (value: number) => {
    const data = {
      Prescriptionid: value,
    };
    protocolServices.GetProtocol(data).then((res: any) => {
      setProtocolItemList(res.data);
    });
  };

  const TimeFormate = (rowData: any) => {
    const ConvertedTime = ConvertTime(rowData.p_scheduletime);
    return <p>{moment(ConvertedTime).format("h:mm A")}</p>;
  };

  const ConvertTime = (value: string) => {
    const [hours, minutes, seconds] = value.split(":");
    const scheduletime = new Date();
    scheduletime.setHours(Number(hours));
    scheduletime.setMinutes(Number(minutes));
    scheduletime.setSeconds(Number(seconds));
    return scheduletime;
  };
  const rowExpansionTemplate = (data: any) => {
    return (
      <>
        <div className="p-3 treament-history-row">
          <h5 className="med-text">Medical</h5>
          <DataTable
            value={medicineList}
            scrollable
            scrollHeight="750px"
            emptyMessage="No package detail found"
            loading={loading}
          >
            <Column header="Sr.No." style={{ minWidth: "2rem" }} body={SrNo} />
            <Column field="p_medicinename" header="Medicinename"></Column>
            <Column field="p_dose" header="Dose"></Column>
            <Column
              header="Start date"
              body={(rowData) => formatDate(rowData.p_startdate)}
            />
            <Column
              header="End date"
              body={(rowData) => formatDate(rowData.p_enddate)}
            />
            <Column field="p_frequency" header="Timing for doses"></Column>
            <Column field="p_days" header="Days"></Column>
            <Column field="p_does" header="Does"></Column>
            <Column field="p_donts" header="Donts"></Column>
            <Column field="Action" header="Action" body={Action}></Column>
          </DataTable>
        </div>
        <div className="p-3 treament-history-row">
          <h5 className="med-text">Vedic Treatments</h5>

          <DataTable
            value={therapyItemList}
            emptyMessage="No Therapy detail found"
            scrollable
            scrollHeight="400px"
            loading={loading}
            dataKey="id"
          >
            <Column
              header="Sr.No."
              style={{ minWidth: "100px", width: "100px" }}
              body={SrNo}
            />
            <Column
              field="p_therapyename "
              header="Therapy"
              style={{ minWidth: "200px", width: "200px" }}
            ></Column>
            
            <Column
              field="p_poiname "
              header="POI"
              style={{ minWidth: "200px", width: "200px" }}
            ></Column>
            <Column
              field="p_scheduledate"
              header="Date"
              style={{ minWidth: "250px", width: "250px" }}
              showFilterMenu={false}
            ></Column>
            <Column
              field="p_scheduletime"
              header="Time"
              style={{ minWidth: "250px", width: "250px" }}
              showFilterMenu={false}
              body={TimeFormate}
            ></Column>
            <Column
              field="p_does"
              header="Does"
              style={{ minWidth: "250px", width: "250px" }}
              showFilterMenu={false}
            ></Column>
            <Column
              field="p_donts"
              header="Donts"
              style={{ minWidth: "200px", width: "200px" }}
            ></Column>
            <Column
              field="Action"
              header="Action"
              style={{ minWidth: "100px" }}
              body={Action}
            ></Column>
          </DataTable>
        </div>
        <div className="p-3 treament-history-row">
          <h5 className="med-text">Protocol</h5>

          <DataTable
            value={protocolItemList}
            emptyMessage="No protocol detail found"
            scrollable
            scrollHeight="400px"
            loading={loading}
            dataKey="id"
          >
            <Column
              header="Sr.No."
              style={{ minWidth: "100px", width: "100px" }}
              body={SrNo}
            />
            <Column
              field="p_activityname"
              header="Activity"
              style={{ minWidth: "200px", width: "200px" }}
            ></Column>
            <Column
              field="p_poiname"
              header="Point of interests"
              style={{ minWidth: "200px", width: "200px" }}
            ></Column>
            <Column
              field="p_videoname"
              header="Video"
              style={{ minWidth: "200px", width: "200px" }}
            ></Column>
            <Column
              field="p_audioname"
              header="Audio"
              style={{ minWidth: "200px", width: "200px" }}
            ></Column>
            <Column
              field="p_scheduledate"
              header="Date"
              style={{ minWidth: "250px", width: "250px" }}
              showFilterMenu={false}
            ></Column>
            <Column
              field="p_scheduletime"
              header="Time"
              style={{ minWidth: "200px", width: "200px" }}
            ></Column>
            <Column
              field="Action"
              header="Action"
              style={{ minWidth: "100px" }}
              body={Action}
            ></Column>
          </DataTable>
        </div>
      </>
    );
  };
  const allowExpansion = (rowData: any) => {
    return rowData.orders!.length > 0;
  };
  const formatDate = (value: string) => {
    const date = new Date(value);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  return (
    <div>
      <Toast ref={toast} />
      <HeaderWithBackButton  controls={[]} handleSearch={handleSearch} searchQuery={searchQuery} /> 
      <div className="table-section mt-3">
        <DataTable
          value={filteredData}
          dataKey="p_prescriptionid"
          tableStyle={{ minWidth: "60rem" }}
          scrollHeight="600px"
          paginator rows={15}
          loading={loading}
          emptyMessage="No history found."
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
          className="treatment-history"
        >
          <Column
            field="srNo"
            header="Sr. No."
            style={{ minWidth: "3rem" }}
            body={SrNo}
          />
          <Column field="p_prescriptiondate" header="Date" body={(rowData) => formatDate(rowData.p_prescriptiondate)} sortable />
          <Column field="p_prescriptionname" header="Prescriptions" sortable />
          <Column
            field="Action"
            header="Action"
            style={{ minWidth: "100px" }}
            body={Action}
          ></Column>
        </DataTable>
      </div>
      <div className="card flex justify-content-center">
        <Dialog
          header="Prescription History Details"
          visible={visible}
          onHide={() => {
            if (!visible) return;
            setVisible(false);
          }}
          className="pop-up-close-treatement"
          draggable={false}
        >
          {datatableloading ? (
            <div className="text-center mt-5 flex justify-content-center" style={{ width: "65vw", height: "16vw",flexDirection:"column" }}>
            <ProgressSpinner
              strokeWidth="1"
              fill="var(--surface-ground)"
              animationDuration=".8s"
              style={{textAlign:"center"}}
            />
            </div>
            
          ) : (
            
            <div className="flex-col">
              <div className="p-3 treament-history-row">
                <h5 className="med-text">Medical</h5>
                <div className="table-section">
                <DataTable
                  value={medicineList}
                  scrollable
                  scrollHeight="750px"
                  emptyMessage="No medical detail found"
                  loading={loading}
                  className="mt-2"
                >
                  <Column
                    header="Sr.No."
                    style={{ minWidth: "2rem" }}
                    body={SrNo}
                  />
                  <Column field="p_medicinename" header="Medicinename"></Column>
                  <Column field="p_dose" header="Dose"></Column>
                  <Column
                    header="Start date"
                    body={(rowData) => formatDate(rowData.p_startdate)}
                  />
                  <Column
                    header="End date"
                    body={(rowData) => formatDate(rowData.p_enddate)}
                  />
                  <Column
                    field="p_frequency"
                    header="Timing for doses"
                  ></Column>
                  <Column field="p_days" header="Days"></Column>
                  <Column field="p_does" header="Does"></Column>
                  <Column field="p_donts" header="Donts"></Column>
                </DataTable>
                </div>
              </div>
              <div className="p-3 treament-history-row">
                <h5 className="med-text">Vedic Treatments</h5>
                <div className="table-section">
                <DataTable
                  value={therapyItemList}
                  emptyMessage="No Therapy detail found"
                  scrollable
                  scrollHeight="400px"
                  loading={loading}
                  dataKey="id"
                  className="mt-2"
                >
                  <Column
                    header="Sr.No."
                    style={{ minWidth: "100px", width: "100px" }}
                    body={SrNo}
                  />
                  <Column
                    field="p_therapyename"
                    header="Therapy"
                    style={{ minWidth: "200px", width: "200px" }}
                  ></Column>
                  <Column
                    field="p_poiname"
                    header="POI"
                    style={{ minWidth: "200px", width: "200px" }}
                  ></Column>
                  <Column
                    field="p_scheduledate"
                    header="Date"
                    style={{ minWidth: "250px", width: "250px" }}
                    showFilterMenu={false}
                    body={(rowData) => formatDate(rowData.p_scheduledate)}
                  ></Column>
                  <Column
                    field="p_scheduletime"
                    header="Time"
                    style={{ minWidth: "250px", width: "250px" }}
                    showFilterMenu={false}
                    body={TimeFormate}
                  ></Column>
                  <Column
                    field="p_does"
                    header="Does"
                    style={{ minWidth: "250px", width: "250px" }}
                    showFilterMenu={false}
                  ></Column>
                  <Column
                    field="p_donts"
                    header="Donts"
                    style={{ minWidth: "200px", width: "200px" }}
                  ></Column>
                </DataTable>
              </div>
              <div className="mt-5">
              <div className="treament-history-row">
                <h5 className="med-text">Protocol</h5>
                <div className="table-section">
                <DataTable
                  value={protocolItemList}
                  emptyMessage="No protocol detail found"
                  scrollable
                  scrollHeight="400px"
                  loading={loading}
                  dataKey="id"
                  className="mt-2"
                >
                  <Column
                    header="Sr.No."
                    style={{ minWidth: "100px", width: "100px" }}
                    body={SrNo}
                  />
                  <Column
                    field="p_activityname"
                    header="Activity"
                    style={{ minWidth: "200px", width: "200px" }}
                  ></Column>
                  <Column
                    field="p_poiname"
                    header="Point of interests"
                    style={{ minWidth: "200px", width: "200px" }}
                  ></Column>
                  <Column
                    field="p_videoname"
                    header="Video"
                    style={{ minWidth: "200px", width: "200px" }}
                  ></Column>
                  <Column
                    field="p_audioname"
                    header="Audio"
                    style={{ minWidth: "200px", width: "200px" }}
                  ></Column>
                  <Column
                    field="p_scheduledate"
                    header="Date"
                    style={{ minWidth: "250px", width: "250px" }}
                    showFilterMenu={false}
                    body={(rowData) => formatDate(rowData.p_scheduledate)}
                  ></Column>
                  <Column
                    field="p_scheduletime"
                    header="Time"
                    style={{ minWidth: "200px", width: "200px" }}
                  ></Column>
                </DataTable>
                </div>
                </div>
              </div>
            </div>
            </div>
          )}
        </Dialog>
      </div>
    </div>
  );
};

export default TreatmentHistory;
