import { Dialog } from "primereact/dialog";
import { useContext, useEffect, useState,useRef } from "react";
import { contextStore } from "../../context/CustomStateProvider";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { MultiSelect } from "primereact/multiselect";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import ExpertiesValidation from "../../validation/ExpertiesValidation";
import ExpertiesService from "../../services/expertiseServices";
import { confirmDialog } from "primereact/confirmdialog";
import poiServices from "../../services/poiServices";

interface AddExpertValues {
  Exptertise: string;
  Description: string;
  AssignExpert: string[];
  AssignActivity: string[];
  AssignTherapy: string[];
  AssignForm: string[];
  IsActive: boolean;
}

const AddExpertPopup = (props: any) => {
  const context = useContext(contextStore);
  const resource = context.state.strings;
  const [submitting, setSubmitting] = useState(false);
  const [selectedExpert, setSelectedExpert] = useState<any[]>([]);
  const [selectedTherapy, setselectedTherapy] = useState<any[]>([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState<any[]>([]);
  const [selectedForm, setSelectedForm] = useState<any[]>([]);

  const initialValues = {
    Exptertise: "",
    Description: "",
    AssignExpert: [],
    AssignActivity: [],
    AssignTherapy: [],
    AssignForm: [],
    IsActive: true,
  };
  useEffect(() => {
    GetAllExperties();
    GetAllTherapy();
    GetAllActivity();
    GetForm();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.editMode) {
      GetExpert();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editMode]);

  const GetExpert = () => {
    const data = {
      expertpanelid: props.isEditExpert.expertpanelid,
    };
    props.setLoading(false);

    ExpertiesService.GetAllExpterBindValues(data).then((res: any) => {
      try {
        props.setExperDataValues(res.data);
        formik.setValues({
          Exptertise: res.data[0].expertpanelname,
          Description: res.data[0].description,
          AssignExpert: res.data[0].experts?res.data[0].experts.map((expert: any) => ({ p_userid: expert.p_userid })):[],
          AssignActivity: res.data[0].activity?res.data[0].activity.map((activitys: any) => ({ p_activityid: activitys.p_activityid })):[],
          AssignTherapy: res.data[0].therapy?res.data[0].therapy.map((therapys: any) => ({ p_therapyid: therapys.p_therapyid })):[],
          AssignForm: res.data[0].forms?res.data[0].forms.map((form: any) => ({ p_formid: form.p_formid })):[],
          IsActive: res.data[0].isactive, // or false, depending on your requirements
        });
        return res.data;
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          // handle error
        }
      }
    });
  };

  const handleSubmit = async (values: AddExpertValues) => {
    setSubmitting(true);
    try {
      if (formik.isValid && props.editMode === false) {
        await AddExpertise(values);
      }
      if (formik.isValid && props.editMode === true) {
        if (parseInt(props.experDataValues[0].expertpanelid)) {
              UpdateExpertise(values);
          };
        }
      }
     catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  const UpdateExpertise = async (values: any) => {
    setSubmitLoading(true);
    props.setLoading(true);
    const data = {
      expertpanelid: props.experDataValues[0].expertpanelid,
      expertpanelname: values.Exptertise,
      description: values.Description,
      userid: values.AssignExpert.map((expert: any) => expert.p_userid),
      therapyid: values.AssignTherapy.map((therapy: any) => therapy.p_therapyid),
      activityid: values.AssignActivity.map((activity: any) => activity.p_activityid),
      isactive: formik.values.IsActive,
      formid: values.AssignForm.map((form: any) => form.p_formid),
    };

    ExpertiesService.UpdateExpter(data)
      .then((res: any) => {
        setSubmitLoading(false);
        if (res.status === 200) {
          if (res.data.p_response) {
            props.toast.current?.show({ severity: "success", summary: "Success", detail: res.data.p_msg, life: 3000 });
            props.setLoading(false);
            props.setIsSubmitValue(true);
          } else {
            props.toast.current?.show({ severity: "warn", summary: "Warning", detail: res.data.p_msg, life: 3000 });
            props.setLoading(false);
            props.setIsSubmitValue(true);
          }
          props.setAddNewExpertPopup(false);
          formik.resetForm();
        }
        else if (res.response.status === 400 &&  res.response.data.success===false)
        {
          const errorMessage = res.response.data.errorMessage;
          props.toast.current?.show({ severity: "warn", summary: "Warning", detail: errorMessage, life: 3000 });
          props.setLoading(false);
        }
         else {
          props.setLoading(false);
          props.setIsSubmitValue(true);
          props.toast.current?.show({ severity: "warn", summary: "Warning", detail: res.data.p_msg, life: 3000 });
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        props.setLoading(false);
        console.error("AddNewAreaService error:", error);
        props.toast.current?.show({ severity: "error", summary: "Error", detail: "Something went wrong", life: 3000 });
      });
  };

  const AddExpertise = async (values: AddExpertValues) => {
    setSubmitLoading(true);
    props.setLoading(true);
    const data = {
      expertpanelname: values.Exptertise,
      description: values.Description,
      userid: values.AssignExpert.map((expert: any) => expert.p_userid),
      therapyid: values.AssignTherapy.map((therapy: any) => therapy.p_therapyid),
      activityid: values.AssignActivity.map((activity: any) => activity.p_activityid),
      isactive: true,
      formid: values.AssignForm.map((form: any) => form.p_formid),
    };

    ExpertiesService.AddExpertise(data)
      .then((res: any) => {
        setSubmitLoading(false);

        if (res.status === 200) {
          if (res.data.p_response) {
            props.toast.current?.show({ severity: "success", summary: "Success", detail: res.data.p_msg, life: 3000 });
            props.setLoading(false);
            props.setIsSubmitValue(true);
          } else {
            props.toast.current?.show({ severity: "warn", summary: "Warning", detail: res.data.p_msg, life: 3000 });
            props.setLoading(false);
            props.setIsSubmitValue(true);
          }
          props.setAddNewExpertPopup(false);
          formik.resetForm();
        }
        else if (res.response.status === 400 &&  res.response.data.success===false)
        {
          const errorMessage = res.response.data.errorMessage;
          props.toast.current?.show({ severity: "warn", summary: "Warning", detail: errorMessage, life: 3000 });
          props.setLoading(false);
        }
         else {
          props.toast.current?.show({ severity: "warn", summary: "Warning", detail: res.data.p_msg, life: 3000 });
          props.setLoading(false);
          props.setIsSubmitValue(true);
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        props.setLoading(false);
        console.error("AddNewAreaService error:", error);
        props.toast.current?.show({ severity: "error", summary: "Error", detail: "Something went wrong", life: 3000 });
      });
  };
  const GetAllExperties = () => {
    ExpertiesService.GetExpertise()
      .then((res: any) => {
        if (res.status === 200) {
          setSelectedExpert(res.data);
        } else {
          props.toast.current?.show({ severity: "warn", summary: "Warning", detail: "Failed to get expertise", life: 3000 });
        }
      })
      .catch((error) => {
        console.error("AddNewAreaService error:", error);
        props.toast.current?.show({ severity: "error", summary: "Error", detail: "Something went wrong", life: 3000 });
      });
  };

  const GetAllTherapy = () => {
    ExpertiesService.GetAllTherapy()
      .then((res: any) => {
        if (res.status === 200) {
          setselectedTherapy(res.data);
        } else {
          props.toast.current?.show({ severity: "warn", summary: "Warning", detail: "Failed to get therapy", life: 3000 });
        }
      })
      .catch((error) => {
        console.error("AddNewAreaService error:", error);
        props.toast.current?.show({ severity: "error", summary: "Error", detail: "Something went wrong", life: 3000 });
      });
  };
  const GetAllActivity = () => {
    poiServices
      .GetActivity()
      .then((res: any) => {
        if (res.status === 200) {
          setSelectedActivity(res.data);
        } else {
          props.toast.current?.show({ severity: "warn", summary: "Warning", detail: "Failed to get activity", life: 3000 });
        }
      })
      .catch((error) => {
        props.toast.current?.show({ severity: "error", summary: "Error", detail: "Something went wrong", life: 3000 });
      });
  };

  const GetForm = () => {
    ExpertiesService.GetAllForm()
      .then((res: any) => {
        if (res.status === 200) {
          setSelectedForm(res.data);
        } else {
          props.toast.current?.show({ severity: "warn", summary: "Warning", detail: "Failed to get form", life: 3000 });
        }
      })
      .catch((error) => {
        props.toast.current?.show({ severity: "error", summary: "Error", detail: "Something went wrong", life: 3000 });
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ExpertiesValidation,
    onSubmit: handleSubmit,
  });

  const onCancelClick = () => {
    formik.resetForm();
    props.setAddNewExpertPopup(false);
  };

  const header = (
    <div className="p-d-flex p-jc-between p-ai-center">
      <span>{resource.expertPanel}</span>
      <Button
        icon="pi pi-times"
        className="p-button-rounded p-button-text popup-close-button"
        onClick={() => {
          props.setAddNewExpertPopup(false);
          onCancelClick();
        }}
      />
    </div>
  );

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (props.addNewExpertPopup) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 0);
    }
  }, [props.addNewExpertPopup]);
  return (
    <div>
      <Dialog
        className="popup popup-card-layout"
        header={header}
        draggable={false}
        visible={props.addNewExpertPopup}
        style={{ width: "50vw" }}
        onHide={() => {
          props.setAddNewExpertPopup(false);
          onCancelClick();
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="card-wrapper">
            <div className="flex mb-2">
              <div className="col-6 relative">
                <label htmlFor="Exptertise" className="block text-900 font-medium mb-2 position-relative">
                  {resource.experties} <span className="text-red">*</span>
                </label>
                <InputText
                  id="Exptertise"
                  type="text"
                  placeholder="Enter expertise"
                  className="w-full mb-2"
                  value={formik.values.Exptertise}
                  onChange={formik.handleChange}
                  ref={inputRef}                  
                  tabIndex={1}
                  autoFocus={true}
                />
                {formik.errors.Exptertise && formik.touched.Exptertise && <div className="text-red-500 text-xs">{formik.errors.Exptertise}</div>}
              </div>
              <div className="col-6 relative">
                <label htmlFor="Description" className="block text-900 font-medium mb-2">
                  {resource.description}
                </label>
                <InputTextarea
                  id="Description"
                  placeholder="Enter description"
                  className="w-full mb-2"
                  rows={3}
                  value={formik.values.Description}
                  onChange={formik.handleChange}
                  tabIndex={2}
                />
                {formik.errors.Description && formik.touched.Description && <div className="text-area-red-bottom text-xs">{formik.errors.Description}</div>}
              </div>
            </div>
          </div>
          <div className="card-wrapper">
            <div className="flex flex-wrap">
              <div className="col-6 relative">
                <label htmlFor="AssignExpert" className="block text-900 font-medium mb-2">
                  {resource.assignExpert}
                </label>
                <MultiSelect
                  id="AssignExpert"
                  value={formik.values.AssignExpert.map((experts: any) => experts.p_userid)}
                  onChange={(e) => {
                    const selectedExpertiseId = e.value;
                    const selectedExpertise = selectedExpert?.filter((experts) => selectedExpertiseId.includes(experts.p_userid));
                    formik.setFieldValue("AssignExpert", selectedExpertise);
                  }}
                  options={selectedExpert}
                  optionValue="p_userid"
                  optionLabel="p_username"
                  placeholder="Select expert"
                  className="w-full "
                  tabIndex={3}
                />
              </div>
              <div className="col-6">
                <label className="block text-900 font-medium mb-2">{resource.status}</label>
                <div className="custom-inputswitch">
                  <InputSwitch
                    id="IsActive"
                    checked={formik.values.IsActive}
                    onChange={(e) => formik.setFieldValue("IsActive", e.target.value)}
                    tabIndex={4}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card-wrapper">
            <div className="flex flex-wrap">
              <div className="col-6 relative">
                <label htmlFor="AssignActivity" className="block text-900 font-medium mb-2">
                  {resource.assignActivity}
                </label>
                <MultiSelect
                  id="AssignActivity"
                  value={formik.values.AssignActivity.map((activity: any) => activity.p_activityid)}
                  onChange={(e) => {
                    const selectedActivityIds = e.value;
                    const selectedActivitys = selectedActivity?.filter((activity) => selectedActivityIds.includes(activity.p_activityid));
                    formik.setFieldValue("AssignActivity", selectedActivitys);
                  }}
                  options={selectedActivity}
                  optionValue="p_activityid"
                  optionLabel="p_activityname"
                  placeholder="Select activity"
                  className="w-full "
                  tabIndex={5}
                  filter
                  filterBy="p_activityname"
                />
              </div>
              <div className="col-6 relative">
                <label htmlFor="AssignTherapy" className="block text-900 font-medium mb-2">
                  {resource.assignTherapy}
                </label>
                <MultiSelect
                  id="AssignTherapy"
                  value={formik.values.AssignTherapy.map((therapy: any) => therapy.p_therapyid)}
                  onChange={(e) => {
                    const selectedTherapyId = e.value;
                    const selectedTherapys = selectedTherapy?.filter((therapy) => selectedTherapyId.includes(therapy.p_therapyid));
                    formik.setFieldValue("AssignTherapy", selectedTherapys);
                  }}
                  options={selectedTherapy}
                  optionValue="p_therapyid"
                  optionLabel="p_therapyname"
                  placeholder="Select therapy"
                  className="w-full"
                  tabIndex={6}
                  filter
                  filterBy="p_therapyname"
                />
              </div>
            </div>
          </div>
          <div className="card-wrapper">
            <div className="flex flex-wrap">
              <div className="col-6 relative">
                <label htmlFor="AssignForm" className="block text-900 font-medium mb-2">
                  {resource.form}
                </label>
                <MultiSelect
                  id="AssignForm"
                  value={formik.values.AssignForm.map((formCheck: any) => formCheck.p_formid)}
                  onChange={(e) => {
                    const selectedFormId = e.value;
                    const selectedForms = selectedForm?.filter((formCheck) => selectedFormId.includes(formCheck.p_formid));
                    formik.setFieldValue("AssignForm", selectedForms);
                  }}
                  options={selectedForm}
                  optionValue="p_formid"
                  optionLabel="p_formname"
                  placeholder="Select form"
                  className="w-full"
                  tabIndex={7}
                  filter
                  filterBy="p_formname"
                />
              </div>
            </div>
          </div>
          <div className="cancel-submit-btn mt-8">
            <Button label="Cancel" className="cancel-btn" onClick={onCancelClick} type="reset" tabIndex={8} />
            <Button label="Submit" className="submit-btn ml-2" type="submit" loading={submitLoading} disabled={submitting} tabIndex={9} />
          </div>
        </form>
      </Dialog>
    </div>
  );
};

export default AddExpertPopup;
