import { useContext, useState } from "react";
import { contextStore } from "../../context/CustomStateProvider";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import AddTabValidation from "../../validation/AddTabValidation";



interface FormValue {
  attributename: string;
  description: string;

}

const CustomTabPopup = (props: any) => {
  const context = useContext(contextStore);
  const resource = context.state.strings;

  const [, setLoading] = useState(false);


  const handleSubmit = async (values: FormValue) => {
    try {
      props.AddAttribute(values);
    } catch (error) {
      console.error("Failed to add tab:", error);
      setLoading(false);
    }
  };





  const initialValues = {
    attributename: "",
    description: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: AddTabValidation,
    onSubmit: handleSubmit,
  });

  const onCancelClick = () => {
    formik.resetForm();
    props.setAddCustomTabPopup(false);


  };



  const header = (
    <div className="p-d-flex p-jc-between p-ai-center">
      <span>{resource.addCustomTab}</span>
      <Button
        icon="pi pi-times"
        className="p-button-rounded p-button-text popup-close-button"
        onClick={() => {
          props.setAddCustomTabPopup(false);
          onCancelClick();
        }}
      />
    </div>
  );

  return (
    <div>
      <Dialog
        className="popup popup-card-layout"
        header={header}
        draggable={false}
        visible={props.addCustomTabPopup}
        style={{ width: "50vw" }}
        onHide={() => {
          props.setAddCustomTabPopup(false);
          onCancelClick();
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="">
            <div className="card-wrapper">
              <div className="flex mb-2">
                <div className="col-6 relative">
                  <label htmlFor="attributename" className="block text-900 font-medium mb-2">
                    {resource.tabName} <span className="text-red">*</span>
                  </label>
                  <InputText
                    id="attributename"
                    type="text"
                    placeholder="Enter tab name"
                    className="w-full mb-1"
                    value={formik.values.attributename}
                    onChange={formik.handleChange}
                    tabIndex={1}
                    autoFocus
                  />

                  {formik.errors.attributename && formik.touched.attributename && <div className="text-red-500 text-xs">{formik.errors.attributename}</div>}

                </div>

                <div className="col-6 relative">
                  <label htmlFor="description" className="block text-900 font-medium mb-2">
                    {resource.description}
                  </label>
                  <InputTextarea
                    id="description"
                    placeholder="Enter description"
                    className="w-full mb-2"
                    rows={3}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    tabIndex={2}
                  />
                </div>


              </div>

            </div>

            <div className="cancel-submit-btn mt-8">
              <Button label="Cancel" className="cancel-btn " onClick={onCancelClick} type="reset" tabIndex={3} />
              <Button label="Submit" className="submit-btn ml-2" type="submit" tabIndex={4} />
            </div>
          </div>
        </form>
      </Dialog>
    </div>
  );
};

export default CustomTabPopup;
