import React, { ReactNode, useEffect, useState } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { useLocation } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import PageNotFound from "../../pages/PageNotFound";


interface MainLayoutProps {
  children: ReactNode;
}
interface MenuItem {
  p_Description: string;
  p_MenuId: any;
  p_MenuName: string;
  p_Url: any;
  p_MenuIcon: any;
}
const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const isLogginuser = localStorage.getItem("user");
  const [loadingSpinner, setloadingSpinner] = useState(false);
  const location = useLocation();
  const [menuItemsList, setMenuItemList] = useState<MenuItem[]>([]);
  const currentUrl = location.pathname; // Define currentUrl

  useEffect(() => {
    setloadingSpinner(true);
    // Check if user is logged in when the component mounts
    if (isLogginuser !== null) {
      setIsLoggedIn(true);
      setTimeout(() => {
        setloadingSpinner(false);
      }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    const sideBarMenu = localStorage.getItem("user");
    if (sideBarMenu !== null) {
      const userData = JSON.parse(sideBarMenu);
      const menuItemList = userData.menuDetailsResponses;
      setMenuItemList(menuItemList);

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isRouteDefined = (url: any) => {
    const items = menuItemsList?.map((menuItem) => ({
      icon: menuItem.p_MenuIcon,
      label: menuItem.p_MenuName,
      url: menuItem.p_Url,
    }));

    // Define your list of route paths here
    const definedRoutes = menuItemsList.map((menuItem) => menuItem.p_Url); return definedRoutes.includes(url);
  };
  const isRouteDefinedCheck = () => {

    // Define your list of route paths here
    const definedRoutes = ["/profile", "/change-password"];
    return definedRoutes.includes(location.pathname);

  };

  return (
    <>
      {(isLoggedIn && isRouteDefined(currentUrl)) || (isLoggedIn && isRouteDefinedCheck()) ? (

        <>
          <div className="progress-spinner">
            {loadingSpinner ?
              <ProgressSpinner style={{ width: '70px', height: '70px', }} strokeWidth="2" fill="var(--surface-ground)" animationDuration=".9s" />
              : <>   <Sidebar />
                <Navbar setIsLoggedIn={setIsLoggedIn} />
                {children}</>}

          </div>
        </>
      ) : <><PageNotFound /></>}
    </>
  );
};

export default MainLayout;
