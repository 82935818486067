import moment from "moment";

import React, { useEffect, useState } from "react";
import AddProtocol from "./AddProtocol";

interface UserProfile {
  p_UserId: number;
  p_CustomerId: number;
  p_FirstName: string;
  p_LastName: string;
  p_Nationality: number;
  p_AadharNo: string;
  p_PassportNo: string;
  p_PassportUrl: string;
  p_AadharUrl: string;
  p_MobileNo: string;
  p_Dob: string;
  p_ZodiacName: string;
  p_Nakshatra1Name: string;
  p_Nakshatra2Name: string;
  p_Nakshatra3Name: string;
  p_Pob: string;
  p_email: string;
  p_Tob: string;
  p_PobLat: string;
  p_PobLong: string;
  p_Country: string;
  p_State: string;
  p_City: string;
  p_Address: string;
  p_Pincode: string;
  p_ProfilePhotoUrl: string;
}

const ProtocolHistory = (props: any) => {
  const [customerData] = useState<UserProfile>(props.viewTreatMentData);
  const [isbackbuttonpressed,setIsBackButtonPressed]=useState(false);

  const dateOfBirth = moment(customerData.p_Dob).format("MM/DD/YYYY");

  useEffect(() => {
    if(isbackbuttonpressed){
      setIsBackButtonPressed(true);
      props.setViewHistoryfunction(false);
    }
   

  }, [isbackbuttonpressed]);
  return (
    <div className="flex">
      <div className="customer-card col">
        <div className="customer-card-header">
          <div className="flex justify-content-between">
            <h4 className="mb-2">
              {customerData.p_FirstName} {customerData.p_LastName}
            </h4>
            <span>{dateOfBirth}</span>
          </div>
          <p className="mb-5">{customerData.p_Address}</p>
          <span className="basic-info-tab">Basic Information</span>
        </div>
        <div className="customer-card-body">
          <div className="customer-info flex">
            <div className="col">
              <ul>
                <li>Full Name: </li>
                <li>
                  {" "}
                  {customerData.p_FirstName} {customerData.p_LastName}
                </li>
              </ul>
              <ul>
                <li>Date of Birth: </li>
                <li> {dateOfBirth}</li>
              </ul>
              <ul>
                <li>Time of Birth: </li>
                <li> {customerData.p_Tob}</li>
              </ul>
              <ul>
                <li>Birth Place: </li>
                <li>{customerData.p_Pob}</li>
              </ul>
              <ul>
                <li>Gender:</li>
                <li>Male</li>
              </ul>
              <ul>
                <li>City: </li>
                <li>{customerData.p_City}</li>
              </ul>
              <ul>
                <li>ZIP Code: </li>
                <li>{customerData.p_Pincode}</li>
              </ul>
              <ul>
                <li>Phone Number:</li>
                <li>{customerData.p_MobileNo}</li>
              </ul>
              <ul>
                <li>Email Address:</li>
                <li>{customerData.p_email}</li>
              </ul>
            </div>
          </div>
          <div className="customer-info flex">
           {/* <div className="fcol">
              <h5 className="mb-2">Emergency Contact</h5>
              <ul className="flex flex-column">
                <li>Name:</li>
                <li>Relationship: </li>
                <li>Phone Number: </li>
              </ul>
            </div>*/}
          </div>
        </div>
      </div>
      <div className="col-9 ml-3">
        <AddProtocol propsData={props} setIsBackButtonPressed={setIsBackButtonPressed} />
      </div>
    </div>
  );
};

export default ProtocolHistory;
