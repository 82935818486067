import { useEffect, useState } from "react";
import HomeIcon from "../../assetes/images/icons/home.svg";
import CalenderIcon from "../../assetes/images/icons/calendar.svg";
import AlertIcon from "../../assetes/images/icons/notifications-bell.svg";
import LogOutIcon from "../../assetes/images/icons/logout.svg";
import { useNavigate } from "react-router-dom";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import UserIcon from "../../assetes/images/icons/person.svg";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

const Navbar = (props: any) => {
  type MababarItem = {
    icon: string;
    text: string;
    onClick?: () => void;
  };
  const navigate = useNavigate();

  const [userFirstName, setUserFirstName] = useState();
  const [userLasttName, setUserLastName] = useState();


  useEffect(() => {
    const UserName = localStorage.getItem("user");
    if (UserName !== null) {
      const userData = JSON.parse(UserName);
      const firstName = userData.p_firstname;
      const lastName = userData.p_lastname;
      setUserFirstName(firstName);
      setUserLastName(lastName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [navbarItemsState] = useState<MababarItem[]>([
    /*{
      icon: HomeIcon,

      text: "Home",
    },
    {
      icon: CalenderIcon,
      text: "Appointment",
    },
    {
      icon: AlertIcon,
      text: "Alerts",
    },*/
  ]);

  const onChatBotClick=()=>
  {
    navigate("/chat");
  }

  const handleButtonClick = (index: number) => {
    if (index === 3) {
      localStorage.clear();
      props.setIsLoggedIn(false);
      navigate("/", { replace: true });
    }
  };

  const onLogout = () => {
    confirmDialog
    ({
      message: "Are you sure you want to logout",
      header: "Confirm logout",
      icon: "pi pi-exclamation-triangle",
      accept: () => logoutAction(),
    });
  };


  const logoutAction=()=>
  {
    localStorage.clear();
    props.setIsLoggedIn(false);
    navigate("/");
  }

  const onProfileClick = () => {
    navigate("/profile");
  };
  const onChangePassword = () => {
    navigate("/change-password");
  };

  return (
    <div className="navbar flex align-items-center">
      <ConfirmDialog />
      {navbarItemsState.map((item, index) => (
        <button
          key={index}
          className="navbar-button"
          onClick={() => handleButtonClick(index)}
        >
          <img className="navbar-icon" src={item.icon} alt={item.text} />
          <span className="sidebar-menu-text">{item.text}</span>
        </button>
      ))}
      <div>
       <div>
        
       </div>

      <div className="userProfile relative">
        <Button className="flex-row profile-hover">
          <Avatar
            image={UserIcon}
            shape="circle"
            className="mr-2"
            style={{ background: "white", padding: "2px" }}
          />
        </Button>
        <span className="">
          {" "}
          <span>Welcome</span>{" "}
          <span className="welcome-text">
            {userFirstName} {userLasttName}
          </span>

        </span>
       
        <div className="profile-dropdown ">
          <ul>
            <li>
              <button className="p-0 m-0" onClick={onProfileClick}>
                Profile{" "}
              </button>
            </li>
            <li>
              <button className="p-0 m-0" onClick={onChangePassword}>
                Change Password{" "}
              </button>
            </li>
          </ul>
        </div>
      </div>
 
        </div>
      <div>
        <button className="navbar-button flex align-center " onClick={onLogout}>
          <img className="navbar-icon" src={LogOutIcon} alt="logout" />
          <span className="sidebar-menu-text"></span>
        </button>
      </div>
    </div>
  );
};

export default Navbar;
