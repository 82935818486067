import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useContext, useEffect, useRef, useState } from "react";
import GetAllCustomerService from "../../services/getallcustomer";
import moment from "moment";
import { contextStore } from "../../context/CustomStateProvider";
import { Button } from "primereact/button";
import ProtocolHistory from "./ProtocolHistory";
import HeaderWithBackButton from "../common/HeaderWIthBackButton";
import { Toast } from "primereact/toast";

interface Customer {
  srNo: number;
  p_Dob: any;
  p_FirstName: string;
  p_LastName: string;
  p_MobileNo: any;
  p_UserId: number;
  p_email: any;
}

const Protocol = () => {
  const [customerdata, setCustomerData] = useState<Customer[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const context = useContext(contextStore);
  const resource = context.state.strings;
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState<any>([]);
  const [viewTreatMentData, setViewTreatMentData] = useState<any>();
  const [viewHistory, setViewHistory] = useState(false);
  const toast = useRef<Toast>(null);

  useEffect(() => {
    ShowAllCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchQuery) {
      const lowercasedFilter = searchQuery.toLowerCase();
      const filteredData = customerdata.filter((customer: any) => {
        return Object.keys(customer).some((key) => String(customer[key]).toLowerCase().includes(lowercasedFilter));
      });
      setFilteredData(filteredData);
    } else {
      setFilteredData(customerdata);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, customerdata]);

  const ShowAllCustomers = () => {
    setLoading(true);
    GetAllCustomerService.GetAllCustomers(null).then((res: any) => {
      try {
        if (res.status === 200) {
          setCustomerData(res.data);
          setLoading(false);
        }
        return res.data;
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          setLoading(false);
        }
      }
    });
  };

  const SrNo = (rowData: any, index: any) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{index.rowIndex + 1}</span>
      </div>
    );
  };

  const DateOfbirth = (rowData: any) => {
    const DateOfbirth = rowData.p_Dob ? moment(rowData.p_Dob).format("DD/MM/YYYY") : "";
    return (
      <div className="flex align-items-center gap-2">
        <span>{DateOfbirth}</span>
      </div>
    );
  };

  const onViewTreatMent = (rowData: any) => {
    setViewTreatMentData(rowData);
    setViewHistory(true);
  };
  const verifiedBodyTemplate = (rowData: Customer) => {
    return (
      <React.Fragment>
        <Button className="table-button green-btn" label="View treatment" onClick={() => onViewTreatMent(rowData)} />
      </React.Fragment>
    );
  };

  const handleSearch = (event: any) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div className="container ">
      <Toast ref={toast} />  
      {!viewHistory ? (
        <>
          <HeaderWithBackButton title={resource.customerDetails} controls={[]} handleSearch={handleSearch} searchQuery={searchQuery} />
          <div className="table-section">
            <DataTable value={filteredData} dataKey="id" scrollable scrollHeight="600px" loading={loading} emptyMessage="No POI found." paginator rows={15}>
              <Column field="srNo" header="Sr. No." style={{ minWidth: "3rem" }} body={SrNo} />
              <Column field="p_FirstName" header="First Name" sortable />
              <Column field="p_LastName" header="Last Name" sortable />
              <Column field="p_email" header="Email" sortable />
              <Column field="p_MobileNo" header="Mobile No." sortable />
              <Column field="p_Dob" header="DOB" body={DateOfbirth} sortable />
              <Column field="Action" header="Action" style={{ minWidth: "2rem" }} body={verifiedBodyTemplate} />
            </DataTable>
          </div>
        </>
      ) : (
        <>
          <ProtocolHistory viewTreatMentData={viewTreatMentData} toast={toast} setViewHistory={viewHistory} setViewHistoryfunction={setViewHistory} />
        </>
      )}
    </div>
  );
};

export default Protocol;
