// LoginPage.tsx
import React, { useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";

import { Toast } from "primereact/toast";

import ForgetPasswordSchema from "../validation/ForgetPasswordValidation";
import LoginServices from "../services/loginServices";
import { useNavigate } from 'react-router-dom';
interface FormValues {
  userName: string;
}

const ForgotPassWord: React.FC = () => {
  const [submitting, setSubmitting] = React.useState(false);
  const toast = useRef<Toast>(null);
  const [successMessage, setSuccessMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);
    try {
      setSubmitting(true);
      if (formik.isValid) {
        setSubmitting(false);
        await forgetPassword(values);
      }
    } catch (error) {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      userName: "",
    },
    validationSchema: ForgetPasswordSchema,
    onSubmit: handleSubmit,
  });

  const forgetPassword = async (values: FormValues) => {
    LoginServices.ForgetPasswordService(values).then((res: any) => {
      try {
        if (res.status === 200) {
          setSuccessMessage(true);
          setLoading(false);
          toast.current?.show({ severity: "success", summary: "Success", detail: "Please check your Mail id. Check your SPAM folder for if don’t find our mail to your Inbox", life: 3000 });
          setTimeout(() => {
            navigate('/');
          }, 4000)        
        }

        if (res.response.status === 404) {
          setLoading(false);
          toast.current?.show({ severity: "success", summary: "Success", detail: "Please check your Mail id. Check your SPAM folder for if don’t find our mail to your Inbox", life: 3000 });
        }

        if (res.response.status === 400) {
          setLoading(false);
          toast.current?.show({ severity: "success", summary: "Success", detail: "Please check your Mail id. Check your SPAM folder for if don’t find our mail to your Inbox", life: 3000 });
        }
        // if (res.response.status === 400) {
        //   setLoading(false);
        //   toast.current?.show({
        //     severity: "warn",
        //     summary: "Warning",
        //     detail: res.response.data.errorMessage,
        //     life: 3000,
        //   });
        // }
        return res.data;
        // Redirect the user to the protected route
      } catch (error: any) {
        if (error.response && error.response.status === 404) {
          const errorMessage = error.response.data.errorMessage;
          toast.current?.show({ severity: "warn", summary: "Warning", detail: errorMessage, life: 3000 });
        }

        setLoading(false);
      }
    });
  };
  return (
    <div className="Login-page flex align-items-center  justify-content-center h-screen">
      <Toast ref={toast} />
      {/* Your login page UI */}
      <form onSubmit={formik.handleSubmit}>
        <div className="login-card login-form forget-password">
          <h5>Forget Password</h5>

          <div className="relative mb-2">
            <label htmlFor="email" className="block">
              Your registered mail ID{" "}
              <span className="text-red">*</span>
            </label>
            <InputText
              id="userName"
              type="text"
              placeholder="Your registered mail id"
              className="w-full mb-3"
              value={formik.values.userName}
              onChange={formik.handleChange}
            />
            {formik.errors.userName && formik.touched.userName && <div className="text-red-500 text-xs">{formik.errors.userName}</div>}
          </div>

          <div className="flex align-items-center justify-content-end mb-7">
            <Button label="Submit" className="login-button" type="submit" disabled={submitting} loading={loading} />
          </div>
          <div className="text-center ">
            {successMessage ? (
              <div>
                <p>Please check your Mail id</p>
                <p>Check your SPAM folder for if don’t find our mail to your Inbox</p>
                <a href="/">Login again</a>
              </div>
            ) : (
              <div>
                <a href="/">Login again</a>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default ForgotPassWord;
