import axios from "axios";
import authHeader from "./authHeader";

const AddEmployee = async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_API_ENDPOINT}api/User/register`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const AddEmployeeService = {
  AddEmployee,
};

export default AddEmployeeService;
