import axios from "axios";
import authHeader from "./authHeader";

const AddNewArea = async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_CMS_ENDPOINT}api/Area/RegisterArea`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const GetAllArea = async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_CMS_ENDPOINT}api/Area/GetArea`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const GetAllActiveArea = async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_CMS_ENDPOINT}api/Area/GetActiveArea`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const GetAllUsers = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_API_ENDPOINT}api/User/manager`, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
const GetAllCMS = async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_CMS_ENDPOINT}api/POI/poidetails`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const DeleteAreaData = async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_CMS_ENDPOINT}api/Area/DeleteArea`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const AddNewAreaService = {
  AddNewArea,
  GetAllArea,
  GetAllUsers,
  GetAllCMS,
  DeleteAreaData,
  GetAllActiveArea,
};

export default AddNewAreaService;
