export const UserLogin = {
  url: "api/User/login",
};

export const ForgetPassword = {
  url: "api/User/forgotpassword",
};

export const GetCountry = {
  url: "api/CSC/GetCountry",
};
