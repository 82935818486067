import { useContext, useState, useRef } from "react";
import HeaderWithBackButton from "../common/HeaderWIthBackButton";
import { contextStore } from "../../context/CustomStateProvider";
import PoiTable from "./PoiTable";
import AddPoiPopup from "../../components/poi/AddPoiPopup";
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";

const PoiLayout = (props: any) => {
  const context = useContext(contextStore);
  const resource = context.state.strings;
  const [addPoiChange, setaddPoiChange] = useState(false);
  const [backButton, setBackButton] = useState(false);
  const [addPoiPopup, setAddPoiPopup] = useState<boolean>(false);
  const [reloadPOIGrid, setReloadPOIGrid] = useState(false);
  const toast = useRef<Toast>(null);
  const [editPoiData, setEditPoiData] = useState();
  const [editMode, setEditMode] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const cardControls = [
    {
      ctrlType: "button",
      name: "btnAdd",
      title: resource.addNewPOI,
      type: "button",
      disabled: false,
      className: "green-btn ",
    },
  ];

  const onButtonClick = (e: string) => {
    if (e === "btnAdd") {
      setaddPoiChange(true);
      setAddPoiPopup(true);
    }
  };
  const onBackButtonClick = () => {
    setBackButton(!backButton);
  };

  const onEditPoi = (data: any) => {
    setAddPoiPopup(true);
    setEditPoiData(data);
    setEditMode(true);
  };

  const handleSearch = (event: any) => {
    setSearchQuery(event.target.value);
  };


  return (
    <div className="container ">
      <Toast ref={toast} />
      <AddPoiPopup
        addPoiPopup={addPoiPopup}
        setAddPoiPopup={setAddPoiPopup}
        toast={toast}
        setReloadPOIGrid={setReloadPOIGrid}
        reloadPOIGrid={reloadPOIGrid}
        editPoiData={editPoiData}
        setEditPoiData={setEditPoiData}
        editMode={editMode}
        setEditMode={setEditMode}
      />
      <HeaderWithBackButton
        title={!addPoiChange || backButton ? resource.poi : resource.poi}
        controls={cardControls}
        onButtonClicked={onButtonClick}
        onBackButtonClick={onBackButtonClick}
        handleSearch={handleSearch}
        searchQuery={searchQuery}
      />
      <div className="card-section">
        <PoiTable onEditPoi={onEditPoi} reloadPOIGrid={reloadPOIGrid} searchQuery={searchQuery} />
      </div>
    </div>
  );
};

export default PoiLayout;
