import React, { useEffect, useState, useRef } from "react";
import { DataTable, DataTableExpandedRows, DataTableRowEvent, DataTableValueArray } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import AddNewSubAreaService from "../../services/addSubArea";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";

interface Customer {
  srNo: number;
  areaName: string;
  description: string;
  createdBy: string;
  createdDate: string;
  areaManager: string;
  status: number;
  includedArea: any;
}

const AreaTable = (props: any) => {
  const [, setSelectedArea] = useState<Customer[]>([]);
  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | DataTableValueArray | undefined>(undefined);
  const [, setExpand] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const toast = useRef<Toast>(null);

  const EditArea = (rowData: any) => {
    props.onEditAreaClicked(rowData);
  };

  const onDeleteClick = (data: any) => {
    var subareaId = {
      subareaid: data.p_subareaid,
    };
    confirmDialog({
      message: `Are you sure you want to delete subarea?`,
      header: "Confirm delete subarea",
      icon: "pi pi-exclamation-triangle",
      accept: () => onSubareaDelete(),
    });

    const onSubareaDelete = () => {
      setLoading(true);
      AddNewSubAreaService.DeleteSubArea(subareaId)
        .then((res: any) => {
          if (res.data.p_response) {
            toast.current?.show({ severity: "success", summary: "Success", detail: res.data.p_msg, life: 3000 });
            GetSubArea();
          } else {
            toast.current?.show({ severity: "warn", summary: "Warning", detail: res.data.p_msg, life: 3000 });
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("AddNewAreaService error:", error);
          toast.current?.show({ severity: "error", summary: "Error", detail: "Something went wrong", life: 3000 });
        });
    };
  };

  const verifiedBodyTemplate = (rowData: Customer) => {
    return (
      <React.Fragment>
        <Button className="table-button custome-icon  edit-icon " onClick={() => EditArea(rowData)} tooltip="Edit" />
        <Button className="table-button custome-icon delete-icon" onClick={() => onDeleteClick(rowData)} tooltip="Delete"></Button>
      </React.Fragment>
    );
  };
  const onRowExpand = (event: DataTableRowEvent) => {
    setExpand(true);
  };

  const onRowCollapse = (event: DataTableRowEvent) => {
    setExpand(false);
  };

  const rowExpansionTemplate = (data: any) => {
    return (
      <div className="p-3">
        <h5>Orders for</h5>
      </div>
    );
  };
  useEffect(() => {
    GetSubArea();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.addNewSubAreaPopup === false]);

  const GetSubArea = () => {
    setLoading(true);
    var data = {
      Id: null,
    };

    AddNewSubAreaService.GetSubArea(data).then((res: any) => {
      try {
        if (res.status === 200) {
          setSelectedArea(res.data);
          props.setSubAreaDatFilter(res.data);
          setLoading(false);
        }

        return res.data;
        // Redirect the user to the protected route
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: "Unauthorized", life: 3000,
          });
          window.location.pathname = "/";
        }
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    GetSubArea();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SrNo = (rowData: any, index: any) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{index.rowIndex + 1}</span>
      </div>
    );
  };
  const Status = (rowData: any) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{rowData.p_isactive === true ? "Active" : "InActive"}</span>
      </div>
    );
  };
  return (
    <div className="table-section">
      <Toast ref={toast} />
      <DataTable
        paginator rows={15}
        value={props.filteredEmployeeData}
        dataKey="id"
        scrollHeight="600px"
        loading={loading}
        emptyMessage="No Sub Area found."
        onRowExpand={onRowExpand}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        onRowCollapse={onRowCollapse}
        rowExpansionTemplate={rowExpansionTemplate}
      >
        <Column field="srNo" header="Sr. No." style={{ minWidth: "3rem" }} body={SrNo} />
        <Column field="p_subareaname" header="Subarea name" style={{ minWidth: "12rem" }} sortable />
        <Column header="Description" field="p_description" style={{ minWidth: "12rem" }} sortable />
        <Column field="p_isactive" header="Status" body={Status} sortable />
        <Column field="Action" header="Action" style={{ minWidth: "6rem" }} body={verifiedBodyTemplate} />
      </DataTable>
    </div>
  );
};

export default AreaTable;
