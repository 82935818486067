import { useContext, useDebugValue, useEffect, useRef, useState } from "react";
import HeaderWithBackButton from "../common/HeaderWIthBackButton";
import { contextStore } from "../../context/CustomStateProvider";
import AddProtocolLayout from "./AddProtocolLayout";
import TreatmentHistory from "./TreatMentHistory";
import { Button } from "primereact/button";
import protocolServices from "../../services/protocolServices";
import ReportService from "../../services/reportServices";
import { Toast } from "primereact/toast";
import UserActivityLogReport from "./UserActivityLogReport";


const AddProtocol = (props: any) => {
  const context = useContext(contextStore);
  const resource = context.state.strings;
  const [addProtocol, setAddProtcol] = useState(false);
  const [prescriptionid, setPrescriptionid] = useState<any>(null);
  const [userTreatmentid, setUserTreatmentid] = useState<any>(null);
  const toast = useRef<Toast>(null);
  const [editPrescriptionto, setEditPrescriptionto] = useState<any>(null);
  const [editPrescription, setEditPrescription] = useState(false);
  const [isReportButtonClick,setIsReportButtonClick]=useState(false);
  const [isDownloadReport,setIsDownloadReport]=useState(false);
  const [isDowndoadDisabled, setIsDowndoadDisabled] = useState(true);


  const[userId,setUserId]=useState('');





  const formatDate = (value: string) => {
    const date = new Date(value);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); 
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const cardControls = [
    {
      ctrlType: "button",
      name: "btnAdd",
      title: addProtocol ? resource.treatmentHistory : resource.addTreatMent,
      type: "button",
      disabled: false,
      className: "orange-btn",
      icon: !addProtocol ? "pi pi-plus" : "",
    },
  ];
  const cardControls_backButton = [
    {
      ctrlType: "button",
      name: "btnAdd",
      title: "Back",
      type: "button",
      disabled: false,
      className: "orange-btn",
      icon: "pi pi-chevron-left",      
    },
  ];

  const onButtonClick = (e: any) => {

      setAddProtcol(!addProtocol);
      setEditPrescriptionto(null);
      setPrescriptionid(null)
      setEditPrescription(false);
    
  };
  const onBackButtonClick=()=>
  {
    if(!addProtocol && !isReportButtonClick)
    {
    props.setIsBackButtonPressed(true);
    }
    else if(isReportButtonClick)
    {
      setIsReportButtonClick(false);
    }
    else
    {
      setAddProtcol(!addProtocol);
      setEditPrescriptionto(null);
      setPrescriptionid(null)
      setEditPrescription(false);
    }
  }
  const onDownloadReport=()=>
  {
    setIsDownloadReport(true);
  }
const onDownloadReportClick=()=>
{
  if(isReportButtonClick)
  {
    
      onDownloadReport();
    
  }
  else
  {
  
  setIsReportButtonClick(true);
  }



}
  const GenerateReport=()=>
  {
    const data = {
      userid: props.propsData.viewTreatMentData.p_UserId,
    };

    protocolServices
      .UserWiseReport(data)
      .then((res: any) => {
        //const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        if (res.status === 200) {


          let transformedData = res.data.map((item: any) => {
            const prescribedOnDate = new Date(item.prescribedOn).toLocaleString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            });

            return {
              "Prescription name": item.prescriptionName,
              "Activity type": item.activityType,
              "Activity": item.activity,
              "POI name": item.poiName,
              "Status": item.status,
              "Prescribed start date": formatDate(item.prescribedStartDate),
              "Prescribed end date": formatDate(item.prescribedEndDate),
              "Dose": item.dose,
              "Frequency": item.frequency,
              "Do's": item.does,
              "Dont's": item.donts,
              "Prescribed on": prescribedOnDate,
              "Prescribed by": item.prescribedBy,
              "Log date": formatDate(item.logDate),
              "Start time": item.startTime,
              "End time": item.endTime,
              "Duration": item.duration  
            };
          });

          
          const userName = `${props.propsData.viewTreatMentData.p_FirstName||''}${props.propsData.viewTreatMentData.p_LastName|| ''}`;
            const ReportData = {
              data:  transformedData,
              name: `UserActivityLog_${userName}.xlsx`
            };

            ReportService.ReportDownload(ReportData);
            toast.current?.show({ 
              severity: "success", 
              summary: "Success", 
              detail: "Report downloaded successfully.", 
              life: 3000 });

        }
        else if (res.response.status==500)
        {
          toast.current?.show({
               severity: "warn",
                summary: "Warning",
                detail: "No reports are available for download.",
                life: 3000,
            });
        }

      }).catch(() => {
        props.toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      });
  }

  useEffect(() => {
    setUserTreatmentid(props.propsData.viewTreatMentData.p_UserId);
    setEditPrescription(false);
    setIsReportButtonClick(false);
  }, []);

  return (
    <>
      <Toast ref={toast} />

    


      <div className="flex">
      <div className="col 2">
    <div className="flex">
        <Button className="orange-btn back-btn" icon="pi pi-chevron-left" onClick={onBackButtonClick} />
        {!addProtocol && (
            <h3 className="mt-2 ml-2">{!addProtocol ? (isReportButtonClick ? resource.userActivitylogreport : resource.treatmentHistory) : resource.addTreatMent}
            </h3>
        )}
    </div>
    </div>
    <div className="d-flex right mr-5">
            <>
            {(!addProtocol && !isReportButtonClick) && (
                <Button 
                    className="orange-btn mr-2" 
                    label={resource.addPrescription}
                    onClick={onButtonClick} 
                    icon="pi pi-plus" 
                    iconPos="left"
                />)}
                 {(!addProtocol) && (
                <Button 
                    className="orange-btn" 
                    label={isReportButtonClick?"Download":"Generate user activity log report" }
                    onClick={onDownloadReportClick }  
                    {...(isReportButtonClick && { icon: "pi pi-download", iconPos: "left" })}
                    disabled={isReportButtonClick && isDowndoadDisabled}
                />
                )}
            </>
    </div>
</div>

         <div className="">  
         { isReportButtonClick && (<UserActivityLogReport userTreatmentid={userTreatmentid} 
         onDownloadReport={onDownloadReport} isReportButtonClick={isReportButtonClick}
         setIsDownloadReport={setIsDownloadReport}
         isDownloadReport={isDownloadReport} treatmentData={props.propsData.viewTreatMentData}
         isDowndoadDisabled={isDownloadReport}  setIsDowndoadDisabled={setIsDowndoadDisabled}
         
         />)}     
        {addProtocol  ? (
          <AddProtocolLayout
            data={props}
            setEditPrescriptionto={setEditPrescriptionto}
            editPrescriptionto={editPrescriptionto}
            editPrescription={editPrescription}
            setPrescriptionid={setPrescriptionid}
            prescriptionid={prescriptionid}
            userTreatmentid={userTreatmentid}
          />
        ) : !isReportButtonClick ? (
          <TreatmentHistory
            userTreatmentid={userTreatmentid}
            setEditPrescriptionto={setEditPrescriptionto}
            setAddProtcol={setAddProtcol}
            prescriptionid={prescriptionid}
            setEditPrescription={setEditPrescription}
          />
        ): null}
      </div>
    </>
  );
};

export default AddProtocol;
