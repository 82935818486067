import * as Yup from "yup";

const TherapyValiDation = Yup.object().shape({
    capacity: Yup.number()
        .required('Please enter capacity')
    
});


export default TherapyValiDation;
