import React, { useContext, useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import attributeServices from "../../services/attributeServices";
import { contextStore } from "../../context/CustomStateProvider";
import { useFormik } from "formik";
import poiServices from "../../services/poiServices";
import { ProgressSpinner } from "primereact/progressspinner";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

interface Field {
  commonName: string;
  botanicalName: string;
  isEditable: boolean;
}

const FloraFaunaLayout = (props: any) => {
  const context = useContext(contextStore);
  const resource = context.state.strings;
  const [attribute, setAttribute] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [, setOnChangeValues] = useState<any>(null);
  const [showSave, setShowSave] = useState(false);
  const [newCommonName, setNewCommonName] = useState<any>("");
  const [newBotanicalName, setNewBotanicalName] = useState<any>("");
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [isEditableFiled, setIsEditableFiled] = useState(false);
  const [fields, setFields] = useState<Field[]>([
    {
      commonName: "",
      botanicalName: "",
      isEditable: true,
    },
  ]);

  const addField = (event: any) => {
    event.preventDefault();

    // Check if the new field with the same values already exists (case-insensitive)
    const isDuplicate = fields.some(
      (field) =>
        field.commonName.toLowerCase() === newCommonName.toLowerCase() && field.botanicalName.toLowerCase() === newBotanicalName.toLowerCase()
    );

    if (isDuplicate) {
      // Prompt the user with a dialog box
      confirmDialog({
        message: "This entry is already exist. Would you like to add it anyway?",
        header: "Duplicate Entry",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          // If the user accepts, proceed with adding the field
          addNewField();
        },
        reject: () => {
          // If the user rejects, do nothing
        },
      });
    } else {
      // If no duplicate, simply add the new field
      addNewField();
    }
  };

  const addNewField = () => {
    if (newCommonName !== "" && newBotanicalName !== "") {
      const newField = {
        commonName: newCommonName,
        botanicalName: newBotanicalName,
        isEditable: false,
      };

      // Add the new field at index 0
      const updatedFields = [newField, ...fields.map((field) => ({ ...field, isEditable: false }))];

      setFields(updatedFields);

      // Update the formik values with the latest fields
      formik.setValues({
        ...formik.values,
        AttributeValueDetails: updatedFields,
      });

      // Clear the input fields for the new common name and botanical name
      setNewCommonName("");
      setNewBotanicalName("");
      setShowSave(false);

      setTimeout(() => {
        props.toast.current?.show({ severity: "success", summary: "Success", detail: "Field added successfully", life: 3000 });
      }, 200);
    }
  };

  const AttributeDetails = () => {
    setLoadingSpinner(true);
    let data = {
      PoiId: props.poiId,
      AttributeId: props.attributeId,
    };
    attributeServices.GetAttributeDetails(data).then((res: any) => {
      try {
        if (res.status === 200) {
          setTimeout(() => {
            setLoadingSpinner(false);
          }, 400);
          setAttribute(res.data);
          const pValues = JSON.parse(res.data[0].p_values);
          const bidiValues = pValues.map((item: any) => ({
            commonName: item.commonName,
            botanicalName: item.botanicalName,
          }));

          setFields(bidiValues.map((item: any) => ({ ...item, isEditable: false })));
          formik.setValues({
            PoiId: props.poiId,
            AttributeId: props.attributeId,
            AttributeValueDetails: bidiValues,
          });
        }
        return true;
        // Redirect the user to the protected route
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
        }
      }
    });
  };
  useEffect(() => {
    AttributeDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onPoiClick]);

  const handleSubmit = (values: any) => {
    setLoading(true);
    if (attribute && values.AttributeValueDetails?.length > 0) {
      const attributeValueDetailsJson = JSON.stringify(
        values.AttributeValueDetails.map((field: Field) => ({
          commonName: field.commonName,
          botanicalName: field.botanicalName,
        }))
      );

      const attributeValues: any[] = [];
      attribute?.map((items: any) => {
        attributeValues.push({
          AttributeDetailId: items.p_attributedetailid,
          AttributeValues: attributeValueDetailsJson,
        });
      });

      // Make API call here
      const data = {
        PoiId: props.poiId,
        AttributeId: props.attributeId,
        AttributeValueDetails: attributeValues,
      };

      setTimeout(() => {
        const isValid = attributeValues.some((item: { AttributeValues: string }) => {
          const attributeValuesJson = JSON.parse(item.AttributeValues);
          return attributeValuesJson.some((attr: { commonName: string; botanicalName: string }) => attr.commonName || attr.botanicalName);
        });
        if (isValid) {
          // Update fields state with the new field before making the API call
          setFields([
            ...values.AttributeValueDetails.map((field: Field) => ({ ...field, isEditable: false })),
            ...fields.filter((field) => field.isEditable),
          ]);
          // Update the formik values with the latest fields
          formik.setValues({
            ...formik.values,
            AttributeValueDetails: [
              ...values.AttributeValueDetails.map((field: Field) => ({ ...field, isEditable: false })),
              ...fields.filter((field) => field.isEditable),
            ],
          });
          setLoadingSpinner(true);
          UpdateOverView(data);
        } else {
          setLoading(false);
          setLoadingSpinner(false);
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: "Enter at least one field",
            life: 3000,
          });
        }
      }, 400);
    }
  };

  const formik = useFormik({
    initialValues: {
      PoiId: props.poiId,
      AttributeId: props.attributeId,
      AttributeValueDetails: fields,
    },
    onSubmit: handleSubmit,
  });

  const onCancelClick = () => {
    formik.resetForm();
    setFields(
      fields.map((field) => {
        if (field.isEditable) {
          return { commonName: "", botanicalName: "", isEditable: true };
        }
        return field;
      })
    );
  };

  const onSaveClick = (event: any) => {
    event.preventDefault();
    formik.handleSubmit();
  };

  const UpdateOverView = (data: any) => {
    poiServices.UpdateFloraFaunaDetails(data).then((res: any) => {
      try {
        if (res.status === 200) {
          setLoading(false);
          setLoadingSpinner(false);
          props.toast.current?.show({ severity: "success", summary: "Success", detail: "Field updated successfully", life: 3000 });
        }
        return res.data;
        // Redirect the user to the protected route
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: "Unauthorized",
            life: 3000,
          });
          setLoadingSpinner(false);
        }
        if (res.response.status === 400) {
          setLoading(false);
          setLoadingSpinner(false);
          const errorMessage = res.response.data.errorMessage;
          props.toast.current?.show({ severity: "warn", summary: "Warning", detail: errorMessage, life: 3000 });
        }
        if (res.response.status === 500) {
          setLoading(false);
          setLoadingSpinner(false);
          const errorMessage = res.response.data.errorMessage;
          props.toast.current?.show({ severity: "warn", summary: "Warning", detail: errorMessage, life: 3000 });
        }
      }
    });
  };

  const onEditClick = (event: any, currentIndex: number) => {
    event.preventDefault();
    setIsEditableFiled(true);
    setFields(
      fields.map((field, index) => {
        if (index === currentIndex) {
          return { ...field, isEditable: true };
        }
        return field;
      })
    );
  };

  const onFeildCancelClick = (event: any, index: number) => {
    event.preventDefault();
    setIsEditableFiled(false);
    setFields(
      fields.map((field, idx) => {
        if (idx === index) {
          return { ...field, isEditable: false };
        }
        return field;
      })
    );
  };

  const onDeleteClick = (event: any, index: number) => {
    event.preventDefault();
    confirmDialog({
      message: `Are you sure you want to delete this field?`,
      header: "Confirm delete field",
      icon: "pi pi-exclamation-triangle",
      accept: () => onDeleteFlorafauna(index), // Pass a function to be executed on confirmation
    });
  };

  const onDeleteFlorafauna = (index: any) => {
    const updatedFields = [...fields];
    updatedFields.splice(index, 1);
    setFields(updatedFields); // Update the fields state
    formik.setValues({
      ...formik.values,
      AttributeValueDetails: updatedFields,
    });
    setTimeout(() => {
      props.toast.current?.show({ severity: "success", summary: "Success", detail: "Fields deleted successfully", life: 3000 });
    }, 200);
  };

  const onAddClick = () => {
    setShowSave(true);
  };
  const onSaveFiled = (event: any, index: number) => {
    event.preventDefault();
    setIsEditableFiled(false);
    // Update the fields state with the edited values
    const updatedFields = [...fields];
    updatedFields[index].isEditable = false; // Set isEditable to false to make the field non-editable
    setFields(updatedFields);

    // Update the formik values with the edited fields
    formik.setValues({
      ...formik.values,
      AttributeValueDetails: updatedFields,
    });
    setTimeout(() => {
      props.toast.current?.show({ severity: "success", summary: "Success", detail: "Field updated successfully", life: 3000 });
    }, 200);
  };
  const onCancelField = () => {
    setShowSave(false);
    setNewCommonName("");
    setNewBotanicalName("");
  };

  return (
    <div className="tab-scrollable-content">
      {loadingSpinner ? (
        <ProgressSpinner style={{ width: "70px", height: "70px" }} strokeWidth="2" fill="var(--surface-ground)" />
      ) : (
        <>
          <div className="flex col-12 align-items-end">
            <div className="grid mt-3 ">
              <div className="">
                <div className="p-field ">
                  <div className="">
                    <label htmlFor="place" className="p-d-block">
                      Common Name
                    </label>
                  </div>
                  <InputText
                    id="place"
                    type="text"
                    disabled={!showSave}
                    className="inputform mt-2 m-0"
                    placeholder="Enter common name"
                    value={newCommonName}
                    onChange={(e) => {
                      setNewCommonName(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="grid mt-3 ml-8">
              <div className="">
                <div className="p-field ">
                  <div className="">
                    <label htmlFor="place" className="p-d-block">
                      Botanical Name
                    </label>
                  </div>
                  <InputText
                    id="place"
                    type="text"
                    disabled={!showSave}
                    placeholder="Enter botanical name"
                    className="inputform mt-2  m-0"
                    value={newBotanicalName}
                    onChange={(e) => {
                      setNewBotanicalName(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="black-button ml-3">
              {!showSave ? (
                <Button icon="pi pi-plus" rounded severity="secondary" aria-label="Cancel" onClick={onAddClick} />
              ) : (
                <>
                  <Button
                    icon="pi pi-save"
                    rounded
                    severity="secondary"
                    aria-label="Save"
                    onClick={addField}
                    disabled={!newCommonName && !newBotanicalName}
                  />
                  <Button icon="pi pi-times" rounded severity="secondary" aria-label="Cancel" onClick={() => onCancelField()} />
                </>
              )}
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            {fields
              .filter((field) => field.commonName !== "" && field.botanicalName !== "") // Filter out empty fields
              .map((field, index) => (
                <div key={index} className="flex col-12 align-items-end">
                  <div className="grid mt-3 ">
                    <div className="">
                      <div className="p-field ">
                        <div className="">
                          <label htmlFor="place" className="p-d-block">
                            Common Name
                          </label>
                        </div>
                        <InputText
                          id="place"
                          type="text"
                          disabled={!field.isEditable}
                          className="inputform mt-2 m-0"
                          value={field.commonName}
                          onChange={(e) => {
                            setOnChangeValues(e.target.value);
                            const updatedFields = [...fields];
                            updatedFields[index].commonName = e.target.value;
                            formik.setValues({
                              ...formik.values,
                              AttributeValueDetails: updatedFields,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="grid mt-3 ml-8">
                    <div className="">
                      <div className="p-field ">
                        <div className="">
                          <label htmlFor="place" className="p-d-block">
                            Botanical Name
                          </label>
                        </div>
                        <InputText
                          id="place"
                          type="text"
                          disabled={!field.isEditable}
                          className="inputform mt-2  m-0"
                          value={field.botanicalName}
                          onChange={(e) => {
                            setOnChangeValues(e.target.value);
                            const updatedFields = [...fields];
                            updatedFields[index].botanicalName = e.target.value;
                            formik.setValues({
                              ...formik.values,
                              AttributeValueDetails: updatedFields,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="black-button ml-3">
                    {field.isEditable ? (
                      <>
                        <Button icon="pi pi-save" rounded severity="secondary" aria-label="Save" onClick={(event) => onSaveFiled(event, index)} />
                        <Button icon="pi pi-times" rounded severity="secondary" aria-label="Cancel" onClick={(e) => onFeildCancelClick(e, index)} />
                      </>
                    ) : (
                      <>
                        <Button icon="pi pi-pencil" rounded severity="secondary" aria-label="Cancel" onClick={(e) => onEditClick(e, index)} />
                        <Button
                          icon="pi pi-trash"
                          rounded
                          severity="secondary"
                          aria-label="Cancel"
                          onClick={(event) => onDeleteClick(event, index)}
                        />
                      </>
                    )}
                  </div>
                </div>
              ))}

            <div className="cancel-submit-btn w-full justify-content-end mt-6 mb-8">
              <Button className="cancel-btn " label={resource.cancel} onClick={onCancelClick} type="reset" />
              <Button
                className="submit-btn ml-2 "
                label={resource.submit}
                type="submit"
                disabled={showSave || isEditableFiled}
                loading={loading}
                onClick={onSaveClick}
              />
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default FloraFaunaLayout;
