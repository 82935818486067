import { useContext, useState, useRef, useEffect } from "react";
import HeaderWithBackButton from "../common/HeaderWIthBackButton";
import { contextStore } from "../../context/CustomStateProvider";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { TabPanel, TabView } from "primereact/tabview";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import OverViewLayout from "./OverviewTab";
import CharacteristicsTab from "./CharacteristicsTab";
import PhysiologyLayout from "./Physiology";
import GalleryLayout from "./Gallery";
import MediaLayout from "./MediaLayout";
import FloraFaunaLayout from "./FloraFaunaLayout";
import AddNewAreaService from "../../services/addNewArea";
import { TreeNode } from "primereact/treenode";
import { Tree } from "primereact/tree";
import { Button } from "primereact/button";
import poiServices from "../../services/poiServices";
import { ProgressSpinner } from "primereact/progressspinner";
import ActivityLayout from "./Activities";
import CustomTab from "./CustomTab";
import CustomTabPopup from "./CustomTabPopup";
import TherapyLayout from "./Therapy";

const CmsLayout = () => {
  const context = useContext(contextStore);
  const resource = context.state.strings;
  const [backButton, setBackButton] = useState(false);
  const toast = useRef<Toast>(null);
  const [nodes, setNodes] = useState<TreeNode[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSpinner, setLoadingSpinner] = useState<boolean>(false);
  const [loadingSpinnerLayout, setLoadingSpinnerLayout] = useState<boolean>(true);
  const [selectedNodeKey, setSelectedNodeKey] = useState<any>('');
  const [poiId, setPoiId] = useState();
  const [poiAttributeData, setPoiAttributeData] = useState<any>();
  const [poiData, setPoiData] = useState<any>();
  const [addCustomTabPopup, setAddCustomTabPopup] = useState(false);

  const buttons = [
    
    {
      ctrlType: "button",
      name: "btnAdd",
      title: resource.addCustomTab,
      type: "button",
      disabled: !poiId,
      className: "green-btn",
    },
  ];
  const onButtonClick = (e: string) => {
    if (e === "btnAdd") {
      setAddCustomTabPopup(true);

    }
  };

  const onBackButtonClick = () => {
    setBackButton(!backButton);
  };

  useEffect(() => {

    GetAreaData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GetAreaData = () => {
    setLoading(true);
    const data = {
      Id: null,
    };
    const setData = data as TreeNode;
    setTimeout(() => {
      AddNewAreaService.GetAllCMS(setData).then((res: any) => {
        try {
          if (res.status === 200) {
            setNodes(res.data?.areas);
            setLoading(false);
            setLoadingSpinnerLayout(false);
          }
          return res.data;
          // Redirect the user to the protected route
        } catch (error: any) {
          if (error.response && error.response.status === 401) {
          }
        }
      });
    }, 200)

  };

  const onPoiClick = (e: any) => {
    setPoiId(e);
    GetPoIAttribute(e)
    ShowPOI(e);
  }

  const cmsValue = nodes?.map((area: any, index) => {
    return {
      key: index.toString(),
      label: area.areaName,
      data: area.p_areaName,
      icon: null,
      children: area.subAreas?.map((subArea: any, subIndex: number) => {
        return {
          key: `${index}-${subIndex}`,
          label: subArea.subAreaName,
          data: subArea.subAreaName,
          icon: null,
          children: subArea.pois?.map((poi: any, poiIndex: number) => {

            return {
              key: `${index}-${poiIndex}`,
              label: <><Button className="poi-button" onClick={() => onPoiClick(poi.poiId)}>{poi.poiName}</Button></>,
              data: poi.poiName,
              icon: null,
            };

          })
        };
      })
    };
  });

  const GetPoIAttribute = (poiId: any) => {
    const data = {
      poiid: poiId
    }
    poiServices.GetPoIAttribute(data).then((res: any) => {
      try {
        if (res.status === 200) {
          setPoiAttributeData(res.data)
        }
        if (res.response.status === 400) {
        }
        return res.data;
        // Redirect the user to the protected route
      } catch (error: any) {
        if (error.response && error.response.status === 400) {

        }
      }
    });
  }

  const ShowPOI = (poiId: any) => {
    setLoadingSpinner(true);
    var data = {
      poiid: poiId ? poiId : 0,
    };
    setTimeout(() => {
      poiServices.GetPOIData(data).then((res: any) => {
        try {
          if (res.status === 200) {
            setPoiData(res.data);
            setLoadingSpinner(false)
          }
          return true;
          // Redirect the user to the protected route
        } catch (error: any) {
          if (error.response && error.response.status === 401) {

          }
        }
      });
    }, 1000)

  };

  const AddAttribute = (values: any) => {
    setLoadingSpinnerLayout(true);
    let data = {
      poiid: poiId,
      Attributename: values.attributename,
      Description: values.description,

    }
    poiServices.AddPOIAttribute(data).then((res: any) => {

      try {
        setLoading(false);

        if (res.status === 200 && res.data.p_response === true) {
          toast.current?.show({
            severity: "success",
            summary: "Success",
            detail: "Tab added successfully",
            life: 3000,
          });
          setAddCustomTabPopup(false);
          setLoadingSpinnerLayout(false);
          onPoiClick(poiId);
        }
        if (res.status === 200 && res.data.p_response === false) {
          toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: res.data.p_msg,
            life: 3000,
          });
          setLoadingSpinnerLayout(false);
          setAddCustomTabPopup(false);
        }

        return res.data;
        // Redirect the user to the protected route
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: "Unauthorized", life: 3000,
          });
          window.location.pathname = "/";
        }
      }
    });
  }

  return (
    <div className="container cms-layout ">
      <Toast ref={toast} />
      {loadingSpinnerLayout ?
        <ProgressSpinner style={{ width: '70px', height: '70px', }} strokeWidth="2" fill="var(--surface-ground)" animationDuration=".9s" />
        : <>


          <CustomTabPopup
            addCustomTabPopup={addCustomTabPopup}
            poiId={poiId}
            setAddCustomTabPopup={setAddCustomTabPopup}
            toast={toast}
            AddAttribute={AddAttribute}
          />

          <HeaderWithBackButton
            title={resource.CMS}
            controls={poiId? buttons:[]}
            onButtonClicked={onButtonClick}
            isSearch
            onBackButtonClick={onBackButtonClick}
          />
          <div className="grid m-0 overflow-hidden">
            <div className="col-3 poicard mt-1">
              <div className=" p-3 bg-white">
                <h5 className="mb-3">POI</h5>
                <div className="mt-3 scrollable-content">
                  <Tree filter filterMode="lenient" filterPlaceholder="Search" value={cmsValue}
                    loading={loading}
                    className="w-full "
                    onSelectionChange={(e) => setSelectedNodeKey(e.value)}
                    selectionKeys={selectedNodeKey}
                  />
                </div>
              </div>
            </div>
            <div className="col-9 transparant-table mt-1">
              {loadingSpinner ?
                <ProgressSpinner style={{ width: '70px', height: '70px', }} strokeWidth="2" fill="var(--surface-ground)" animationDuration=".9s" /> :
                <div className="ml-3 mb-3">
                  {poiData?.map((poi: any) => {

                    return (
                      <div className="flex poi-flex">
                        <div className="col mr-3">
                          <label htmlFor={poi.p_areaname} className="mr-3">
                            Area:
                          </label>
                          <InputText
                            id={poi.p_areaname}
                            type="text"
                            value={poi.p_areaname}
                            className=" mt-2"
                            disabled={true}
                          />
                        </div>
                        <div className="col mr-3">
                          <label htmlFor={poi.p_subareaname} className="mr-3">
                            Sub Area:
                          </label>
                          <InputText
                            id={poi.p_subareaname}
                            type="text"
                            value={poi.p_subareaname}
                            className=" mt-2"
                            disabled={true}
                          />
                        </div>
                        <div className="col mr-3">
                          <label htmlFor={poi.p_poiname} className="mr-3 ">
                            Place:
                          </label>
                          <InputText
                            id={poi.p_poiname}
                            type="text"
                            className=" mt-2"
                            value={poi.p_poiname}
                            disabled={true}
                          />
                        </div>
                      </div>
                    );
                  })}
                  {poiData && poiAttributeData ?
                    <div className="tab-sectionTable ">
                      <TabView scrollable>
                        {poiAttributeData?.map((tab: any) => {

                          const attributeId = tab.attribute_id;

                          return (
                            <TabPanel key={tab.attribute_id} header={tab.attribute_name}>
                              {tab.attribute_id === 1 && <OverViewLayout attributeId={attributeId} poiId={poiId} onPoiClick={onPoiClick} poiData={poiData} toast={toast} />}
                              {tab.attribute_id === 2 && <CharacteristicsTab attributeId={attributeId} poiId={poiId} onPoiClick={onPoiClick} toast={toast} />}
                              {tab.attribute_id === 3 && <PhysiologyLayout attributeId={attributeId} poiId={poiId} onPoiClick={onPoiClick} toast={toast} />}
                              {tab.attribute_id === 4 && <GalleryLayout attributeId={attributeId} poiId={poiId} onPoiClick={onPoiClick} toast={toast} />}
                              {tab.attribute_id === 5 && <MediaLayout attributeId={attributeId} poiId={poiId} onPoiClick={onPoiClick} toast={toast} />}
                              {tab.attribute_id === 6 && <FloraFaunaLayout attributeId={attributeId} poiId={poiId} onPoiClick={onPoiClick} toast={toast} />}
                              {tab.attribute_id === 7 && <ActivityLayout attributeId={attributeId} poiId={poiId} onPoiClick={onPoiClick} toast={toast} />}
                              {tab.attribute_id === 8 && <TherapyLayout attributeId={attributeId} poiId={poiId} onPoiClick={onPoiClick} toast={toast} />}
                              {tab.attribute_id > 8 && <CustomTab attributeId={attributeId} poiId={poiId} onPoiClick={onPoiClick} poiData={poiData} toast={toast} />}

                            </TabPanel>
                          );
                        })}
                      </TabView>
                    </div> : <div className="select-text">
                      <span className=""> Please select POI</span></div>
                  }
                </div>}
            </div>
          </div>
        </>}
    </div>
  );
};

export default CmsLayout;
