
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import routes from "./router/routes";
import LoginPage from "./pages/LoginPage";
import "./assetes/css/global.css";
import "./assetes/css/common.css";
import "./assetes/css/loginPage.css";
import "./assetes/css/sidebar.css";
import "./assetes/css/navbar.css";
import "./assetes/css/chatBot.css"
import "./assetes/css/flag.css";
import BackgroundGradiant from "./components/common/BackgroundGradiant";
import ForgotPassWord from "./pages/ForgetPassword";
import PageNotFound from "./pages/PageNotFound";
import CreatePassWord from "./pages/CreatePassword";
import ResetPassWord from "./pages/ResetPassword";
import Chat from "./components/ChatBot/Chat";


const App = () => {
  const handleLogin = () => {
    return <Navigate to="/" replace />;
  };

  return (
    <BrowserRouter>
      <BackgroundGradiant />
      <Routes>
        <Route path="/" element={<LoginPage handleLogin={handleLogin} />} />
        <Route path="/forget-password" element={<ForgotPassWord />} />
        <Route path="/set-password/:token" element={<CreatePassWord />} />
        <Route path="/reset-password/:token" element={<ResetPassWord />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/*" element={<PageNotFound />} />
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
    </BrowserRouter>
  );
};

export default App;