import { useContext, useEffect, useRef, useState } from "react";
import HeaderWithBackButton from "../common/HeaderWIthBackButton";
import { contextStore } from "../../context/CustomStateProvider";
import AddActivityPopup from "./AddActivityPopup";
import { Toast } from "primereact/toast";
import ActivityTable from "./ActivityTable";
import { ConfirmDialog } from "primereact/confirmdialog";
import { ProgressSpinner } from "primereact/progressspinner";

const ActivityLayout = (props: any) => {
  const context = useContext(contextStore);
  const resource = context.state.strings;
  const [addActivityChange, setAddActivityChange] = useState(false);
  const [backButton, setBackButton] = useState(false);
  const [addNewActivityPopup, setaddNewActivityPopup] = useState(false);
  const [isEditActivity, setIsEditActivity] = useState();
  const [editMode, setEditMode] = useState(false);
  const toast = useRef<Toast>(null);
  const [reloadActivityGrid, setReloadActivityGrid] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [activityDataFilter, setActivityDataFilter] = useState([]);
  const [loadingSpinnerLayout, setLoadingSpinnerLayout] = useState<boolean>(true);

  const cardControls = [
    {
      ctrlType: "button",
      name: "btnAdd",
      title: resource.addNewActivity,
      type: "button",
      disabled: false,
      className: "green-btn mr-2",
    },
  ];

  const onButtonClick = (e: string) => {
    if (e === "btnAdd") {
      setAddActivityChange(true);
      setaddNewActivityPopup(true);
      setEditMode(false);
    } else if (e === "btnAddSubArea") {
      setAddActivityChange(true);
      setEditMode(false);
    }
  };
  const onBackButtonClick = () => {
    setBackButton(!backButton);
  };

  const onEditActivityClicked = (data: any) => {
    setaddNewActivityPopup(true);
    setIsEditActivity(data);
    setEditMode(true);
  };

  const handleSearch = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const filteredActivityData = activityDataFilter?.filter((activityDataFilter: any) => {
    return (
      activityDataFilter?.p_activityname?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      activityDataFilter?.p_description?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });
  useEffect(() => {
    setTimeout(() => {
      setLoadingSpinnerLayout(false);
    }, 400);
  }, []);

  return (
    <div className="ml-2">
      <Toast ref={toast} />
      {loadingSpinnerLayout ? (
        <ProgressSpinner style={{ width: "70px", height: "70px" }} strokeWidth="2" fill="var(--surface-ground)" animationDuration=".9s" />
      ) : (
        <>
          <div>
            {addNewActivityPopup && (
              <AddActivityPopup
                isEditActivity={isEditActivity}
                addNewActivityPopup={addNewActivityPopup}
                setaddNewActivityPopup={setaddNewActivityPopup}
                editMode={editMode}
                toast={toast}
                setReloadActivityGrid={setReloadActivityGrid}
                reloadActivityGrid={reloadActivityGrid}
              />
            )}

            <HeaderWithBackButton
              title={!addActivityChange || backButton ? resource.activityDetails : resource.activityDetails}
              controls={cardControls}
              onButtonClicked={onButtonClick}
              onBackButtonClick={onBackButtonClick}
              handleSearch={handleSearch}
              searchQuery={searchQuery}
            />
            <div className="transparant-table">
              <div className="row flex">
                <div className="col-12">
                  <ActivityTable
                    onEditActivityClicked={onEditActivityClicked}
                    addNewActivityPopup={addNewActivityPopup}
                    setaddNewActivityPopup={setaddNewActivityPopup}
                    reloadActivityGrid={reloadActivityGrid}
                    filteredActivityData={filteredActivityData}
                    setActivityDataFilter={setActivityDataFilter}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ActivityLayout;
