import React, { useState,useEffect, useContext } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { contextStore } from '../../context/CustomStateProvider';

const hours = Array.from({ length: 24 }, (_, i) => {
    const val = `${i < 10 ? '0' + i : i}`;
    return { label: val, value: val };
});

const minutes = Array.from({ length: 60 }, (_, i) => {
    const val = `${i < 10 ? '0' + i : i}`;
    return { label: val, value: val };
});

const seconds = Array.from({ length: 60 }, (_, i) => {
    const val = `${i < 10 ? '0' + i : i}`;
    return { label: val, value: val };
});

const DurationInput = (props:any) => {
  const context = useContext(contextStore);
  const resource = context.state.strings;
    const [selectedHour, setSelectedHour] = useState('00');
    const [selectedMinute, setSelectedMinute] = useState('00');
    const [selectedSecond, setSelectedSecond] = useState('00');


    useEffect(() => {
        if (props.durationValue) {
          const [hour, minute, second] = props.durationValue.split(':');
          setSelectedHour(hour);
          setSelectedMinute(minute);
          setSelectedSecond(second);
        }

      }, [props.durationValue]);

    const handleHourChange = (e: { value: string }) => {
        const updatedDuration=e.value+":"+selectedMinute+":"+selectedSecond;
        setSelectedHour(e.value);
        props.onValueChange(updatedDuration);
        props.setDurationClear(true);
    };

    const handleMinuteChange = (e: { value: string }) => {
        const updatedDuration=selectedHour+":"+e.value+":"+selectedSecond;
        setSelectedMinute(e.value);
        props.onValueChange(updatedDuration);
        props.setDurationClear(true);

    };

    const handleSecondChange = (e: { value: string }) => {
        const updatedDuration=selectedHour+":"+selectedMinute+":"+e.value;
        props.onValueChange(updatedDuration);
        setSelectedSecond(e.value);
        props.setDurationClear(true);
    };

    return (
        <div className="duration-input">
           
            <label htmlFor="therapy" className="block  font-medium mb-2">
            Duration
              <span style={{ color: "red" }}>*</span>
            </label>
            <div className='flex'>
                <div className="p-field col-4" >
                <label className="block text-900 font-medium mb-2">{resource.hours}</label>
                    <Dropdown
                        value={selectedHour}
                        options={hours}
                        onChange={handleHourChange}
                        placeholder="Hour"   
                        tabIndex={props.tabindexvalue+1}  
                    />
                </div>
                <div className="p-field col-4">
                <label className="block text-900 font-medium mb-2">{resource.minutes}</label>
                    <Dropdown
                        value={selectedMinute}
                        options={minutes}
                        onChange={handleMinuteChange}
                        placeholder="Minute"
                        tabIndex={props.tabindexvalue+2}
                    
                    />
                </div>
                <div className="p-field col-4" >
                <label className="block text-900 font-medium mb-2">{resource.seconds}</label>
                    <Dropdown
                        value={selectedSecond}
                        options={seconds}
                        onChange={handleSecondChange}
                        placeholder="Second"
                        tabIndex={props.tabindexvalue+3}
                       
                    />
                </div>
            </div>
        </div>
    );
};

export default DurationInput;
