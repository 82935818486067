import React from "react";
import Logo from "./Logo";

const BackgroundGradiant: React.FC<{}> = () => {
  return (
    <>
      <Logo />
      <div className="back-drop-gradiant">
        <div className="circle-wrap">
          <div className="first-circle">
            <div className="second-circle"></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BackgroundGradiant;
