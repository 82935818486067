import { object, string, ref } from 'yup';

const CreatePasswordValidation = object().shape({
    NewPassword: string()
        .required('Please enter new password ')
        .min(8, 'Password must be at least 8 characters')
        .test('uppercase', 'Password must contain at least one uppercase letter', (value) => /[A-Z]/.test(value))
        .test('lowercase', 'Password must contain at least one lowercase letter', (value) => /[a-z]/.test(value))
        .test('number', 'Password must contain at least one number', (value) => /\d/.test(value))
        .test('specialChar', 'Password must contain at least one special character', (value) => /[!@#$%^&*()_+\-=\]{};':"\\|,.<>?]/.test(value)),
    ConfirmPassword: string().required('Please enter confirm password').oneOf([ref('NewPassword')], 'The password does not match'),
});

export default CreatePasswordValidation;