import axios from "axios";
import authHeader from "./authHeader";

const IconUpload = async (data: any, onUploadProgress: (progress: number) => void) => {
    try {
        // Convert Blob URL to Blob object
        const blob = await fetch(data.File.objectURL).then(response => response.blob());

        // Create FormData and append data
        const formData = new FormData();
        formData.append("file", blob, data.File.name); // Use the actual file name
        formData.append("poiid", data.PoiId);
        formData.append("attributeid", data.AttributeId);
        formData.append("attributedetailid", data.AttributeDetailId);

        // Perform the axios POST request with onUploadProgress
        const response = await axios.post(
            `${process.env.REACT_APP_EC2_CMS_ENDPOINT}api/MediaUpload/Upload`,
            formData,
            {
                headers: {
                    ...authHeader(),
                    'Content-Type': 'multipart/form-data',
                    'Access-Control-Allow-Origin': '*', // Optional: Specify to allow all origins
                },
                onUploadProgress: progressEvent => {
                    if (progressEvent.total) {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        onUploadProgress(percentCompleted);
                    } else {
                        // Fallback in case 'total' is undefined
                        onUploadProgress(100); // Assume upload is complete if we can't calculate progress
                    }
                }
            }
        );
        return response;
    } catch (error) {
        console.error("Error uploading icon:", error);
        throw error; // Rethrow the error to propagate it further if necessary
    }
};


const ImageUpload = async (data: any, onUploadProgress: (progress: number) => void) => {
    try {
        // Convert Blob URL to Blob object
        const blob = await fetch(data.File.objectURL).then(response => response.blob());

        // Create FormData and append data
        const formData = new FormData();
        formData.append("file", blob, data.File.name); // Replace "filename.png" with the desired filename
        formData.append("poiid", data.PoiId);
        formData.append("attributeid", data.AttributeId);
        formData.append("attributedetailid", data.AttributeDetailId);

        const response = await axios.post(
            `${process.env.REACT_APP_EC2_CMS_ENDPOINT}api/MediaUpload/Upload`,
            formData,
            {
                headers: {
                    ...authHeader(),
                    'Access-Control-Allow-Origin': '*', // Optional: Specify to allow all origins
                },
                onUploadProgress: progressEvent => {
                    if (progressEvent.total) {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        onUploadProgress(percentCompleted);
                    } else
                     {
                       
                        onUploadProgress(100); 
                    }
                }
            }
        );
        return response;
    } catch (error) {
        console.error("Error uploading icon:", error);
        throw error; // Rethrow the error to propagate it further if necessary
    }
};
const GallreyImageUpload = async (data: any, onUploadProgress: (progress: number) => void) => {
    try {
        // Convert Blob URL to Blob object
        const blob = await fetch(data.File.objectURL).then(response => response.blob());

        // Create FormData and append data
        const formData = new FormData();
        formData.append("file", blob, data.File.name); // Replace "filename.png" with the desired filename
        formData.append("poiid", data.PoiId);
        formData.append("attributeid", data.AttributeId);
        formData.append("attributedetailid", data.AttributeDetailId);

        const response = await axios.post(
            `${process.env.REACT_APP_EC2_CMS_ENDPOINT}api/MediaUpload/Upload`,
            formData,
            {
                headers: {
                    ...authHeader(),
                    'Access-Control-Allow-Origin': '*', // Optional: Specify to allow all origins
                },
                onUploadProgress: progressEvent => {
                    if (progressEvent.total) {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        onUploadProgress(percentCompleted);
                    } else
                     {
                       
                        onUploadProgress(100); 
                    }
                }
            }
        );
        return response;
    } catch (error) {
        console.error("Error uploading icon:", error);
        throw error; // Rethrow the error to propagate it further if necessary
    }
};


const MediaVideoUpload = async (data: any, onUploadProgress: (progress: number) => void) => {
    try {
        // Convert Blob URL to Blob object
        const blob = await fetch(data.File.objectURL).then(response => response.blob());

        // Create FormData and append data
        const formData = new FormData();
        formData.append("file", blob, data.File.name); // Replace "filename.png" with the desired filename
        formData.append("poiid", data.PoiId);
        formData.append("attributeid", data.AttributeId);
        formData.append("attributedetailid", data.AttributeDetailId);
        formData.append("filename", data.FileName);
        formData.append("duration", data.duration);

        const response = await axios.post(
            `${process.env.REACT_APP_EC2_CMS_ENDPOINT}api/MediaUpload/Upload`,
            formData,
            {
                headers: {
                    ...authHeader(),
                    'Access-Control-Allow-Origin': '*', 
                },
                onUploadProgress: progressEvent => {
                    if (progressEvent.total) {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        onUploadProgress(percentCompleted);
                    } else
                     {
                       
                        onUploadProgress(100); 
                    }
                }
            }
        );
        return response;
    } catch (error) {
        console.error("Error uploading icon:", error);
        throw error;
    }
};

const MediaAudioUpload = async (data: any, onUploadProgress: (progress: number) => void) => {
    try {
        const blob = await fetch(data.File.objectURL).then(response => response.blob());

        const formData = new FormData();
        formData.append("file", blob, data.File.name);
        formData.append("poiid", data.PoiId);
        formData.append("attributeid", data.AttributeId);
        formData.append("attributedetailid", data.AttributeDetailId);
        formData.append("filename", data.FileName);
        formData.append("duration", data.duration);
        const response = await axios.post(
            `${process.env.REACT_APP_EC2_CMS_ENDPOINT}api/MediaUpload/Upload`,
            formData,
            {
                headers: {
                    ...authHeader(),
                    'Access-Control-Allow-Origin': '*', 
                },
                onUploadProgress: progressEvent => {
                    if (progressEvent.total) {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        onUploadProgress(percentCompleted);
                    } else
                     {
                       
                        onUploadProgress(100); 
                    }
                }
            }
        );
        return response;
    } catch (error) {
        console.error("Error uploading icon:", error);
        throw error; // Rethrow the error to propagate it further if necessary
    }
};


const fileUpload = {

    IconUpload,
    ImageUpload,
    GallreyImageUpload,
    MediaVideoUpload,
    MediaAudioUpload,

};

export default fileUpload;
