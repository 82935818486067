import * as Yup from "yup";

const today = new Date();

const MedicineLineItemValidation = Yup.object().shape({
    medicinename: Yup.string().required('Please enter medicine name')
   .max(50, 'Medicinename cannot be longer than 50 characters')
   .matches(/^[A-Za-z0-9\s()-.]+$/, 'Please enter medicine name using letters and digits only.'),
    dose: Yup.string().required('Please enter dose')
    .matches(/^[A-Za-z0-9\s,-.?!/()%&']+$/, 'Please enter dose using letters and digits only.'),
    startdate: Yup.string().required('Please enter start date'),
    enddate: Yup.string().required('Please enter end date')
    .test('is-after-startdate', 'End date can be the same as or after the start date',
     function(value)
     {
        const enddate = new Date(value);
        const  startdate  = new Date(this.parent.startdate);
        return enddate >= startdate;
      }),
    frequency:Yup.string().required('Please enter timing for doses')
    .matches(/^[A-Za-z0-9\s,-.:?!/()%&']+$/, 'Please enter timings for doses using letter and digits only.'),
    does: Yup.string().max(500, "Do's cannot be longer than 500 characters")
   .matches(/^[A-Za-z0-9\s,-.?!/()%&']+$/, "Please enter do's using letter and digits only."),
   donts: Yup.string().max(500, "Dont's cannot be longer than 500 characters")
   .matches(/^[A-Za-z0-9\s,-.?!/()%&']+$/, "Please enter don'ts using letter and digits only."),
  
  
});


export default MedicineLineItemValidation;
