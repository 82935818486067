import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "primereact/button";
import medicineServices from "../../../services/medicineServices";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import MedicineServices from "../../../services/medicineServices";
import MedicineLineItemValidation from "../../../validation/MedicineLineItemValidation";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import { contextStore } from "../../../context/CustomStateProvider";

const MedicalLayout = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [medicineLineItemList, setMedicineLineItemList] = useState<MedicineLineItemTable[]>([]);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [, setMedicineLineItemDisabled] = useState(false);
  const [, setSelectedMedicineLineItem] = useState<MedicineLineItem | null>(null);
  const context = useContext(contextStore);
  const resource = context.state.strings;
  const [medicineLineItemId, setMedicineLineItemId] = useState(null);
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [editClick,setEditClick]=useState(false);
  const [isEditDisabled, setIsEditDisabled] = useState<boolean>(false);
  const getMinDate = () => {
    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0); // Set today's date to midnight to ignore time
    const prescriptionDate = props.prescriptiondate ? new Date(props.prescriptiondate) : null;
    return prescriptionDate && prescriptionDate > todayDate ? prescriptionDate : todayDate;
  };
  const [minDate, setMinDate] = useState<Date>(getMinDate);
  const todayDate = new Date();

  if(minDate)

  interface MedicineLineItem {
    p_medicinelineitemid: BigInteger;
    p_medicinename: string;
    p_dose: string;
    p_days: string;
    p_startdate: string;
    p_enddate: string;
    p_frequency: string;
    p_does: string;
    p_donts: string;
  }

  interface MedicineLineItemTable {
    srNo: number;
    p_medicinelineitemid: BigInteger;
    p_medicinename: string;
    p_dose: string;
    p_days: string;
    p_startdate: string;
    p_enddate: string;
    p_frequency: string;
    p_does: string;
    p_donts: string;
  }
  interface MedicineChange {
    medicinename: string;
    dose: string;
    startdate: any;
    enddate: any;
    frequency: string;
    does: string;
    donts: string;
  }
  const GetMedicineLineItemValues = () => {
    setLoading(true);
    let data = {
      Prescriptionid: props.prescriptionid ? props.prescriptionid : props.editPrescriptionid,
    };
    setTimeout(() => {
      medicineServices.GetMedicineLineItem(data).then((res: any) => {
        setMedicineLineItemList(res.data);
        setLoading(false);
        setButtonClicked(false);
        setMedicineLineItemDisabled(false);
      });
    }, 600);
  };

  const MedicineLineItemAdded = (values: any) => {
    setLoading(true);
    let data = {
      medicinelineitemid: medicineLineItemId,
      prescriptionid: props.prescriptionid ? props.prescriptionid : props.editPrescriptionid,
      medicinename: values.medicinename,
      dose: values.dose,
      startdate: values.startdate,
      enddate: values.enddate,
      frequency: values.frequency,
      does: values.does,
      donts: values.donts,
    };
    
    if(editClick)
    {
      MedicineServices.UpdateMedicineLineItem(data).then((res: any) => {
        try {
          setLoading(false);
          setMedicineLineItemId(null);

          if (res.status === 200 && res.data.p_response) {
            GetMedicineLineItemValues();
            setButtonClicked(true);
            props.toast.current?.show({
              severity: "success",
              summary: "Sucess",
              detail: res.data.p_msg,
              life: 3000,
            });
            formik.resetForm();
            setStartDate(null);
            setEndDate(null);
          }
          else if (res.response.status === 400 &&  res.response.data.success===false)
          {
            const errorMessage = res.response.data.errorMessage;
            props.toast.current?.show({ severity: "warn", summary: "Warning", detail: errorMessage, life: 3000 });
            props.setLoading(false);
          }
          
          else {
            props.toast.current?.show({
              severity: "warn",
              summary: "Warning",
              detail: res.data.p_msg,
              life: 3000,
            });
          }
          return res.data;
        } catch (error: any) {
          if (error.response && error.response.status === 401) {
          }
        }
      });
      setEditClick(false);

    }
    else
    {
    MedicineServices.AddMedicineLineItem(data).then((res: any) => {
      try {
        setLoading(false);
        setMedicineLineItemId(null);
        if (res.status === 200 && res.data.p_response) {
          GetMedicineLineItemValues();
          setButtonClicked(true);
          props.toast.current?.show({
            severity: "success",
            summary: "Sucess",
            detail: res.data.p_msg,
            life: 3000,
          });
          formik.resetForm();
          setStartDate(null);
          setEndDate(null);
        }
        else if (res.response.status === 400 &&  res.response.data.success===false)
        {
          const errorMessage = res.response.data.errorMessage;
          props.toast.current?.show({ severity: "warn", summary: "Warning", detail: errorMessage, life: 3000 });
          props.setLoading(false);
        }
         else {
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: res.data.p_msg,
            life: 3000,
          });
        }
        return res.data;
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
        }
      }
    });
  };
}
  const handleSubmit = async (values: MedicineChange) => {
    MedicineLineItemAdded(values);
  };

  const formik = useFormik({
    initialValues: {
      medicinename: "",
      dose: "",
      startdate: null,
      enddate: null,
      frequency: "",
      does: "",
      donts: "",
    },
    validationSchema: MedicineLineItemValidation,
    onSubmit: handleSubmit,
  });

  const onEditClick = (value: any) => {
    setIsEditDisabled(false); 
    const startDate = new Date(value.p_startdate);
    const todayDateOnly = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate());
    const startDateOnly = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());

    if (startDateOnly < todayDateOnly)
    {
      setIsEditDisabled(true)
    }
    setEditClick(true);
    setMedicineLineItemId(value.p_medicinelineitemid);
    formik.setFieldValue("medicine", value.p_medicineid);
    formik.setFieldValue("medicinename", value.p_medicinename);
    formik.setFieldValue("dose", value.p_dose);
    formik.setFieldValue("frequency", value.p_frequency);
    formik.setFieldValue("does", value.p_does);
    formik.setFieldValue("donts", value.p_donts);
    setStartDate(new Date(value.p_startdate));
    setEndDate(new Date(value.p_enddate));
    formik.setFieldValue("startdate", new Date(value.p_startdate));
    formik.setFieldValue("enddate", new Date(value.p_enddate));
  };
  const onDeleteClick = (values: any) => {
    confirmDialog({
      message: `Are you sure you want to delete this field?`,
      header: "Confirm delete field",
      icon: "pi pi-exclamation-triangle",
      accept: () => OnDeleteMedicineLineItem(values),
    });
  };
  const OnDeleteMedicineLineItem = (values: any) => {
    let data = {
      medicinelineitemid: values.p_medicinelineitemid,
    };

    medicineServices.DeleteMedicineLineItem(data).then((res: any) => {
      try {
        setLoading(false);
        if (res.data.p_response) {
          props.toast.current?.show({
            severity: "success",
            summary: "Success",
            detail: res.data.p_msg,
            life: 3000,
          });

          GetMedicineLineItemValues();
        }
        return res.data;
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: "Unauthorized",
            life: 3000,
          });
        }
      }
    });
  };

  const Action = (rowData: any) => {
    return (
      <React.Fragment>
        <Button className="table-button custome-icon  edit-icon " onClick={() => onEditClick(rowData)} />
        <Button className="table-button custome-icon delete-icon" onClick={() => onDeleteClick(rowData)}></Button>
      </React.Fragment>
    );
  };

  const SrNo = (rowData: any, index: any) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{index.rowIndex + 1}</span>
      </div>
    );
  };
  const onCancelClick = () => {
    setIsEditDisabled(false); 
    setMedicineLineItemDisabled(false);
    setSelectedMedicineLineItem(null);
    formik.resetForm();
    setStartDate("");
    setEndDate("");
  };

  const formatDate = (value: string) => {
    const date = new Date(value);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    if (props.editPrescriptionid !== null) {
      GetMedicineLineItemValues();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editPrescriptionid]);

  useEffect(() => {
    //GetActivityByUserId();
   // GetMedicineLineItemValues();
    setIsEditDisabled(false);
    setMinDate(getMinDate);
  }, []);
  useEffect(() => {
    setMinDate(getMinDate);
  }, [props.prescriptiondate]);

  let TableData = (
    <div className="table-section">
      <DataTable value={medicineLineItemList} scrollable scrollHeight="750px" emptyMessage="No package detail found" loading={loading}>
        <Column header="Sr.No." style={{ minWidth: "2rem" }} body={SrNo} />
        <Column field="p_medicinename" header="Medicine name"></Column>
        <Column field="p_dose" header="Dose"></Column>
        <Column header="Start date" body={(rowData) => formatDate(rowData.p_startdate)} />
        <Column header="End date" body={(rowData) => formatDate(rowData.p_enddate)} />
        <Column field="p_frequency" header="Timing for doses"></Column>
        <Column field="p_days" header="Days"></Column>
        <Column field="p_does" header={resource.does}></Column>
        <Column field="p_donts" header={resource.donts}></Column>
        <Column field="Action" header="Action" body={Action}></Column>
      </DataTable>
    </div>
  );

  return (
    <>
      <Toast ref={props.toast} />
      <div>
        <form onSubmit={formik.handleSubmit}>
          <div className="gray-bg">
            <div className="flex flex-wrap  ml-1 mt-1">
              <div className="col-2 relative">
                <label htmlFor="medicinename" className="block font-medium mb-2">
                  {resource.medicinename}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <InputText
                  id="medicinename"
                  type="text"
                  className="inputbox w-full"
                  value={formik.values.medicinename}
                  disabled={isEditDisabled}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  placeholder="Enter medicine name"
                  autoFocus={true}
                  tabIndex={1}
                />
                {formik.errors.medicinename && formik.touched.medicinename && !buttonClicked && (
                  <div className="text-red-500 text-xs">{formik.errors.medicinename}</div>
                )}{" "}
              </div>
              <div className="col-2 relative">
                <label htmlFor="Dose" className="block font-medium mb-2">
                  {resource.dose}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <InputText
                  id="dose"
                  type="text"
                  className="inputbox w-full"
                  value={formik.values.dose}
                  disabled={isEditDisabled}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  placeholder="Enter dose"
                  tabIndex={2}
                />
                {formik.errors.dose && formik.touched.dose && !buttonClicked && <div className="text-red-500 text-xs">{formik.errors.dose}</div>}{" "}
              </div>
              <div className="col-2 relative">
                <label htmlFor="Satrtdate" className="block  font-medium mb-2">
                  {resource.startdate}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Calendar
                  placeholder="DD/MM/YYYY"
                  dateFormat="dd/mm/yy"
                  readOnlyInput
                  id="startdate"
                  value={startDate}
                  minDate={minDate}
                  className="w-full"
                  disabled={isEditDisabled}
                  showIcon
                  tabIndex={3}
                  onChange={(e) => {
                    if (e.value) {
                      const adjustedStartDate = new Date(Date.UTC(e.value.getFullYear(), e.value.getMonth(), e.value.getDate()));
                      formik.setFieldValue("startdate", adjustedStartDate);
                      setStartDate(adjustedStartDate);
                    }
                  }}
                />
                {formik.errors.startdate && formik.touched.startdate && <div className="text-red-500 text-xs">{formik.errors.startdate}</div>}
              </div>
              <div className="col-2 relative">
                <label htmlFor="Enddate" className="block  font-medium mb-2">
                  {resource.enddate}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Calendar
                  placeholder="DD/MM/YYYY"
                  dateFormat="dd/mm/yy"
                  readOnlyInput
                  id="enddate"
                  value={endDate}
                  minDate={minDate}
                  tabIndex={4}
                  className="w-full"
                  disabled={isEditDisabled}
                  showIcon
                  onChange={(e) => {
                    if (e.value) {
                      const adjustedEndDate = new Date(Date.UTC(e.value.getFullYear(), e.value.getMonth(), e.value.getDate()));
                      formik.setFieldValue("enddate", adjustedEndDate);
                      setEndDate(adjustedEndDate);
                    }
                  }}
                />
                {formik.errors.enddate && formik.touched.enddate && <div className="text-red-500 text-xs">{formik.errors.enddate}</div>}
              </div>
              <div className="col-3 relative">
                <label htmlFor="Frequency" className="block  font-medium mb-2">
                  {resource.frequency}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <InputText
                  id="frequency"
                  type="text"
                  className="inputbox w-full"
                  value={formik.values.frequency}
                  disabled={isEditDisabled}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  placeholder="Enter timing for doses"
                  tabIndex={5}
                />
                {formik.errors.frequency && formik.touched.frequency && !buttonClicked && (
                  <div className="text-red-500 text-xs">{formik.errors.frequency}</div>
                )}{" "}
              </div>
            </div>
            <div className=" flex ml-1 mt-4">
              <div className="col-4 relative">
                <label htmlFor="does" className="block font-medium mb-2">
                  {resource.does}
                </label>
                <InputTextarea
                  id="does"
                  className="w-full mb-5"
                  value={formik.values.does}
                  rows={3}
                  tabIndex={6}
                  disabled={isEditDisabled}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  placeholder="Enter Do’s"
                />
              {formik.errors.does && formik.touched.does && <div className="text-area-red-does-donts-bottom text-xs">{formik.errors.does}</div>}

              </div>
              <div className="col-4 relative">
                <label htmlFor="donts" className="block font-medium mb-2">
                  {resource.donts}
                </label>
                <InputTextarea
                  id="donts"
                  className="w-full mb-5"
                  value={formik.values.donts}
                  rows={3}
                  tabIndex={7}
                  disabled={isEditDisabled}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  placeholder="Enter Don’t"
                />
              {formik.errors.donts && formik.touched.donts && <div className="text-area-red-does-donts-bottom text-xs">{formik.errors.donts}</div>}

              </div>
              <div className="cancel-submit-btn flex w-full justify-content-end mt-8 mb-4 mr-4">
                <Button
                  type="button"
                  onClick={() => {
                    onCancelClick();
                  }}
                  label="Cancel"
                  className="cancel-btn"
                  tabIndex={8}
                />
                <Button type="submit" label="Save" className="submit-btn ml-2" tabIndex={9} disabled={isEditDisabled}/>
              </div>
            </div>
          </div>
        </form>
        {TableData}
      </div>
    </>
  );
};

export default MedicalLayout;
