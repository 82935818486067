import axios from "axios";
import authHeader from "./authHeader";

const GetEmployeeData = async () => {
  return axios
    .get(process.env.REACT_APP_BASE_API_ENDPOINT + `api/User`, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    })
    .then((res) => res)
    .catch((err) => err);
};

const UpdateEmployeeData = async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_API_ENDPOINT}api/User/update`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const DeleteUserData = async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_API_ENDPOINT}api/User/deleteemployee`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const EmployeeService = {
  GetEmployeeData,
  UpdateEmployeeData,
  DeleteUserData,
};

export default EmployeeService;
