import * as Yup from "yup";

const AddNewTherapyValidation = Yup.object().shape({
    TherapyName: Yup.string().required("Please enter therapy name")
    .max(50, 'Therapy name cannot be longer than 50 characters')
    .matches(/^[A-Za-z0-9\s()-.]+$/, 'Therapy name must only contain letters, numbers, and spaces.'),
    Description: Yup.string()
    .matches(/^[A-Za-z0-9\s,-.?!/()%&']+$/, 'Please enter description using letters and digits only.')
    .max(500, 'Description cannot be longer than 500 characters.'),

});
export default AddNewTherapyValidation;