// LoginPage.tsx
import React, { useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import LoginServices from "../services/loginServices";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import CreatePasswordValidation from "../validation/UpdatePassword";
import { useNavigate } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";

interface FormValues {
    NewPassword: string;
    ConfirmPassword: string;
}

const ResetPassWord = () => {
    const toast = useRef<Toast>(null);
    const [successMessage, setSuccessMessage] = useState(false);
    const navigate = useNavigate();
    const passwordInput: HTMLInputElement = document.getElementById("NewPassword") as HTMLInputElement;
    const passwordStrength: NodeListOf<HTMLDivElement> = document.querySelectorAll(".password-check > div");
    const [loading, setLoading] = useState(false);
    const [likIsExpired, setLikIsExpired] = useState(false);
    const [loadingSpinner, setLoadingSpinner] = useState<boolean>(true);
    const [confirmPassword, setConfirmPassword] = useState(false);
    const [expiredMessaged, setExpiredMessaged] = useState("");

    const handleSubmit = async (values: FormValues) => {
        setLoading(true);
        try {
            if (formik.isValid) {
                await onSubmit(values);
            }
        } catch (error) {
        }
    };

    const formik = useFormik({
        initialValues: {
            NewPassword: '',
            ConfirmPassword: '',
        },
        validationSchema: CreatePasswordValidation,
        onSubmit: handleSubmit,
    });

    const onSubmit = (values: FormValues) => {
        const currentURL = window.location.pathname;
        const matchResult = currentURL && currentURL.match(/\/reset-password\/([^\/]+)$/);
        const token = matchResult ? matchResult[1] : null;
        const data = {
            NewPassword: values.NewPassword,
            ConfirmPassword: values.ConfirmPassword,
            Token: token
        }
        LoginServices.ResetPasswordService(data).then((res: any) => {
            try {
                if (res.status === 200) {
                    toast.current?.show({ severity: "success", summary: "Success", detail: "Password updated successfully", life: 3000 });
                    setSuccessMessage(true);
                    setLoading(false);
                    formik.setFieldValue("NewPassword", "");
                    formik.setFieldValue("ConfirmPassword", "");
                    setConfirmPassword(true);
                    setTimeout(() => {
                        navigate("/");
                    }, 700)
                }

                if (res.response.status === 400 && res.response.data.success === false) {
                    toast.current?.show({
                        severity: "warn",
                        summary: "Warning",
                        detail: res.response.data.errorMessage,
                        life: 3000,
                    });
                    setLoading(false);
                    setTimeout(() => {
                        navigate("/");
                    }, 700)
                }
                if (res.response.status === 500 && res.response.data.success === false) {
                    toast.current?.show({
                        severity: "warn",
                        summary: "Warning",
                        detail: "Something went wrong",
                        life: 3000,
                    });
                    setLoading(false);
                    setTimeout(() => {
                        navigate("/");
                    }, 700)

                }
                return res.data;
                // Redirect the user to the protected route
            } catch (error: any) {

            }
        });
    };

    useEffect(() => {
        onLinkExpired();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onLinkExpired = () => {
        const currentURL = window.location.pathname;
        const matchResult = currentURL && currentURL.match(/\/reset-password\/([^\/]+)$/);
        const token = matchResult ? matchResult[1] : null;
        const data = {
            Token: token,
            ActionType: "reset-password"
        }
        LoginServices.LinkExpiredService(data).then((res: any) => {

            try {
                if (res.status === 200 && res.data.success === false) {

                    setLikIsExpired(true);
                    setLoadingSpinner(false);
                    setExpiredMessaged(res.data.errorMessage)
                }

                if (res.status === 200 && res.data.success === true) {
                    setLikIsExpired(false);
                    setLoadingSpinner(false);
                }
                if (res.response.status === 500 && res.data.success === false) {
                    toast.current?.show({
                        severity: "warn",
                        summary: "Warning",
                        detail: "Something went wrong",
                        life: 3000,
                    });
                    setLoading(false);
                    setTimeout(() => {
                        navigate("/");
                    }, 700)

                }
                return res.data;
                // Redirect the user to the protected route
            } catch (error: any) {

            }
        });
    }

    function checkPasswordStrength(password: string): number {
        let strength = 0;
        const hasLower = /[a-z]/.test(password);
        const hasUpper = /[A-Z]/.test(password);
        const hasDigit = /\d/.test(password);
        const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>?]/.test(password);
        const length = password.length;

        if (length < 8) {
            return 0; // Too short
        }

        if (hasLower) strength++;
        if (hasUpper) strength++;
        if (hasDigit) strength++;
        if (hasSpecialChar) strength++;

        if (strength >= 3) {
            return 3; // Strong
        } else if (strength >= 2) {
            return 2; // Moderate
        } else {
            return 1; // Weak
        }
    }

    passwordInput?.addEventListener("input", () => {
        const password: string = passwordInput.value;
        const strength: number = checkPasswordStrength(password);

        passwordStrength.forEach((element: HTMLDivElement, index: number) => {
            if (index < strength) {
                element.classList.add("active");
            } else {
                element.classList.remove("active");
            }
        });
    });

    const onForgetPassword = () => {
        navigate("/forget-password", { replace: true });
    };

    return (
        <div className="Login-page flex align-items-center  justify-content-center h-screen cms-layout">
            {/* Your login page UI */}
            <Toast ref={toast} />
            {loadingSpinner ? <ProgressSpinner style={{ width: '70px', height: '70px', }} strokeWidth="2" fill="var(--surface-ground)" animationDuration=".9s" /> :
                <>
                    {likIsExpired ? <><div>
                        <h2 >{expiredMessaged}</h2>
                        <div className="text-center mb-4 mt-5">
                            <a className="text-orange-500 font-14" href="/forget-password" onClick={onForgetPassword}>
                                Forgot your password?
                            </a>
                        </div>
                    </div>
                    </> :
                        <form onSubmit={formik.handleSubmit}>
                            <div className="login-card login-form  forget-password">
                                <h5>Reset Password</h5>
                                <>

                                    <div className="update-password-input mb-2 relative">
                                        <label htmlFor="NewPassword" className="block">
                                            New Password{" "} <span className="text-red">*</span>
                                        </label>
                                        <InputText id="NewPassword" type="password" placeholder="Enter new password" className="w-full"
                                            value={formik.values.NewPassword}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.NewPassword && formik.touched.NewPassword && <div className="error-msg">{formik.errors.NewPassword}</div>}

                                    </div>
                                    <div className="password-check flex  mt-4 ">
                                        <div className="w-3 mr-3 flex flex-column text-center ">
                                            <div className="Poor w-full mb-1" ></div>
                                            <div className="password-strength mb-3">Poor</div>
                                        </div>
                                        <div className="w-3 mr-3 flex flex-column text-center">
                                            <div className="Normal w-full  mb-1" ></div>
                                            <div className="password-strength mb-3">Normal</div>
                                        </div>
                                        <div className="w-3 mr-3 flex flex-column text-center ">
                                            <div className="Strong w-full  mb-1" ></div>
                                            <div className="password-strength mb-3">Strong</div>
                                        </div>
                                    </div>
                                    <div className="update-password-input mb-4 relative">
                                        <label htmlFor="ConfirmPassword" className="block">
                                            Confirm Password{" "} <span className="text-red">*</span>
                                        </label>
                                        <InputText id="ConfirmPassword" type="password" placeholder="Enter confirm password" className="w-full"
                                            value={formik.values.ConfirmPassword}
                                            onChange={formik.handleChange} />
                                        {formik.errors.ConfirmPassword && formik.touched.ConfirmPassword && !confirmPassword && <div className="error-msg">{formik.errors.ConfirmPassword}</div>}

                                    </div>
                                    <div className="flex align-items-start justify-content-between ">
                                        <div>
                                            <h6 className="mb-2 font-12">Password Must content</h6>
                                            <ul className="green-tick-add">
                                                <li>Min 8 Characters</li>
                                                <li>Lower- case letter </li>
                                                <li>Upper -case letter</li>
                                                <li>Special Character</li>
                                                <li>Number</li>
                                            </ul>
                                        </div>
                                        <div>
                                            <Button label="Save" className="login-button" type="submit" loading={loading} />
                                        </div>
                                    </div>
                                    <div className="text-center ">
                                        {successMessage ? (
                                            <div>
                                                <a href="/">Login again</a>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </>

                            </div>
                        </form>
                    }
                </>
            }
        </div>
    );
};

export default ResetPassWord;
