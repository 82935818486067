import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useFormik } from "formik";
import { contextStore } from "../../context/CustomStateProvider";
import poiServices from "../../services/poiServices";
import DurationInput from "../common/DurationInput";
import TherapyValiDation from "../../validation/TherapyValidation";

interface Therapy {
  p_therapyid: any;
  p_therapyname: string;
}

interface TherapyTable {
  srNo: number;
  p_therapyname: string;
  p_capacity: string;
  p_duration: string;
}

interface TherapyChange {
  therapy: any;
  capacity: any;
}

const TherapyLayout = (props: any) => {
  const context = useContext(contextStore);
  const resource = context.state.strings;
  const [loading, setLoading] = useState(true);
  const [therapyList, setTherapyList] = useState<TherapyTable[]>([]);
  const toastTopRight = useRef<Toast>(null);
  const [selectedTherapy, setSelectedTherapy] = useState<Therapy | null>(null);
  const [therapyValue, setTherapyValue] = useState([]);
  const [therapyDisabled, settherapyDisabled] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [durationValue, setDurationValue] = useState("00:00:00");
  const[durationValidationError,SetDurationValidationError] = useState('Please enter duration');
  const [durationClear, setDurationClear] = useState<boolean>(false);
  const[submitClick,SetSubmitClick]=useState(false);
  const GetTherapyValues = () => {
    poiServices.GetTherapy().then((res: any) => {
      setTherapyValue(res.data);
    });
  };
  const GetPoiTherapyValues = () => {
    setLoading(true);
    let data = {
      PoiId: props.poiId,
    };
    setTimeout(() => {
      poiServices.GetPoiTherapy(data).then((res: any) => {
        setTherapyList(res.data);
        setLoading(false);
        setSelectedTherapy(null);
        settherapyDisabled(false);
        setButtonClicked(false);
        formik.setFieldValue("capacity", "");
      });
    }, 600);
  };

  const setDurationMessage=()=>
  {
    SetSubmitClick(true);
   setDurationClear(false);
   if(!durationClear)
   {
    SetDurationValidationError(durationValidationError);
   }

   
   
  }



  useEffect(() => {
    GetTherapyValues();
    GetPoiTherapyValues();
    setDurationClear(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (values: TherapyChange) => {
    if (durationValue === "00:00:00")
      {
    
        setDurationMessage();
      }
      else
      {
        TherapyAdded(values);
      }
    
  };

  const formik = useFormik({
    initialValues: {
      therapy: null,
      capacity: "",
      Duration: durationValue,
    },
    validationSchema: TherapyValiDation,
    onSubmit: handleSubmit,
  });
  const onEditClick = (rowData: any) => {
    
    settherapyDisabled(true);
    formik.setFieldValue("capacity", rowData.p_capacity);
    formik.setFieldValue("duration", rowData.p_duration);
    setDurationValue(rowData.p_duration);
    setDurationClear(false);
    SetSubmitClick(false);
    therapyValue.forEach((value: any) => {
      if (parseInt(value.p_therapyid) === parseInt(rowData.p_therapyid)) {
        formik.setFieldValue("therapy", value.p_therapyid);
        setSelectedTherapy(value);
      }
    });
  };

  const onDeleteClick = (values: any) => {
    confirmDialog({
      message: `Are you sure you want to delete this field?`,
      header: "Confirm delete field",
      icon: "pi pi-exclamation-triangle",
      accept: () => OnDeleteTherapy(values), // Pass a function to be executed on confirmation
    });
  };

  const OnDeleteTherapy = (values: any) => {
    let data = {
      PoiId: props.poiId,
      therapyid: values.p_therapyid,
    };

    poiServices.DeletePOITherapy(data).then((res: any) => {
      try {
        setLoading(false);
        if (res.status === 200) {
          props.toast.current?.show({
            severity: "success",
            summary: "Success",
            detail: "Therapy deleted successfully",
            life: 3000,
          });

          GetPoiTherapyValues();
        }
        return res.data;
        // Redirect the user to the protected route
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: "Unauthorized",
            life: 3000,
          });
        }
      }
    });
  };

  const onCancelClick = () => {
    settherapyDisabled(false);
    setSelectedTherapy(null);
    formik.setFieldValue("capacity", "");
    formik.setFieldValue("duration", "");
    setDurationValue("00:00:00");
  };

  const TherapyAdded = (values: any) => {
    setLoading(true);
    let data = {
      PoiId: props.poiId,
      TherapyID: values.therapy?.p_therapyid ? values.therapy?.p_therapyid : values.therapy,
      Capacity: values.capacity,
      Duration : durationValue
    };
    poiServices.AddTherapy(data).then((res: any) => {
      try {
        setLoading(false);
        formik.resetForm();
        
        if (res.status === 200 && res.data.p_response === false) {
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: res.data.p_msg,
            life: 3000,
          });
          return;
        } else if (res.status === 200) {
          setDurationValue("00:00:00");
          GetPoiTherapyValues();
          setButtonClicked(true);
          if (!therapyDisabled) {
            props.toast.current?.show({
              severity: "success",
              summary: "Sucess",
              detail: res.data.p_msg,
              life: 3000,
            });
          } else {
            props.toast.current?.show({
              severity: "success",
              summary: "Sucess",
              detail: res.data.p_msg,
              life: 3000,
            });
          }
        }
        return res.data;
        // Redirect the user to the protected route
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
        }
      }
    });
  };

  const Action = (rowData: any) => {
    return (
      <React.Fragment>
        <Button className="table-button custome-icon  edit-icon " onClick={() => onEditClick(rowData)} />
        <Button className="table-button custome-icon delete-icon" onClick={() => onDeleteClick(rowData)}></Button>
      </React.Fragment>
    );
  };

  const SrNo = (rowData: any, index: any) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{index.rowIndex + 1}</span>
      </div>
    );
  };

  let TableData = (
    <div className="table-section">
      <DataTable value={therapyList} emptyMessage="No package detail found" loading={loading}>
        <Column header="Sr.No." style={{ minWidth: "2rem" }} body={SrNo} />
        <Column field="p_therapyname" header="Therapy"></Column>
        <Column field="p_capacity" header="Capacity"></Column>
        <Column field="p_duration" header="Duration"></Column>
        <Column field="Action" header="Action" body={Action}></Column>
      </DataTable>
    </div>
  );
  const selectedRoleTemplate = (option: Therapy) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.p_therapyname}</div>
        </div>
      );
    }
    return <span>{resource.selectTherapy}</span>;
  };
  const RoleOptionTemplate = (option: Therapy) => {
    return (
      <div className="flex align-items-center">
        <div>{option.p_therapyname}</div>
      </div>
    );
  };

  return (
    <>
      <Toast ref={toastTopRight} />
      <form onSubmit={formik.handleSubmit}>
        <div className="flex ml-3 mt-2">
          <div className="col-3 relative">
            <label htmlFor="therapy" className="block  font-medium mb-2">
              {resource.therapy}
              <span style={{ color: "red" }}>*</span>
            </label>
            <Dropdown
              value={selectedTherapy}
              onChange={(e: DropdownChangeEvent) => {
                formik.setFieldValue("therapy", e.value);
                setSelectedTherapy(e.value);
              }}
              disabled={therapyDisabled}
              options={therapyValue}
              valueTemplate={selectedRoleTemplate}
              itemTemplate={RoleOptionTemplate}
              optionLabel="name"
              placeholder="Select Therapy"
              className=" dropdown w-full"
              tabIndex={1}
              filter
              filterBy="p_therapyname"
            />
          </div>
          <div className="col-3 relative">
            <label htmlFor="capacity" className="block  font-medium mb-2">
              {resource.subCapacity}
              <span style={{ color: "red" }}>*</span>
            </label>
            <InputText
              id="capacity"
              type="text"
              disabled={!selectedTherapy}
              className="inputbox w-full"
              value={formik.values.capacity}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d{0,10}$/.test(value)) {
                  formik.handleChange(e);
                }
              }}
              placeholder="Enter sub capacity"
              tabIndex={2}
            />
            {formik.errors.capacity && formik.touched.capacity && !buttonClicked && (
              <div className="text-red-500 text-xs">{formik.errors.capacity}</div>
            )}{" "}
          </div>
          
        </div>
        <div className="flex ml-3 mt-2">
            <div className="col-6 relative">
                <DurationInput onValueChange={setDurationValue} durationValue={durationValue} setDurationClear={setDurationClear} durationClear={durationClear}
                tabindexvalue={3}/>
                {submitClick && !durationClear ? <p className="text-area-red-bottom text-xs">{durationValidationError} {durationClear}</p>:<></>}  
              </div>
            </div>
            

        <>
          <div className="flex mt-2 mb-4 justify-content-end">
            <Button
              type="button"
              onClick={() => {
                onCancelClick();
              }}
              label="Cancel"
              className="cancel-btn"
            />

            <Button type="submit" disabled={!selectedTherapy} label="Save" className="orange-btn ml-3" />
          </div>
        </>
      </form>

      {TableData}
    </>
  );
};

export default TherapyLayout;
