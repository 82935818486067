import { useContext, useEffect, useRef, useState } from "react";
import { contextStore } from "../../context/CustomStateProvider";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { TabPanel, TabView } from "primereact/tabview";
import MedicalLayout from "./medical/MedicalLayout";
import ProtocolTabLayout from "./protocolTab/protocolTabLayout";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import ProtocolValidationSchema from "../../validation/Protocolvalidation";
import protocolServices from "../../services/protocolServices";
import moment from "moment";
import { Nullable } from "primereact/ts-helpers";
import VedicTreatmentTabLayout from "./vedicTreatementTab/vedicTreatmentTabLayout";
import HeaderWithBackButton from "../common/HeaderWIthBackButton";

interface FormValues {
  prescriptionname: string;
  prescriptiondate: any;
  description: string;
}

const AddProtocolLayout = (props: any) => {
  const context = useContext(contextStore);
  const resource = context.state.strings;
  const [activeIndex, setActiveIndex] = useState(0);
  const [prescriptionValue, setPrescriptionValue] = useState<any>(null);
  const [prescriptiontoValue, setPrescriptiontoValue] = useState<any>(null);
  const [editPrescriptionid, setEditPrescriptionid] = useState<any>(null);
  const toast = useRef<Toast>(null);

  const [loading, setLoading] = useState(false);
  const [dateOfTreatment, setDateOfTreatment] = useState<Nullable<Date>>(null);
  const minDate = new Date();
  minDate.setHours(0, 0, 0, 0);
  
  const [isEditDisabled, setIsEditDisabled] = useState<boolean>(false);
  const todayDate = new Date();

  const sections = [
    { id: "section-1", label: "Medicine", content: "Section 1 content" },
    { id: "section-2", label: "Vedic Treatments", content: "Section 2 content" },
    { id: "section-3", label: "Protocol", content: "Section 3 content" },
  ];
  const renderContent = () => {
    const activeSection = sections[activeIndex];

    if (activeSection.id === "section-1") {
      return (
        <MedicalLayout 
         toast={toast} prescriptionid={props.prescriptionid} 
         editPrescriptionid={editPrescriptionid}
         prescriptiontoValue={prescriptiontoValue}
         prescriptiondate={dateOfTreatment} />
      );
    } else if (activeSection.id === "section-3") {
      return (
        <ProtocolTabLayout
          toast={toast}
          prescriptionid={props.prescriptionid}
          prescriptionValue={prescriptionValue}
          prescriptiontoValue={prescriptiontoValue}
          editPrescriptionid={editPrescriptionid}
          prescriptiondate={dateOfTreatment}
        />
      );
    } else if (activeSection.id === "section-2") {
      return (
        <VedicTreatmentTabLayout
          toast={toast}
          prescriptionid={props.prescriptionid}
          prescriptionValue={prescriptionValue}
          prescriptiontoValue={prescriptiontoValue}
          editPrescriptionid={editPrescriptionid}
          prescriptiondate={dateOfTreatment}
        />
      );
    }
  };
  const onCancelClick = () => {
    formik.resetForm();
    setDateOfTreatment(null);
  };




  const handleSubmit = async (values: FormValues) => {
    try {
      if (formik.isValid) {
        await onSubmit(values);
      }
    } catch (error) { }
  };

  const onSubmit = (values: any) => {
    const treatmentDate = moment(values.prescriptiondate).format("YYYY-MM-DD") as any;
    const data = {
      description: values.description,
      prescriptiondate: treatmentDate,
      prescriptionname: values.prescriptionname,
      prescriptiontoid: props.userTreatmentid ? props.userTreatmentid : prescriptiontoValue,
      Prescriptionid: editPrescriptionid,
    };
    protocolServices.AddProtocol(data).then((res: any) => {
      try {

        if (res.data.p_response) {
          props.setPrescriptionid(res.data.p_id);
          toast.current?.show({ severity: "success", summary: "Success", detail: "Prescription added successfully", life: 3000 });
          setLoading(false);

        } else {
          toast.current?.show({ severity: "warn", summary: "Warning", detail: res.data.p_msg, life: 3000 });
          setDateOfTreatment(null);
          setLoading(false);
        }
        if (res.response.status === 400 && res.response.data.success === false) {
          toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: res.response.data.errorMessage,
            life: 3000,
          });
          setLoading(false);
        }
        if (res.response.status === 500 && res.response.data.success === false) {
          toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: res.response.data.errorMessage,
            life: 3000,
          });
          setLoading(false);
        }
        return res.data;
      } catch (error: any) { }
    });
  };

  const formik = useFormik({
    initialValues: {
      prescriptionname: "",
      description: "",
      prescriptiondate: "",
    },
    validationSchema: ProtocolValidationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    setIsEditDisabled(false)
    if (props.editPrescriptionto && props.editPrescriptionto.length > 0) {
      setPrescriptionValue(props.editPrescriptionto);
      setPrescriptiontoValue(props.editPrescriptionto[0].p_prescriptiontoid ? props.editPrescriptionto[0].p_prescriptiontoid : null);
      setEditPrescriptionid(props.editPrescriptionto[0].p_prescriptionid ? props.editPrescriptionto[0].p_prescriptionid : null);
      formik.setFieldValue("prescriptionname", props.editPrescriptionto[0].p_prescriptionname ? props.editPrescriptionto[0].p_prescriptionname : "");
      formik.setFieldValue("description", props.editPrescriptionto[0].p_description ? props.editPrescriptionto[0].p_description : "");
      const dateOfJoining = moment(props.editPrescriptionto[0].p_prescriptiondate ? props.editPrescriptionto[0].p_prescriptiondate : "").format(
        "MM/DD/YYYY"
      ) as any;
      setDateOfTreatment(new Date(dateOfJoining));
      formik.setFieldValue("prescriptiondate", new Date(dateOfJoining));
      if(new Date(dateOfJoining)<todayDate)
      {
        setIsEditDisabled(true)
      }
    }
  }, [props.editPrescriptionto]);

  return (
    <>
      <Toast ref={toast} />
      <div>
        <form onSubmit={formik.handleSubmit}>
          <div className="flex ">
            <div className="col-3">
              <label className="col-4">{resource.prescription}</label>
              <span className="text-red">*</span>
              <InputText
                id="prescriptionname"
                type="text"
                placeholder="Enter prescription name"
                className="w-full blank-input-text relative mt-2"
                value={formik.values.prescriptionname}
                onChange={formik.handleChange}
                disabled={props.prescriptionid || isEditDisabled}
              />
              {formik.errors.prescriptionname && formik.touched.prescriptionname && <div className="error-msg">{formik.errors.prescriptionname}</div>}
            </div>
            <div className="col-3">
              <label className="col-4"  title={formik.values.description}>{resource.description}</label>
              <InputText
                id="description"
                type="text"
                placeholder="Enter description"
                className="w-full blank-input-text mt-2"
                value={formik.values.description}
                onChange={formik.handleChange}
                disabled={props.prescriptionid || isEditDisabled}
                title={formik.values.description}
              />
          {formik.errors.description && formik.touched.description && <div className="error-msg">{formik.errors.description}</div>}

            </div>

            <div className="col-3">
              <label className="col-5">
                {resource.dateOfPrescription} <span className="text-red">*</span>{" "}
              </label>
              <div className="update-password-input relative mt-2">
                <Calendar
                  id="prescriptiondate"
                  placeholder="DD/MM/YYYY"
                  className="relative"
                  showIcon
                  disabled={props.editPrescription || props.prescriptionid}
                  minDate={minDate}
                  value={dateOfTreatment}
                  dateFormat="dd/mm/yy"
                  onChange={(e) => {
                    formik.setFieldValue("prescriptiondate", e.value);
                    setDateOfTreatment(e.value);
                  }}
                />
                {formik.errors.prescriptiondate && formik.touched.prescriptiondate && (
                  <div className="error-msg">{formik.errors.prescriptiondate}</div>
                )}
              </div>
            </div>
            <div className="col-5 flex cancel-submit-btn  align-items-center">
              <Button label="Submit" disabled={props.prescriptionid} className="submit-btn" type="submit" />
            </div>
          </div>
        </form>


        {(props.editPrescription || props.prescriptionid !== null) && (
          <div className="treatment-tab mt-4">
            <div className="flex p-4 align-items-center">
              <h4>{resource.typeOfTreatment}</h4>
              <div className="ml-3">
                <TabView activeIndex={activeIndex} scrollable onTabChange={(e) => setActiveIndex(e.index)}>
                  {sections?.map((tab: any) => {
                    return <TabPanel key={tab.attributeId} header={tab.label}></TabPanel>;
                  })}
                </TabView>
              </div>
            </div>
            <div className="scrollable-content-tab">{renderContent()}</div>
          </div>
        )}

      </div>
    </>
  );
};

export default AddProtocolLayout;