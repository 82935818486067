import * as Yup from "yup";

const AddPoiValidation = Yup.object().shape({
  poiname: Yup.string().required("Please enter POI name")
  .matches(/^[A-Za-z0-9\s()-.]+$/, 'Please enter POI name using letters and digits only.'),
  code: Yup.string().required("Please enter POI code"),
  capacity: Yup.string().required("Please enter master capacity"),
  areaid: Yup.string().required("Please select area"),
  subareaid: Yup.string().required("Please select subarea"),
  categoryid: Yup.string().required("Please select category"),
  latitude: Yup.string().required("Please enter latitude")
  .matches(
    /^-?\d+(\.\d+)?$/,
    "Please enter a valid latitude"
  ),
  longitude: Yup.string().required("Please enter longitude")
  .matches(
    /^-?\d+(\.\d+)?$/,
    "Please enter a valid longitude"
  ),
  description: Yup.string()
    .matches(/^[A-Za-z0-9\s,-.?!/()%&']+$/, 'Please enter description using letters and digits only.')
    .max(500, 'Description cannot be longer than 500 characters.'),
});

export default AddPoiValidation;
