import { useEffect, useState } from "react";
import DashboardServices from "../../services/DashboardServices";

const Dashboard: React.FC = () => {

  const [dashboardCounts, setDashboardCounts] = useState({
    customer_count: 0,
    visitor_count: 0,
    poi_count: 0,
    staying_count: 0,
    expert_count: 0,
  });


  const GetDashBoardCounts = () => {
    DashboardServices.GetDashboardCount()
      .then((res: any) => {
        if (res.status === 200) {
          setDashboardCounts(res.data);
        } 
      })
      .catch((error) => {
      });
  };

  const mapUrl = process.env.REACT_APP_MAP_URL;

  useEffect(() => {
    GetDashBoardCounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (
    <div className="container">
      <div className="flex dashboard-card">
        <div className="col-12 ">
          <div className="dashboard-card-wrapper">
            <div className="flex justify-content-between mb-2">
              <div className="border w-19rem h-7rem  bg-white flex align-items-center  flex-column  justify-content-center">
                <span className="counter">{dashboardCounts.customer_count}</span>
                <p>Total Customer Count</p>
              </div>

              <div className="border w-19rem h-7rem  bg-white flex align-items-center flex-column justify-content-center">
                <span className="counter">{dashboardCounts.visitor_count}</span>
                <p>Visitors Today</p>
              </div>
              <div className="border w-19rem h-7rem   bg-white flex align-items-center flex-column justify-content-center">
                <span className="counter">{dashboardCounts.poi_count}</span>
                <p>POI Count</p>
              </div>

              <div className="border w-19rem h-7rem  bg-white flex align-items-center  flex-column  justify-content-center">
                <span className="counter">{dashboardCounts.staying_count}</span>
                <p>Staying Count</p>
              </div>
              <div className="border w-19rem h-7rem  bg-white flex align-items-center flex-column justify-content-center">
                <span className="counter">{dashboardCounts.expert_count}</span>
                <p>Experts Count</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex dashboard-card map-section">
        <div className="col-7">
          <div className="bg-white w-12 border text-center p-3  mb-4 ">
            <h3 className="mb-3">Top area wise user count</h3>
            <div className="flex flex-row flex-wrap">
              <div className="area-card flex align-items-center h-8rem card-border-color w-15rem mr-3 mt-4">
                <div className="col-4 h-full">
                  <i className="pi pi-map-marker mt-5" style={{ fontSize: "2rem", color: "slateblue" }}></i>
                </div>
                <div className="col-8 flex flex-column  text-left">
                  <span className="area-count">0</span>
                  <p>Shopping Market and Restaurant</p>
                </div>
              </div>
              <div className="area-card flex align-items-center h-8rem card-border-color w-15rem mr-3 mt-4">
                <div className="col-4 h-full">
                  <i className="pi pi-map-marker mt-5" style={{ fontSize: "2rem", color: "slateblue" }}></i>
                </div>
                <div className="col-8 flex flex-column  text-left">
                  <span className="area-count">0</span>
                  <p>Ram Path</p>
                </div>
              </div>
              <div className="area-card flex align-items-center h-8rem card-border-color w-15rem mr-3 mt-4">
                <div className="col-4 h-full">
                  <i className="pi pi-map-marker mt-5" style={{ fontSize: "2rem", color: "slateblue" }}></i>
                </div>
                <div className="col-8 flex flex-column  text-left">
                  <span className="area-count">0</span>
                  <p>Medication Caves</p>
                </div>
              </div>
              <div className="area-card flex align-items-center h-8rem card-border-color w-15rem mr-3 mt-4">
                <div className="col-4 h-full">
                  <i className="pi pi-map-marker mt-5" style={{ fontSize: "2rem", color: "slateblue" }}></i>
                </div>
                <div className="col-8 flex flex-column  text-left">
                  <span className="area-count">0</span>
                  <p>Goshala</p>
                </div>
              </div>
              <div className="area-card flex align-items-center h-8rem card-border-color w-15rem mr-3 mt-4">
                <div className="col-4 h-full">
                  <i className="pi pi-map-marker mt-5" style={{ fontSize: "2rem", color: "slateblue" }}></i>
                </div>
                <div className="col-8 flex flex-column  text-left">
                  <span className="area-count">0</span>
                  <p>Museum</p>
                </div>
              </div>
              <div className="area-card flex align-items-center h-8rem card-border-color w-15rem mr-3 mt-4">
                <div className="col-4 h-full">
                  <i className="pi pi-map-marker mt-5" style={{ fontSize: "2rem", color: "slateblue" }}></i>
                </div>
                <div className="col-8 flex flex-column  text-left">
                  <span className="area-count">0</span>
                  <p>Garden</p>
                </div>
              </div>
              <div className="area-card flex align-items-center h-8rem card-border-color w-15rem mr-3 mt-4">
                <div className="col-4 h-full">
                  <i className="pi pi-map-marker mt-5" style={{ fontSize: "2rem", color: "slateblue" }}></i>
                </div>
                <div className="col-8 flex flex-column  text-left">
                  <span className="area-count">0</span>
                  <p>Vedic Health Gardens</p>
                </div>
              </div>
              <div className="area-card flex align-items-center h-8rem card-border-color w-15rem mr-3 mt-4">
                <div className="col-4 h-full">
                  <i className="pi pi-map-marker mt-5" style={{ fontSize: "2rem", color: "slateblue" }}></i>
                </div>
                <div className="col-8 flex flex-column  text-left">
                  <span className="area-count">0</span>
                  <p>Rig Veda</p>
                </div>
              </div>
            </div>
          </div>


        </div>
        <div className="col-5">
          <iframe
            id="map-viewer-iframe"
            className="map"
            title="vedBhoomi"
            src={mapUrl} ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
