import axios from "axios";
import authHeader from "./authHeader";


const GetPOIData = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_CMS_ENDPOINT  + `api/POI/GetPOI`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};


const UpdateActivity = async (data: any) => {
  try {
    const response = await axios.put(process.env.REACT_APP_BASE_CMS_ENDPOINT + `api/Activity/UpdateActivity`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const GetActivity = async (data:any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_CMS_ENDPOINT + `api/Activity/GetActivity`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};


const AddActivity = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_CMS_ENDPOINT + `api/Activity/RegisterActivity`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },

    });
    return response;
  } catch (error) {
    return error;
  }
};

const DeleteActivity = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_CMS_ENDPOINT + `api/Activity/DeleteActivity`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },

    });
    return response;
  } catch (error) {
    return error;
  }
};

const activityServices = {
  
  GetPOIData,  
  AddActivity,
  GetActivity,
  UpdateActivity,
  DeleteActivity

};

export default activityServices;
