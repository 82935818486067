import axios from "axios";
import authHeader from "./authHeader";

const AddNewSubArea = async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_CMS_ENDPOINT}api/SubArea/RegisterSubArea`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },

    });
    return response;
  } catch (error) {
    return error;
  }
};
const UpdateSubArea = async (data: any) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_BASE_CMS_ENDPOINT}api/SubArea/UpdateSubArea`, data, {
        headers: {
          "Content-Type": "application/json",
          ...authHeader(),
        },
      });
      return response;
    } catch (error) {
      return error;
    }
  };
  const GetSubArea = async (data: any) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_CMS_ENDPOINT}api/SubArea/GeSubArea`, data,{
        headers: {
          "Content-Type": "application/json",
          ...authHeader(),
        },
      });
      return response;
    } catch (error) {
      return error;
    }
  };

  const DeleteSubArea = async (data: any) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_CMS_ENDPOINT}api/SubArea/DeleteSubArea`, data, {
        headers: {
          "Content-Type": "application/json",
          ...authHeader(),
        },
      });
      return response;
    } catch (error) {
      return error;
    }
  };

  const GetAllUsers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_API_ENDPOINT}api/User/manager`, {
        headers: {
          "Content-Type": "application/json",
          ...authHeader(),
        },
      });
      return response;
    } catch (error) {
      return error;
    }
  };

const AddNewSubAreaService = {
    AddNewSubArea,
    UpdateSubArea,
    GetSubArea,
    DeleteSubArea,
    GetAllUsers
};

export default AddNewSubAreaService;
