import { useContext, useEffect, useState,useRef } from "react";
import { contextStore } from "../../context/CustomStateProvider";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import AddEmployeeService from "../../services/addEmployee";
import { Dialog } from "primereact/dialog";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { useFormik } from "formik";
import moment from "moment";
import EmployeeService from "../../services/EmlpoyeeServices";
import AddEmployeeValidationSchema from "../../validation/AddEmployeeValidation";
import AddRoleMenue from "../../services/roleServices";
import { InputSwitch } from "primereact/inputswitch";
import { confirmDialog } from "primereact/confirmdialog";

interface RoleId {
  p_roleid: any;
  p_rolename: string;
  p_code: any;
}

interface FormValue {
  employeeId: any;
  firstName: string;
  lastName: string;
  emailId: string;
  middleName: string;
  mobileNumber: string;
  companyAssignedNo: string;
  dateOfJoin: any;
  dateOfRelease: any;
  role: any;
  IsActive: boolean;
}

const AddEmployeePopup = (props: any) => {
  const context = useContext(contextStore);
  const resource = context.state.strings;
  const [submitting] = useState(false);
  const [edituserData, setEditUserData] = useState<any>(null);
  const [selectedRole, setSelectedRole] = useState<RoleId | null>(null);
  const [dateOfJoin, setDateOfJoin] = useState<any>();
  const [dateofRelease, setDateofRelease] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [RoleMenu, setRoleMenu] = useState([]);
  const maxDate = new Date(Date.now());


  const handleSubmit = async (values: FormValue) => {
    try {
      if (formik.isValid && !props.editMode) {
        setLoading(true);
        await AddEmployee(values);
      }

      if (props.editMode === true) {
        if (values.IsActive !== edituserData.p_IsActive && parseInt(edituserData.p_RoleId) === 97) {
          confirmDialog({
            message: `An area/sub area might be assigned to this employee. Are you sure you want to change status of this employee?`,
            header: "Employee update confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => onEmployeeUpdate(),
          });

          const onEmployeeUpdate = () => {
            setLoading(true);
            UpdateEmployee(values);
          };
        } else {
          setLoading(true);
          UpdateEmployee(values);
        }
      }
    } catch (error) {
      console.error("Failed to add employee:", error);
      setLoading(false);
    }
  };

  const GetRole = () => {
    AddRoleMenue.RoleMenus().then((res: any) => {
      setRoleMenu(res.data);
      if (res?.response?.status === 401) {
        window.location.pathname = "/";
      }
    });
  };
  useEffect(() => {
    GetRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedRoleTemplate = (option: RoleId) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.p_rolename}</div>
        </div>
      );
    }

    return <span>{resource.selectarole}</span>;
  };

  const RoleOptionTemplate = (option: RoleId) => {
    return (
      <div className="flex align-items-center">
        <div>{option.p_rolename}</div>
      </div>
    );
  };

  const AddEmployee = async (values: FormValue) => {
    var data = {
      FirstName: values.firstName,
      LastName: values.lastName,
      EmailId: values.emailId,
      CompanyAssignedNo: values.companyAssignedNo,
      MiddleName: values.middleName,
      MobileNo: values.mobileNumber,
      DateOfJoining: values.dateOfJoin,
      DateOfRelease: values.dateOfRelease,
      RoleID: values.role,
    };
    AddEmployeeService.AddEmployee(data).then((res: any) => {
      try {
        if (res.status === 200) {
          props.toast.current?.show({ severity: "success", summary: "Success", detail: "Your Email id register successfully", life: 3000 });
          setLoading(false);
          props.setAllEmployeePopup(false);
          props.setEditMode(false);
          formik.resetForm();
        }
        if (res.response.status === 400) {
          const errorMessage = res.response.data.errorMessage;
          props.toast.current?.show({ severity: "warn", summary: "Warning", detail: errorMessage, life: 3000 });
          setLoading(false);
        }
        return res.data;
        // Redirect the user to the protected route
      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          const errorMessage = error.response.data.errorMessage;
          props.toast.current?.show({ severity: "warn", summary: "Warning", detail: errorMessage, life: 3000 });
          setLoading(false);
        }
      }
    });
  };

  const initialValues = {
    employeeId: "",
    firstName: "",
    lastName: "",
    emailId: "",
    middleName: "",
    mobileNumber: "",
    companyAssignedNo: "",
    dateOfJoin: null,
    dateOfRelease: null,
    role: "",
    IsActive: true,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: AddEmployeeValidationSchema,
    onSubmit: handleSubmit,
  });

  const onCancelClick = () => {
    formik.resetForm();
    props.setAllEmployeePopup(false);
    props.setEditMode(false);
    setDateOfJoin(null);
    setDateofRelease(null);
    setSelectedRole(null);
  };
  useEffect(() => {
    setEditUserData(props.editUserData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editUserData]);

  const UpdateEmployee = (values: FormValue) => {
    var employeedata = {
      EmployeeId: values.employeeId,
      FirstName: values.firstName,
      LastName: values.lastName,
      MiddleName: values.middleName,
      MobileNo: values.mobileNumber,
      CompanyAssignedNo: values.companyAssignedNo,
      DateOfJoining: values.dateOfJoin,
      DateOfRelease: values.dateOfRelease,
      RoleId: values.role,
      IsActive: values.IsActive,
    };
    EmployeeService.UpdateEmployeeData(employeedata).then((res: any) => {
      try {
        if (res.status === 200) {
          setLoading(false);
          props.toast.current?.show({ severity: "success", summary: "Success", detail: "Employee details updated successfully", life: 3000 });
          props.setAllEmployeePopup(false);
          props.setEditMode(false);
          formik.resetForm();
        }
        if (res.response.status === 400) {
          setLoading(false);
          const errorMessage = res.response.data.errorMessage;
          props.toast.current?.show({ severity: "warn", summary: "Warning", detail: errorMessage, life: 3000 });
        }
        return res.data;
        // Redirect the user to the protected route
      } catch (error: any) {
        setLoading(false);
        if (error.response && error.response.status === 400) {
          const errorMessage = error.response.data.errorMessage;
          props.toast.current?.show({ severity: "warn", summary: "Warning", detail: errorMessage, life: 3000 });
        }
      }
    });
  };

  const OnEditData = () => {
    if (edituserData) {
      formik.setValues({
        employeeId: edituserData.p_EmployeeId ? edituserData.p_EmployeeId : null,
        firstName: edituserData.p_FirstName,
        lastName: edituserData.p_LastName,
        emailId: edituserData.p_EmailId,
        middleName: edituserData.p_middlename,
        mobileNumber: edituserData.p_MobileNo,
        companyAssignedNo: edituserData.p_companyassignedno,
        dateOfJoin: edituserData.p_DateOfJoining,
        dateOfRelease: edituserData.P_DateOfRelease,
        role: edituserData.p_RoleId,
        IsActive: edituserData.p_IsActive,
      });

      formik.setFieldValue("role", edituserData.p_RoleId);
      if (edituserData.p_DateOfJoining) {
        const dateOfJoining = moment(edituserData.p_DateOfJoining).format("MM/DD/YYYY") as any;
        setDateOfJoin(new Date(dateOfJoining));
      }

      if (edituserData.p_DateOfRelease) {
        const dateOfRelase = moment(edituserData.p_DateOfRelease).format("MM/DD/YYYY") as any;
        setDateofRelease(new Date(dateOfRelase));
      }

      RoleMenu.forEach((role: any) => {
        if (parseInt(role.p_code) === parseInt(edituserData.p_RoleId)) {
          formik.setFieldValue("role", role.p_code);
          setSelectedRole(role);
        }
      });
    }
  };

  useEffect(() => {
    if (edituserData) {
      OnEditData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edituserData]);

  useEffect(() => {
    if (props.editMode) {
      GetRole();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editMode]);

  useEffect(() => {
    setDateOfJoin(null);
    setDateofRelease(null);
    setSelectedRole(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.addEmployeedPopup]);

  const header = (
    <div className="p-d-flex p-jc-between p-ai-center">
      <span>{resource.employeeDetailsCreateUpdate}</span>
      <Button
        icon="pi pi-times"
        className="p-button-rounded p-button-text popup-close-button"
        onClick={() => {
          props.setAllEmployeePopup(false);
          onCancelClick();
        }}
      />
    </div>
  );
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (props.addEmployeedPopup) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 0);
    }
  }, [props.addEmployeedPopup]);

  return (
    <div>
      <Dialog
        className="popup popup-card-layout"
        header={header}
        draggable={false}
        visible={props.addEmployeedPopup}
        style={{ width: "70vw" }}
        onHide={() => {
          props.setAllEmployeePopup(false);
          onCancelClick();
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="card-wrapper">
            <div className="flex mb-2">
              <div className="col-4 relative">
                <label htmlFor="firstName" className="block text-900 font-medium mb-2">
                  {resource.firstName} <span className="text-red">*</span>
                </label>
                <InputText
                  id="firstName"
                  type="text"
                  placeholder="Enter first name"
                  className="w-full mb-2"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  tabIndex={1}
                  ref={inputRef}
                />
                {formik.errors.firstName && formik.touched.firstName && <div className="text-red-500 text-xs">{formik.errors.firstName}</div>}
              </div>
              <div className="col-4 relative">
                <label htmlFor="middleName" className="block text-900 font-medium mb-2">
                  {resource.middleName}
                </label>
                <InputText
                  id="middleName"
                  type="text"
                  placeholder="Enter middle name"
                  className="w-full mb-2"
                  value={formik.values.middleName}
                  onChange={formik.handleChange}
                  tabIndex={2}
                />
                {formik.errors.middleName && formik.touched.middleName && <div className="text-red-500 text-xs">{formik.errors.middleName}</div>}
            </div>
              <div className="col-4 relative">
                <label htmlFor="lastName" className="block text-900 font-medium mb-2">
                  {resource.lastName} <span className="text-red">*</span>
                </label>
                <InputText
                  id="lastName"
                  type="text"
                  placeholder="Enter last name"
                  className="w-full mb-2"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  tabIndex={3}
                />
                {formik.errors.lastName && formik.touched.lastName && <div className="text-red-500 text-xs">{formik.errors.lastName}</div>}
              </div>
            </div>

            <div className="flex mb-2">
              <div className="col-4 relative">
                <label htmlFor="mobileNumber" className="block text-900 font-medium mb-2">
                  {resource.mobileNumber} <span className="text-red">*</span>
                </label>
                <InputText
                  id="mobileNumber"
                  type="text"
                  placeholder="Enter mobile no"
                  className="w-full mb-2"
                  value={formik.values.mobileNumber}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d{0,10}$/.test(value)) {
                      formik.handleChange(e);
                    }
                  }}
                  maxLength={10}
                  tabIndex={4}
                />
                {formik.errors.mobileNumber && formik.touched.mobileNumber && (
                  <div className="text-red-500 text-xs">{formik.errors.mobileNumber}</div>
                )}
              </div>
              <div className="col-4 relative">
                <label htmlFor="companyAssignedNo" className="block text-900 font-medium mb-2">
                  {resource.companyAssignedMobileNo}
                </label>
                <InputText
                  id="companyAssignedNo"
                  type="text"
                  placeholder="Enter assigned mobile no"
                  className="w-full mb-2"
                  value={formik.values.companyAssignedNo}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d{0,10}$/.test(value)) {
                      formik.handleChange(e);
                    }
                  }}
                  maxLength={10}
                  tabIndex={5}
                />
                {formik.errors.companyAssignedNo && formik.touched.companyAssignedNo && (
                  <div className="text-red-500 text-xs">{formik.errors.companyAssignedNo}</div>
                )}
              </div>
              <div className="col-4 relative">
                <label htmlFor="emailId" className="block text-900 font-medium mb-2">
                  {resource.emailId} <span className="text-red">*</span>
                </label>
                <InputText
                  id="emailId"
                  type="email"
                  placeholder="Enter email id"
                  className="w-full mb-2"
                  value={formik.values.emailId}
                  disabled={props.editMode}
                  onChange={formik.handleChange}
                  tabIndex={6}
                />
                {formik.errors.emailId && formik.touched.emailId && <div className="text-red-500 text-xs">{formik.errors.emailId}</div>}
              </div>
            </div>
          </div>
          <div className="card-wrapper">
            <div className="flex flex-wrap ">
              <div className="col-4 relative">
                <label htmlFor="dateOfJoin" className="block text-900 font-medium mb-2">
                  {resource.dateOfJoining} <span className="text-red">*</span>
                </label>
                <Calendar
                  id="dateOfJoin"
                  placeholder="DD/MM/YYYY"
                  className="w-full mb-2"
                  showIcon
                  value={dateOfJoin}
                  maxDate={maxDate}
                  onChange={(e) => {
                    if (e.value) {
                      const adjustedJoinDate = new Date(Date.UTC(e.value.getFullYear(), e.value.getMonth(), e.value.getDate()));
                      formik.setFieldValue("dateOfJoin", adjustedJoinDate);
                      setDateOfJoin(adjustedJoinDate);
                    } else {
                      formik.setFieldValue("dateOfJoin", null);
                      setDateOfJoin(null);
                    }
                    formik.setFieldValue("dateOfRelease", null);
                    setDateofRelease(null);
                  }}
                  dateFormat="dd/mm/yy"
                  tabIndex={7}
                />
                {formik.errors.dateOfJoin && formik.touched.dateOfJoin && <div className="text-red-500 text-xs">{formik.errors.dateOfJoin}</div>}
              </div>
              <div className="col-4 relative">
                <label htmlFor="dateofRelease" className="block text-900 font-medium mb-2">
                  {resource.dateOfRelease}
                </label>
                <Calendar
                  id="dateofRelease"
                  placeholder="DD/MM/YYYY"
                  className="w-full mb-2"
                  showIcon
                  minDate={dateOfJoin}
                  value={dateofRelease}
                  onChange={(e) => {
                    if (e.value) {
                      const adjustedReleaseDate = new Date(Date.UTC(e.value.getFullYear(), e.value.getMonth(), e.value.getDate()));
                      formik.setFieldValue("dateOfRelease", adjustedReleaseDate);
                      setDateofRelease(adjustedReleaseDate);
                    } else {
                      formik.setFieldValue("dateOfRelease", null);
                      setDateofRelease(null);
                    }
                  }}
                  dateFormat="dd/mm/yy"
                  tabIndex={8}
                />
              </div>

              <div className="col-4">
                <label className="block text-900 font-medium mb-2">{resource.status}</label>
                <div className="custom-inputswitch">
                  <InputSwitch
                    id="IsActive"
                    checked={formik.values.IsActive}
                    onChange={(e) => formik.setFieldValue("IsActive", e.target.value)}
                    tabIndex={9}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="col-4 relative">
                <label htmlFor="role" className="block text-900 font-medium mb-2">
                  {resource.role} <span className="text-red">*</span>
                </label>
                <Dropdown
                  value={selectedRole}
                  onChange={(e: DropdownChangeEvent) => {
                    formik.setFieldValue("role", e.value.p_code);
                    setSelectedRole(e.value);
                  }}
                  options={RoleMenu}
                  valueTemplate={selectedRoleTemplate}
                  itemTemplate={RoleOptionTemplate}
                  optionLabel="name"
                  placeholder="Select a role"
                  className="w-full mb-2"
                  tabIndex={10}
                  filter
                  filterBy="p_rolename"
                />
                {formik.errors.role && formik.touched.role && <div className="text-red-500 text-xs">{formik.errors.role}</div>}
              </div>
            </div>
          </div>

          <div className="cancel-submit-btn mt-8">
            <Button label="Cancel" className="cancel-btn " onClick={onCancelClick} type="reset" tabIndex={11} />
            <Button label="Submit" className="submit-btn ml-2" type="submit" disabled={submitting} loading={loading} tabIndex={12} />
          </div>
        </form>
      </Dialog>
    </div>
  );
};

export default AddEmployeePopup;
