import axios from "axios";
import authHeader from "./authHeader";

const GetVedicTherapyById = async () => {
  try {
    const response = await axios.get(process.env.REACT_APP_BASE_PROTOCOL_ENDPOINT + `api/Prescription/Therapy`, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const AddProtocol = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_PROTOCOL_ENDPOINT + `api/Prescription/AddPrescription`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
const GetProtocolTreatmentHistory = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_PROTOCOL_ENDPOINT + `api/Prescription/GetPrescription`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const SaveTherapy = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_PROTOCOL_ENDPOINT + `api/LineItem/SaveTherapy`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const GetTherapy = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_PROTOCOL_ENDPOINT + `api/LineItem/GetTherapyLineItem`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const GetPOIByTherapyId = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_PROTOCOL_ENDPOINT + `api/Prescription/TherapyPOI`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const DeleteTherapyLine = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_PROTOCOL_ENDPOINT + `api/LineItem/DeletePrescriptionTherapy`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
const GetPrescription = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_PROTOCOL_ENDPOINT + `api/Prescription/GetPrescription`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const vedicTreatmentServices = {
  GetVedicTherapyById,
  GetProtocolTreatmentHistory,
  GetPOIByTherapyId,
  AddProtocol,
  SaveTherapy,
  GetTherapy,
  DeleteTherapyLine,
  GetPrescription,
};

export default vedicTreatmentServices;
