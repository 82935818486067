import axios from "axios";
import authHeader from "./authHeader";

const GetAllCustomers = async (data: any) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_API_ENDPOINT}api/User/customer`,  {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const UpdateCustomerType = async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_API_ENDPOINT}api/User/UpdateCustomerType`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },

    });
    return response;
  } catch (error) {
    return error;
  }
};

const GetAllCustomerService = {
    GetAllCustomers,
    UpdateCustomerType
};


export default GetAllCustomerService;
