import { Dialog } from "primereact/dialog";
import { useContext, useEffect, useState, useRef } from "react";
import { contextStore } from "../../context/CustomStateProvider";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import AddNewTherapyValidation from "../../validation/AddNewTherapyValidation";
import DurationInput from "../common/DurationInput";
import therapyServices from "../../services/therapyServices";


interface AddTherapyValues {
  TherapyName: string;
  Description: string;
  name: string;
}




const AddTherapyPopup = (props: any) => {

  const context = useContext(contextStore);
  const resource = context.state.strings;
  const [edituserData, setEditUserData] = useState<any>(null);
  const [durationValue, setDurationValue] = useState("00:00:00");
  const[durationValidationError,SetDurationValidationError] = useState('Please enter duration');
  const [durationClear, setDurationClear] = useState(false);
  const[submitClick,SetSubmitClick]=useState(false);



  const OnEditData = () => {
    setDurationClear(false);
    if (edituserData) {
      setTimeout(() => {
        setDurationValue(edituserData.p_duration)
        formik.setValues({
          TherapyName: edituserData.p_therapyname ? edituserData.p_therapyname : null,
          Description: edituserData.p_description,
          IsActive: edituserData.p_isactive,
          Duration: edituserData.p_duration
        });
      }, 20);
    }
  };

  const handleSubmit = async (values: any) => {
    try 
    { 
      
        if (formik.isValid && !props.editMode) {
          await AddNewTherapy(values);
          SetSubmitClick(false);
        }
  
        if (props.editMode === true && formik.isValid ) {
  
          await UpdateTherapy(values);
          SetSubmitClick(false)
  
        }
      

    }
    catch (error) {
      console.error("Failed to add actiivty:", error);
    }
  };

  const AddNewTherapy = async (values: AddTherapyValues) => {
    var data = {
      therapyname: values.TherapyName,
      description: values.Description,
      duration: durationValue
    };

    therapyServices.AddTherapy(data).then((res: any) => {
      try {
        if (res.status === 200) {
          if (res.data.p_response) {
            props.toast.current?.show({ severity: "success", summary: "Success", detail: res.data.p_msg, life: 3000 });
            props.setReloadTherapyGrid(!props.reloadTherapyGrid);

          } else {
            props.toast.current?.show({ severity: "warn", summary: "Warning", detail: res.data.p_msg, life: 3000 });
          }

          props.setaddNewTherapyPopup(false);
        }
        else if (res.response.status === 400 &&  res.response.data.success===false)
        {
          const errorMessage = res.response.data.errorMessage;
          props.toast.current?.show({ severity: "warn", summary: "Warning", detail: errorMessage, life: 3000 });
          props.setLoading(false);
        }
        return res.data;
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: "Unauthorized",
            life: 3000,
          });
        }
      }
    });
  };
  const UpdateTherapy = async (values: any) => {
    var data = {
      therapyid: edituserData.p_therapyid,
      therapyname: values.TherapyName,
      description: values.Description,
      isactive: formik.values.IsActive,
      duration: durationValue
    };

    therapyServices.UpdateTherapy(data).then((res: any) => {
      try {
        if (res.status === 200) {
          if (res.data.p_response) {
            props.toast.current?.show({ severity: "success", summary: "Success", detail: res.data.p_msg, life: 3000 });
            props.setReloadTherapyGrid(!props.reloadTherapyGrid);
          } else {
            props.toast.current?.show({ severity: "warn", summary: "Warning", detail: res.data.p_msg, life: 3000 });
          }
          props.setaddNewTherapyPopup(false);
        }
        else if (res.response.status === 400 &&  res.response.data.success===false)
        {
          const errorMessage = res.response.data.errorMessage;
          props.toast.current?.show({ severity: "warn", summary: "Warning", detail: errorMessage, life: 3000 });
          props.setLoading(false);
        }
        return res.data;
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: "Unauthorized",
            life: 3000,
          });
        }
      }
    });
  };
  const initialValues = {
    TherapyName: "",
    Description: "",
    IsActive: true,
    Duration: durationValue,
  };
  const formik = useFormik({
    initialValues,
    validationSchema: AddNewTherapyValidation,
    onSubmit: handleSubmit,
  });

  const onCancelClick = () => {
    formik.resetForm();
    props.setaddNewTherapyPopup(false);
  };

  const header = (
    <div className="p-d-flex p-jc-between p-ai-center">
      <span>{resource.addNewTherapyHeader}</span>
      <Button
        icon="pi pi-times"
        className="p-button-rounded p-button-text popup-close-button"
        onClick={() => {
          props.setaddNewTherapyPopup(false);
          onCancelClick();
        }}
      />
    </div>
  );
   
 const setDurationMessage=()=>
 {
  setDurationClear(false);
  if(!durationClear){
    SetDurationValidationError(durationValidationError);
  }
  
 }

  useEffect(() => {
    setEditUserData(props.isEditTherapy);
  }, [props.isEditTherapy]);

  useEffect(() => {
    if (props.editMode) {
      setTimeout(() => {
        OnEditData();
      }, 20);
    }
  }, [edituserData]);
  
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (props.addNewTherapyPopup) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 0);
    }
  }, [props.addNewTherapyPopup]);

  return (
    <div>
      <Dialog
        className="popup popup-card-layout"
        header={header}
        draggable={false}
        visible={props.addNewTherapyPopup}
        style={{ width: "40vw" }}
        onHide={() => {
          props.addNewTherapyPopup(false);
          onCancelClick();
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="card-wrapper">
            <div className="flex mb-2">
              <div className="col-6 relative">
                <label htmlFor="TherapyName" className="block text-900 font-medium mb-2">
                  {resource.therapyName} <span className="text-red">*</span>
                </label>
                <InputText
                  id="TherapyName"
                  type="text"
                  placeholder="Enter therapy name"
                  className="w-full mb-2"
                  value={formik.values.TherapyName}
                  onChange={formik.handleChange}
                  tabIndex={1}
                  ref={inputRef}                />
                {formik.errors.TherapyName && formik.touched.TherapyName && <div className="text-red-500 text-xs">{formik.errors.TherapyName}</div>}
              </div>

              <div className="col-6 relative">
                <label htmlFor="middleName" className="block text-900 font-medium mb-2">
                  {resource.description}
                </label>
                <InputTextarea
                  id="Description"
                  placeholder="Enter description"
                  className="w-full mb-2"
                  rows={3}
                  value={formik.values.Description}
                  onChange={formik.handleChange}
                  tabIndex={2}
                />
              {formik.errors.Description && formik.touched.Description && <div className="text-area-red-bottom text-xs">{formik.errors.Description}</div>}
              </div>
            </div>
          </div>
          <div className="card-wrapper">
            <div className="flex flex-wrap  align-items-center">

              
              <div className="col-2">
                <label className="block text-900 font-medium mb-2">{resource.status}</label>
                <InputSwitch id="IsActive" checked={formik.values.IsActive} onChange={(e) => formik.setFieldValue("IsActive", e.target.value)} tabIndex={4} />
              </div>
            </div>
          </div>
          <div className="cancel-submit-btn mt-8">
            <Button label="Cancel" className="cancel-btn " onClick={onCancelClick} type="reset" tabIndex={5} />
            <Button label="Submit" className="submit-btn ml-2" type="submit" tabIndex={6} />
          </div>
        </form>
      </Dialog>
    </div>
  );
};

export default AddTherapyPopup;