import * as Yup from "yup";

const AddNewAreaValidation = Yup.object().shape({
    AreaName: Yup.string().required("Please enter area name")
    .max(50, 'Area name cannot be longer than 50 characters')
    .matches(/^[A-Za-z0-9\s()-.]+$/, 'Please enter an area name using letters and digits only.'),
    Description: Yup.string()
    .matches(/^[A-Za-z0-9\s,-.?!/()%&']+$/, 'Please enter description using letters and digits only.')
    .max(500, 'Description cannot be longer than 500 characters.'),
    AreaManager: Yup.array().min(1, 'Please select at least one manager').required('Manager is required')
});

export default AddNewAreaValidation;
