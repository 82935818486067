import * as Yup from "yup";

const AddNewSubAreaValidation = Yup.object().shape({
    SubAreaName: Yup.string().required("Please enter subarea name")
    .max(50, 'Subarea name cannot be longer than 50 characters')
    .matches(/^[A-Za-z0-9\s()-.]+$/, 'Please enter subarea name using letters and digits only.'),
    Description: Yup.string()
    .matches(/^[A-Za-z0-9\s,-.?!/()%&']+$/, 'Please enter description using letters and digits only.')
    .max(500, 'Description cannot be longer than 500 characters.'),
    name: Yup.string().required("Please select area")
});
export default AddNewSubAreaValidation;
