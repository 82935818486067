import axios from "axios";
import authHeader from "./authHeader";


const GetSitumSetting = async () =>
{
    try {
      const response = await axios.get(process.env.REACT_APP_BASE_CMS_ENDPOINT + `api/MapSync/GetSitumSetting`, {
        headers: {
          "Content-Type": "application/json",
          ...authHeader(),
        },
      });
      return response;
    } catch (error) {
      return error;
    }
};

const GetPOIDataForSync = async () =>
    {
        try {
          const response = await axios.get(process.env.REACT_APP_BASE_CMS_ENDPOINT + `api/MapSync/POI`, {
            headers: {
              "Content-Type": "application/json",
              ...authHeader(),
            },
          });
          return response;
        } catch (error) {
          return error;
        }
    };

    const UpdateSitumSetting = async (data: any) => { 
      try {
        const response = await axios.post(process.env.REACT_APP_BASE_CMS_ENDPOINT + `api/MapSync/UpdateSitumSetting`, data, {
          headers: {
            "Content-Type": "application/json",
            ...authHeader(),
          },
    
        });
        return response;
      } catch (error) {
        return error;
      }
    }; 
    
    const PublishPoi = async (data: any) => { 
      try {
        const response = await axios.post(process.env.REACT_APP_BASE_CMS_ENDPOINT + `api/MapSync/Publish`, data, {
          headers: {
            "Content-Type": "application/json",
            ...authHeader(),
          },
    
        });
        return response;
      } catch (error) {
        return error;
      }
    }; 



const SitumServices = {
    GetPOIDataForSync,
    GetSitumSetting,
    UpdateSitumSetting,
    PublishPoi
};

export default SitumServices