import { Dialog } from "primereact/dialog";
import { useContext, useEffect, useState,useRef } from "react";
import { contextStore } from "../../context/CustomStateProvider";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import AddNewAreaService from "../../services/addNewArea";
import AddNewSubAreaService from "../../services/addSubArea";

import AddNewSubAreaValidation from "../../validation/AddNewSubAreaValidation";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { confirmDialog } from "primereact/confirmdialog";
import { MultiSelect } from "primereact/multiselect";

interface AddSubAreaValues {
  SubAreaName: string;
  Description: string;
  name: string;
  AreaManager: string[];

}
const AddSubAreaPopup = (props: any) => {
  const context = useContext(contextStore);
  const resource = context.state.strings;

  const [selectedArea, setSelectedArea] = useState([]);
  const [selectedAreaName, setSelectedAreaName] = useState([]);
  const [edituserData, setEditUserData] = useState<any>(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [selectedSubAreaManger, setSelectedSubAreaManger] = useState<any[]>([]);


  const inputRef = useRef<HTMLInputElement | null>(null);


  useEffect(() => {
    GetAllArea();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(props.editMode)
    {
    setEditUserData(props.isEditArea);
    GetSubAreaById(props.isEditArea.p_subareaid);
    }
  }, [props.isEditArea]);

  
  const GetAllArea = () => {
    var data = {
      Id: null,
    };
     
    AddNewAreaService.GetAllActiveArea(data).then((res: any) => {
      try {
        if (res.status === 200) {
          setSelectedArea(res.data);
          if (props.editMode) {
            res.data.forEach((area: any) => {
              if (area.p_areaid === props.isEditArea.p_areaid) {
                formik.setFieldValue("name", area.p_areaid);
                setSelectedAreaName(area);
              }
            });
          }
        }
        return res.data;
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: "Unauthorized",
            life: 3000,
          });
        }
      }
    });
  };

  const OnEditData = () => {
    if (edituserData) {
      setTimeout(() => {
        formik.setValues({
          SubAreaName: edituserData.p_subareaname ? edituserData.p_subareaname : null,
          name: edituserData.p_areaid,
          Description: edituserData.p_description,
          IsActive: edituserData.p_isactive,
          AreaManager: edituserData.managers || [],
        });
      }, 20);
    }
  };
  const GetAllUsers = () => {
    AddNewAreaService.GetAllUsers()
      .then((res: any) => {
        if (res.status === 200) {
          setSelectedSubAreaManger(res.data);
        } else {
          props.toast.current?.show({ severity: "warn", summary: "Warning", detail: "Failed to get area", life: 3000 });
        }
      })
      .catch((error) => {
        console.error("AddNewAreaService error:", error);
        props.toast.current?.show({ severity: "error", summary: "Error", detail: "Something went wrong", life: 3000 });
      });
  };
  useEffect(() => {
    GetAllUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (props.editMode) {
      setTimeout(() => {
        OnEditData();
      }, 20);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edituserData]);

  const handleSubmit = async (values: any) => {
    try {
      if (formik.isValid && !props.editMode) {
        await AddNewSubArea(values);
      }

      if (props.editMode === true) {
        if (parseInt(edituserData.p_areaid) !== parseInt(values.name)) {
          confirmDialog({
            message: `Change in area might affect in POI. Are you sure you want to update subarea?`,
            header: "POI update confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => onAreaUpdate(),
          });
          const onAreaUpdate = () => {
            UpdateSubArea(values);
          };
        } else {
          await UpdateSubArea(values);
        }
      }
    } catch (error) {
    }
  };

  const AddNewSubArea = async (values: AddSubAreaValues) => {
    setSubmitLoading(true);
    var data = {
      subareaname: values.SubAreaName,
      areaid: values.name,
      description: values.Description,
      userid: values.AreaManager.map((manager: any) => manager.p_userid)
    };

    AddNewSubAreaService.AddNewSubArea(data).then((res: any) => {
      setSubmitLoading(false);
      try {
        if (res.status === 200) {
          if (res.data.response) {
            props.toast.current?.show({ severity: "success", summary: "Success", detail: res.data.msg, life: 3000 });
            props.setaddNewSubAreaPopup(false);
          }
          else {
            props.toast.current?.show({ severity: "warn", summary: "Warning", detail: res.data.msg, life: 3000 });
          }         
        }
        else if (res.response.status === 400 && res.response.data.success === false)
           {
            props.toast.current?.show({
              severity: "warn",
              summary: "Warning",
              detail: res.response.data.errorMessage,
              life: 3000,
            });
          }
        return res.data;
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: "Unauthorized",
            life: 3000,
          });
        }
      }
    });
  };
  const UpdateSubArea = async (values: any) => {
    setSubmitLoading(true);
    var data = {
      subareaid: edituserData.p_subareaid,
      subareaname: values.SubAreaName,
      description: values.Description,
      areaid: values.name,
      isactive: formik.values.IsActive,
      userid: values.AreaManager.map((manager: any) => manager.p_userid),
    };

    AddNewSubAreaService.UpdateSubArea(data).then((res: any) => {
      setSubmitLoading(false);
      try {
        if (res.status === 200) {
          if (res.data.response) {
            props.toast.current?.show({ severity: "success", summary: "Success", detail: res.data.msg, life: 3000 });
            props.setaddNewSubAreaPopup(false);
          } else {
            props.toast.current?.show({ severity: "warn", summary: "Warning", detail: res.data.msg, life: 3000 });
          }         
        }
        else if (res.response.status === 400 && res.response.data.success === false)
        {
         props.toast.current?.show({
           severity: "warn",
           summary: "Warning",
           detail: res.response.data.errorMessage,
           life: 3000,
         });
       }
        return res.data;
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: "Unauthorized",
            life: 3000,
          });
        }
      }
    });
  };

  const GetSubAreaById = (values: any) => {
    const data = {
      Id: values,
    };

    AddNewSubAreaService.GetSubArea(data)
      .then((res: any) => {
        if (res.status === 200) {
          setTimeout(() => {
            formik.setValues({
              SubAreaName: res.data[0].p_subareaname,
              Description: res.data[0].p_description,
              name:res.data[0].p_areaid,
              AreaManager: res.data[0].managers.map((manager: any) => ({ p_userid: manager.p_userid })),
              IsActive: res.data[0].p_isactive
            });
          }, 20);
        } else {
          props.toast.current?.show({ severity: "warn", summary: "Warning", detail: "Failed to get area", life: 3000 });
        }
      })
      .catch((error) => {
        console.error("AddNewAreaService error:", error);
        props.toast.current?.show({ severity: "error", summary: "Error", detail: "Something went wrong", life: 3000 });
      });
  };

  const initialValues = {
    SubAreaName: "",
    Description: "",
    AreaManager: [],
    name: "",
    IsActive: true,
  };
  const formik = useFormik({
    initialValues,
    validationSchema: AddNewSubAreaValidation,
    onSubmit: handleSubmit,
  });

  const onCancelClick = () => {
    formik.resetForm();
    props.setaddNewSubAreaPopup(false);
  };

  const header = (
    <div className="p-d-flex p-jc-between p-ai-center">
      <span>{resource.addNewSubAreaHeader}</span>
      <Button
        icon="pi pi-times"
        className="p-button-rounded p-button-text popup-close-button"
        onClick={() => {
          props.setaddNewSubAreaPopup(false);
          onCancelClick();
        }}
      />
    </div>
  );

  return (
    <div>
      <Dialog
        className="popup popup-card-layout"
        header={header}
        draggable={false}
        visible={props.addNewSubAreaPopup}
        style={{ width: "40vw" }}
        onHide={() => {
          props.setaddNewSubAreaPopup(false);
          onCancelClick();
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="card-wrapper">
            <div className="flex mb-2">
              <div className="col-6 relative">
                <label htmlFor="AreaName" className="block text-900 font-medium mb-2">
                  {resource.addsubareaname} <span className="text-red">*</span>
                </label>
                <InputText
                  id="SubAreaName"
                  type="text"
                  placeholder="Enter subarea name"
                  className="w-full mb-2"
                  value={formik.values.SubAreaName}
                  onChange={formik.handleChange}
                  autoFocus={true}
                  tabIndex={1}
                  />
                {formik.errors.SubAreaName && formik.touched.SubAreaName && <div className="text-red-500 text-xs">{formik.errors.SubAreaName}</div>}
              </div>

              <div className="col-6 relative">
                <label htmlFor="middleName" className="block text-900 font-medium mb-2">
                  {resource.description}
                </label>
                <InputTextarea
                  id="Description"
                  placeholder="Enter description"
                  className="w-full mb-2"
                  rows={3}
                  value={formik.values.Description}
                  onChange={formik.handleChange}
                  tabIndex={2}
                />
              {formik.errors.Description && formik.touched.Description && <div className="text-area-red-bottom text-xs">{formik.errors.Description}</div>}

              </div>
            </div>
          </div>
          <div className="card-wrapper">
            <div className="flex flex-wrap ">
              <div className="col-6">
                <label htmlFor="AreaManager" className="block text-900 font-medium mb-2">
                  {resource.assignManager} 
                </label>
                <MultiSelect
                  id="AreaManager"
                  value={formik.values.AreaManager.map((manager: any) => manager.p_userid)}
                  onChange={(e) => {
                    const selectedManagerIds = e.value;
                    const selectedManagers = selectedSubAreaManger?.filter((manager) => selectedManagerIds.includes(manager.p_userid));
                    formik.setFieldValue("AreaManager", selectedManagers);
                  }}
                  options={selectedSubAreaManger}
                  optionValue="p_userid"
                  optionLabel="p_username"
                  placeholder="Select manager"
                  className="w-full "
                  disabled={props.isAreaAdmin}
                  tabIndex={3}
                  filter
                  filterBy="p_username"
                />
              </div>
              <div className="col-6 relative">
                <label className="block text-900 font-medium mb-2">
                  {resource.area}
                  <span className="text-red">*</span>
                </label>

                <Dropdown
                  value={selectedAreaName}
                  onChange={(e: DropdownChangeEvent) => {
                    formik.setFieldValue("name", e.value.p_areaid);
                    setSelectedAreaName(e.value);
                  }}
                  options={selectedArea}
                  optionLabel="p_areaname"
                  placeholder="Select area"
                  className="w-full mb-2"
                  tabIndex={4}
                  filter
                  filterBy="p_areaname"
                />
                {formik.errors.name && formik.touched.name && <div className="text-red-500 text-xs">{formik.errors.name}</div>}
              </div>
            </div>
          </div>

          <div className="card-wrapper">
            <div className="flex flex-wrap ">
              <div className="col-6 relative">

              <label className="block text-900 font-medium mb-2">{resource.status}</label>
                <InputSwitch
                  id="IsActive"
                  checked={formik.values.IsActive}
                  onChange={(e) => formik.setFieldValue("IsActive", e.target.value)}
                  tabIndex={5}
                />

              </div>
              </div>
              </div>
          <div className="cancel-submit-btn mt-8">
            <Button label="Cancel" className="cancel-btn " onClick={onCancelClick} type="reset" tabIndex={6} />
            <Button label="Submit" className="submit-btn ml-2" type="submit" tabIndex={7} loading={submitLoading} />
          </div>
        </form>
      </Dialog>
    </div>
  );
};

export default AddSubAreaPopup;
