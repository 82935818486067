import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

const CustomTime = (props: any) => {
  const [timeValue, setTimeValue] = useState('');
  const [periodValue, setPeriodValue] = useState('');
  const [finalTime, setFinalTime] = useState('');

   



  const periodOptions = [
    { label: ' ', value: '' },
    { label: 'AM', value: 'AM' },
    { label: 'PM', value: 'PM' }
  ];

  useEffect(() => {
 
      const newFinalTime = `${timeValue} ${periodValue}`;
      props.setCustomTimeChangeValue(newFinalTime);
    console.log('final time is in custom',newFinalTime)
  }, [timeValue, periodValue ])




    useEffect(() => {
      console.log('prop time:', props.editTimeValue);
    
      if (!props.editTimeValue) {
        setTimeValue('');
        setPeriodValue('');
        return;
      }
    
      const [time, period] = props.editTimeValue.split(' ');
    
      setTimeValue(time);
      setPeriodValue(period);
      }, [props.editTimeValue]);

      useEffect(()=>
      {
        props.setCustomTimeChangeValue('')
      },[])


  // Function to format time value
  const formatTime = (value: string) => {
    let formattedValue = value.replace(/[^0-9:]/g, '');
    if (formattedValue.length > 5) {
      formattedValue = formattedValue.slice(0, 5);
    }
    return formattedValue;
  };

  // Handle input change for time
  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFinalTime('');
    const value = e.target.value;
    const formattedValue = formatTime(value);
    setTimeValue(formattedValue);
  };



  // Handle change for period selection
  const handlePeriodChange = (e: { value: string }) => {
    setFinalTime('')
    setPeriodValue(e.value);
    console.log('peiod in custommmmm chnggg')
    //handleBlur();
  };


  return (
    <div>
      <div className="flex">
        <InputText
          value={timeValue}
          className="small-input"
          type="text"
          placeholder="HH:MM"
          onChange={handleTimeChange}
          inputMode="numeric"
          maxLength={7}
          disabled={props.isDisabled}
        />
        <Dropdown
          className="small-dropdown"
          options={periodOptions}
          value={periodValue}
          onChange={handlePeriodChange}
          disabled={props.isDisabled}
        />
      </div>
    </div>
  );
};

export default CustomTime;
