import { useState, useContext, useEffect } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import { Button } from "primereact/button";
import { contextStore } from "../../context/CustomStateProvider";
import attributeServices from "../../services/attributeServices";
import { useFormik } from "formik";
import poiServices from "../../services/poiServices";
import { ProgressSpinner } from "primereact/progressspinner";

interface InitialValues {
  [key: string]: string;
}

const PhysiologyLayout = (props: any) => {
  const context = useContext(contextStore);
  const resource = context.state.strings;
  const [loading, setLoading] = useState<boolean>(false);
  const [attribute, setAttribute] = useState<any>(null);
  const [loadingSpinnerLayout, setLoadingSpinnerLayout] = useState<boolean>(false);
  const initialValues: InitialValues = attribute
    ? attribute.reduce((acc: any, current: any) => {
      acc[current.p_attributedetailid] = ""; // initialize each field with an empty string
      return acc;
    }, {})
    : {};
  const [state, setState] = useState<InitialValues>(initialValues);

  const AttributeDetails = () => {
    setLoadingSpinnerLayout(true);
    let data = {
      PoiId: props.poiId,
      AttributeId: props.attributeId,
    };
    attributeServices.GetAttributeDetails(data).then((res: any) => {
      try {
        if (res.status === 200) {
          setAttribute(res.data);
          const attributes = res.data.map((item: any) => ({
            ...item,
            p_values: item.p_values || "",
          }));

          setState((prevState) => ({
            ...prevState,
            ...attributes.reduce((acc: any, current: any) => {
              acc[current.p_attributedetailid] = current.p_values;
              return acc;
            }, {}),
          }));
          setTimeout(() => {
            setLoadingSpinnerLayout(false);
          }, 400);
        }
        return true;
        // Redirect the user to the protected route
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: "Unauthorized",
            life: 3000,
          });
          window.location.pathname = "/";
        }
      }
    });
  };
  const onSaveClick = () => {
    formik.handleSubmit();
  };

  const handleSubmit = (values: any) => {
    setLoading(true);
    const attributeValues: any[] = [];
    attribute?.map((items: any) => {
      attributeValues.push({
        AttributeDetailId: items.p_attributedetailid,
        AttributeValues: values[String(items.p_attributedetailid)] ? values[String(items.p_attributedetailid)] : "",
      });
    });

    const data = {
      PoiId: props.poiId,
      AttributeId: props.attributeId,
      AttributeValueDetails: attributeValues,
    };
    setTimeout(() => {
      const isValid = attributeValues.every((item: { AttributeValues: string }) => item.AttributeValues !== "");
      if (isValid && attributeValues !== null) {
        UpdateOverView(data);
      } else {
        setLoading(false);
        props.toast.current?.show({
          severity: "warn",
          summary: "Warning",
          detail: "Enter atleast one key",
          life: 3000,
        });
      }
    }, 400);
  };
  useEffect(() => {
    AttributeDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onPoiClick]);

  const formik = useFormik({
    initialValues: state,
    onSubmit: handleSubmit,
  });

  const onCancelClick = () => {
    formik.resetForm();
    setState(initialValues)

  }

  const UpdateOverView = (data: any) => {
    poiServices.UpdatePoiDetails(data).then((res: any) => {
      try {
        if (res.status === 200) {
          setLoading(false);
          props.toast.current?.show({ severity: "success", summary: "Success", detail: "keys updated successfully", life: 3000 });
        }
        return res.data;
        // Redirect the user to the protected route
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: "Unauthorized",
            life: 3000,
          });
          window.location.pathname = "/";
        }
      }
    });
  };

  return (
    <div className="tab-scrollable-content">
      {loadingSpinnerLayout ? (
        <ProgressSpinner style={{ width: "70px", height: "70px" }} strokeWidth="2" fill="var(--surface-ground)" animationDuration=".9s" />
      ) : (
        <>
          {attribute?.map((items: any, index: any) => (
            <div key={index} className=" mt-3">
              <div className="flex col-6">
                <label htmlFor={items.p_attributedetailid} className="p-d-block mt-2">
                  {items.p_attributedetailname}:
                </label>

                <InputTextarea
                  id={items.p_attributedetailid}
                  className="w-full ml-3 text-area"
                  value={state[String(items.p_attributedetailid)]} // convert to string
                  onChange={(e) => {
                    setState((prevstate) => ({ ...prevstate, [String(items.p_attributedetailid)]: e.target.value }));
                    formik.setFieldValue(String(items.p_attributedetailid), e.target.value);
                  }}
                />
              </div>
            </div>
          ))}
          <div className="cancel-submit-btn  w-full justify-content-end mt-6">
            <Button className="cancel-btn " label={resource.cancel} type="reset" onClick={onCancelClick} />
            <Button className="submit-btn ml-2 " label={resource.submit} type="submit" loading={loading} onClick={onSaveClick} />
          </div>
        </>
      )}
    </div>
  );
};

export default PhysiologyLayout;
