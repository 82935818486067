import axios from "axios";
import authHeader from "./authHeader";

const GetMedicineLineItem = async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_PROTOCOL_ENDPOINT}api/LineItem/GetMedicineLineItem`, data,{
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const AddMedicineLineItem = async (data: any) => { 
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_PROTOCOL_ENDPOINT + `api/LineItem/RegisterMedicineLineItem`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },

    });
    return response;
  } catch (error) {
    return error;
  }
};

const UpdateMedicineLineItem = async (data: any) => { 
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_PROTOCOL_ENDPOINT + `api/LineItem/UpdateMedicineLineItem`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },

    });
    return response;
  } catch (error) {
    return error;
  }
};

const DeleteMedicineLineItem = async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_PROTOCOL_ENDPOINT}api/LineItem/DeleteMedicineLineItem`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const MedicineServices = {
  GetMedicineLineItem,
  DeleteMedicineLineItem,
  AddMedicineLineItem,
  UpdateMedicineLineItem
    
  };
  
  export default MedicineServices;