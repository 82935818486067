import { useState, useContext, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { InputTextarea } from "primereact/inputtextarea";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import { Button } from "primereact/button";
import { contextStore } from "../../context/CustomStateProvider";
import attributeServices from "../../services/attributeServices";
import poiServices from "../../services/poiServices";
import fileUpload from "../../services/fileUpload";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { Image } from "primereact/image";
import { ProgressBar } from 'primereact/progressbar';

const OverViewLayout = (props: any) => {
  const [fileName, setFileName] = useState<any>();
  const context = useContext(contextStore);
  const resource = context.state.strings;
  const [loading, setLoading] = useState<boolean>(false);
  const [OverViewLayout, setOverViewAttribute] = useState<any>();
  const [imageFileName, setImageFileName] = useState<any>();
  const [discriptionValue, setDiscriptionValue] = useState<any>("");
  const [loadingSpinnerLayout, setLoadingSpinnerLayout] = useState<boolean>(true);
  const [progressForIcon, setProgressForIcon] = useState<number>(0); 
  const [progressForImage, setProgressForImage] = useState<number>(0); // To track progress




  const onLogoFileSelect = (event: any, attributeDetailId: any) => {
    const file: File = event.files[0];
    if (file) {
      const reader = new FileReader();
      if ((!file.type.startsWith("image/png")) && (!file.type.startsWith("image/jpeg")) && (!file.type.startsWith("image/jpg"))) {

        props.toast.current?.show({
          severity: "warn",
          summary: "Warning",
          detail: "Please select an image file only.",
          life: 3000,
        });
        return;
      } 
      // else if (file.size > 5000000) {
      //   props.toast.current?.show({
      //     severity: "warn",
      //     summary: "Warning",
      //     detail: "Selected file exceeds the 5MB limit.",
      //     life: 3000,
      //   });
      //   return;
      // }
      reader.onloadend = () => {
        setFileName(file.name);
        if (file !== null) {
          const data = {
            PoiId: props.poiId as number,
            AttributeId: props.attributeId as number,
            AttributeDetailId: attributeDetailId as number,
            File: file,
          };

          UploadIconFile(data);
        }
      };

      reader.readAsDataURL(file);
    } else {
      props.toast.current?.show({
        severity: "warn",
        summary: "Warning",
        detail: "Something went wrong.",
        life: 3000,
      });
      return;
    }
  };


  useEffect(() => {
    setTimeout(() => {
      setLoadingSpinnerLayout(false);
    }, 400)
  }, [])


  const onImageFileSelect = (event: any, attributeDetailId: any) => {
    const file: File = event.files[0];
    if (file) {
      const reader = new FileReader();
      if ((!file.type.startsWith("image/png")) && (!file.type.startsWith("image/jpeg")) && (!file.type.startsWith("image/jpg"))) {
        props.toast.current?.show({
          severity: "warn",
          summary: "Warning",
          detail: "Please select an image file only.",
          life: 3000,
        });
        return;
      } 
      // else if (file.size > 5000000) {
      //   props.toast.current?.show({
      //     severity: "warn",
      //     summary: "Warning",
      //     detail: "Selected file exceeds the 5MB limit.",
      //     life: 3000,
      //   });
      //   return;
      // }
      reader.onloadend = () => {
        setImageFileName(file.name);
        if (file !== null) {
          const data = {
            PoiId: props.poiId as number,
            AttributeId: props.attributeId as number,
            AttributeDetailId: attributeDetailId as number,
            File: file,
          };

          UploadImageFile(data);
        }
      };

      reader.readAsDataURL(file);
    } else {
      props.toast.current?.show({
        severity: "warn",
        summary: "Warning",
        detail: "Something went wrong.",
        life: 3000,
      });
      return;
    }
  };

  const onCancelClick = () => {
    setFileName("");
    setImageFileName("");
    setDiscriptionValue("");
  };

  const AttributeDetails = () => {

    let data = {
      PoiId: props.poiId as number,
      AttributeId: props.attributeId,
    };
    attributeServices.GetAttributeDetails(data).then((res: any) => {
      try {
        if (res.status === 200) {
          setOverViewAttribute(res.data);
          var discription=discriptionValue
          for (const item of res.data) {
            if (item.p_attributedetailid === 2) {
              if (discription==="")
              {
              discription=item.p_values;
              }
              break;
            }
          }
          setFileName("");
          setImageFileName("");
          setDiscriptionValue(discription);
        }
        return true;
        // Redirect the user to the protected route
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          setLoading(false);
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: "Unauthorized",
            life: 3000,
          });
          window.location.pathname = "/";
        }
      }
    });
  };
  useEffect(() => {
    AttributeDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onPoiClick]);

  const onSubmitClick = () => {
    setLoading(true);
    const attributeValues: any[] = [];
    OverViewLayout?.map((items: any) => {
      attributeValues.push({
        AttributeDetailId: items.p_attributedetailid,
        AttributeValues: items.p_attributedetailid === 1 ? "" : items.p_attributedetailid === 2 ? discriptionValue : "",
      });
    });

    const data = {
      PoiId: props.poiId,
      AttributeId: props.attributeId,
      AttributeValueDetails: attributeValues,
    };

    setTimeout(() => {
      const isValid = attributeValues.some((item: { AttributeValues: string }) => item.AttributeValues !== "");
      if (isValid) {
        UpdateOverView(data);
      } else {
        setLoading(false);
        props.toast.current?.show({
          severity: "warn",
          summary: "Warning",
          detail: "Enter description",
          life: 3000,
        });
      }
    }, 400);
  };

  const UpdateOverView = (data: any) => {
    poiServices.UpdatePoiDetails(data).then((res: any) => {
      try {
        if (res.status === 200) {
          AttributeDetails();
          setLoading(false);
          props.toast.current?.show({ severity: "success", summary: "Success", detail: "Description updated successfully", life: 3000 });
        }
        return res.data;
        // Redirect the user to the protected route
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          setLoading(false);
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: "Unauthorized",
            life: 3000,
          });
          window.location.pathname = "/";
        }
      }
    });
  };

  const UploadIconFile = (data: any) => {
    setProgressForIcon(0);

    // Call the IconUpload function and pass the onUploadProgress callback
    fileUpload.IconUpload(data, (progress: number) => {
        setProgressForIcon(progress); // Set progress using the number received
    })
    .then((res: any) => {
        try {
            if (res.status === 200) {
                props.toast.current?.show({ severity: "success", summary: "Success", detail: "Icon uploaded successfully", life: 3000 });
                AttributeDetails();
              }
              setProgressForIcon(100); 
              setTimeout(() => setProgressForIcon(0), 2000);
            return res.data;
        } catch (error: any) {
            if (error.response && error.response.status === 401) {
                props.toast.current?.show({
                    severity: "warn",
                    summary: "Warning",
                    detail: "Unauthorized",
                    life: 3000,
                });
            }
        }
    })
    .catch((error: any) => {
        console.error("Error uploading icon:", error);
    });
};


  const UploadImageFile = (data: any) => {
    fileUpload.ImageUpload(data, (progress: number) => {
      setProgressForImage(progress); 
  }).then((res: any) => {
      try {
        if (res.status === 200) {
          props.toast.current?.show({ severity: "success", summary: "Success", detail: "Image uploaded successfully", life: 3000 });
          AttributeDetails();
        }
        setProgressForImage(100); // Set to 100 when done
        setTimeout(() => setProgressForImage(0), 2000);
        return res.data;
        // Redirect the user to the protected route
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: "Unauthorized",
            life: 3000,
          });
          window.location.pathname = "/";
        }
      }
    });
  };

  const handleDiscriptionChange = (e: any) => {
    setDiscriptionValue(e.target.value);
  };

  const onDeleteIcon = (values: any) => {
    confirmDialog({
      message: `Are you sure you want to delete this icon?`,
      header: "Confirm delete media",
      icon: "pi pi-exclamation-triangle",
      accept: () => onDeleteFiles(values), // Pass a function to be executed on confirmation
    });
  };

  const onDeleteImage = (values: any) => {
    confirmDialog({
      message: `Are you sure you want to delete this image?`,
      header: "Confirm delete media",
      icon: "pi pi-exclamation-triangle",
      accept: () => onDeleteFiles(values), // Pass a function to be executed on confirmation
    });
  };

  const onDeleteFiles = (values: any) => {
    const data = {
      poimediaid: values,
    };
    poiServices.DeleteFile(data).then((res: any) => {
      try {
        setLoading(false);
        if (res.status === 200) {
          props.toast.current?.show({
            severity: "success",
            summary: "Success",
            detail: "Media deleted successfully",
            life: 3000,
          });
          AttributeDetails();
        }
        return res.data;
        // Redirect the user to the protected route
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: "Unauthorized",
            life: 3000,
          });
          window.location.pathname = "/";
        }
      }
    });
  };



  return (
    <div className="tab-scrollable-content">
      {loadingSpinnerLayout ? (
        <ProgressSpinner style={{ width: "70px", height: "70px" }} strokeWidth="2" fill="var(--surface-ground)" animationDuration=".9s" />
      ) : (
        <>
          {OverViewLayout?.map((items: any, index: any) => {
            return (
              <>
                {/* Render the form fields */}
        
          
                <div key={index}>
                  <div className="flex col-12">
                    <div className="flex w-full">


                      <label htmlFor={items.p_attributedetailid} className="mt-2 w-120 ">
                        {items.p_attributedetailname === "Description" ? <>  {items.p_attributedetailname}  <span className="text-red">*</span></> : <>  {items.p_attributedetailname}</>}
                      </label>

                      {items.p_attributedetailid === 1 && (
                        <>
                          <div className="flex flex-column ">

                            <div className="flex">


                              <InputText
                                id={items.p_attributedetailid}
                                type="Text"
                                className="fileinput m-0"
                                name="icon"
                                placeholder="Choose Icon"
                                value={fileName}
                              />
                              <FileUpload
                                mode="basic"
                                name={items.p_attributedetailname}
                                accept="image/*"
                                auto
                                chooseLabel="Select"
                                className=" flex justify-content-center  fileselectbtn"
                                onSelect={(e) => onLogoFileSelect(e, items.p_attributedetailid)}
                              />
                            

                            {progressForIcon > 0 && (
                          <div className="ml-3" style={{ width: "150px" }}>
                            <ProgressBar
                              value={progressForIcon}
                              showValue={true}
                              className="custom-progress-bar" 
                            />
                          </div>
                        )}

                      </div>
                            
                            
                            <small id="file-size" className="mt-1">File type JPG, PNG,JPEG</small>

                            {OverViewLayout.filter((item: any, index: 1) => item.p_attributedetailid === 1).map((items: any) => {
                              const images = JSON.parse(items.p_file);
                              return (
                                <div key={index} className=" flex image-layout ">
                                  {images.map((image: any, imageIndex: any) => (
                                    <div key={imageIndex} className="image-wrapper  ml-3">

                                      <>
                                        <Button
                                          label=""
                                          icon="pi pi-trash"
                                          className="icon-delete ml-2"
                                          onClick={() => onDeleteIcon(image.poimediaid)}
                                        />
                                        <Image className="image-style" src={image.mediaurl} alt="images" />
                                      </>
                                    </div>
                                  ))}
                                </div>
                              );
                            })}
                          </div>

                        </>
                      )}
                      {items.p_attributedetailid === 2 && (
                        <InputTextarea
                          id={items.p_attributedetailid}
                          className="w-full ml-3 text-area"
                          name="discription"
                          value={discriptionValue}
                          onChange={handleDiscriptionChange}
                        />
                      )}
                      {items.p_attributedetailid === 3 && (
                        <>
                          <div className="flex flex-column ">
                            <div className="flex">
                              <InputText
                                id={items.p_attributedetailid}
                                type="text"
                                className="fileinput ml-1 "
                                name="images"
                                placeholder="Choose Image"
                                value={imageFileName}
                              />
                              <FileUpload
                                mode="basic"
                                name={items.p_attributedetailname}
                                accept="image/*"
                                // maxFileSize={1000000}
                                auto
                                chooseLabel="Select"
                                className=" flex justify-content-center  fileselectbtn"
                                onSelect={(e) => onImageFileSelect(e, items.p_attributedetailid)}
                              />
                              {progressForImage > 0 && (
                              <div className="ml-3" style={{ width: "150px" }}>
                                <ProgressBar
                                  value={progressForImage}
                                  showValue={true}
                                  className="custom-progress-bar" 
                                />
                              </div>
                              )}
                            </div>


                            <small id="file-size" className="mt-1">File type JPG, PNG,JPEG</small>

                            {OverViewLayout.filter((item: any, index: 1) => item.p_attributedetailid === 3).map((items: any) => {
                              const images = JSON.parse(items.p_file);
                              return (
                                <div key={index} className=" flex image-layout">
                                  {images.map((image: any, imageIndex: any) => (
                                    <div key={imageIndex} className="image-wrapper ml-3">


                                      <>
                                        <Button
                                          label=""
                                          icon="pi pi-trash"
                                          className="icon-delete ml-2"
                                          onClick={() => onDeleteImage(image.poimediaid)}
                                        />
                                        <Image className="image-style" src={image.mediaurl} alt="images" />
                                      </>
                                    </div>
                                  ))}
                                </div>
                              );
                            })}
                          </div>
                        </>
                      )}

                    </div>
                  </div>
                </div>
              </>
            );
          })}
          <div className="cancel-submit-btn mb-8 w-full justify-content-end">
            <Button className="cancel-btn " label={resource.cancel} onClick={onCancelClick} type="reset" />
            <Button className="submit-btn ml-2 " label={resource.submit} type="submit" loading={loading} onClick={onSubmitClick} />
          </div>
        </>
      )}
    </div>
  );
};

export default OverViewLayout;
