import * as Yup from "yup";

const AddNewActivityValidation = Yup.object().shape({
    ActivityName: Yup.string().required("Please enter activity name")
    .max(50, 'Activity name cannot be longer than 50 characters')
    .matches(/^[A-Za-z0-9\s()-.]+$/, 'Activity name must only contain letters, numbers, and spaces.'),
    Description: Yup.string()
    .matches(/^[A-Za-z0-9\s,-.?!/()%&']+$/, 'Please enter description using letters and digits only.')
    .max(500, 'Description cannot be longer than 500 characters.'),

});
export default AddNewActivityValidation;