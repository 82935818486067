import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // Import useLocation and useNavigate hooks
import "../../assetes/css/common.css";
import { Link } from "react-router-dom";

interface MenuItem {
  p_Description: string;
  p_MenuId: any;
  p_MenuName: string;
  p_Url: any;
  p_MenuIcon: any;
}

const Sidebar: React.FC = () => {
  const location = useLocation();
  const currentURL = location.pathname;
  const navigate = useNavigate(); // Get the navigate function from useNavigate hook
  const [menuItemsList, setMenuItemList] = useState<MenuItem[]>([]);

  useEffect(() => {
    const sideBarMenu = localStorage.getItem("user");
    if (sideBarMenu !== null) {
      const userData = JSON.parse(sideBarMenu);
      const menuItemList = userData.menuDetailsResponses;
      setMenuItemList(menuItemList);
      console.log('menu List',menuItemList)
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const items = menuItemsList?.map((menuItem) => ({
    icon: menuItem.p_MenuIcon,
    label: menuItem.p_MenuName,
    url: menuItem.p_Url,
    command: () => {
      navigate(menuItem.p_Url); // Navigate to the menuItem.p_Url when the menu item is clicked
    },
  }));
  return (
    <div className="sidebar">
      <ul>
        {items?.map((menuItem, index) => (
          <li key={index} className={currentURL === menuItem.url ? "active" : ""}>
            <Link to={menuItem.url}  >
              <div className={`${menuItem.icon}`}> </div>
              <span>{menuItem.label}</span>
            </Link>
          </li>
        ))}
      </ul>
    </div>

  );
};

export default Sidebar;