import axios from "axios";
import authHeader from "./authHeader";

const GetDashboardCount = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_CMS_ENDPOINT}api/Dashboard/GetDashboardCount`, {
        headers: {
          "Content-Type": "application/json",
          ...authHeader(),
        },
      });
      return response;
    } catch (error) {
      return error;
    }
  };


  
const DashboardServices = {
    GetDashboardCount
  };
  
  export default DashboardServices;