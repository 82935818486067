import { useContext, useEffect, useRef, useState } from "react";
import HeaderWithBackButton from "../common/HeaderWIthBackButton";
import { contextStore } from "../../context/CustomStateProvider";
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";
import AddExpertPopup from "./AddExpertPopup";
import ExpertiesTable from "./ExpertiesTable";
import { ProgressSpinner } from "primereact/progressspinner";

const ExpertiesLayout = (props: any) => {
  const context = useContext(contextStore);
  const resource = context.state.strings;
  const [, setAddAreaChange] = useState(false);
  const [backButton, setBackButton] = useState(false);
  const [addNewExpertPopup, setAddNewExpertPopup] = useState(false);
  const [isEditExpert, setIsEditExpert] = useState();
  const [editMode, setEditMode] = useState(false);
  const toast = useRef<Toast>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [expertDatFilter, setExpertDatFilter] = useState([]);
  const [, setIsAreaAdmin] = useState(false);
  const AREA_ADMIN_ROLE_ID = 97;
  const [loading, setLoading] = useState<boolean>(false);
  const [isSubmitValue, setIsSubmitValue] = useState(false);
  const [loadingSpinnerLayout, setLoadingSpinnerLayout] = useState<boolean>(true);
  const [experDataValues, setExperDataValues] = useState<any[]>([]);
  const [reloadExpertGrid, setReloadExpertGrid] = useState(false);

  const cardControls = [
    {
      ctrlType: "button",
      name: "btnAdd",
      title: resource.addExpert,
      type: "button",
      disabled: false,
      className: "green-btn mr-2",
    },
  ];

  const onButtonClick = (e: string) => {
    if (e === "btnAdd") {
      setAddAreaChange(true);
      setAddNewExpertPopup(true);
      setEditMode(false);
    } else if (e === "btnAddSubArea") {
      setAddAreaChange(true);
      setEditMode(false);
    }
  };
  const onBackButtonClick = () => {
    setBackButton(!backButton);
  };

  const onEditExpertClicked = (data: any) => {
    setAddNewExpertPopup(true);
    setIsEditExpert(data);
    setEditMode(true);
  };

  useEffect(() => {
    const UserName = localStorage.getItem("user");
    if (UserName !== null) {
      const userData = JSON.parse(UserName);
      setIsAreaAdmin(parseInt(userData.p_roleid) === AREA_ADMIN_ROLE_ID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const filteredExpterData = expertDatFilter?.filter((expertDataFilter: any) => {
    const expertpanelname = expertDataFilter?.expertpanelname;
    const description = expertDataFilter?.description;

    if (typeof expertpanelname === "string" && typeof description === "string") {
      return expertpanelname.toLowerCase().includes(searchQuery.toLowerCase()) || description.toLowerCase().includes(searchQuery.toLowerCase());
    }

    return true; // or some other default behavior
  });

  useEffect(() => {
    setTimeout(() => {
      setLoadingSpinnerLayout(false);
    }, 400);
  }, []);

  return (
    <div className="ml-2">
      <Toast ref={toast} />
      {loadingSpinnerLayout ? (
        <ProgressSpinner style={{ width: "70px", height: "70px" }} strokeWidth="2" fill="var(--surface-ground)" animationDuration=".9s" />
      ) : (
        <>
          {addNewExpertPopup && (
            <AddExpertPopup
              isEditExpert={isEditExpert}
              addNewExpertPopup={addNewExpertPopup}
              setAddNewExpertPopup={setAddNewExpertPopup}
              editMode={editMode}
              setExperDataValues={setExperDataValues}
              experDataValues={experDataValues}
              toast={toast}
              setIsSubmitValue={setIsSubmitValue}
              setLoading={setLoading}
              loading={loading}
              setReloadExpertGrid={setReloadExpertGrid}
              reloadExpertGrid={reloadExpertGrid}
            />
          )}

          <HeaderWithBackButton
            title={resource.expertPanel}
            controls={cardControls}
            onButtonClicked={onButtonClick}
            searchQuery={searchQuery}
            handleSearch={handleSearch}
            onBackButtonClick={onBackButtonClick}
          />

          <div className="transparant-table">
            <div className="row flex">
              <div className="col-12">
                <ExpertiesTable
                  onEditExpertClicked={onEditExpertClicked}
                  filteredExpterData={filteredExpterData}
                  addNewExpertPopup={addNewExpertPopup}
                  setExpertDatFilter={setExpertDatFilter}
                  setLoading={setLoading}
                  setIsSubmitValue={setIsSubmitValue}
                  loading={loading}
                  experDataValues={experDataValues}
                  isSubmitValue={isSubmitValue}
                  reloadExpertGrid={reloadExpertGrid}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ExpertiesLayout;
