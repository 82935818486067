import axios from "axios";
import authHeader from "./authHeader";
const ForgetPasswordService = async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_API_ENDPOINT}api/User/forgotpassword`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const ResetPasswordService = async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_API_ENDPOINT}api/User/setpassword`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
const ChangePasswordService = async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_API_ENDPOINT}api/User/changepassword`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
const CreatePasswordService = async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_API_ENDPOINT}api/User/setregistrationpassword`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
const LinkExpiredService = async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_API_ENDPOINT}api/User/tokenvalidate`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
const LoginServices = {
  ForgetPasswordService,
  ResetPasswordService,
  ChangePasswordService,
  CreatePasswordService,
  LinkExpiredService
};

export default LoginServices;
