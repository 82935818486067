import axios from "axios";
import authHeader from "./authHeader";

const RoleMenus = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_API_ENDPOINT}api/User/roles`, {
        headers: {
          "Content-Type": "application/json",
          ...authHeader(),
        },
  
      });
      return response;
    } catch (error) {
      return error;
    }
  };

  const RoleMenusCheck = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_API_ENDPOINT}api/User/menus`, {
        headers: {
          "Content-Type": "application/json",
          ...authHeader(),
        },
  
      });
      return response;
    } catch (error) {
      return error;
    }
  };

  const UpdateRoleSetting = async (data:any) => {

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_API_ENDPOINT}api/User/updatemenu`, data, {
        headers: {
          "Content-Type": "application/json",
          ...authHeader(),
        },
  
      });
      return response;
    } catch (error) {
      return error;
    }
  };
  const GetAllMenusByRole = async (data:any) => {

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_API_ENDPOINT}api/User/rolemenu`, data, {
        headers: {
          "Content-Type": "application/json",
          ...authHeader(),
        },
  
      });
      return response;
    } catch (error) {
      return error;
    }
  };



  const AddRoleMenue = {
    RoleMenus,
    RoleMenusCheck,
    UpdateRoleSetting,
    GetAllMenusByRole

};

export default AddRoleMenue;