import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import { contextStore } from "../../context/CustomStateProvider";
import { useContext, useEffect, useState } from "react";
import attributeServices from "../../services/attributeServices";
import { useFormik } from "formik";
import poiServices from "../../services/poiServices";
import { ProgressSpinner } from "primereact/progressspinner";

interface InitialValues {
  [key: string]: string;
}

const CharacteristicsTab = (props: any) => {
  const context = useContext(contextStore);
  const resource = context.state.strings;
  const [loading, setLoading] = useState<boolean>(false);
  const [characteristiscAttribute, setCharacteristiscAttribute] = useState<any | null>(null);
  const [showSave, setShowSave] = useState(false);
  const [addCharacterities, setAddCharacterities] = useState<any>(null);
  const initialValues: InitialValues = characteristiscAttribute
    ? characteristiscAttribute.reduce((acc: any, current: any) => {
        acc[current.p_attributedetailid] = ""; // initialize each field with an empty string
        return acc;
      }, {})
    : {};
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const [state, setState] = useState<InitialValues>(initialValues);

  const AttributeDetails = () => {
    setLoadingSpinner(true);
    setAddCharacterities("");
    setShowSave(false);
    let data = {
      PoiId: props.poiId,
      AttributeId: props.attributeId,
    };
    attributeServices.GetAttributeDetails(data).then((res: any) => {
      try {
        if (res.status === 200) {
          setCharacteristiscAttribute(res.data);
          for (const item of res.data) {
            formik.setFieldValue(String(item.p_attributedetailid), item.p_values);
          }
          const attributes = res.data.map((item: any) => ({
            ...item,
            p_values: item.p_values || "",
          }));

          setState((prevState) => ({
            ...prevState,
            ...attributes.reduce((acc: any, current: any) => {
              acc[current.p_attributedetailid] = current.p_values;
              return acc;
            }, {}),
          }));
          setTimeout(() => {
            setLoadingSpinner(false);
          }, 400);
        }
        return true;
        // Redirect the user to the protected route
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: "Unauthorized",
            life: 3000,
          });
        }
      }
    });
  };
  const onCancelClick = () => {
    setState(initialValues);
    formik.resetForm();
  };

  useEffect(() => {
    AttributeDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onPoiClick]);

  const onSaveClick = () => {
    formik.handleSubmit();
  };

  const handleSubmit = (values: any) => {
    setLoading(true);
    const attributeValues: any[] = [];
    characteristiscAttribute?.map((items: any) => {
      attributeValues.push({
        AttributeDetailId: items.p_attributedetailid,
        AttributeValues: values[String(items.p_attributedetailid)] ? values[String(items.p_attributedetailid)] : "",
      });
    });
    const data = {
      PoiId: props.poiId,
      AttributeId: props.attributeId,
      AttributeValueDetails: attributeValues,
    };

    setTimeout(() => {
      const isValid = attributeValues.some((item: { AttributeValues: string }) => item.AttributeValues !== "");
      if (isValid && addCharacterities !== null) {
        setLoadingSpinner(true);
        UpdateOverView(data);
      } else {
        setLoading(false);
        setLoadingSpinner(false);
        props.toast.current?.show({
          severity: "warn",
          summary: "Warning",
          detail: "Enter atleast one characteristics",
          life: 3000,
        });
      }
    }, 600);
  };

  const UpdateOverView = (data: any) => {
    poiServices.UpdatePoiDetails(data).then((res: any) => {
      try {
        if (res.status === 200) {
          setLoading(false);
          setLoadingSpinner(false);
          props.toast.current?.show({ severity: "success", summary: "Success", detail: "Characteristics updated successfully", life: 3000 });
        }
        return res.data;
        // Redirect the user to the protected route
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: "Unauthorized",
            life: 3000,
          });
        }
      }
    });
  };
  const formik = useFormik({
    initialValues: state,
    onSubmit: handleSubmit,
  });

  const onAddCharacteristics = (e: any) => {
    setAddCharacterities(e.target.value);
  };

  const onClickAddCharacteristics = () => {
    setShowSave(true);
    setAddCharacterities("");
  };
  const onSaveCancelClick = () => {
    setShowSave(false);
    setAddCharacterities("");
  };

  const onSaveCharacteristics = () => {
    setLoadingSpinner(true);
    if (characteristiscAttribute?.some((item: any) => item.p_attributedetailname === addCharacterities)) {
      props.toast.current?.show({
        severity: "warn",
        summary: "Warning",
        detail: "Characteristic already exists",
        life: 3000,
      });
      setLoading(false);
      setLoadingSpinner(false);
      return;
    }

    let data = {
      PoiId: props.poiId,
      AttributeId: props.attributeId,
      AttributeDetailName: addCharacterities,
      Description: "",
      Inputtype: "Text",
    };
    attributeServices.AddAttributeDetails(data).then((res: any) => {
      try {
        if (res.status === 200) {
          props.toast.current?.show({ severity: "success", summary: "Success", detail: "Characteristic added successfully", life: 3000 });
          setTimeout(() => {
            AttributeDetails();
          }, 200);
        }
        return true;
        // Redirect the user to the protected route
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: "Unauthorized",
            life: 3000,
          });
        }
      }
    });
  };

  return (
    <div className="tab-scrollable-content">
      {loadingSpinner ? (
        <ProgressSpinner style={{ width: "70px", height: "70px" }} strokeWidth="2" fill="var(--surface-ground)" />
      ) : (
        <>
          <div className="p-field  p-2">
            <div className="">
              <label htmlFor="place" className="p-d-block">
                <h4> {resource.addCharacteristics}</h4>
              </label>
            </div>
            <div className="black-button mt-3  flex align-items-center">
              <InputText
                id="place"
                type="text"
                className="inputform  m-0"
                value={addCharacterities}
                placeholder="Enter characteristics"
                disabled={!showSave}
                onChange={(e) => onAddCharacteristics(e)}
              />
              <div className="ml-2">
                {!showSave ? (
                  <Button icon="pi pi-plus" rounded severity="secondary" aria-label="Add" onClick={onClickAddCharacteristics} />
                ) : (
                  <>
                    <Button
                      icon="pi pi-save"
                      rounded
                      severity="secondary"
                      aria-label="Save"
                      onClick={onSaveCharacteristics}
                      disabled={!addCharacterities}
                    />
                    <Button icon="pi pi-times" rounded severity="secondary" aria-label="Cancel" onClick={onSaveCancelClick} />
                  </>
                )}
              </div>
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="flex flex-wrap">
              {characteristiscAttribute?.map((items: any, index: any) => (
                <div key={index} className="col-4 mt-3">
                  <div className="">
                    <div className="col">
                      <label htmlFor={items.p_attributedetailid} className="p-d-block">
                        {items.p_attributedetailname}:
                      </label>
                    </div>
                    <InputText
                      id={items.p_attributedetailid}
                      type="text"
                      className="char-inputform m-2"
                      value={state[String(items.p_attributedetailid)]}
                      onChange={(e) => {
                        setState((prevstate) => ({ ...prevstate, [String(items.p_attributedetailid)]: e.target.value }));
                        formik.setFieldValue(String(items.p_attributedetailid), e.target.value);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </form>
          <div className="cancel-submit-btn w-full justify-content-end mt-6 mb-8">
            <Button className="cancel-btn " label={resource.cancel} onClick={onCancelClick} type="reset" />
            <Button className="submit-btn ml-2 " label={resource.submit} type="submit" loading={loading} onClick={onSaveClick} />
          </div>
        </>
      )}
    </div>
  );
};

export default CharacteristicsTab;
