import * as Yup from "yup";

const ExpertiesValidation = Yup.object().shape({
  Exptertise: Yup.string().required("Please enter exptertise")
  .max(50, 'Exptertise name cannot be longer than 50 characters')
  .matches(/^[A-Za-z0-9\s()-.]+$/, 'Please enter expertise using letter and digits only'),
  Description: Yup.string()
    .matches(/^[A-Za-z0-9\s,-.?!/()%&']+$/, 'Please enter description using letters and digits only.')
    .max(500, 'Description cannot be longer than 500 characters.')
  
});

export default ExpertiesValidation;
