import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import React, { useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Button } from "primereact/button";
import { contextStore } from "../../context/CustomStateProvider";
import { Calendar } from "primereact/calendar";
import HeaderWithBackButton from "../common/HeaderWIthBackButton";
import { Toast } from "primereact/toast";
import protocolServices from "../../services/protocolServices";
import ReportService from "../../services/reportServices";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";

const UserActivityLogReport = (props: any) => {

  const [userActivityLogList, setUserActivityLogList] = useState<UserActivityLogTable[]>([]);
  const [loading, setLoading] = useState(true);
  const todayDate = new Date();  
  const [date, setDate] = useState<Date>(todayDate);
  const context = useContext(contextStore);
  const resource = context.state.strings;
  const [searchQuery, setSearchQuery] = useState("");
  const toast = useRef<Toast>(null);




  interface UserActivityLogTable 
   {
    srNo: number;
    prescriptionName:string;
    activityType:string;
    activity:string;
    poiName: string;
    status:string;
    prescribedStartDate:string;
    prescribedEndDate:string;
    dose:string;
    frequency:string;
    does:string;
    donts:string;
    prescribedOn:string;
    prescribedBy:string;
    logDate:string;
    startTime:string;
    endTime:string;
    duration:string;
   }

   const formatDate = (value: string) => {
    const date = new Date(value);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleSearch = (event: any) => {
    setSearchQuery(event.target.value);
  };



  const filteredReportData = userActivityLogList?.filter((userActivityLog: UserActivityLogTable) => {
    return (
        userActivityLog.prescriptionName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        userActivityLog.activityType?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        userActivityLog.activity?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        userActivityLog.poiName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        userActivityLog.status?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        formatDate(userActivityLog.prescribedStartDate)?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        formatDate(userActivityLog.prescribedEndDate)?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        userActivityLog.dose?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        userActivityLog.frequency?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        userActivityLog.does?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        userActivityLog.donts?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        userActivityLog.prescribedOn?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        userActivityLog.prescribedBy?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        formatDate(userActivityLog.logDate)?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        userActivityLog.startTime?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        userActivityLog.endTime?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        userActivityLog.duration?.toLowerCase().includes(searchQuery.toLowerCase())
    );
});



  const GetUserActivityLogReport = () => {
    setLoading(true);
    let data =
    {
        userid :  props.userTreatmentid
    };
    setTimeout(() => {
        protocolServices.UserWiseReport(data).then((res: any) => {
        setUserActivityLogList(res.data);
        if(res.data.length>0)
        {
            props.setIsDowndoadDisabled(false);
        }
        setLoading(false);
      });
    }, 600);
  };



  const formatDateAndTime = (value: string) => {
    const date = new Date(value).toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    });
    return date;
};

  const SrNo = (rowData: any, index: any) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{index.rowIndex + 1}</span>
      </div>
    );
  };

  const GenerateReport=()=>
  {
    setLoading(true);
    const data = {
        userid: props.userTreatmentid
      };

    protocolServices
      .UserWiseReport(data)
      .then((res: any) => {
        setLoading(false);
        if (res.status === 200) {
              

          let transformedData = res.data.map((item: any) => {
           

            return {
              "Prescription name": item.prescriptionName,
              "Activity type": item.activityType,
              "Activity": item.activity,
              "POI name": item.poiName,
              "Status": item.status,
              "Prescribed start date": formatDate(item.prescribedStartDate),
              "Prescribed end date": formatDate(item.prescribedEndDate),
              "Dose": item.dose,
              "Frequency": item.frequency,
              "Do's": item.does,
              "Dont's": item.donts,
              "Prescribed on":formatDateAndTime(item.prescribedOnDate),
              "Prescribed by": item.prescribedBy,
              "Log date": formatDate(item.logDate),
              "Start time": item.startTime,
              "End time": item.endTime,
              "Duration": item.duration  
            };
          });

          
          const userName = `${props.treatmentData.p_FirstName||''}${props.treatmentData.p_LastName|| ''}`;
            const ReportData = {
              data:  transformedData,
              name: `UserActivityLog_${userName}.xlsx`
            };

            ReportService.ReportDownload(ReportData);
            toast.current?.show({ 
              severity: "success", 
              summary: "Success", 
              detail: "Report downloaded successfully.", 
              life: 3000 });

        }
        else if (res.response.status==500)
        {
          toast.current?.show({
               severity: "warn",
                summary: "Warning",
                detail: "No reports are available for download.",
                life: 3000,
            });
        }
        
      }).catch(() => {
        setLoading(false);
        props.toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      });
  }



useEffect(()=>{
    props.setIsDowndoadDisabled(true);
    GetUserActivityLogReport();
    }
  , []);





let TableData = (
  <div className="table-section">
    <DataTable value={filteredReportData} dataKey="id" scrollable scrollHeight="600px" loading={loading} emptyMessage="No details found." paginator rows={15}>
        <Column header="Sr.No." style={{ minWidth: "2rem" }} body={SrNo} />
        <Column field="prescriptionName" header="Prescription name" style={{ minWidth: "10rem" }} sortable/>
        <Column field="activityType" header="Activity type" style={{ minWidth: "10rem" }} sortable/>
        <Column field="activity" header="Activity" style={{ minWidth: "10rem" }} sortable/>
        <Column field="poiName" header="POI name" style={{ minWidth: "10rem" }} sortable/>
        <Column field="status" header="Status" style={{ minWidth: "10rem" }} sortable />
        <Column field="prescribedStartDate" header="Prescribed start date"
        style={{ minWidth: "10rem" }}  body={(rowData) => formatDate(rowData.prescribedStartDate)} sortable/>
        <Column field="prescribedEndDate" header="Prescribed end date"
        style={{ minWidth: "10rem" }} body={(rowData) => formatDate(rowData.prescribedEndDate)} sortable/>
        <Column field="dose" header="Dose" style={{ minWidth: "10rem" }} sortable/>
        <Column field="frequency" header="Frequency" style={{ minWidth: "10rem" }} sortable/>
        <Column field="does" header={resource.does} style={{ minWidth: "10rem" }} sortable/>
        <Column field="donts" header={resource.donts} style={{ minWidth: "10rem" }} sortable/>
        <Column field="prescribedOn" header="Prescribed On" 
        style={{ minWidth: "10rem" }} body={(rowData) => formatDateAndTime(rowData.prescribedOn)} sortable/>
        <Column field="prescribedBy" header="Prescribed by" style={{ minWidth: "10rem" }} sortable/>
        <Column field="logDate" header="Log date" style={{ minWidth: "10rem" }}
                body={(rowData) => formatDate(rowData.logDate)} sortable/>
        <Column field="startTime" header="Start time" style={{ minWidth: "10rem" }} sortable/>
        <Column field="endTime" header="End time" style={{ minWidth: "10rem" }} sortable/>
        <Column field="duration" header="Duration" style={{ minWidth: "10rem" }} sortable/>
    </DataTable>
  </div>
);



return( 
    <> 
  <div>
    <HeaderWithBackButton  controls={[]} handleSearch={handleSearch} searchQuery={searchQuery} /> 

    <div className="table-section mt-3">
      <div className="row flex">
        <div className="col-12">
          {TableData}
        </div>
      </div>
    </div>
    </div>
    </>
);
};
export default UserActivityLogReport;
