import LogoVedBhumi from "../../assetes/images/logo/logo.svg";
import { useNavigate } from "react-router-dom";
import { renderRoutes } from "../../router/routes";
import { useEffect, useState } from "react";


interface MenuItem {
  p_Description: string;
  p_MenuId: any;
  p_MenuName: string;
  p_Url: any;
  p_MenuIcon: any;
}

const Logo = () => {
  const navigate = useNavigate();
  const [menuItemsList, setMenuItemList] = useState<MenuItem[]>([]);


  useEffect(() => {
    const sideBarMenu = localStorage.getItem("user");
    if (sideBarMenu !== null) {
      const userData = JSON.parse(sideBarMenu);
      const menuItemList = userData.menuDetailsResponses;
      setMenuItemList(menuItemList);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const items = menuItemsList?.map((menuItem) => ({
    icon: menuItem.p_MenuIcon,
    label: menuItem.p_MenuName,
    url: menuItem.p_Url,

  }));

  const OnLogoClick = () => {
    if (menuItemsList !== null) {
      if (items.length > 0) {
        navigate(items[0].url);
      }
    } else if (menuItemsList !== null) {
      localStorage.clear();
      navigate("/");
    }
    else {
      localStorage.clear();
      navigate("/");
    }

  };

  return (
    <div className="logo" onClick={OnLogoClick}>
      <div className="logo-wrapper">
        <button onClick={OnLogoClick}>
          {" "}
          <img src={LogoVedBhumi} alt="logo" />{" "}
        </button>
      </div>
    </div>
  );
};

export default Logo;
