import React, { useContext, useRef, useState } from "react";
import HeaderWithBackButton from "../common/HeaderWIthBackButton";
import { contextStore } from "../../context/CustomStateProvider";
import AreaTable from "../area/AreaTable";
import AddSubAreaPopup from "../area/AddSubAreaPopup";
import { Toast } from "primereact/toast";

const SubAreaLayout = (props: any) => {
  const context = useContext(contextStore);
  const resource = context.state.strings;
  const [addAreaChange, setAddAreaChange] = useState(false);
  const [backButton, setBackButton] = useState(false);
  const [addNewSubAreaPopup, setaddNewSubAreaPopup] = useState(false);
  const [isEditArea, setIsEditArea] = useState();
  const [editMode, setEditMode] = useState(false);
  const toast = useRef<Toast>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [subAreaDataFilter, setSubAreaDatFilter] = useState([]);

  const cardControls = [
    {
      ctrlType: "button",
      name: "btnAddSubArea",
      title: resource.addNewSubArea,
      type: "button",
      disabled: false,
      className: "green-btn ",
    },
  ];

  const onButtonClick = (e: string) => {
    if (e === "btnAddSubArea") {
      setAddAreaChange(true);
      setaddNewSubAreaPopup(true);
      setEditMode(false);      
    }
  };
  const onBackButtonClick = () => {
    setBackButton(!backButton);
  };

  const onEditAreaClicked = (data: any) => {
    setaddNewSubAreaPopup(true);
    setIsEditArea(data);
    setEditMode(true);
  };

  const handleSearch = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const filteredEmployeeData = subAreaDataFilter?.filter((subAreaDataFilter: any) => {
    return (
      subAreaDataFilter?.p_areaname?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      subAreaDataFilter?.p_description?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      subAreaDataFilter?.p_description?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });
  return (
    <div className="container ">
      <Toast ref={toast} />

      {addNewSubAreaPopup && (
        <AddSubAreaPopup
          isEditArea={isEditArea}
          addNewSubAreaPopup={addNewSubAreaPopup}
          setaddNewSubAreaPopup={setaddNewSubAreaPopup}
          editMode={editMode}
          toast={toast}
        />
      )}
      <HeaderWithBackButton
        title={!addAreaChange || backButton ? resource.subAreaDetails : resource.subAreaDetails}
        controls={cardControls}
        onButtonClicked={onButtonClick}
        handleSearch={handleSearch}
        onBackButtonClick={onBackButtonClick}
        searchQuery={searchQuery}
      />

      <div className="transparant-table">
        <div className="row flex">
          <div className="col-12">
            <AreaTable
              addNewSubAreaPopup={addNewSubAreaPopup}
              onEditAreaClicked={onEditAreaClicked}
              filteredEmployeeData={filteredEmployeeData}
              setSubAreaDatFilter={setSubAreaDatFilter}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubAreaLayout;
