import { InputText } from "primereact/inputtext";
import { useContext, useEffect, useState } from "react";
import { contextStore } from "../../context/CustomStateProvider";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import AddressService from "../../services/addressServices";
import { SelectButton } from "primereact/selectbutton";
import { useFormik } from "formik";
import EmployeeService from "../../services/EmlpoyeeServices";

interface Country {
  p_countryid: number;
  p_countryname: string;
  p_shortname: string;
}
interface State {
  p_stateid: number;
  p_statename: string;
}
interface City {
  p_cityid: number;
  p_cityname: string;
}
interface Item {
  name: string;
  value: number;
}

interface ProfileForm {
  firstName: string;
  lastName: string;
  middleName: string;
  emailId: string;
  mobileNumber: string;
  country: any;
  state: any;
  city: any;
  pinCode: any;
  dateOfBirth: any;
  adharCard: any;
  isMarried: boolean;
}

const AddUserForm = (props: any) => {
  const context = useContext(contextStore);
  const resource = context.state.strings;
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [selectedState, setSelectedState] = useState<State | null>(null);
  const [selectedCity, setSelectedCity] = useState<City | null>(null);
  const [countryId, setCountryId] = useState([]);
  const [stateId, setStateId] = useState([]);
  const [cityId, setCityId] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState(null);
  const [selectedStateId, setSelectedStateId] = useState(null);
  const [, setDateofBirth] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const items: Item[] = [
    { name: "Active", value: 1 },
    { name: "Inactive", value: 2 },
  ];

  useEffect(() => {
    const UserName = localStorage.getItem("user");

    if (UserName !== null) {

      const userData = JSON.parse(UserName);

      formik.setFieldValue("firstName", userData.p_firstname);
      formik.setFieldValue("middleName",userData.p_middlename)
      formik.setFieldValue("lastName", userData.p_lastname);
      formik.setFieldValue("mobileNumber", userData.p_mobileno);
      formik.setFieldValue("employeeId", userData.p_employeeid);
      formik.setFieldValue("emailId", userData.p_emailid);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [status, setStatus] = useState(items[0].value);

  const onCountryChnage = (item: any) => {
    setSelectedCountry(item.value);
    setSelectedCountryId(item.value.p_countryid);
    formik.setFieldValue("country", item.value.p_countryid);
  };

  const GetAllCountry = () => {
    AddressService.GetAllCountry().then((res: any) => {
      if (res?.response?.status === 401) {
        window.location.pathname = "/";
      }
      setCountryId(res.data);
    });
  };

  const GetAllState = (selectedCountryId: any) => {
    var stateData = {
      Country_Id: selectedCountryId,
      id: null,
    };
    AddressService.GetAllState(stateData).then((res) => {
      if (res?.response?.status === 401) {
        window.location.pathname = "/";
      }
      setStateId(res.data);
    });
  };

  const GetAllCity = (selectedStateId: any) => {
    var cityData = {
      State_Id: selectedStateId,
      id: null,
    };

    AddressService.GetAllCity(cityData).then((res) => {
      if (res?.response?.status === 401) {
        window.location.pathname = "/";
      }
      setCityId(res.data);
    });
  };

  useEffect(() => {
    GetAllCountry();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedCountryId !== null) {
      GetAllState(selectedCountryId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountryId]);

  useEffect(() => {
    if (selectedStateId !== null) {
      GetAllCity(selectedStateId);
    }
  }, [selectedStateId]);

  const onStateChange = (item: any) => {
    setSelectedState(item.value);
    setSelectedStateId(item.value.p_stateid);
    formik.setFieldValue("state", item.value.p_stateid);
  };

  const onCityChange = (item: any) => {
    formik.setFieldValue("city", item.value.p_cityid);
    setSelectedCity(item.value);
  };
  const selectedCountryTemplate = (option: Country) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.p_countryname}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option: Country) => {
    return (
      <div className="flex align-items-center">
        <div>{option.p_countryname}</div>
      </div>
    );
  };

  const selectedStateTemplate = (option: State) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.p_statename}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };
  const selectedCityTemplate = (option: City) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option ? option.p_cityname : ""}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const cityOptionTemplate = (option: City) => {
    return (
      <div className="flex align-items-center">
        <div>{option.p_cityname}</div>
      </div>
    );
  };
  const stateOptionTemplate = (option: State) => {
    return (
      <div className="flex align-items-center">
        <div>{option.p_statename}</div>
      </div>
    );
  };

  const handleSubmit = async (values: ProfileForm) => {
    props.setLoadingSpinnerLayout(true);
    setLoading(true);
    try {
      await UpdateEmployee(values);
    } catch (error) {
      console.error("Failed to add employee:", error);
    }
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    emailId: "",
    middleName: "",
    mobileNumber: "",
    country: null,
    city: null,
    state: null,
    pinCode: "",
    dateOfBirth: null,
    adharCard: "",
    isMarried: true,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });


  const UpdateEmployee = (data: any) => {

    var employeedata = {
      EmployeeId: data.employeeId,
      FirstName: data.firstName,
      LastName: data.lastName,
      MiddleName: data.middleName,
      MobileNumber: data.mobileNumber ? data.mobileNumber : "",
      AdharCardNo: data.adharCard,
      Country: data.country ? data.country : 0,
      City: data.city ? data.city : 0,
      State: data.state ? data.state : 0,
      PinCode: data.pinCode,
      DateOfBirth: data.dateOfBirth ? data.dateOfBirth : null,
      Married: data.isMarried,
    };

    EmployeeService.UpdateEmployeeData(employeedata).then((res: any) => {
      try {

        if (res.status === 200) {

          props.toast.current?.show({ severity: "success", summary: "success", detail: "Employee details updated successfully", life: 3000 });
          setLoading(false);
          props.setLoadingSpinnerLayout(false);
        }
        if (res.response.status === 400) {
          setLoading(false);
          props.setLoadingSpinnerLayout(false);
          const errorMessage = res.response.data.errors.updateEmployeeDetailsRequest;
          props.toast.current?.show({ severity: "warn", summary: "Warning", detail: errorMessage, life: 3000 });

        }
        if (res.response.data.success === false) {
          setLoading(false);
          props.setLoadingSpinnerLayout(false);
          props.toast.current?.show({ severity: "warn", summary: "Warning", detail: "Something went wrong ", life: 3000 });

        }
        return res.data;
        // Redirect the user to the protected route
      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          const errorMessage = error.response.data.errorMessage;
          props.toast.current?.show({ severity: "warn", summary: "Warning", detail: errorMessage, life: 3000 });
          setLoading(false);
          props.setLoadingSpinnerLayout(false);
        }
      }
    });
  };

  const onSaveClick = (data: any) => {
    formik.handleSubmit(data);
  };

  return (
    <div className="relative vh-70 pl-2 pt-3 pr-2">
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-wrap">
          <div className="col-4 relative">
            <label htmlFor="firstName" className="block text-900 font-medium mb-2">
              {resource.firstName}
            </label>
            <InputText
              id="firstName"
              type="text"
              disabled={true}
              placeholder="Enter first name"
              className="w-full mb-2"
              value={formik.values.firstName}
              onChange={formik.handleChange}
            />
          </div>
          <div className="col-4 relative">
            <label htmlFor="middleName" className="block text-900 font-medium mb-2">
              {resource.middleName}
            </label>
            <InputText
              id="middleName"
              type="text"
              disabled={true}
              placeholder="Enter middle name"
              className="w-full mb-2"
              value={formik.values.middleName}
              onChange={formik.handleChange}
            />
          </div>
          <div className="col-4 relative">
            <label htmlFor="lastName" className="block text-900 font-medium mb-2">
              {resource.lastName}
            </label>
            <InputText
              id="lastName"
              type="text"
              disabled={true}
              placeholder="Enter last name"
              className="w-full mb-2"
              value={formik.values.lastName}
              onChange={formik.handleChange}
            />
          </div>
        </div>
        {/* <div className="flex flex-wrap">
          <div className="col-4 relative">
            <label htmlFor="adharCard" className="block text-900 font-medium mb-2">
              {resource.adharCard}
            </label>
            <InputText
              id="adharCard"
              type="text"
              placeholder="Please enter 12 digit adhar no"
              className="w-full mb-2"
              value={formik.values.adharCard}
              onChange={formik.handleChange}
            />
          </div>
          <div className="col-4">
            <label htmlFor="country" className="block text-900 font-medium mb-2">
              {resource.country}
            </label>

            <Dropdown
              value={selectedCountry}
              onChange={(e) => onCountryChnage(e)}
              options={countryId}
              optionLabel="Country"
              disabled={!countryId}
              placeholder="Please Select country"
              valueTemplate={selectedCountryTemplate}
              itemTemplate={countryOptionTemplate}
              className="w-full"
            />
          </div>

          <div className="col-4">
            <label htmlFor="state" className="block text-900 font-medium mb-2">
              {resource.state}
            </label>

            <Dropdown
              value={selectedState}
              onChange={(e) => onStateChange(e)}
              options={stateId}
              disabled={!selectedCountryId}
              optionLabel="State"
              placeholder="Please Select State"
              valueTemplate={selectedStateTemplate}
              itemTemplate={stateOptionTemplate}
              className="w-full"
            />
          </div>
        </div> */}

        <div className="flex flex-wrap ">
          {/* <div className="col-4">
            <label htmlFor="city" className="block text-900 font-medium mb-2">
              {resource.city}
            </label>

            <Dropdown
              value={selectedCity}
              onChange={(e) => onCityChange(e)}
              options={cityId}
              disabled={!selectedState}
              optionLabel="City"
              placeholder="Please Select city"
              valueTemplate={selectedCityTemplate}
              itemTemplate={cityOptionTemplate}
              className="w-full"
            />
          </div>
          <div className="col-4 relative">
            <label htmlFor="pinCode" className="block text-900 font-medium mb-2">
              {resource.pinCode}
            </label>
            <InputText
              id="pinCode"
              type="text"
              placeholder="Enter pincode "
              className="w-full mb-2"
              max={6}
              value={formik.values.pinCode}
              onChange={formik.handleChange}
            />{" "}
          </div> */}
          <div className="col-4 relative">
            <label htmlFor="emailId" className="block text-900 font-medium mb-2">
              {resource.emailId}
            </label>
            <InputText
              id="emailId"
              type="text"
              disabled={true}
              placeholder="Please enter email Id"
              className="w-full mb-2"
              value={formik.values.emailId}
              onChange={formik.handleChange}
            />
          </div>
          <div className="col-4 relative">
            <label htmlFor="mobileNumber" className="block text-900 font-medium mb-2">
              {resource.mobileNumber}
            </label>
            <InputText
              id="mobileNumber"
              type="text"
              disabled={true}
              placeholder="Enter mobile no"
              className="w-full mb-2"
              value={formik.values.mobileNumber}
              onChange={formik.handleChange}
            />
          </div>
        </div>

        {/* <div className="flex flex-wrap ">
        
          <div className="col-4">
            <label htmlFor="dateOfBirth" className="block text-900 font-medium mb-2">
              {resource.dateOfBirth}
            </label>
            <Calendar
              dateFormat="dd/mm/yy"
              readOnlyInput
              id="buttondisplay"
              placeholder="Please select date of birth"
              className="w-full"
              showIcon
              onChange={(e) => {
                formik.setFieldValue("dateOfBirth", e.value);
                setDateofBirth(e.value);
              }}
            />
            {formik.errors.dateOfBirth && formik.touched.dateOfBirth && <div className="text-red-500 text-xs">{formik.errors.dateOfBirth}</div>}
          </div>

          <div className="col-4">
            <label className="block text-900 font-medium mb-2">{resource.isMarried}</label>
            <SelectButton value={status} optionLabel="name" onChange={(e) => setStatus(e.value)} options={items} />
          </div>
        </div> */}

        {/* <div className="footer-button cancel-submit-btn">
          <Button className="cancel-btn " label={resource.cancel} onClick={onCancelClick} type="reset" />
          <Button className="submit-btn ml-2 " label={resource.submit} type="submit" loading={loading} onClick={onSaveClick} />
        </div> */}
      </form>
    </div>
  );
};

export default AddUserForm;
