import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import ReportService from "../../services/reportServices";
import { contextStore } from "../../context/CustomStateProvider";
import { Calendar } from "primereact/calendar";
import HeaderWithBackButton from "../common/HeaderWIthBackButton";
import { Toast } from "primereact/toast";

const ReportLayout = (props: any) => {

  const [areaWiseVisitorList, setAreaWiseVisitorList] = useState<AreaWiseVisitorTable[]>([]);
  const [loading, setLoading] = useState(true);
  const todayDate = new Date();  
  const [date, setDate] = useState<Date>(todayDate);
  const context = useContext(contextStore);
  const resource = context.state.strings;
  const [searchQuery, setSearchQuery] = useState("");
  const toast = useRef<Toast>(null);
  const [isDowndoadDisabled, setIsDowndoadDisabled] = useState(true);




  interface AreaWiseVisitorTable {
        srNo: number;
        sn: string;
        date: string;
        poiName:string; 
        areaName:string;
        subareaName:string;
        visitorName:string;
        visitorContactNo:string;
        prescriptionName:string;
        activityType:string;
        activity:string;
        startTime:string;
        endTime:string;
        duration:string;
        does:string;
        donts:string;
  }

  const handleSearch = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const formatDate = (value: string) => {
    const date = new Date(value);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const filteredReportData = areaWiseVisitorList?.filter((areaWiseVisitorList: any) => {
    return (
      areaWiseVisitorList?.poiName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      areaWiseVisitorList?.areaName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      areaWiseVisitorList?.subareaName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      areaWiseVisitorList?.visitorName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      areaWiseVisitorList?.visitorContactNo?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      areaWiseVisitorList?.prescriptionName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      areaWiseVisitorList?.activityType?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      areaWiseVisitorList?.activity?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      areaWiseVisitorList?.startTime?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      areaWiseVisitorList?.endTime?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      areaWiseVisitorList?.duration?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      areaWiseVisitorList?.does?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      areaWiseVisitorList?.donts?.toLowerCase().includes(searchQuery.toLowerCase()) 

    );
  });



  const GetAreaWiseVisitor = () => {
    setLoading(true);
    let formattedDate = date.toLocaleDateString('en-CA'); // "YYYY-MM-DD" format
    let data = {
      date:  formattedDate
    };
    setTimeout(() => {
      ReportService.AreaWiseVisitor(data).then((res: any) => {
      setAreaWiseVisitorList(res.data);
      if(res.data.length>0)
      {
          setIsDowndoadDisabled(false);
      }
      setLoading(false);
      }
      );
    }, 600);
  };

  useEffect(() => {
    if (areaWiseVisitorList.length <= 0) {
        setIsDowndoadDisabled(true);
    }
  }, [areaWiseVisitorList]);


  const SrNo = (rowData: any, index: any) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{index.rowIndex + 1}</span>
      </div>
    );
  };

    const onDownloadReportClick=()=>
  {
    GenerateReport();
  }
  const GenerateReport=()=>
  {
    setLoading(true);
    let formattedDate = date.toLocaleDateString('en-CA'); 
    let data = {
      date:  formattedDate
    };
    ReportService.AreaWiseVisitor(data)
      .then((res: any) => {
        setLoading(false);
        if (res.status === 200) {
      

          let transformedData = res.data.map((item: any) => {
            return {
              "Sn": item.sn,  
              "Date": formatDate(item.date),  
              "POI": item.poiName,  
              "Area name": item.areaName, 
              "Subarea name": item.subareaName,  
              "Visitor name": item.visitorName, 
              "Visitor contact no": item.visitorContactNo,  
              "Prescription name": item.prescriptionName, 
              "Activity type": item.activityType,  
              "Activity": item.activity, 
              "Start time": item.startTime,  
              "End time": item.endTime, 
              "Duration": item.duration,  
              "Do's": item.does, 
              "Dont's": item.donts
            };
          });

            const ReportData = {
              data:  transformedData,
              name: `TodaysVisitorReport_${formatDate(formattedDate)}.xlsx`
            };
            ReportService.ReportDownload(ReportData);
            toast.current?.show({ 
              severity: "success", 
              summary: "Success", 
              detail: "Report downloaded successfully.", 
              life: 3000 });

        }
        else if (res.response.status==500)
        {
          toast.current?.show({
               severity: "warn",
                summary: "Warning",
                detail: "No reports are available for download.",
                life: 3000,
            });
        }

      }).catch(() => {
        props.toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      });
  }



useEffect(()=>{
  setIsDowndoadDisabled(true);
  GetAreaWiseVisitor();
  }, []);

  const GetReportForNewDate=()=>
  {
    setDate(date);
    setIsDowndoadDisabled(false);
    GetAreaWiseVisitor();

  }


let TableData = (
  <div className="table-section">
            <DataTable value={filteredReportData} dataKey="id" scrollable scrollHeight="600px" loading={loading} emptyMessage="No details found." paginator rows={15}>
            <Column header="Sr.No." style={{ minWidth: "2rem" }} body={SrNo} />
            <Column  field="date" header="Date"   body={(rowData) => formatDate(rowData.date)} sortable/>
            <Column field="poiName" header="POI Name" sortable></Column>
            <Column field="areaName" header="Area Name" sortable></Column>
            <Column field="subareaName" header="Subarea Name" sortable></Column>
            <Column field="visitorName" header="Visitor Name" sortable></Column>
            <Column field="visitorContactNo" header="Visitor Contact No" sortable></Column>
            <Column field="prescriptionName" header="Prescription Name" sortable></Column>
            <Column field="activityType" header="Activity Type" sortable></Column>
            <Column field="activity" header="Activity" sortable></Column>
            <Column field="startTime" header="Start Time" sortable></Column>
            <Column field="endTime" header="End Time" sortable></Column>
            <Column field="duration" header="Duration" sortable></Column>
            <Column field="does" header={resource.does} sortable></Column>
            <Column field="donts" header={resource.donts} sortable></Column>
        </DataTable>
  </div>
);



    return(
      <div className="container">
  <Toast ref={toast} />
  
  <div>
    <div className="flex align-items-center justify-content-between ml-3">
      <div className="flex align-items-center">
        <div className="relative">
          <label htmlFor="date" className="block text-900 font-medium mb-2">
            Select date
          </label>
          <Calendar
            placeholder="DD/MM/YYYY"
            dateFormat="dd/mm/yy"
            readOnlyInput
            id="date"
            value={date}
            tabIndex={1}
            className="w-full"
            showIcon
            onChange={(e) => {
              if (e.value) {
                setDate(e.value); 
              }
            }}
          />
        </div>
        <div className="relative ml-5 mt-4"> 
          <Button 
            className="btn" 
            label="Submit"
            tabIndex={2}
            onClick={GetReportForNewDate}
          />
        </div>
      </div>

      <div className="relative  mr-5 mt-4 mb-2"> 
        <Button 
          className="btn" 
          label="Download"
          tabIndex={2}
          onClick={onDownloadReportClick}
          icon="pi pi-download" 
          iconPos="left"
          disabled={isDowndoadDisabled}
        />
      </div>
    </div>
    <HeaderWithBackButton title={resource.todaysVisitorreport} controls={[]} handleSearch={handleSearch} searchQuery={searchQuery} />

    <div className="table-section mt-3">
      <div className="row flex">
        <div className="col-12">
          {TableData}
        </div>
      </div>
    </div>
  </div>
</div>


      );
};
export default ReportLayout;
