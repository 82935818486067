import { Button } from "primereact/button";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";

const HeaderWithBackButton = (props: any) => {
  const toggleCardTitle = props.title ?? "";

  const buttonClick = (e: any) => {
    e.currentTarget.blur();
    if (props.onButtonClicked) {
      props.onButtonClicked(e.currentTarget.name);
    }
  };

  const onHandleSearch = (e: any) => {
    props.handleSearch(e);
  }
  return (
    <>
      <div className="headingWithBackButton flex  mb-2 p-1 justify-content-between">
        <div className="flex align-items-center">
          <div className="card-heading">
            <h5 className=" ml-1">{toggleCardTitle}</h5>
          </div>
        </div>

        <div className="flex justify-content-end align-items-center">
          {!props.isSearch && (
            <div className="ml-3">
              <IconField iconPosition="left">
                <InputIcon className="pi pi-search"> </InputIcon>
                <InputText value={props.searchQuery} onChange={(e) => onHandleSearch(e)} placeholder="Search" />
              </IconField>
            </div>
          )}
          <div className="ml-4">
            {" "}
            {props.controls.map((ctrlItem: any, key: any) => {
              return ctrlItem.ctrlType === "button" ? (
                ctrlItem.hoverImageId === undefined || ctrlItem.icon === undefined ? (
                  <>
                    <Button
                      key={ctrlItem.name}
                      hidden={ctrlItem.hidden || false}
                      name={ctrlItem.name}
                      size={ctrlItem.size}
                      type={ctrlItem.type}
                      color={ctrlItem.color}
                      className={ctrlItem.className}
                      label={ctrlItem.icon !== undefined ? ctrlItem.title : ""}
                      disabled={ctrlItem.excludeToggleDisable ? ctrlItem.disabled : ctrlItem.disabled || ctrlItem.saveInProgress}
                      onClick={(e) => buttonClick(e)}
                      onMouseLeave={(e) => e.currentTarget.blur()}
                      icon={ctrlItem.icon}
                    >
                      {ctrlItem.icon === undefined && (!ctrlItem.saveInProgress ? ctrlItem.title : `${ctrlItem.progressTitle} ...`)}
                    </Button>
                  </>
                ) : (
                  ""
                )
              ) : (
                ""
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderWithBackButton;
