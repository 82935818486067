import axios from "axios";
import authHeader from "./authHeader";

const GetProtocolActivityById = async () => {
  try {
    const response = await axios.get(process.env.REACT_APP_BASE_PROTOCOL_ENDPOINT + `api/Prescription/Activity`, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const GetPOIByActivityId = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_PROTOCOL_ENDPOINT + `api/Prescription/POI`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const AddProtocol = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_PROTOCOL_ENDPOINT + `api/Prescription/AddPrescription`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
const GetProtocolTreatmentHistory = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_PROTOCOL_ENDPOINT + `api/Prescription/GetPrescription`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const SaveProtocol = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_PROTOCOL_ENDPOINT + `api/LineItem/SaveProtocol`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const GetProtocol = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_PROTOCOL_ENDPOINT + `api/LineItem/GetProtocolLineItem`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const GetMedia = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_PROTOCOL_ENDPOINT + `api/Prescription/media`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
const GetPrescription = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_PROTOCOL_ENDPOINT + `api/Prescription/GetPrescription`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
const DeleteProtocolLineItem = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_PROTOCOL_ENDPOINT + `api/LineItem/DeleteProtocol`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const UserWiseReport = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_PROTOCOL_ENDPOINT + `api/Report/UserWiseReport`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};



const protocolServices = {
  GetProtocolActivityById,
  GetProtocolTreatmentHistory,
  GetPOIByActivityId,
  AddProtocol,
  SaveProtocol,
  GetProtocol,
  GetMedia,
  GetPrescription,
  UserWiseReport,
  DeleteProtocolLineItem
};

export default protocolServices;
