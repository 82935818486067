import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import protocolServices from "../../../services/protocolServices";
import { Calendar } from "primereact/calendar";
import { contextStore } from "../../../context/CustomStateProvider";
import { useFormik } from "formik";
import moment from "moment";
import { Nullable } from "primereact/ts-helpers";
import AddProtocolValidation from "../../../validation/AddProtocolValidation";
import { Toast } from "primereact/toast";
import { number } from "yup";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { InputTextarea } from "primereact/inputtextarea";
import CustomTime from "../../common/CustomTime";



interface Activity {
  p_activityid: any;
  p_activityname: string;
  p_duration: any;
}

interface POI {
  p_poiid: any;
  p_poiname: string;
}

interface Media {
  p_poimediaid: number;
  p_medianame: string | null;
  p_mediaurl: string;
  p_duration: string | null;
}
interface Protocoltem {
  srNo: number;
  p_activityname: string;
  p_poiname: string;
  p_video: string;
  p_audio: string;
  p_scheduledate: string;
  p_scheduletime: string;
}

const ProtocolTabLayout = (props: any) => {
  const context = useContext(contextStore);
  const resource = context.state.strings;
  const [loading, setLoading] = useState(true);
  const [activity, setActivity] = useState<Activity | null>(null);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [activityId, setActivityId] = useState([]);
  const [poiId, setpoiId] = useState([]);
  const [poiData, setpoiData] = useState<POI | null>(null);
  const [selectedPOI, setSelectedPOI] = useState(null);
  const [videoData, setVideoData] = useState<Media[]|null>([]);
  const [videoId, setVideoId] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [audioData, setAudioData] = useState<Media[]|null>([]);
  const [audioId, setAudioId] = useState([]);
  const [selectedAudio, setselectedAudio] = useState(null);
  const [date, setDate] = useState<Nullable<Date>>(null);
  const [time, setTime] = useState<Nullable<Date>>(null);
  const [videoDesabled, setVideoDesabled] = useState(false);
  const [audioDesabled, setAudioDesabled] = useState(false);
  const [protocolItemList, setProtocolItemList] = useState<Protocoltem[]>([]);
  const [editrue, seteditrue] = useState(false);
  const [protocollineitemId, setprotocollineitemId] = useState(null);
  const toast = useRef<Toast>(null);
  const [isEditDisabled, setIsEditDisabled] = useState(false);
  const[editTimeValue,setEditTimeValue]=useState('');
  const[customTimeChangeValue,setCustomTimeChangeValue]=useState('');

  const getMinDate = () => {
    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0); // Set today's date to midnight to ignore time
    const prescriptionDate = props.prescriptiondate ? new Date(props.prescriptiondate) : null;
    return prescriptionDate && prescriptionDate > todayDate ? prescriptionDate : todayDate;
  };
  const [minDate, setMinDate] = useState<Date>(getMinDate);  

  const currentDateTime = new Date();
  

  const TimeFormate = (rowData: any) => {
    const ConvertedTime = ConvertTime(rowData.p_scheduletime);
    return (
      
      <p>{ moment(ConvertedTime).format("h:mm A")}</p>
    );
  }


  const convertTo12HourFormat = (time: string): string => {
    const [hoursString, minutes] = time.split(':');
    let hours = parseInt(hoursString, 10);
    let period = 'AM';
  
    if (hours >= 12) {
      period = 'PM';
      if (hours > 12) {
        hours -= 12;
      }
    } else if (hours === 0) {
      hours = 12;
    }
  
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes} ${period}`;
    return formattedTime;
  };


  useEffect(() => {
    setLoading(false);
    setIsEditDisabled(false);
  }, []);

  useEffect(() => {
    setMinDate(getMinDate);
  }, [props.prescriptiondate]);
  
  useEffect(() => {
    if (selectedVideo) {
      const mediaobjectvideo:any = videoId.find((act: any) => act.p_poimediaid === selectedVideo);
      if (mediaobjectvideo) {
        formik.setFieldValue("videoId", mediaobjectvideo.p_poimediaid);
        setVideoData(mediaobjectvideo);
        setSelectedVideo( mediaobjectvideo.p_poimediaid);
      }
    }
  }, [selectedVideo, videoId, videoData]);

  useEffect(() => {
    if (selectedAudio) {
      const mediaobjectaudio:any = audioId.find((act: any) => act.p_poimediaid === selectedAudio);
      if (mediaobjectaudio) {
        formik.setFieldValue("audioId", mediaobjectaudio.p_poimediaid);
        setAudioData(mediaobjectaudio);
        setselectedAudio(mediaobjectaudio.p_poimediaid);
      }
    }
  }, [selectedAudio, audioId, audioData]);

  const Action = (rowData: any) => {
    return (
      <React.Fragment>
        <Button
          className="table-button custome-icon  edit-icon "
          onClick={() => onEditClick(rowData)}
        />
        <Button
          className="table-button custome-icon delete-icon"
          onClick={() => onDeleteClick(rowData)}
        ></Button>
      </React.Fragment>
    );
  };

  const onEditClick = async (value: any) => {
    setIsEditDisabled(false);
    const therapyDateTime = new Date(`${value.p_scheduledate}T${value.p_scheduletime}`);
    if(therapyDateTime<currentDateTime)
    {
      setIsEditDisabled(true);
    }
    setAudioId([]);
    setVideoId([]);
    seteditrue(true);
    setprotocollineitemId(value.p_protocollineitemid);
    const activityIdForPOI = value.p_activityid;
    const videoIdForPOI = value.p_poiid;

    formik.setFieldValue("activityId", value.p_activityid);
    formik.setFieldValue("poiId", value.p_poiid);
    setSelectedActivity(value.p_activityid);
    formik.setFieldValue("does", value.p_does);
    formik.setFieldValue("donts", value.p_donts);
    const [poiResponse, mediaResponse] = await Promise.all([
      GetPOIByActivityId(activityIdForPOI),
      GetMedia(videoIdForPOI)
    ]);

    const updatedActivity: any = activityId.find(
      (act: any) => act.p_activityid === value.p_activityid
    );

    if (updatedActivity) {
      updatedActivity.p_activityname = value.p_activityname;
      updatedActivity.p_duration = value.p_duration;
      setActivity(updatedActivity);
      setActivityId([...activityId]);
      const poiObject: POI = {
        p_poiid: value.p_poiid,
        p_poiname: value.p_poiname,
      };

      setpoiData(poiObject);
      const mediaobject: any = audioId.find(
        (act: any) => act.p_poimediaid === value.p_audioid
      );

      if (mediaobject) {
        mediaobject.p_poimediaid = value.p_audioid;
        mediaobject.p_medianame = value.p_audioname;
        formik.setFieldValue("audioId", mediaobject);
        setselectedAudio(value.p_audioid)
        setAudioData(mediaobject);

      }
      const mediaobjectvideo: any = videoId.find(
        (act: any) => act.p_poimediaid === value.p_videoid
      );
      setSelectedVideo(value.p_videoid);
      setselectedAudio(value.p_audioid);
    }
    const scheduletime =  convertTo12HourFormat(value.p_scheduletime);
    setEditTimeValue(scheduletime);

    formik.setFieldValue("date", new Date(value.p_scheduledate));
    setDate(new Date(value.p_scheduledate));

  };
  
  const ConvertTime = (value: string) => {
    const [hours, minutes, seconds] = value.split(":");
    const scheduletime = new Date();
    scheduletime.setHours(Number(hours));
    scheduletime.setMinutes(Number(minutes));
    scheduletime.setSeconds(Number(seconds));
    return scheduletime;
  };
  const GetActivityByUserId = async () => {
    protocolServices.GetProtocolActivityById().then((res: any) => {
      try {
        if (res.status === 200) {
          setActivityId(res.data);
        }
        return res.data;
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: "Unauthorized",
            life: 3000,
          });
          setLoading(false);
          window.location.pathname = "/";
        }
      }
    });
  };

  const onPOIChange = (e: any) => {
    formik.setFieldValue("poiId", e.value.p_poiid);
    setpoiData(e.value);
    setSelectedPOI(e.value.p_poiid);
    setAudioDesabled(true);
    GetMedia(e.value.p_poiid);
    setselectedAudio(null);
    setSelectedVideo(null);
  };

  const onActivityChange = (e: any) => {
    formik.setFieldValue("activityId", e.value.p_activityid);
    setActivity(e.value);
    setSelectedActivity(e.value.p_activityid);
    GetPOIByActivityId(e.value.p_activityid);
  };

  const GetPOIByActivityId = async (value: any) => {
    const data = {
      ActivityId: value,
    };

    protocolServices
      .GetPOIByActivityId(data)
      .then((res: any) => {
        if (res.status === 200) {
          setpoiId(res.data);
        }
      })
      .catch(() => {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      });
  };

  const SrNo = (rowData: any, index: any) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{index.rowIndex + 1}</span>
      </div>
    );
  };

  const GetMedia = async (values: any) => {
    const data = {
      Poiid: values,
    };

    protocolServices
      .GetMedia(data)
      .then((res: any) => {
        if (res.status === 200) {
          if (res.data.videos !== null) {
            const nonNullMedianameItems = res.data.videos.filter(
              (item: any) => item.p_medianame !== null
            );
            nonNullMedianameItems.forEach((option: any) => {
              subVideoItemTemplate(option);
            });
            setVideoId(nonNullMedianameItems);
            setVideoDesabled(true);
          } else {
            setVideoDesabled(false);
          }
          if (res.data.audios !== null) {
            const nonNullMedianameItems = res.data.audios.filter(
              (item: any) => item.p_medianame !== null
            );
            nonNullMedianameItems.forEach((option: any) => {
              subAudioItemTemplate(option);
            });
            setAudioId(nonNullMedianameItems);
            setAudioDesabled(true);
          } else {
            setAudioDesabled(false);
          }
        }
      })
      .catch(() => {
        props.toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      });
  };

  useEffect(() => {
    GetActivityByUserId();
    GetProtocol();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=>{
    setCustomTimeChangeValue(customTimeChangeValue);
    formik.setFieldValue('customTimeChangeValue', customTimeChangeValue);
},[customTimeChangeValue])

  const subActivityValueTemplate = (option: Activity) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.p_activityname}</div>
        </div>
      );
    }
  };
  const activityTemplate = (option: Activity) => {
    return (
      <div className="flex align-items-center">
        <div>{option.p_activityname}</div>
      </div>
    );
  };
  const subPOIItemTemplate = (option: POI) => {
    return (
      <div className="flex align-items-center">
        <div>{option?.p_poiname}</div>
      </div>
    );
  };
  const POITemplate = (option: POI) => {
    return (
      <div className="flex align-items-center">
        <div>{option?.p_poiname}</div>
      </div>
    );
  };
  const onVideoChange = (e: any) => {
    formik.setFieldValue("videoId", e.value);
    setVideoData(e.value);
    setSelectedVideo(e.value.p_poimediaid);
  };

  const subVideoItemTemplate = (option: Media) => {
    if (option?.p_medianame) {
      return (
        <div className="flex align-items-center">
          <div>{option.p_medianame}</div>
        </div>
      );
    } else {
      setVideoDesabled(false);
    }
  };
  const VideoTemplate = (option: Media) => {
    return (
      <div className="flex align-items-center">
        <div>{option?.p_medianame}</div>
      </div>
    );
  };
  const subAudioItemTemplate = (option: Media) => {
    if (option?.p_medianame) {
      return (
        <div className="flex align-items-center">
          <div>{option.p_medianame}</div>
        </div>
      );
    } else {
      setAudioDesabled(false);
    }
  };

  const AudioTemplate = (option: Media) => {
    return (
      <div className="flex align-items-center">
        <div>{option?.p_medianame}</div>
      </div>
    );
  };
  const onAudioChange = (e: any) => {
    formik.setFieldValue("audioId", e.value);
    setAudioData(e.value);
    setselectedAudio(e.value?.p_poimediaid);
  };
  const onCancel = () => {
    setActivity(null);
    setpoiData(null);
    setAudioData([]);
    setVideoData([]);
    setprotocollineitemId(null);
    formik.resetForm();
    setIsEditDisabled(false);
    setselectedAudio(null);
    setSelectedVideo(null);
    setEditTimeValue(' ');

  };
  const onDeleteClick = (value: any) => { 
    confirmDialog({
      message: `Are you sure you want to delete this field?`,
      header: "Confirm delete field",
      icon: "pi pi-exclamation-triangle",
      accept: () => OnDeleteMedicineLineItem(value),
    });
    
    }
  const OnDeleteMedicineLineItem = (value: any) =>{
    setLoading(false);
    const data = {
      id : value.p_protocollineitemid
    };
    protocolServices.DeleteProtocolLineItem(data).then((res: any) => {
      try {
        if (res.status === 200  && res.data.p_response) {
          toast.current?.show({
            severity: "success",
            summary: "Sucess",
            detail: res.data.p_msg,
            life: 3000,
          });
          setLoading(false);
          GetProtocol();
        }
        else
        {
          setLoading(false);
          toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: res.data.p_msg,
            life: 3000,
          });
        }
        if (res.response.status === 400 && res.response.data.success === false) {
          toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: res.response.data.errorMessage,
            life: 3000,
          });
          setLoading(false);
        }
        if (res.response.status === 500 && res.response.data.success === false) {
          toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: res.response.data.errorMessage,
            life: 3000,
          });
          setLoading(false);
        }
        return res.data;
        // Redirect the user to the protected route
      } catch (error: any) { }
    })
  }

  const handleSubmit = (values: any) => {
    setLoading(true);
    const treatmentDate = moment(values.date).format("YYYY-MM-DD") as any;
    const scheduleTime = moment(values.time).format("h:mm A");
    const data = {
      prescriptionid: props.prescriptionid
        ? props.prescriptionid
        : props.editPrescriptionid,
      poiid: values.poiId,
      activityid: values.activityId,
      videoid: selectedVideo,
      audioid: selectedAudio,
      scheduledate: treatmentDate,
      scheduletime: customTimeChangeValue,
      does: values.does,
      donts: values.donts,
      protocollineitemid: protocollineitemId !== null ? protocollineitemId : null
    };

    protocolServices.SaveProtocol(data).then((res: any) => {
      setLoading(false);
      GetProtocol();
      try {
        if (res.status === 200 &&  res.data.p_response === true) {
        
          toast.current?.show({
            severity: "success",
            summary: "Success",
            detail: res.data.p_msg,
            life: 3000,
          });

          formik.resetForm();
          setSelectedActivity(null);
          setVideoDesabled(false);
          setAudioDesabled(false)
          setActivity(null);
          setpoiData(null);
          setAudioData([]);
          setVideoId([]);
          setAudioId([])
          setselectedAudio(null);
          setSelectedVideo(null)
          setVideoData([]);
          setDate(null);
          setTime(null);
          setEditTimeValue(' ');

        }
        if (
          res.status === 200 &&
          res.data.p_response === false
        ) {
          toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: res.data.p_msg,
            life: 3000,
          });
          setLoading(false);
        }
        if (
          res.response.status === 400 &&
          res.response.data.success === false
        ) {
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: res.response.data.errorMessage,
            life: 3000,
          });
          setLoading(false);
        }
        if (
          res.response.status === 500 &&
          res.response.data.success === false
        ) {
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: res.response.data.errorMessage,
            life: 3000,
          });
          setLoading(false);
        }
        return res.data;
        // Redirect the user to the protected route
      } catch (error: any) {
        
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      activityId: "",
      poiId: "",
      videoId: "",
      audioId: "",
      date: "",
      time: "",
      does:"",
      donts:"",
      customTimeChangeValue: ""
    },
    validationSchema: AddProtocolValidation,
    onSubmit: handleSubmit,
  });

  const GetProtocol = () => {
    const data = {
      Prescriptionid: props.prescriptionid
        ? props.prescriptionid
        : props.editPrescriptionid,
    };
    protocolServices.GetProtocol(data).then((res: any) => {
      setProtocolItemList(res.data);
      setLoading(false);
    });
  };
  
  const formatDate = (value: string) => {
    const date = new Date(value);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <>
      {" "}
      <Toast ref={toast} />
      <div className="table-section">
        <div className="gray-bg">
          <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-wrap  ">
              <div className="col-3 relative">
                <label
                  htmlFor="activityId"
                  className="block text-900 font-medium mb-2"
                >
                  {resource.activity} <span style={{ color: "red" }}>*</span>
                </label>
                <Dropdown
                  value={activity}
                  onChange={(e) => onActivityChange(e)}
                  options={activityId}
                  placeholder="Select activity"
                  valueTemplate={subActivityValueTemplate}
                  itemTemplate={activityTemplate}
                  autoFocus={true}
                  tabIndex={1}
                  className="w-full"
                  filter
                  filterBy="p_activityname"
                  disabled={isEditDisabled}
                />
                {formik.errors.activityId && formik.touched.activityId && (
                  <div className="text-red-500 text-xs">
                    {formik.errors.activityId}
                  </div>
                )}{" "}
              </div>
              <div className="col-3 relative">
                <label
                  htmlFor="poiId"
                  className="block text-900 font-medium mb-2"
                >
                  {resource.pointofInterest}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Dropdown
                  value={poiData}
                  options={poiId}
                  disabled={!selectedActivity || isEditDisabled}
                  onChange={(e) => onPOIChange(e)}
                  placeholder="Select POI"
                  className="p-column-filter"
                  itemTemplate={subPOIItemTemplate}
                  valueTemplate={POITemplate}
                  tabIndex={2}
                  filter
                  filterBy="p_poiname"
                />
                {formik.errors.poiId && formik.touched.poiId && (
                  <div className="text-red-500 text-xs">
                    {formik.errors.poiId}
                  </div>
                )}
              </div>
              <div className="col-3 relative">
                <label
                  htmlFor="videoid"
                  className="block text-900 font-medium mb-2"
                >
                  {resource.video}
                </label>
                <Dropdown
                  value={videoData}
                  options={videoId}
                  disabled={!videoDesabled || isEditDisabled}
                  onChange={(e) => onVideoChange(e)}
                  placeholder="Select video"
                  className="p-column-filter"
                  itemTemplate={subVideoItemTemplate}
                  valueTemplate={VideoTemplate}
                  tabIndex={3}
                  filter
                  filterBy="p_medianame"
                />
              </div>
              <div className="col-3 relative">
                <label
                  htmlFor="audioId"
                  className="block text-900 font-medium mb-2"
                >
                  {resource.audio}
                </label>
                <Dropdown
                  value={audioData}
                  options={audioId}
                  disabled={!audioDesabled || isEditDisabled}
                  onChange={(e) => onAudioChange(e)}
                  placeholder="Select audio"
                  className="p-column-filter"
                  itemTemplate={subAudioItemTemplate}
                  valueTemplate={AudioTemplate}
                  tabIndex={4}
                  filter
                  filterBy="p_medianame"
                />
              </div>
            </div>

            <div className="flex flex-wrap ">
              <div className="col-6 flex p-0 ">
                <div className="col-6 relative">
                  <label
                    htmlFor="date"
                    className="block text-900 font-medium mb-2"
                  >
                    {resource.date} <span style={{ color: "red" }}>*</span>
                  </label>
                  <Calendar
                    dateFormat="dd/mm/yy"
                    readOnlyInput
                    id="buttondisplay"
                    placeholder="DD/MM/YYYY"
                    className="w-full"
                    showIcon
                    value={date}
                    minDate={minDate}
                    tabIndex={5}
                    disabled={isEditDisabled}
                    onChange={(e) => {
                      formik.setFieldValue("date", e.value);
                      setDate(e.value);
                    }}
                  />
                  {formik.errors.date && formik.touched.date && (
                    <div className="text-red-500 text-xs">
                      {formik.errors.date}
                    </div>
                  )}{" "}
                </div>
                <div className="col-6 relative">
                  <label
                    htmlFor="time"
                    className="block text-900 font-medium mb-2"
                  >
                    {resource.time} <span style={{ color: "red" }}>*</span>
                  </label>
                  <CustomTime    editTimeValue={editTimeValue}  isDisabled={isEditDisabled}
                  customTimeChangeValue={customTimeChangeValue} setCustomTimeChangeValue={setCustomTimeChangeValue}
                  />
                 {formik.errors.customTimeChangeValue && formik.touched.customTimeChangeValue && (
        <div className="text-red-500 text-xs">{formik.errors.customTimeChangeValue}</div>
      )}{" "}
                </div>
                <div className="col-6 relative">
                <label htmlFor="does" className="block font-medium mb-2">
                  {resource.does}
                </label>
                <InputTextarea
                  id="does"
                  className="w-full mb-5"
                  value={formik.values.does}
                  disabled={isEditDisabled}
                  rows={3}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  placeholder="Enter does"
                />
              </div>
              <div className="col-6 relative">
                <label htmlFor="donts" className="block font-medium mb-2">
                  {resource.donts}
                </label>
                <InputTextarea
                  id="donts"
                  className="w-full mb-5"
                  value={formik.values.donts}
                  rows={3}
                  disabled={isEditDisabled}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  placeholder="Enter donts"
                />
              </div>
              </div>
              </div>
              <div className="col-12 flex justify-content-end cancel-submit-btn">
                <Button
                  label="Cancel"
                  className="cancel-btn mt-10 mr-2"
                  type="reset"
                  onClick={onCancel}
                  tabIndex={7}
                />
                <Button
                  label="Save"
                  className="w-1 submit-btn ml-2 mt-10"
                  type="submit"
                  tabIndex={8}
                  disabled={isEditDisabled}
                />
              </div>
          </form>
        </div>

        <DataTable
          value={protocolItemList}
          emptyMessage="No protocol detail found"
          scrollable
          scrollHeight="400px"
          loading={loading}
          dataKey="id"
        >
          <Column
            header="Sr.No."
            style={{ minWidth: "100px", width: "100px" }}
            body={SrNo}
          />
          <Column
            field="p_activityname"
            header="Activity"
            style={{ minWidth: "200px", width: "200px" }}
          ></Column>
          <Column
            field="p_poiname"
            header="Point of interests"
            style={{ minWidth: "200px", width: "200px" }}
          ></Column>
          <Column
            field="p_videoname"
            header="Video"
            style={{ minWidth: "200px", width: "200px" }}
          ></Column>
          <Column
            field="p_audioname"
            header="Audio"
            style={{ minWidth: "200px", width: "200px" }}
          ></Column>
          <Column
            field="p_scheduledate"
            header="Date"
            body={(rowData) => formatDate(rowData.p_scheduledate)}
            style={{ minWidth: "250px", width: "250px" }}
            showFilterMenu={false}
          ></Column>
          <Column
            field="p_scheduletime"
            header="Time"
            style={{ minWidth: "200px", width: "200px" }}
            body={TimeFormate}
          ></Column>
          <Column 
            field="p_does" 
            header={resource.does}
            style={{ minWidth: "200px", width: "200px" }}
            ></Column>
          <Column 
            field="p_donts" 
            header={resource.donts}
            style={{ minWidth: "200px", width: "200px" }}
            ></Column>
          <Column
            field="Action"
            header="Action"
            style={{ minWidth: "100px" }}
            body={Action}
          ></Column>
        </DataTable>
      </div>
    </>
  );
};

export default ProtocolTabLayout;
