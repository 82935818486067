import { fetchEventSource } from "@microsoft/fetch-event-source";
import axios from "axios";
import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import remarkGfm from "remark-gfm";
import CommunicationIcon from "./Images/communication-icon.svg";
import AnswerIcon from "./Images/vedbhoomilogo.png";
import Header from "./Common/Header";
import Sidebar from "./Common/Sidebar";
import CommonValues from "./Common/Utils";
import SupportLoader from "./Common/SupportLoader";
import { relative } from "path";
import authHeader from "../../services/authHeader";


interface ChatResponse {
  answer: string;
  thoughts: string;
  dataPoints: { title: string; content: string }[];
  citationBaseUrl: string;
  error: string;
  question: string;
  transactionId: string;
  like: boolean;
  disLike: boolean;
  feedback: string;
  showFeedback: boolean;
  feedbackLoading: boolean;
}

export default function Chat() {
  const [question, setQuestion] = useState("");
  const [questionloading, setQuestionLoading] = useState(false);
  const [chatResponses, setChatResponses] = useState<ChatResponse[]>([]);
  const [avatarName, setAvatarName] = useState<string | null>("");
  const toast: any = useRef("");
  const navigate = useNavigate();
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const prevChatResponses = usePrevious<ChatResponse[]>(chatResponses);
  const [answerIndex, setAnswerIndex] = useState<number>(0);
  const [chatAnswer, setChatAnswer] = useState("");


  function usePrevious<T>(value: T): T | undefined {
    const ref = useRef<T>();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }
  useEffect(() => {
    adjustTextareaHeight();
    setAvatarName(localStorage.getItem("AvatarName"));
  }, []);

  useEffect(() => {
    if (chatResponses.length > 1) {
      scrollToBottomForQuestion();
      scrollToBottomForAnswer();
    }
  }, [chatResponses]);

  const onQuestionChange = (e: any) => {
    setQuestion(e.target.value);
    adjustTextareaHeight();
  };
  const onFeedbackChange = (transactionId: string, newFeedback: string) => {
    setChatResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.transactionId === transactionId
          ? { ...response, feedback: newFeedback }
          : response
      )
    );
  };
  const scrollToBottomForQuestion = () => {
    if (chatContainerRef.current) {
      const lastAnswerElement = document.querySelector(".last-question-block");
      if (lastAnswerElement) {
        const { offsetTop } = lastAnswerElement as HTMLElement;
        chatContainerRef.current.scrollTop = offsetTop;
      }
    }
  };
  const scrollToBottomForAnswer = () => {
    if (chatContainerRef.current) {
      const lastAnswerElement = document.querySelector(".last-answer-block");
      if (lastAnswerElement) {
        const { offsetTop } = lastAnswerElement as HTMLElement;
        chatContainerRef.current.scrollTop = offsetTop;
      }
    }
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const onEnterClick = (e: any) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      sendQuestion(question);
    }
  };
  const toggleFeedbackVisibility = (transactionId: string) => {
    setChatResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.transactionId === transactionId
          ? { ...response, showFeedback: !response.showFeedback }
          : response
      )
    );
  };
  const sendQuestion = async (que: any) => {
    if (que === "") {
      return;
    }
    const newQuestion: ChatResponse = {
      question: que,
      answer: "",
      thoughts: "",
      dataPoints: [],
      citationBaseUrl: "",
      error: "",
      transactionId: "",
      like: false,
      disLike: false,
      feedback: "",
      showFeedback: false,
      feedbackLoading: false,
    };
    setChatResponses((prevResponses: any) => [...prevResponses, newQuestion]);
    setQuestion("");
    scrollToBottomForQuestion();

    var data = JSON.stringify({
      History: [
        {
          User: que,
          chat: null,
        },
      ],
      Overrides: {
        SemanticRanker: false,
        RetrievalMode: "Vector",
        SemanticCaptions: null,
        ExcludeCategory: null,
        Top: 5,
        Temperature: null,
        PromptTemplate: null,
        PromptTemplatePrefix: null,
        PromptTemplateSuffix: null,
        SuggestFollowupQuestions: true,
      },
      LastUserQuestion: "",
      Approach: 0,
    });
    setQuestionLoading(true);

    const index = chatResponses.length;
    const token = CommonValues.GetToken();

    try {
      const UserName = localStorage.getItem("user");
      const ctrl = new AbortController();
      await fetchEventSource(
        //`${process.env.REACT_APP_NcSupport_Url}/api/ncsupportbot/chat`,
        "https://tgc27hlbz3.execute-api.ap-south-1.amazonaws.com/Prod/api/VedbhoomiSupportBot/chat",
        //"http://localhost:5122/api/VedbhoomiSupportBot/chat",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ...authHeader(),
          },
          body: JSON.stringify({ Question: que }),
          signal: ctrl.signal,
          onopen(response: Response): Promise<void> {
            if (response.status === 401) {
              CommonValues.Logout(navigate);
            } else if (response.status !== 200) {
              setQuestionLoading(false);
              toast.current.show({
                severity: "error",
                detail: "Unknown error while sending question",
                life: 3000,
              });
            }
            return Promise.resolve();
          },
          onmessage(event: any) {
            let responseObject = JSON.parse(event.data);
            // if (responseObject.Answer !== "") {
            setQuestionLoading(false);
            // }
            setChatResponses((prevResponses: any) => {
              const updatedResponses = [...prevResponses];

              const newAnswer =
                (updatedResponses[index]?.answer || "") + responseObject.Answer;

              updatedResponses[index] = {
                ...newQuestion,
                answer: newAnswer,
                transactionId: responseObject.TransactionId,
              };
              return updatedResponses;
            });

            scrollToBottomForAnswer();
          },
          onclose() {
            console.log("Connection closed by the server");
          },
          onerror(error: any) {
            setQuestionLoading(false);
          },
        }
      );
    } catch (error: any) { }
  };

  const onCopyResponseClick = (copiedData: any) => {
    navigator.clipboard
      .writeText(copiedData)
      .then(() => {
        toast.current.show({
          severity: "success",
          detail: "Copied  Successfully!",
          life: 3000,
        });
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          detail: "Unknown error occurred while coping data",
          life: 3000,
        });
      });
  };
  const onFollowupQuestionClick = (followupQuestion: any) => {
    sendQuestion(followupQuestion);
  };
  return (
    <div className="landing-page">
      <Toast ref={toast} />
          
      <div className="container-fluid chat-content">
        {chatResponses.map((response, index) => {
          const trimmedAnswer = response.answer.trim();
          let mainAnswer = trimmedAnswer;
          let followUpQuestions: string[] = [];

          if (trimmedAnswer.includes("$$FollowupQuestions$$")) {
            const answerParts = trimmedAnswer.split(
              "$$FollowupQuestions$$"
            );
            mainAnswer = answerParts[0];
            followUpQuestions = answerParts[1]
              ? answerParts[1]
                .trim()
                .split("\n")
                .filter((q) => q)
              : [];
          } else {
            const answerParts =
              trimmedAnswer.split("FollowupQuestions");
            mainAnswer = answerParts[0];
            followUpQuestions = answerParts[1]
              ? answerParts[1]
                .trim()
                .split("\n")
                .filter((q) => q)
              : [];
          }

          const isLastAnswer = index === chatResponses.length - 1;

          return (
            <div
              id="last-answer"
              className={`col-12 ${isLastAnswer && questionloading == false
                ? "last-answer-block"
                : "question-answer-block"
                } mt-3`}
            >
              <div className="ans-text-flex">
                <span className="me-1 avtar border-rounded">
                  VB
                </span>

                <span className="question-block"><b>{response.question}</b></span>

              </div>

              <div style={{ position: "relative" }}>
                <img
                  src={AnswerIcon}
                  className="me-2 "
                  height={50}
                  style={{ position: "absolute" }}
                />
                <div className="p-text mt-4" style={{ marginLeft: "50px" }}>
                  {isLastAnswer && questionloading ? (
                    <div
                      id="last-question"
                      className={`row w-100 ${isLastAnswer && questionloading
                        ? "last-question-block"
                        : "question-answer-block"
                        }`}
                    >
                      <div className="col-12">
                        <Skeleton className="mb-2"></Skeleton>
                      </div>
                      <div className="col-12">
                        <Skeleton
                          width="70%"
                          className="mb-2 d-block"
                        ></Skeleton>
                      </div>

                      <div className="col-12">
                        <Skeleton
                          width="40%"
                          className="mb-2 d-block"
                        ></Skeleton>
                      </div>
                      <div className="col-12">
                        <Skeleton
                          width="20%"
                          className="mb-2 d-block"
                        ></Skeleton>
                      </div>
                    </div>

                  ) : (
                    <>
                      <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        className=""
                      >
                        {mainAnswer}
                      </ReactMarkdown>

                      {followUpQuestions.length > 0 && (
                        <>
                          <h6>Follow-up Questions:</h6>

                          {followUpQuestions.map((question, i) => (
                            <span
                              key={i}
                              className="followup-que"
                              onClick={() =>
                                onFollowupQuestionClick(question)
                              }
                            >
                              {question}
                            </span>
                          ))}
                        </>
                      )}{" "}
                    </>
                  )}
                </div>
              </div>
            </div>
          );
        })}







        {chatResponses.length > 0 ? null : (
          <div className="main-page-title w-100">
            <h2 className=" d-flex justify-content-center align-items-center " style={{marginBottom:"150px"}}>
              How may I assist you today?
            </h2>
          </div>
        )}

      </div>

      <div className="position-relative question-text-div">
        <textarea
          name="question"
          onChange={onQuestionChange}
          value={question}
          className="form-control question-textarea mb-2"
          rows={2}
          placeholder="Ask me anything about Ramayan-in-Human-Physiology...."
          maxLength={2000}
          style={{
            minHeight: `${Math.max(2, question.length / 150)}em`,
          }}
          onKeyDown={onEnterClick}
        />
        <span className="info-msg mb-2 ">
          Support Bot can make mistakes. Check important info.
        </span>

        <img
          src={CommunicationIcon}
          onClick={() => sendQuestion(question)}
          className="communication-icon"
        />
      </div>
      {/* </div>
      </div> */}
    </div>
  );
}