import { useContext, useEffect, useRef, useState } from "react";
import HeaderWithBackButton from "../common/HeaderWIthBackButton";
import { contextStore } from "../../context/CustomStateProvider";
import UsersTable from "./UsersTables";

import AddEmployeePopup from "./AddEmployeePoup";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import EmployeeService from "../../services/EmlpoyeeServices";

const Users = () => {
  const context = useContext(contextStore);
  const resource = context.state.strings;
  const [addUserChange, setAddUserChange] = useState(false);
  const [backButton, setBackButton] = useState(false);
  const [addEmployeedPopup, setAllEmployeePopup] = useState(false);
  const [editUserData, setEditUserData] = useState();
  const [editMode, setEditMode] = useState(false);
  const toast = useRef<Toast>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [employeeDataFilter, setEmployeeDatFilter] = useState([]);
  const cardControls = [
    {
      ctrlType: "button",
      name: "btnAdd",
      title: resource.addNewEmployee,
      type: "button",
      disabled: false,
      className: "green-btn ",
      icon: "pi pi-plus",
    },
  ];

  const onButtonClick = (e: string) => {
    if (e === "btnAdd") {
      setAllEmployeePopup(true);
    } else if (e === "btnCancel") {
      setAddUserChange(false);
    }
  };
  const onBackButtonClick = () => {
    setBackButton(!backButton);
  };

  const onEditUser = (data: any) => {
    setAllEmployeePopup(true);
    setEditUserData(data);
    setEditMode(true);
  };

  const onDeleteClick = (data: any) => {
    var userid = {
      UserId: data.p_UserId,
    };

    confirmDialog({
      message: `Are you sure you want to delete entry?`,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => onUserDelete(),
    });

    const onUserDelete = () => {
      EmployeeService.DeleteUserData(userid).then((response) => {});
    };
  };

  useEffect(() => {
    setEditMode(false);
  }, []);

  const handleSearch = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const filteredEmployeeData = employeeDataFilter?.filter((employeeDataFilter: any) => {
    return (
      employeeDataFilter?.p_FirstName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      employeeDataFilter?.p_MobileNo?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      employeeDataFilter?.p_EmailId?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      employeeDataFilter?.p_DateOfJoining?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  return (
    <div className="container">
      <Toast ref={toast} />
      <AddEmployeePopup
        addEmployeedPopup={addEmployeedPopup}
        editUserData={editUserData}
        setAllEmployeePopup={setAllEmployeePopup}
        toast={toast}
        editMode={editMode}
        setEditMode={setEditMode}
      />
      <HeaderWithBackButton
        title={!addUserChange || backButton ? resource.employeeDetails : resource.addEmployee}
        controls={cardControls}
        onButtonClicked={onButtonClick}
        onBackButtonClick={onBackButtonClick}
        handleSearch={handleSearch}
        searchQuery={searchQuery}
      />
      <div className="card-section">
        <>
          <UsersTable
            onEditUser={onEditUser}
            onDeleteClick={onDeleteClick}
            addEmployeedPopup={addEmployeedPopup}
            setEmployeeDatFilter={setEmployeeDatFilter}
            filteredEmployeeData={filteredEmployeeData}
          />
        </>
      </div>
    </div>
  );
};

export default Users;
