import * as Yup from "yup";

const LoginValidationSchema = Yup.object().shape({
  userName: Yup.string().required("Please enter user name").min(3, "Username must be at least 3 characters long"),
  password: Yup.string().required("Please enter password ").min(8, "Password must be at least 8 characters long"),
});



export default LoginValidationSchema;
