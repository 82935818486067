import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import vedicTreatmentServices from  "../../../services/vedicTreatmentServices"
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";
import { contextStore } from "../../../context/CustomStateProvider";
import { Field, useFormik } from "formik";
import moment from "moment";
import { Nullable } from "primereact/ts-helpers";
import VedicTreatmentTabValidation from "../../../validation/VedicTreatmentTabValidation";
import { InputTextarea } from "primereact/inputtextarea";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import CustomTime from "../../common/CustomTime";
import { debounce } from 'lodash';



interface Therapy {
  p_therapyid: any;
  p_therapyname: string;
}

interface POI {
  p_poiid: any;
  p_poiname: string;
}


interface Therapyitem {
  srNo: number;
  p_therapylineitemid: number;
  p_therapyname: string;
  p_therapyid: string;
  p_scheduledate: string;
  p_scheduletime: string;
  p_does: string;
  p_donts: string;
}

const VedicTreatmentTabLayout = (props: any) => {
  const context = useContext(contextStore);
  const resource = context.state.strings;
  const [loading, setLoading] = useState(true);
  const [therapy, setTherapy] = useState<Therapy | null>(null);
  const [selectedTherapy, setSelectedTherapy] = useState(null);
  const [therapyId, setTherapyId] = useState([]);
  const [poiData, setpoiData] = useState<POI | null>(null);
  const [date, setDate] = useState<Nullable<Date>>(null);
  const [poiId, setpoiId] = useState([]);
  const [therapyItemList, setTherapyItemList] = useState<Therapyitem[]>([]);
  const [therapyLineItemId, setTherapyLineItemId] = useState(null);
  const [isEditDisabled, setIsEditDisabled] = useState(false);
  const [selectedPOI, setSelectedPOI] = useState(null);
  const toast = useRef<Toast>(null);
  const[editTimeValue,setEditTimeValue]=useState('');
  const[customTimeChangeValue,setCustomTimeChangeValue]=useState('');



  const getMinDate = () => {
    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0); // Set today's date to midnight to ignore time
    const prescriptionDate = props.prescriptiondate ? new Date(props.prescriptiondate) : null;
    return prescriptionDate && prescriptionDate > todayDate ? prescriptionDate : todayDate;
  };
  const [minDate, setMinDate] = useState<Date>(getMinDate);
    const currentDateTime = new Date();
  

    const convertTo12HourFormat = (time: string): string => {
      const [hoursString, minutes] = time.split(':');
      let hours = parseInt(hoursString, 10);
      let period = 'AM';
    
      if (hours >= 12) {
        period = 'PM';
        if (hours > 12) {
          hours -= 12;
        }
      } else if (hours === 0) {
        hours = 12;
      }
    
      const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes} ${period}`;
      return formattedTime;
    };


  const onEditClick = (value: any) => {

    setIsEditDisabled(false);
    const scheduletime =  convertTo12HourFormat(value.p_scheduletime);
    setEditTimeValue(scheduletime);
    const therapyDateTime = new Date(`${value.p_scheduledate}T${value.p_scheduletime}`);
    if(therapyDateTime<currentDateTime)
    {
      setIsEditDisabled(true);
    }
    setTherapyLineItemId(value.p_therapylineitemid);
    GetPOIByTherapyId(value.p_therapyid);
    formik.setFieldValue("poiId", value.p_poiid);

    const poiObject: POI = {
      p_poiid: value.p_poiid,
      p_poiname: value.p_poiname,
    };

    setpoiData(poiObject);
    setSelectedPOI(value.p_poiid);
    formik.setFieldValue("date", new Date(value.p_scheduledate));
    setDate(new Date(value.p_scheduledate));
    formik.setFieldValue("does", value.p_does);
    formik.setFieldValue("donts", value.p_donts);


    const therapyObject: Therapy = {
      p_therapyid: value.p_therapyid, 
      p_therapyname: value.p_therapyename,
    };
    
    formik.setFieldValue("therapyId", therapyObject.p_therapyid);
    setTherapy(therapyObject);
    
    setSelectedTherapy(therapyObject.p_therapyid);
    
  };

  const ConvertTime = (value : string ) => 
  {
    const [hours, minutes, seconds] = value.split(':');
    const scheduletime = new Date();
    scheduletime.setHours(Number(hours));
    scheduletime.setMinutes(Number(minutes));
    scheduletime.setSeconds(Number(seconds)); 
    return scheduletime;
  }

  const onDeleteClick = (value: any) => { 
    confirmDialog({
      message: `Are you sure you want to delete this field?`,
      header: "Confirm delete field",
      icon: "pi pi-exclamation-triangle",
      accept: () => OnDeleteMedicineLineItem(value),
    });
    
    }


  const OnDeleteMedicineLineItem = (value: any) =>{
    setLoading(false);
    const data = {
      id : value.p_therapylineitemid
    };
    vedicTreatmentServices.DeleteTherapyLine(data).then((res: any) => {
      try {
        if (res.status === 200  && res.data.p_response) {
          props.toast.current?.show({
            severity: "success",
            summary: "Sucess",
            detail: res.data.p_msg,
            life: 3000,
          });
          setLoading(false);
          GetTherapy();
        }
        else
        {
           
          setLoading(false);
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: res.data.p_msg,
            life: 3000,
          });
        }

        

        if (res.response.status === 400 && res.response.data.success === false) {
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: res.response.data.errorMessage,
            life: 3000,
          });
          setLoading(false);
        }
        if (res.response.status === 500 && res.response.data.success === false) {
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: res.response.data.errorMessage,
            life: 3000,
          });
          setLoading(false);
        }
        return res.data;
        // Redirect the user to the protected route
      } catch (error: any) { }
    })
  }


  useEffect(() => {
    setLoading(false);
    setIsEditDisabled(false);
  }, []);


  useEffect(() => {
    setMinDate(getMinDate);
  }, [props.prescriptiondate]);

  const Action = (rowData: any) => {
    return (
      <React.Fragment>
        <Button className="table-button custome-icon  edit-icon " onClick={() => onEditClick(rowData)} />
        <Button className="table-button custome-icon delete-icon" onClick={() => onDeleteClick(rowData)}></Button>
      </React.Fragment>
    );
  };

  const TimeFormate = (rowData: any) => {
    const ConvertedTime = ConvertTime(rowData.p_scheduletime);
    return (
      
      <p>{ moment(ConvertedTime).format("h:mm A")}</p>
    );
  }
  const GetTherapyByUserId = async () => {
    vedicTreatmentServices.GetVedicTherapyById().then((res: any) => {
      try {
        if (res.status === 200) {
          setTherapyId(res.data);
          //setActivityId(res.data);
        }
        return res.data;
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: "Unauthorized",
            life: 3000,
          });
          setLoading(false);
          window.location.pathname = "/";
        }
      }
    });
  };

  

  const onTherapyChange = (e: any) => {
    formik.setFieldValue("therapy", e.value.p_therapyid);
    formik.setFieldValue("therapyId", e.value.p_therapyid);

    formik.setFieldValue("poiId", null);

    setpoiData(null);
    setSelectedPOI(null);
    setTherapy(e.value);
    setSelectedTherapy(e.value.p_therapyid);
    GetPOIByTherapyId(e.value.p_therapyid);
  };

  const GetPOIByTherapyId = async (value: any) => {
    const data = {
      TherapyId: value,
    };

    vedicTreatmentServices
      .GetPOIByTherapyId(data)
      .then((res: any) => {
        if (res.status === 200) {
          setpoiId(res.data);
        }
      })
      .catch(() => {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong",
          life: 3000,
        });
      });
  };


  const SrNo = (rowData: any, index: any) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{index.rowIndex + 1}</span>
      </div>
    );
  };



 useEffect(()=>{
      setCustomTimeChangeValue(customTimeChangeValue);
      formik.setFieldValue('customTimeChangeValue', customTimeChangeValue);
 },[customTimeChangeValue])
  

  

  useEffect(() => {
    //GetActivityByUserId();
    GetTherapyByUserId();
    GetTherapy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const subTherapyValueTemplate = (option: Therapy) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.p_therapyname}</div>
        </div>
      );
    }
  };
  const therapyTemplate = (option: Therapy) => {
    return (
      <div className="flex align-items-center">
        <div>{option.p_therapyname}</div>
      </div>
    );
  };
  
  const subPOIItemTemplate = (option: POI) => {
    return (
      <div className="flex align-items-center">
        <div>{option?.p_poiname}</div>
      </div>
    );
  };

  const POITemplate = (option: POI) => {
    return (
      <div className="flex align-items-center">
        <div>{option?.p_poiname}</div>
      </div>
    );
  };
  
  const onPOIChange = (e: any) => {
    formik.setFieldValue("poiId", e.value.p_poiid);
    setpoiData(e.value);
    setSelectedPOI(e.value.p_poiid);
    
  };

  
  const onCancel = () => {
    setTherapy(null);
    setpoiId([]);
    setSelectedTherapy(null);
    setTherapyLineItemId(null);
    setDate(null);
    formik.resetForm();
    setIsEditDisabled(false);
    setEditTimeValue(' ');

   };
  
  
  const handleSubmit = (values: any) => {
   
    setLoading(true);
    const treatmentDate = moment(date).format("YYYY-MM-DD") as any;
    const data = {
      therapylineitemid: therapyLineItemId,
      prescriptionid: props.prescriptionid ? props.prescriptionid : props.editPrescriptionid,
      therapyid: therapy?.p_therapyid,
      poiid : formik.values.poiId,
      scheduledate: treatmentDate,
      scheduletime: customTimeChangeValue,
      does: formik.values.does,
      donts: formik.values.donts,
    };

    vedicTreatmentServices.SaveTherapy(data).then((res: any) => {
      try {
        if (res.status === 200  && res.data.p_response) {
          props.toast.current?.show({
            severity: "success",
            summary: "Sucess",
            detail: res.data.p_msg,
            life: 3000,
          });
          setLoading(false);
          
          onCancel();
   
          GetTherapy();
        }
        else
        {
           
          setLoading(false);
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: res.data.p_msg,
            life: 3000,
          });
        }

        

        if (res.response.status === 400 && res.response.data.success === false) {
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: res.response.data.errorMessage,
            life: 3000,
          });
          setLoading(false);
        }
        if (res.response.status === 500 && res.response.data.success === false) {
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: res.response.data.errorMessage,
            life: 3000,
          });
          setLoading(false);
        }
        return res.data;
        // Redirect the user to the protected route
      } catch (error: any) { }
    });
};

const formik = useFormik({
  initialValues: {
    therapyId: "",
    poiId: "",
    date: "",
    does: "",
    donts: "",
    customTimeChangeValue: "",
  },
  validationSchema: VedicTreatmentTabValidation,
  onSubmit: handleSubmit,
  
});


  const GetTherapy = () => {
    const data = {
      Prescriptionid: props.prescriptionid ? props.prescriptionid : props.editPrescriptionid,
    };
    vedicTreatmentServices.GetTherapy(data).then((res: any) => {
      
      setTherapyItemList(res.data);
      setLoading(false);
    });
  };
  
  const formatDate = (value: string) => {
    const date = new Date(value);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }; 
  
  return (
    <>
      <Toast ref={props.toast} />
      {" "}
      <div className="table-section">
        <div className="gray-bg">
          <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-wrap  ">
              <div className="col-3 relative">
                <label htmlFor="therapyId" className="block text-900 font-medium mb-2">
                  {resource.therapy} <span style={{ color: "red" }}>*</span>
                </label>
                <Dropdown
                  value={therapy}
                  onChange={(e) => onTherapyChange(e)}
                  options={therapyId}
                  placeholder="Select Therapy"
                  valueTemplate={subTherapyValueTemplate}
                  itemTemplate={therapyTemplate}
                  className="w-full"
                  disabled={isEditDisabled}
                  autoFocus={true}
                  tabIndex={1}
                />
                {formik.errors.therapyId && formik.touched.therapyId && <div className="text-red-500 text-xs">{formik.errors.therapyId}</div>}{" "}

              </div>
              <div className="col-3 relative">
                <label
                  htmlFor="poiId"
                  className="block text-900 font-medium mb-2"
                >
                  {resource.pointofInterest}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Dropdown
                  value={poiData}
                  options={poiId}
                  disabled={!selectedTherapy || isEditDisabled}
                  onChange={(e) => onPOIChange(e)}
                  placeholder="Select POI"
                  className="p-column-filter"
                  itemTemplate={subPOIItemTemplate}
                  valueTemplate={POITemplate}
                  tabIndex={2}
                  filter
                  filterBy="p_poiname"
                />
                {formik.errors.poiId && formik.touched.poiId && (
                  <div className="text-red-500 text-xs">
                    {formik.errors.poiId}
                  </div>
                )}
              </div>
              <div className="col-3 relative">
                  <label htmlFor="date" className="block text-900 font-medium mb-2">
                    {resource.date} <span style={{ color: "red" }}>*</span>
                  </label>
                  <Calendar
                    dateFormat="dd/mm/yy"
                    readOnlyInput
                    value={date}
                    id="buttondisplay"
                    placeholder="DD/MM/YYYY"
                    className="w-full"
                    showIcon
                    minDate={minDate}
                    tabIndex={2}
                    disabled={isEditDisabled}
                    onChange={(e) => {
                      formik.setFieldValue("date", e.value);
                      setDate(e.value);
                    }}
                  />
                  {formik.errors.date && formik.touched.date && <div className="text-red-500 text-xs">{formik.errors.date}</div>}{" "}
                </div>
                <div className="col-3 relative">
                  <label htmlFor="time" className="block text-900 font-medium mb-2">
                    {resource.time} <span style={{ color: "red" }}>*</span>
                  </label>
                  <CustomTime    editTimeValue={editTimeValue}  isDisabled={isEditDisabled}
                  customTimeChangeValue={customTimeChangeValue} setCustomTimeChangeValue={setCustomTimeChangeValue}
                  />

       {formik.errors.customTimeChangeValue && formik.touched.customTimeChangeValue && (
        <div className="text-red-500 text-xs">{formik.errors.customTimeChangeValue}</div>
      )}
         </div>
            </div>

            <div className=" flex ml-1 mt-1 mb-1">
              <div className="col-4 relative">
                <label htmlFor="does" className="block font-medium mb-2">
                  {resource.does}
                </label>
                <InputTextarea
                  id="does"
                  className="w-full mb-5"
                  value={formik.values.does}
                  rows={3}
                  tabIndex={4}
                  disabled={isEditDisabled}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  placeholder="Enter does"
                />
              {formik.errors.does && formik.touched.does && <div className="text-area-red-does-donts-bottom text-xs">{formik.errors.does}</div>}

              </div>
              <div className="col-4 relative">
                <label htmlFor="donts" className="block font-medium mb-2">
                  {resource.donts}
                </label>
                <InputTextarea
                  id="donts"
                  className="w-full mb-5"
                  value={formik.values.donts}
                  rows={3}
                  tabIndex={5}
                  disabled={isEditDisabled}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  placeholder="Enter donts"
                />
              {formik.errors.donts && formik.touched.donts && <div className="text-area-red-does-donts-bottom text-xs">{formik.errors.donts}</div>}

              </div>
              <div className="col-4 flex justify-content-end cancel-submit-btn mt-6">
                <Button label="Cancel" className="cancel-btn mt-4 mr-2 " type="reset" onClick={onCancel} tabIndex={6}/>
                <Button label="Save" className="w-3 green-btn mt-4" type="submit" tabIndex={7} disabled={isEditDisabled}/>
              </div>
            </div>
          </form>
        </div>

        <DataTable value={therapyItemList} emptyMessage="No Therapy detail found" scrollable scrollHeight="400px" loading={loading} dataKey="id">
          <Column header="Sr.No." style={{ minWidth: "100px", width: "100px" }} body={SrNo} />
          <Column field="p_therapyename" header="Therapy" style={{ minWidth: "200px", width: "200px" }}></Column>
          <Column field="p_poiname" header="POI" style={{ minWidth: "200px", width: "200px" }}></Column>
          <Column field="p_scheduledate" header="Date" style={{ minWidth: "250px", width: "250px" }} showFilterMenu={false} body={(rowData) => formatDate(rowData.p_scheduledate)}></Column>
          <Column field="p_scheduletime" header="Time" style={{ minWidth: "250px", width: "250px" }} showFilterMenu={false} body={TimeFormate}></Column>
          <Column field="p_does" header={resource.does} style={{ minWidth: "250px", width: "250px" }} showFilterMenu={false}></Column>
          <Column field="p_donts" header={resource.donts} style={{ minWidth: "200px", width: "200px" }}></Column>
          <Column field="Action" header="Action" style={{ minWidth: "100px" }} body={Action}></Column>
        </DataTable>
      </div>
    </>
  );
};

export default VedicTreatmentTabLayout;
