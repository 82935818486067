import axios from "axios";
import authHeader from "./authHeader";

import * as XLSX from 'xlsx';

const ReportDownload = async (Req :any) => {
    try {
      const data = Req.data;

            // Convert JSON data to a worksheet
            const worksheet = XLSX.utils.json_to_sheet(data);

            // Create a new workbook and append the worksheet
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

            // Generate Excel file
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

            // Create a Blob from the buffer
            const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Create a download link and trigger download
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
           // link.download = 'UserWiseReport.xlsx';
           link.download = Req.name;
            link.click();
    } catch (error) {
      return error;
    }
  };

  const AreaWiseVisitor = async (data: any) => { 
    try {
      const response = await axios.post(process.env.REACT_APP_BASE_PROTOCOL_ENDPOINT + `api/Report/AreaWiseVisitor`, data, {
        headers: {
          "Content-Type": "application/json",
          ...authHeader(),
        },
  
      });
      return response;
    } catch (error) {
      return error;
    }
  };



  const ReportService = {
    ReportDownload,
    AreaWiseVisitor

};

export default ReportService;