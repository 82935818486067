import { Dialog } from "primereact/dialog";
import { useContext, useEffect, useState,useRef } from "react";
import { contextStore } from "../../context/CustomStateProvider";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { MultiSelect } from "primereact/multiselect";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import AddNewAreaValidation from "../../validation/AddNewAreaValidation";
import AddNewAreaService from "../../services/addNewArea";
import { InputSwitch } from "primereact/inputswitch";

interface AddAreaValues {
  AreaName: string;
  Description: string;
  AreaManager: string[];
  IsActive: boolean;
}

const AddAreaPopup = (props: any) => {
  const context = useContext(contextStore);
  const resource = context.state.strings;
  const [submitting, setSubmitting] = useState(false);
  const [selectedCities, setSelectedCities] = useState<any[]>([]);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    GetAllUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.editMode && props.isEditSubArea !== undefined) {
      GetAreaById(props.isEditSubArea.p_areaid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editMode]);

  const handleSubmit = async (values: AddAreaValues) => {
    setSubmitting(true);
    try {
      if (formik.isValid) {
        await AddNewArea(values);
      }
    } catch (error) {
      console.error("Failed to add area:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const AddNewArea = async (values: AddAreaValues) => {
    setSubmitLoading(true);
    const data = {
      areaname: values.AreaName,
      description: values.Description,
      userid: values.AreaManager.map((manager: any) => manager.p_userid),
      areacode: "New",
      areaid: props.editMode ? props.isEditSubArea.p_areaid : null,
      isactive: formik.values.IsActive,
      isstaying:formik.values.IsStaying
    };

    AddNewAreaService.AddNewArea(data)
      .then((res: any) => 
      {
        setSubmitLoading(false);
        if (res.status === 200) {
          if (res.data.response) {
            props.toast.current?.show({ severity: "success", summary: "Success", detail: res.data.msg, life: 3000 });
            props.setaddNewAreaPopup(false);
            formik.resetForm();
          } else {
            props.toast.current?.show({ severity: "warn", summary: "Warning", detail: res.data.msg, life: 3000 });
          }          
        } 
        else if (res.response.status === 400 && res.response.data.success === false) {
          props.toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: res.response.data.errorMessage,
            life: 3000,
          });
        }
          else {
          props.toast.current?.show({ severity: "warn", summary: "Warning", detail: "Failed to add area", life: 3000 });
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.error("AddNewAreaService error:", error);
        props.toast.current?.show({ severity: "error", summary: "Error", detail: "Something went wrong", life: 3000 });
      });
  };



  const GetAllUsers = () => {
    AddNewAreaService.GetAllUsers()
      .then((res: any) => {
        if (res.status === 200) {
          setSelectedCities(res.data);
        } else {
          props.toast.current?.show({ severity: "warn", summary: "Warning", detail: "Failed to get area", life: 3000 });
        }
      })
      .catch((error) => {
        console.error("AddNewAreaService error:", error);
        props.toast.current?.show({ severity: "error", summary: "Error", detail: "Something went wrong", life: 3000 });
      });
  };

  const GetAreaById = (values: any) => {
    const data = {
      Id: values,
    };

    AddNewAreaService.GetAllArea(data)
      .then((res: any) => {
        if (res.status === 200) {
          setTimeout(() => {
            formik.setValues({
              AreaName: res.data[0].p_areaname,
              Description: res.data[0].p_description,
              AreaManager: res.data[0].managers.map((manager: any) => ({ p_userid: manager.p_userid })),
              IsActive: res.data[0].p_isactive,
              IsStaying:res.data[0].p_isstaying
            });
          }, 20);
        } else {
          props.toast.current?.show({ severity: "warn", summary: "Warning", detail: "Failed to get area", life: 3000 });
        }
      })
      .catch((error) => {
        console.error("AddNewAreaService error:", error);
        props.toast.current?.show({ severity: "error", summary: "Error", detail: "Something went wrong", life: 3000 });
      });
  };

  const initialValues = {
    AreaName: "",
    Description: "",
    AreaManager: [],
    IsActive: true,
    IsStaying:false
  };

  const formik = useFormik({
    initialValues,
    validationSchema: AddNewAreaValidation,
    onSubmit: handleSubmit,
  });

  const onCancelClick = () => {
    formik.resetForm();
    props.setaddNewAreaPopup(false);
  };
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (props.addNewAreaPopup) {
        setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 0);
    }
  }, [props.addNewAreaPopup]);

  const header = (
    <div className="p-d-flex p-jc-between p-ai-center">
      <span>{resource.AddNewArea}</span>
      <Button
        icon="pi pi-times"
        className="p-button-rounded p-button-text popup-close-button"
        onClick={() => {
          props.setaddNewAreaPopup(false);
          onCancelClick();
        }}
      />
    </div>
  );

  return (
    <div>
      <Dialog
        className="popup popup-card-layout"
        header={header}
        draggable={false}
        visible={props.addNewAreaPopup}
        style={{ width: "40vw" }}
        onHide={() => {
          props.setaddNewAreaPopup(false);
          onCancelClick();
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="card-wrapper">
            <div className="flex mb-2">
              <div className="col-6 relative">
                <label htmlFor="AreaName" className="block text-900 font-medium mb-2 position-relative">
                  {resource.areaName} <span className="text-red">*</span>
                </label>
                <InputText
                  id="AreaName"
                  type="text"
                  placeholder="Enter area name"
                  className="w-full mb-2"
                  value={formik.values.AreaName}
                  onChange={formik.handleChange}
                  autoFocus={true}
                  ref={inputRef}                  
                  tabIndex={1}
                />
                {formik.errors.AreaName && formik.touched.AreaName && <div className="text-red-500 text-xs">{formik.errors.AreaName}</div>}
              </div>
              <div className="col-6 relative">
                <label htmlFor="Description" className="block text-900 font-medium mb-2">
                  {resource.description}
                </label>
                <InputTextarea
                  id="Description"
                  placeholder="Enter description"
                  className="w-full mb-2"
                  rows={3}
                  value={formik.values.Description}
                  onChange={formik.handleChange}
                  tabIndex={2}
                />
                {formik.errors.Description && formik.touched.Description && <div className="text-area-red-bottom text-xs">{formik.errors.Description}</div>}
              </div>
            </div>
          </div>
          <div className="card-wrapper">
            <div className="flex flex-wrap">
              <div className="col-6 relative">
                <label htmlFor="AreaManager" className="block text-900 font-medium mb-2">
                  {resource.assignManager} <span className="text-red">*</span>
                </label>
                <MultiSelect
                  id="AreaManager"
                  value={formik.values.AreaManager.map((manager: any) => manager.p_userid)}
                  onChange={(e) => {
                    const selectedManagerIds = e.value;
                    const selectedManagers = selectedCities?.filter((manager) => selectedManagerIds.includes(manager.p_userid));
                    formik.setFieldValue("AreaManager", selectedManagers);
                  }}
                  options={selectedCities}
                  optionValue="p_userid"
                  optionLabel="p_username"
                  placeholder="Select manager"
                  className="w-full "
                  disabled={props.isAreaAdmin}
                  tabIndex={3}
                  filter
                  filterBy="p_username"
                />

                {formik.errors.AreaManager && formik.touched.AreaManager && <div className="text-red-500 text-xs">{formik.errors.AreaManager}</div>}
              </div>
              <div className="col-6 flex">
                <div className="col-5">
                <label className="block text-900 font-medium mb-2">{resource.status}</label>
                <div className="custom-inputswitch">
                  <InputSwitch
                    id="IsActive"
                    checked={formik.values.IsActive}
                    onChange={(e) => formik.setFieldValue("IsActive", e.target.value)}
                    tabIndex={4}
                  />
                </div>
                </div>
                <div className="col-3">
                <label className="block text-900 font-medium mb-2">{resource.staying}</label>
                <div className="custom-inputswitch">
                  <InputSwitch
                    id="IsStaying"
                    checked={formik.values.IsStaying}
                    onChange={(e) => formik.setFieldValue("IsStaying", e.target.value)}
                    tabIndex={5}
                  />
                </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cancel-submit-btn mt-8">
            <Button label="Cancel" className="cancel-btn" onClick={onCancelClick} type="reset" tabIndex={6} />
            <Button label="Submit" className="submit-btn ml-2" type="submit" loading={submitLoading} disabled={submitting} tabIndex={7} />
          </div>
        </form>
      </Dialog>
    </div>
  );
};

export default AddAreaPopup;
