import * as Yup from "yup";

const ProtocolValidationSchema = Yup.object().shape({
    prescriptionname: Yup.string().required("Please enter prescription name.")
    .max(50, 'prescription cannot be longer than 50 characters.')
   .matches(/^[A-Za-z0-9\s()-.]+$/, 'Please enter prescription using letters and digits only.'),
   description: Yup.string()
   .matches(/^[A-Za-z0-9\s,-.?!/()%&']+$/, 'Please enter description using letters and digits only.')
   .max(500, 'Description cannot be longer than 500 characters.'),
    prescriptiondate: Yup.string().required("Please select date of treatment "),
});

export default ProtocolValidationSchema;
