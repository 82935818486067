import axios from "axios";
import authHeader from "./authHeader";


const GetPOIData = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_CMS_ENDPOINT  + `api/POI/GetPOI`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};


const UpdateTherapy = async (data: any) => {
  try {
    const response = await axios.put(process.env.REACT_APP_BASE_CMS_ENDPOINT + `api/Therapy/UpdateTherapy`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const GetTherapy = async (data:any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_CMS_ENDPOINT + `api/Therapy/GetTherapy`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};


const AddTherapy = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_CMS_ENDPOINT + `api/Therapy/RegisterTherapy`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },

    });
    return response;
  } catch (error) {
    return error;
  }
};

const DeleteTherapy = async (data: any) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_CMS_ENDPOINT + `api/Therapy/DeleteTherapy`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },

    });
    return response;
  } catch (error) {
    return error;
  }
};

const activityServices = {
  
  GetPOIData,  
  AddTherapy,
  GetTherapy,
  UpdateTherapy,
  DeleteTherapy

};

export default activityServices;
